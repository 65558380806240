import React from "react";
export const DepotIcon = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0012 7.375C15.4567 7.375 14.9124 7.55212 14.4625 7.90643H14.4615L8.82417 12.3486C7.96907 13.0225 7.46875 14.0522 7.46875 15.1409V23.7159C7.46875 24.6236 8.2201 25.375 9.12784 25.375H22.8746C23.7823 25.375 24.5337 24.6236 24.5337 23.7159V15.1409C24.5337 14.0522 24.0333 13.0225 23.1782 12.3486L17.5399 7.90643C17.09 7.55212 16.5457 7.375 16.0012 7.375ZM16.0012 8.79337C16.2334 8.79337 16.4656 8.87053 16.6604 9.0239L22.2987 13.466C22.8122 13.871 23.1116 14.4873 23.1116 15.1409V23.7159C23.1116 23.8558 23.0144 23.9529 22.8746 23.9529H21.6895V20.4033C21.6895 19.4956 20.9382 18.7442 20.0304 18.7442H19.3194V15.663C19.3194 14.7553 18.568 14.0039 17.6603 14.0039H14.3421C13.4344 14.0039 12.683 14.7553 12.683 15.663V18.7442H11.972C11.0643 18.7442 10.3129 19.4956 10.3129 20.4033V23.9529H9.12784C8.98798 23.9529 8.89083 23.8558 8.89083 23.7159V15.1409C8.89083 14.487 9.18981 13.8701 9.70371 13.4651L15.342 9.0239C15.5368 8.87053 15.769 8.79337 16.0012 8.79337ZM14.3421 15.426H17.6603C17.8002 15.426 17.8973 15.5232 17.8973 15.663V18.7442H14.1051V15.663C14.1051 15.5232 14.2023 15.426 14.3421 15.426ZM11.972 20.1663H15.2902V23.9529H11.735V20.4033C11.735 20.2634 11.8321 20.1663 11.972 20.1663ZM16.7122 20.1663H20.0304C20.1703 20.1663 20.2674 20.2634 20.2674 20.4033V23.9529H16.7122V20.1663Z"
        fill={color || "url(#paint0_linear_9034_141561)"}
      />
      <defs>
        <linearGradient id="paint0_linear_9034_141561" x1="7.46875" y1="16.375" x2="24.5337" y2="16.375" gradientUnits="userSpaceOnUse">
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

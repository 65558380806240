import {
  LabelName,
  ChangeReason,
  Email,
  TickOrange,
  Locked,
  UserOrange,
  FirstName,
  CheckReady,
  LockedPurple,
} from '../../../HemeIconLibrary';

const columns = [
  {
    name: 'Sponsor',
    sortable: true,
    cell: (row) => row.name,
  },
  {
    name: 'Study',
    sortable: true,
    cell: (row) => row.study?.name,
  },
  {
    name: 'Site',
    sortable: true,
    cell: (row) => row.siteCode,
  },
];

export const addPortalUserFields = [
  {
    label: 'First Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter first name',
    required: true,
    name: 'firstName',
  },
  {
    label: 'Last Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter last name',
    required: true,
    name: 'lastName',
  },
  {
    label: 'Email',
    icon: <Email />,
    type: 'email',
    initialValue: '',
    placeholder: 'Enter Email',
    required: true,
    fluid: true,
    name: 'email',
  },
  {
    name: 'isActive',
    label: 'Active',
    icon: <CheckReady purple width="18" height="18" />,
    type: 'switch',
    initialValue: true,
    required: false,
  },
  {
    name: 'isLocked',
    label: 'Locked',
    icon: <LockedPurple />,
    type: 'switch',
    initialValue: false,
    required: false,
  },
  {
    name: 'siteIds',
    label: 'Site Access',
    type: 'formTable',
    fluid: true,
    initialValue: [],
    // columns: columns,
    required: true,
  },

  {
    name: 'changeReason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
  },
];

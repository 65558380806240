import React from 'react';
export const DocSideIcon = ({ width }) => {
  return (
    <svg
      width={width || '16'}
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5319 1.30762H6.60877C3.54997 1.30762 1.07031 3.78727 1.07031 6.84608V15.1538C1.07031 18.2126 3.54997 20.6922 6.60877 20.6922H13.5319C16.5907 20.6922 19.0703 18.2126 19.0703 15.1538V6.84608C19.0703 3.78727 16.5907 1.30762 13.5319 1.30762Z"
        stroke="url(#paint0_linear_11719_277453)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2257 7.84619C14.7779 7.84619 15.2257 7.39848 15.2257 6.84619C15.2257 6.29391 14.7779 5.84619 14.2257 5.84619V7.84619ZM5.91797 5.84619C5.36568 5.84619 4.91797 6.29391 4.91797 6.84619C4.91797 7.39848 5.36568 7.84619 5.91797 7.84619V5.84619ZM14.2257 12.0008C14.7779 12.0008 15.2257 11.5531 15.2257 11.0008C15.2257 10.4485 14.7779 10.0008 14.2257 10.0008V12.0008ZM5.91797 10.0008C5.36568 10.0008 4.91797 10.4485 4.91797 11.0008C4.91797 11.5531 5.36568 12.0008 5.91797 12.0008V10.0008ZM10.0718 16.1534C10.6241 16.1534 11.0718 15.7057 11.0718 15.1534C11.0718 14.6011 10.6241 14.1534 10.0718 14.1534V16.1534ZM5.91797 14.1534C5.36568 14.1534 4.91797 14.6011 4.91797 15.1534C4.91797 15.7057 5.36568 16.1534 5.91797 16.1534V14.1534ZM14.2257 5.84619H5.91797V7.84619H14.2257V5.84619ZM14.2257 10.0008H5.91797V12.0008H14.2257V10.0008ZM10.0718 14.1534H5.91797V16.1534H10.0718V14.1534Z"
        fill="url(#paint1_linear_11719_277453)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11719_277453"
          x1="1.07031"
          y1="10.9999"
          x2="19.0703"
          y2="10.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11719_277453"
          x1="5.91797"
          y1="10.9998"
          x2="14.2257"
          y2="10.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";

export const DeletePurple = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40078 20.8C9.74556 20.8 10.0762 20.663 10.32 20.4192C10.5638 20.1754 10.7008 19.8448 10.7008 19.5V11.7C10.7008 11.3552 10.5638 11.0246 10.32 10.7808C10.0762 10.537 9.74556 10.4 9.40078 10.4C9.056 10.4 8.72534 10.537 8.48154 10.7808C8.23775 11.0246 8.10078 11.3552 8.10078 11.7V19.5C8.10078 19.8448 8.23775 20.1754 8.48154 20.4192C8.72534 20.663 9.056 20.8 9.40078 20.8ZM22.4008 5.2H17.2008V3.9C17.2008 2.86566 16.7899 1.87368 16.0585 1.14228C15.3271 0.410892 14.3351 0 13.3008 0H10.7008C9.66644 0 8.67446 0.410892 7.94306 1.14228C7.21167 1.87368 6.80078 2.86566 6.80078 3.9V5.2H1.60078C1.256 5.2 0.92534 5.33696 0.681542 5.58076C0.437745 5.82456 0.300781 6.15522 0.300781 6.5C0.300781 6.84478 0.437745 7.17544 0.681542 7.41924C0.92534 7.66304 1.256 7.8 1.60078 7.8H2.90078V22.1C2.90078 23.1343 3.31167 24.1263 4.04306 24.8577C4.77446 25.5891 5.76644 26 6.80078 26H17.2008C18.2351 26 19.2271 25.5891 19.9585 24.8577C20.6899 24.1263 21.1008 23.1343 21.1008 22.1V7.8H22.4008C22.7456 7.8 23.0762 7.66304 23.32 7.41924C23.5638 7.17544 23.7008 6.84478 23.7008 6.5C23.7008 6.15522 23.5638 5.82456 23.32 5.58076C23.0762 5.33696 22.7456 5.2 22.4008 5.2ZM9.40078 3.9C9.40078 3.55522 9.53775 3.22456 9.78154 2.98076C10.0253 2.73696 10.356 2.6 10.7008 2.6H13.3008C13.6456 2.6 13.9762 2.73696 14.22 2.98076C14.4638 3.22456 14.6008 3.55522 14.6008 3.9V5.2H9.40078V3.9ZM18.5008 22.1C18.5008 22.4448 18.3638 22.7754 18.12 23.0192C17.8762 23.263 17.5456 23.4 17.2008 23.4H6.80078C6.456 23.4 6.12534 23.263 5.88154 23.0192C5.63775 22.7754 5.50078 22.4448 5.50078 22.1V7.8H18.5008V22.1ZM14.6008 20.8C14.9456 20.8 15.2762 20.663 15.52 20.4192C15.7638 20.1754 15.9008 19.8448 15.9008 19.5V11.7C15.9008 11.3552 15.7638 11.0246 15.52 10.7808C15.2762 10.537 14.9456 10.4 14.6008 10.4C14.256 10.4 13.9253 10.537 13.6815 10.7808C13.4377 11.0246 13.3008 11.3552 13.3008 11.7V19.5C13.3008 19.8448 13.4377 20.1754 13.6815 20.4192C13.9253 20.663 14.256 20.8 14.6008 20.8Z"
        fill="url(#paint0_linear_3263_64295)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_64295"
          x1="0.300781"
          y1="13"
          x2="23.7008"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

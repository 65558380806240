import React from "react";
export const Art = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C1.47778 0 0.94539 0.191329 0.568359 0.568359C0.191329 0.94539 0 1.47778 0 2V11C0 11.5222 0.191329 12.0546 0.568359 12.4316C0.94539 12.8087 1.47778 13 2 13H5V16C5 17.1 5.9 18 7 18H16C17.1 18 18 17.1 18 16V7C18 5.9 17.1 5 16 5H13V2C13 1.47778 12.8087 0.94539 12.4316 0.568359C12.0546 0.191329 11.5222 0 11 0H2ZM17 0L15 2L17 4V0ZM2 2H11V6L8 9L6 11H2V2ZM8 9V8C7.72461 8 7.47098 7.97412 7.23047 7.93359C7.24046 7.92624 7.25177 7.92149 7.26172 7.91406C8.13673 7.26089 8.82349 6.26897 8.96875 5H9V4.5V4H7V3H6V4H4V5H7.96094C7.82905 5.92707 7.33606 6.6097 6.66406 7.11133C6.46474 7.26012 6.24959 7.39019 6.02539 7.50195C5.36527 7.10683 5 6.53715 5 6H4C4 6.71944 4.33575 7.37031 4.88672 7.88672C4.58018 7.95277 4.27814 8 4 8V9C4.63979 9 5.32882 8.85653 5.99414 8.5957C6.57844 8.84553 7.25293 9 8 9ZM11.0996 8.09961H12.4004L14.9004 14.8008H13.4004L13 13.4004H10.5996L10.0996 14.8008H8.59961L11.0996 8.09961ZM11.8008 9.80078L10.9004 12.3008H12.5996L11.8008 9.80078ZM1 14V18L3 16L1 14Z"
        fill={color || `url(#paint0_linear_1188_30765)`}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_30771"
          x1="0"
          y1="9"
          x2="18"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};

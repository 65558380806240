import React from "react";

export const CountryInfo = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58297 0.666016C6.51214 0.666016 4.83297 2.34518 4.83297 4.41602C4.83297 6.53602 6.96414 9.18904 8.01331 10.3665C8.31664 10.7074 8.8493 10.7074 9.15263 10.3665C10.2018 9.18904 12.333 6.53602 12.333 4.41602C12.333 2.34518 10.6538 0.666016 8.58297 0.666016ZM8.58297 3.16602C9.27297 3.16602 9.83297 3.72602 9.83297 4.41602C9.83297 5.10602 9.27297 5.66602 8.58297 5.66602C7.89297 5.66602 7.33297 5.10602 7.33297 4.41602C7.33297 3.72602 7.89297 3.16602 8.58297 3.16602ZM14.4472 3.88867C14.1793 3.94371 13.966 4.17711 13.9622 4.47461C13.9589 4.76294 13.9478 5.03652 13.9362 5.12402C13.927 5.18236 13.9193 5.23984 13.9085 5.29818C13.3635 8.46318 10.158 11.7602 9.76136 12.1569C9.11052 12.8077 8.05542 12.8077 7.40459 12.1569C7.08792 11.8402 4.98125 9.67652 3.86292 7.20736V7.20573C3.71292 6.87323 3.58059 6.53661 3.47392 6.19661C3.36559 5.85161 2.92378 5.76143 2.68128 6.0306C1.74294 7.07143 0.95064 8.11734 0.79814 8.42318C0.563973 8.89401 0.657345 9.23277 0.796512 9.44694L3.1663 13.3971V14.8327C3.1663 15.1568 3.35959 15.4545 3.65459 15.5911C6.50042 16.9086 7.67515 17.3783 8.24931 17.3783C8.60014 17.3783 8.72764 17.2021 8.87431 16.9388C9.01848 16.7305 9.64515 16.0513 10.0201 15.7246C10.4543 15.9188 11.2819 16.3966 11.7177 16.6475C12.5144 17.1066 12.7776 17.2541 13.0084 17.3066C13.7359 17.4683 14.7848 16.7603 15.3473 16.3203C15.9581 15.842 15.6139 15.2396 15.178 14.4762C15.0989 14.3371 14.9878 14.1394 14.9078 13.9961C15.1578 13.6403 15.7237 12.9919 16.0879 12.5752C17.2404 11.2552 17.6713 10.761 17.0888 10.0768L15.9674 8.9554C16.9682 7.7804 16.9933 7.69641 17.0433 7.52474C17.2916 6.67307 17.7504 5.10577 16.4671 4.49577C16.2813 4.38327 15.8588 4.19848 14.7272 3.89681C14.6313 3.87119 14.5365 3.87033 14.4472 3.88867ZM5.6663 12.3327C6.1263 12.3327 6.49964 12.706 6.49964 13.166C6.49964 13.626 6.1263 13.9993 5.6663 13.9993C5.2063 13.9993 4.83297 13.626 4.83297 13.166C4.83297 12.706 5.2063 12.3327 5.6663 12.3327Z"
        fill="url(#paint0_linear_3377_148438)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3377_148438"
          x1="9.00266"
          y1="17.2679"
          x2="9.00266"
          y2="0.775536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7006A" />
          <stop offset="1" stopColor="#EAC4BE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
export const IncotermIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20598 3.00001H9.88665C9.92487 3.00001 9.96287 3.00245 10.0007 3.00734C11.4989 3.11211 12.6622 4.35547 12.6673 5.85734V10.1433C12.6618 11.7247 11.3767 13.003 9.79532 13H6.20598C4.62432 13.003 3.33913 11.7243 3.33398 10.1427V5.85734C3.33913 4.27568 4.62432 2.99705 6.20598 3.00001Z"
        stroke="url(#paint0_linear_2850_40237)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6673 6.60781C13.0815 6.60781 13.4173 6.27203 13.4173 5.85781C13.4173 5.4436 13.0815 5.10781 12.6673 5.10781V6.60781ZM10.0007 5.85781H9.25065C9.25065 6.27203 9.58644 6.60781 10.0007 6.60781V5.85781ZM10.7507 3.00781C10.7507 2.5936 10.4149 2.25781 10.0007 2.25781C9.58644 2.25781 9.25065 2.5936 9.25065 3.00781H10.7507ZM9.33398 9.08381C9.7482 9.08381 10.084 8.74803 10.084 8.33381C10.084 7.9196 9.7482 7.58381 9.33398 7.58381V9.08381ZM5.33398 7.58381C4.91977 7.58381 4.58398 7.9196 4.58398 8.33381C4.58398 8.74803 4.91977 9.08381 5.33398 9.08381V7.58381ZM7.33398 7.08381C7.7482 7.08381 8.08398 6.74803 8.08398 6.33381C8.08398 5.9196 7.7482 5.58381 7.33398 5.58381V7.08381ZM5.33398 5.58381C4.91977 5.58381 4.58398 5.9196 4.58398 6.33381C4.58398 6.74803 4.91977 7.08381 5.33398 7.08381V5.58381ZM10.0007 11.0838C10.4149 11.0838 10.7507 10.748 10.7507 10.3338C10.7507 9.9196 10.4149 9.58381 10.0007 9.58381V11.0838ZM5.33398 9.58381C4.91977 9.58381 4.58398 9.9196 4.58398 10.3338C4.58398 10.748 4.91977 11.0838 5.33398 11.0838V9.58381ZM12.6673 5.10781H10.0007V6.60781H12.6673V5.10781ZM10.7507 5.85781V3.00781H9.25065V5.85781H10.7507ZM9.33398 7.58381H5.33398V9.08381H9.33398V7.58381ZM7.33398 5.58381H5.33398V7.08381H7.33398V5.58381ZM10.0007 9.58381H5.33398V11.0838H10.0007V9.58381Z"
        fill="url(#paint0_linear_2850_40237)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_40237"
          x1="2.5"
          y1="8.00001"
          x2="13.5"
          y2="8.00001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';
import DataTable from 'react-data-table-component';
import { Alert as AlertBootstrap } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { Button, Pagination, HemaValue, FormCheckbox } from '../../../utils';
import { KitRequestBuilding } from '../../../components/Tables';
import { BuildValidator } from '../../../components/Formik/allFormValidation';
import { CreateAction, CreateItemPurple, BuildingTab, StagingTab, LockFile, CheckAction, Assign } from '../../../HemeIconLibrary';
import { KitAssemblyPreparation } from '../../../components/Formik/AllForms/kitAssemblyPreparation';
import { ScreeningKitBuild, Unassignkit, assignLineItem } from '../../../components/Formik/AllForms/ScreeningKitBuilder';
import { getReadyInvenotryItemAction } from '../../../Actions/order';
import { setActivestagingForbuild, setAssemblyForStagingBuild } from '../../../Store/reducers/orders';
import { setFormLoaderReducer, setFormCloseReducer, showSuccessReducer, editFormReducer, showErrorReducer, setBuildTemplate } from '../../../Store/reducers/uiSettings';
import { AssignPreparationKitAction, getAllbuildPreparationAction, unAssignLineItemAction, getAssemblyforItemAction, AssignPreLineItemAction } from '../../../Actions/order';
import { HemaDateFormat } from '../../../helpers/commonHelper';
import { useDispatch, useSelector } from 'react-redux';
const RequestPreparation = (props) => {
  const {
    subTab,
    setsubTab,
    orders,
    setformName,
    setFormIcon,
    setokBtnText,
    setCancelBtnText,
    setForm,
    setCTA,
    buildPreparation,
    setUpdatedData,
    stagingPreparation,
    setokBtnIcon,
    setstagingPreparation,
    setFormValidation,
    buildAssignkitItems,
  } = props;
  const dispatch = useDispatch();
  const { common, uiSettings } = useSelector((state) => state);

  const unAssignCustomCTA = (row) => {
    setCTA(() => async (payload) => {
      console.log('row', row);
      dispatch(setFormLoaderReducer(true));
      var resp;
      if (row?.buildType === 'build-assign') {
        resp = await unAssignLineItemAction(orders.activeOrder?.id, row?.id, 'un-assign');
      } else {
        resp = await unAssignLineItemAction(orders.activeOrder?.id, row?.id, 'unassign-custom-line-item');
      }

      if (resp?.status === 200) {
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        getAllbuildPreparationAction('building', orders.activeOrder?.id);
        getAllbuildPreparationAction('Staging', orders.activeOrder?.id);
        dispatch(showSuccessReducer(`${row.item.name} Item successfully unassigned.`));
      }
    });
  };
  const buildPrepLineItemCTA = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));

      const resp = await AssignPreLineItemAction(orders.activeOrder?.id, row.id);
      if (resp?.status === 200) {
        getAllbuildPreparationAction('building', orders.activeOrder?.id);
        getAllbuildPreparationAction('Staging', orders.activeOrder?.id);
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Kit successfully built.`));
      }
    });
  };

  return (
    <div className="w-full pb-[10px]">
      <Tabs
        defaultActiveKey={subTab}
        id="uncontrolled-tab-example"
        className="mb-4"
        onSelect={(key) => {
          setsubTab(key);
        }}
      >
        <Tab eventKey="building" title={<div className="flex items-center title-icon gap-[7px]">{subTab === 'building' ? <BuildingTab purple /> : <BuildingTab />} Building</div>}>
          {orders?.allBuildPreparation ? (
            buildPreparation?.length > 0 ? (
              <div className="kit-table-header-center">
                <DataTable
                  className="border-t border-[#DEE2E6] "
                  data={buildPreparation}
                  columns={[
                    ...KitRequestBuilding,
                    {
                      name: <HemaValue text="Actions" className="font-normal text-xs pr-[48px]" color="text-[#000000]" />,
                      cell: (row) => {
                        return (
                          <div className="flex-grow four pr-[14px]">
                            {common?.permissions?.['Kit Order Process']?.update && (
                              <div className="flex justify-end gap-[16px] meta">
                                <Button
                                  icon={row?.buildType !== 'assign-custom-line-item' ? <CreateAction color="#775FD5" /> : <CheckAction color="#775FD5" />}
                                  // Tick icon also here icon={<CheckAction />}
                                  color="text-white"
                                  bg="bg-bgActionDots"
                                  disabled={row?.remainingQuantity === 0 ? true : false}
                                  cta={async () => {
                                    // const buildType = 'assign-custom-line-item';
                                    if (row?.buildType === 'build-assign') {
                                      dispatch(
                                        editFormReducer({
                                          row,
                                          availableToBuild: row?.remainingQuantity,
                                        }),
                                      );
                                      buildAssignkitItems(row);
                                    } else if (row?.buildType === 'assign-custom-line-item') {
                                      setformName('Line Item Preparation');
                                      setFormIcon(<CreateItemPurple />);
                                      setokBtnText('Confirm');
                                      setCancelBtnText('Cancel');
                                      assignLineItem[1].label = row?.item?.name;
                                      assignLineItem[2].initialValue = row?.remainingQuantity || '0';
                                      // ScreeningKitBuild[2].initialValue =
                                      //   selectedInventory;
                                      setFormValidation(() => (error, values, store) => {
                                        BuildValidator(error, values, store);
                                      });
                                      setUpdatedData(assignLineItem);
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: 'build-custom-line-kit',
                                        }),
                                      );
                                      buildPrepLineItemCTA(row);
                                    } else {
                                      dispatch(
                                        setBuildTemplate({
                                          open: true,
                                          orderItemDetail: row,
                                        }),
                                      );
                                    }
                                  }}
                                />
                                <Button
                                  icon={<LockFile color="#F87FB1" />}
                                  color="text-white"
                                  bg="bg-bgActionDots"
                                  disabled={row?.builtQuantity === 0 ? true : false}
                                  cta={() => {
                                    setformName('Unassign');
                                    setFormIcon(<CreateItemPurple />);
                                    setokBtnText('Confirm');
                                    setCancelBtnText('Cancel');
                                    Unassignkit[1].initialValue = row?.item.name;
                                    Unassignkit[2].initialValue = row?.builtQuantity || 0;
                                    setUpdatedData(Unassignkit);
                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: 'un-assign-custom-line',
                                      }),
                                    );
                                    unAssignCustomCTA(row);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        );
                      },
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    },
                  ]}
                  pagination
                  // onSort={(row, direction, sorted) => {
                  //   setfilteredData(sortedData(row.sortId, direction, sorted));
                  // }}
                  paginationComponent={(e) => {
                    return <Pagination e={e} />;
                  }}
                />
              </div>
            ) : (
              <AlertBootstrap variant="warning" className="mt-3 text-center">
                No Data found
              </AlertBootstrap>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </Tab>
        <Tab eventKey="staging" title={<div className="flex items-center title-icon gap-[7px]">{subTab === 'staging' ? <StagingTab purple /> : <StagingTab />} Staging</div>}>
          {orders?.allStagingPreparation ? (
            stagingPreparation?.length > 0 ? (
              <div className="kit-table-header-center not_lost pre_table">
                <DataTable
                  className="border-t border-[#DEE2E6] "
                  columns={[
                    {
                      name: (
                        <div className="ml-[30px]">
                          <HemaValue text={'Name'} className="text-xs font-normal" color="text-[#000000]" />
                        </div>
                      ),

                      sortId: 'sponsor.name',
                      selector: (row, index) => {
                        return (
                          <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                            <FormCheckbox
                              onClick={(e) => {
                                // dispatch(setAllStagingPreparation(result.data));
                                if (stagingPreparation) {
                                  setstagingPreparation(
                                    stagingPreparation?.map((site, counter) => {
                                      if (counter === index) {
                                        return {
                                          ...site,
                                          checked: e.target?.checked,
                                        };
                                      } else {
                                        return site;
                                      }
                                    }),
                                  );
                                }
                              }}
                              checked={row.checked}
                              disabled={row?.status?.id === 1 ? true : false}
                              type="checkbox"
                            />

                            <HemaValue className="text-xs " text={row?.item?.name} />
                          </div>
                        );
                      },
                    },
                    {
                      name: (
                        <div className="">
                          <HemaValue text={'Built Quantity '} className="text-xs font-normal" color="text-[#000000]" />
                        </div>
                      ),
                      center: true,
                      selector: (row) => <HemaValue className="text-xs" text={row?.builtQuantity} />,
                      sortId: 'study.name',
                    },
                    {
                      name: (
                        <div className="">
                          <HemaValue text={'Lot Number'} className="text-xs font-normal" color="text-[#000000]" />
                        </div>
                      ),
                      center: true,
                      selector: (row) => <HemaValue className="text-xs" text={row?.lotNumber || 'N/A '} />,
                      sortId: 'lotNumber',
                    },
                    {
                      name: (
                        <div className="">
                          <HemaValue text={'Built Date'} className="text-xs font-normal" color="text-[#000000]" />
                        </div>
                      ),
                      center: true,
                      selector: (row) => <HemaValue className="text-xs" text={HemaDateFormat(row?.builtDate)} />,
                      sortId: 'builtDate',
                    },
                    {
                      name: <HemaValue text={'Status'} className="text-xs font-normal" color="text-[#000000]" />,

                      selector: (row) => (
                        <div className={`flex items-center gap-1 px-2 py-1 rounded-full ${row?.status?.name === 'Not Ready' ? 'bg-[#F6E6E6]' : 'bg-[#E6F6EB]'}`}>
                          <div className={`w-2 h-2 rounded-full ${row?.status?.name === 'Not Ready' ? 'bg-[#DA0404]' : 'bg-[#03AE00]'}`} />
                          <HemaValue className="text-xs" color={row?.status?.name === 'Not Ready' ? 'text-[#DA0404]' : 'text-[#03AE00]'} text={row?.status?.name} />
                        </div>
                      ),
                      sortId: 'status.name',
                    },
                    {
                      name: <HemaValue text="Actions" className="text-xs font-normal" color="text-[#000000]" />,
                      cell: (row) => {
                        return (
                          common?.permissions?.['Kit Order Process']?.update && (
                            <div className="flex-grow four pr-[30px]">
                              <div className="flex justify-end gap-[5px] meta">
                                <Button
                                  icon={<CreateAction color="#775FD5" />}
                                  color="text-white"
                                  bg="bg-bgActionDots"
                                  disabled={row?.status?.id === 2 ? true : false}
                                  cta={async () => {
                                    const resp = await getAssemblyforItemAction(orders?.activeOrder?.id, row?.id);

                                    if (resp.status === 200) {
                                      const selectedAssemblies = resp.data;
                                      dispatch(setAssemblyForStagingBuild(selectedAssemblies?.orderItemAssemblies));
                                      setformName('Kit Assembly Preparation');
                                      dispatch(editFormReducer(row));
                                      setFormIcon(<CreateItemPurple />);
                                      setokBtnText('Save');
                                      setCancelBtnText('Cancel');
                                      dispatch(setActivestagingForbuild(row));
                                      KitAssemblyPreparation[1].initialValue = row?.item.name;
                                      setUpdatedData(KitAssemblyPreparation);
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: 'kit-assembly-preparation',
                                        }),
                                      );
                                      setCTA(() => async () => {
                                        dispatch(
                                          setForm({
                                            state: false,
                                            type: '',
                                          }),
                                        );
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )
                        );
                      },
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    },
                  ]}
                  data={stagingPreparation || []}
                  pagination
                  // onSort={(row, direction, sorted) => {
                  //   setfilteredData(sortedData(row.sortId, direction, sorted));
                  // }}
                  paginationComponent={(e) => {
                    return <Pagination e={e} />;
                  }}
                />
              </div>
            ) : (
              <AlertBootstrap variant="warning" className="mt-3 text-center">
                No Staging information found
              </AlertBootstrap>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default RequestPreparation;

import ChangeReason from "../../../assets/images/change-reason.svg";

export const Remove = [
  {
    label: "",
    initialValue: "",
    name: "itemTodelete",
    fluid: true,
    type: "table",
  },
  {
    name: "change_reason",
    label: "Change Reason",
    icon: <img src={ChangeReason} alt="icon" />,
    type: "textarea",
    initialValue: "",
    fluid: true,
    required: true,
    placeholder: "Type reason",
  },
];

import React from 'react';

export const PostalIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8401_299303)">
        <path
          d="M14.0401 1.8062C13.5131 1.28623 12.8203 1 12.0895 1H3.91192C2.38817 1 1.14844 2.24238 1.14844 3.76922V7.65776C1.14844 8.03176 1.45187 8.33518 1.82586 8.33518H7.32327V13.6452H5.47007C5.09608 13.6452 4.79265 13.9486 4.79265 14.3226C4.79265 14.6966 5.09608 15 5.47007 15H10.5313C10.9053 15 11.2087 14.6966 11.2087 14.3226C11.2087 13.9486 10.9053 13.6452 10.5313 13.6452H8.67811V8.33518H14.1755C14.5495 8.33518 14.8529 8.03175 14.8529 7.65776V3.76922C14.8529 3.02741 14.5654 2.33146 14.0401 1.8062ZM2.50328 3.76922C2.50328 2.98948 3.13527 2.35484 3.91192 2.35484C4.28768 2.35484 4.64447 2.50258 4.91041 2.76456C5.17855 3.03623 5.3263 3.39302 5.3263 3.76922V6.98034H2.50328V3.76922ZM13.4981 6.98034H6.68114V3.76922C6.68114 3.26543 6.5434 2.78195 6.28893 2.35484H12.0895C12.4617 2.35484 12.8167 2.50258 13.0857 2.76764C13.3517 3.03358 13.4981 3.38949 13.4981 3.76921L13.4981 6.98034Z"
          fill="url(#paint0_linear_8401_299303)"
          stroke="url(#paint1_linear_8401_299303)"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8401_299303"
          x1="1.14844"
          y1="7.99999"
          x2="14.8529"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8401_299303"
          x1="1.14844"
          y1="7.99999"
          x2="14.8529"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <clipPath id="clip0_8401_299303">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from 'react';

export const StorageLocations = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8136 11.0844H4.35603L3.71317 17.009M3.71317 17.009L3.07031 22.9231H10.7846M3.71317 17.009L7.07788 14.7319M15.3233 11.0944L15.5521 10.9029C16.7687 9.85267 17.4681 8.32504 17.4681 6.71787C17.4681 5.11069 16.7687 3.58306 15.5521 2.53287C13.3908 0.799724 11.1858 0.392152 8.58227 2.53287C7.36567 3.58306 6.66629 5.11069 6.66629 6.71787C6.66629 8.32504 7.36567 9.85267 8.58227 10.9029L8.81112 11.0944L10.4398 12.4496M15.3248 11.0831H19.7823L20.4252 16.9974M15.3288 11.0831L12.0721 13.8166L10.4431 12.4499M10.7846 22.9231L7.07812 14.7268L10.4416 12.4498M10.7846 22.9231L14.6417 22.9233M14.6417 22.9233L21.0703 22.9231L20.4275 17.0089L14.6417 22.9233ZM13.8722 6.53042C13.8602 7.5216 13.049 8.31651 12.0578 8.30851C11.0666 8.30051 10.2684 7.49261 10.2723 6.50137C10.2763 5.51013 11.0809 4.70864 12.0722 4.70857C12.5525 4.71129 13.0121 4.9048 13.3497 5.24651C13.6873 5.58821 13.8753 6.05008 13.8722 6.53042Z"
        stroke="url(#paint0_linear_9398_152903)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9398_152903"
          x1="3.07031"
          y1="11.9997"
          x2="21.0703"
          y2="11.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

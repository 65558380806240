import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboard from '../pages/Dashboard/Dashboard';
import Error from '../pages/Error';
import Inventory from '../pages/inventory';
import Storage from '../pages/storage';
import Login from '../pages/login';
import InventoryDetail from '../pages/inventoryDetail';
import Settings from '../pages/Settings';
import KitBuilder from '../pages/kitBuilder';
import SiteManagement from '../pages/siteManagement';
import SponserManagement from '../pages/sponserManagement';
import Layout from '../components/Layout';
import LogisticsOrchestra from '../pages/logistOrchestra/index';
import StudyManagement from '../pages/studyManagement';
import { refreshTokenAction } from '../Actions/auth';
import PrivateRoute from './privateRoutes';
import OpenRoute from './openRouter';
import hematogenix from '../assets/images/HematogenixRed.svg';
import CreateKit from '../pages/createKit/createKit';
import AuditTrail from '../pages/AuditTrail';
import Audit from '../pages/Audit';
import SuppliesReport from '../pages/suppliesReport';

const App = () => {
  const [ready, setReady] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  useEffect(() => {
    (async () => {
      if (localStorage.getItem('hema-token')) {
        const refreshToken = await refreshTokenAction();
        setReady(true);
        if (refreshToken.status === 200) {
          localStorage.setItem('hema-token', refreshToken.data.token);
          localStorage.setItem(
            'hema-token-refresh',
            refreshToken.data.refreshToken
          );
        } else {
          localStorage.removeItem('hema-token');
          localStorage.removeItem('hema-token-refresh');
          window.location.reload();
        }
      } else {
        setReady(true);
      }
    })();
  }, []);
  return !ready ? (
    <div className="flex items-center justify-center h-screen bg-primary2">
      <img src={hematogenix} alt="logo" />
    </div>
  ) : (
    <Routes>
      <Route
        path="/login"
        element={
          <OpenRoute>
            <Login />
          </OpenRoute>
        }
      />

      <Route path="/" element={<Layout setShowDashboard={setShowDashboard} />}>
        <Route
          path=""
          element={
            <PrivateRoute count="1">
              <Dashboard
                setShowDashboard={setShowDashboard}
                showDashboard={showDashboard}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="inventory-management"
          element={
            <PrivateRoute count="2">
              <Inventory />
            </PrivateRoute>
          }
        />

        <Route
          path="kit-builders"
          element={
            <PrivateRoute count="3">
              <KitBuilder />
            </PrivateRoute>
          }
        />

        <Route
          path="create-kit"
          element={
            <PrivateRoute count="4">
              <CreateKit />
            </PrivateRoute>
          }
        />

        <Route path="dashboard" element={<PrivateRoute></PrivateRoute>} />
        <Route path="reports" element={<PrivateRoute></PrivateRoute>} />
        <Route
          path="Kitorder-process"
          element={<PrivateRoute></PrivateRoute>}
        />

        <Route
          path="inventory/:id"
          element={
            <PrivateRoute count="5">
              <InventoryDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="sponsors-management"
          element={
            <PrivateRoute count="6">
              <SponserManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="study-management"
          element={
            <PrivateRoute count="7">
              <StudyManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="site-management"
          element={
            <PrivateRoute count="8">
              <SiteManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="storage"
          element={
            <PrivateRoute count="9">
              <Storage />
            </PrivateRoute>
          }
        />
        <Route
          path="logistic-orchestrator"
          element={
            <PrivateRoute count="10">
              <LogisticsOrchestra />
            </PrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <PrivateRoute count="11">
              <Settings />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="audit-trail-1"
          element={
            <PrivateRoute>
              <AuditTrail />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="audit-trail"
          element={
            <PrivateRoute count="12">
              <Audit />
            </PrivateRoute>
          }
        />
        <Route
          path="supplies-report"
          element={
            <PrivateRoute count="14">
              <SuppliesReport />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute count="13">
              <Error />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
    // </BrowserRouter>
  );
};
export default App;

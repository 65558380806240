import React from "react";

export const AudutTrail = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="layers-three-01">
        <path
          id="Vector"
          d="M14.3285 14.3293L16.6726 16.6734M15.4615 2.30762H8.53846C5.47965 2.30762 3 4.78727 3 7.84608V16.1538C3 19.2126 5.47965 21.6922 8.53846 21.6922H15.4615C18.5203 21.6922 21 19.2126 21 16.1538V7.84608C21 4.78727 18.5203 2.30762 15.4615 2.30762ZM7.32692 11.4286C7.32663 9.47159 8.70881 7.7869 10.6282 7.40483C12.5475 7.02277 14.4695 8.04975 15.2187 9.8577C15.9678 11.6657 15.3355 13.751 13.7084 14.8385C12.0813 15.9259 9.91268 15.7126 8.52871 14.3289C7.75932 13.5598 7.32701 12.5165 7.32692 11.4286Z"
          stroke="url(#paint0_linear_11711_189592)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11711_189592"
          x1="3"
          y1="11.9999"
          x2="21"
          y2="11.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
export const Pdf = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0.667969C0.895333 0.667969 0 1.5633 0 2.66797V13.3346C0 14.4393 0.895333 15.3346 2 15.3346H10C11.1047 15.3346 12 14.4393 12 13.3346V6.88672C12 6.35605 11.7891 5.84766 11.4141 5.47266L7.19531 1.25391C6.81998 0.878906 6.31158 0.667969 5.78125 0.667969H2ZM2 2.0013H5.33333V5.33464C5.33333 6.4393 6.22867 7.33464 7.33333 7.33464H10.6667V13.3346C10.6667 13.703 10.3683 14.0013 10 14.0013H2C1.63167 14.0013 1.33333 13.703 1.33333 13.3346V2.66797C1.33333 2.29964 1.63167 2.0013 2 2.0013ZM6.66667 2.61068L10.0573 6.0013H7.33333C6.965 6.0013 6.66667 5.70297 6.66667 5.33464V2.61068ZM2.97005 9.66732C2.77972 9.66732 2.68034 9.7909 2.68034 9.99023V12.0404C2.68034 12.2307 2.77992 12.3542 2.96159 12.3542C3.14326 12.3542 3.24609 12.2337 3.24609 12.0404V11.5046H3.77018C4.35318 11.5046 4.74023 11.1416 4.74023 10.5879C4.74023 10.0369 4.35337 9.66732 3.77604 9.66732H2.97005ZM5.28971 9.66732C5.09938 9.66732 5 9.7909 5 9.99023V11.9967C5 12.1961 5.09938 12.319 5.28971 12.319H6.03125C6.85425 12.319 7.31706 11.8443 7.31706 10.9889C7.31706 10.1393 6.85758 9.66732 6.03125 9.66732H5.28971ZM7.94596 9.66732C7.75263 9.66732 7.65234 9.7909 7.65234 9.99023V12.0404C7.65234 12.2307 7.75258 12.3542 7.93425 12.3542C8.11891 12.3542 8.21843 12.2337 8.2181 12.0404V11.2266H9.20247C9.33414 11.2266 9.42188 11.1505 9.42188 11.0215C9.42188 10.8868 9.33447 10.8105 9.20247 10.8105H8.2181V10.1159H9.29362C9.43429 10.1159 9.52734 10.0309 9.52734 9.89323C9.52734 9.75256 9.43429 9.66732 9.29362 9.66732H7.94596ZM3.24609 10.0924H3.69141C4.00507 10.0924 4.19206 10.2772 4.19206 10.5905C4.19206 10.8982 4.00474 11.0827 3.69141 11.0827H3.24609V10.0924ZM5.54492 10.1009H5.95508C6.47075 10.1009 6.7487 10.4058 6.7487 10.9948C6.7487 11.5925 6.47374 11.888 5.95508 11.888H5.54492V10.1009Z"
        fill="url(#paint0_linear_3083_194522)"
      />
      <defs>
        <linearGradient id="paint0_linear_3083_194522" x1="-8.10255e-08" y1="8.00126" x2="12" y2="8.00126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#605DAF" />
          <stop offset="1" stopColor="#716EC6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const SystemUser = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4402_209720)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5729 6.19905C16.5729 8.68425 14.5583 10.6989 12.0731 10.6989C9.58789 10.6989 7.57324 8.68425 7.57324 6.19905C7.57324 3.71386 9.58789 1.69922 12.0731 1.69922C13.2665 1.69922 14.4111 2.17331 15.2549 3.01719C16.0988 3.86107 16.5729 5.00562 16.5729 6.19905Z"
          stroke="url(#paint0_linear_4402_209720)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9209 13.699H8.22021C7.73608 13.6996 7.25546 13.7813 6.79826 13.9405C1.53346 15.757 6.54177 22.6987 12.0706 22.6987C17.5994 22.6987 22.6077 15.757 17.3414 13.9405C16.8847 13.7814 16.4046 13.6998 15.9209 13.699Z"
          stroke="url(#paint1_linear_4402_209720)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4402_209720"
          x1="4.57031"
          y1="12.1989"
          x2="19.5703"
          y2="12.1989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4402_209720"
          x1="4.57031"
          y1="12.1989"
          x2="19.5703"
          y2="12.1989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <clipPath id="clip0_4402_209720">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0703125 0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";

import DataTable from "react-data-table-component";

import { RowExpand, RowCollapsed, DeleteInventory, ActionCopy, AddInventory, DeleteKit, DustBin, Apply, ChangeReason, Confirm } from "../../../HemeIconLibrary";
import { HemaValue, FormCheckbox } from "../../../utils";
import { HemaDateFormat } from '../../../helpers/commonHelper';

const DocumentsSection = ({
    allDocuments,
    deleteDocument,
    setFieldValue,
    values,
    name,
    readOnly,
    type,
    
    setformName,
    setFormIcon,
    dispatch,
    setForm,
    editFormReducer,
    setUpdatedData,
    handleRowSelected,
    deleteKitAssemblyConfig,
    assigner,
    logistic,
    level,
    hideaction,
  }) => {
    //console.log('documentsection', logistic, allDocuments)
    
    
    return (
      <DataTable
        expandableIcon={{
          expanded: <RowExpand />,
          collapsed: <RowCollapsed />,
        }}
        className="lo-kit-config-table"
        onSelectedRowsChange={handleRowSelected}
        data={allDocuments || []}
        selectableRows = {level === "site"  ?'':'selectableRows'}
        columns={[
          {
            width: '35%',
            name: <HemaValue text="File" className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => (
              <a href={row?.file?.storageUrl} target="_blank" rel="noreferrer">
                <HemaValue className="font-normal text-xs decoration-[#605DAF] " color="text-[#605DAF]" text={row?.file?.friendlyName} />
              </a>
            ),
            sortId: 'readToShipQuantity',
          },
          {
            name: <HemaValue text={'Date Created'} className="text-xs font-normal " color="text-[#000000]" />,
            selector: (row) => <HemaValue className="text-xs" text={HemaDateFormat(row?.createdUtc)} />,
            sortId: 'readToShipQuantity',
          },
          {
           
            name: <HemaValue text={'Record Owner'} className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => <HemaValue className="text-xs" text={row?.owner?.name || 'NA'} />,
          },  
          {
            
            name: true && <HemaValue text={type !== "add-region" && "Actions"} className="font-normal text-[#000000]" />,
  
            sortable: false,
            selector: (row) =>
              (
                <div className="flex items-center">
                 
                  {level === "study"  && (
                    <div className="">
                      { (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                         
                              setUpdatedData([
                                {
                                  name: "DocumentId",
                                  initialValue: row.id,
                                  fluid: true,
                                  label:
                                    "Are you sure you want to delete this item? This process cannot be undone and will affect all regions, countries, and sites under this study.",
                                },
                                {
                                  name: "chanereason",
                                  label: "Change Reason",
                                  icon: <ChangeReason />,
                                  type: "textarea",
                                  initialValue: "",
                                  fluid: true,
                                  required: true,
                                  placeholder: "Type reason",
                                },
                              ]);
                        
                            setFormIcon(<DeleteKit />);
                            setformName("Delete Document");
  
                            dispatch(
                              setForm({
                                state: true,
                                type: "delete-document",
                              }),
                            );
                            deleteDocument();
                          }}
                        >
                          <DeleteKit width="18" height="18" color="#F87FB1" />
                        </div>
                      )}
                    </div>
                  )}
  
                  {/* <HemaValue text={row.availableToBuild} /> */}
                </div>
              ),
          },
        ]}
        
      />
    );
  };
  
  export default DocumentsSection;
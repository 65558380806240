import React from 'react'

export const Dust = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.751953" width="21" height="21" rx="5" fill="#F54C3B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.0722 8.68066H6.92884C6.53451 8.68066 6.21484 9.00033 6.21484 9.39466V15.1092C6.21484 16.2926 7.17423 17.252 8.35768 17.252H12.6434C13.2117 17.252 13.7567 17.0263 14.1586 16.6244C14.5604 16.2225 14.7862 15.6775 14.7862 15.1092V9.39466C14.7862 9.00033 14.4665 8.68066 14.0722 8.68066Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.82085 11.5372C9.82085 11.123 9.48506 10.7872 9.07085 10.7872C8.65663 10.7872 8.32085 11.123 8.32085 11.5372H9.82085ZM8.32085 14.3941C8.32085 14.8083 8.65663 15.1441 9.07085 15.1441C9.48506 15.1441 9.82085 14.8083 9.82085 14.3941H8.32085ZM12.6812 11.5372C12.6812 11.123 12.3454 10.7872 11.9312 10.7872C11.5169 10.7872 11.1812 11.123 11.1812 11.5372H12.6812ZM11.1812 14.3941C11.1812 14.8083 11.5169 15.1441 11.9312 15.1441C12.3454 15.1441 12.6812 14.8083 12.6812 14.3941H11.1812ZM12.0109 6.00195C12.4251 6.00195 12.7609 5.66617 12.7609 5.25195C12.7609 4.83774 12.4251 4.50195 12.0109 4.50195V6.00195ZM9.15407 4.50195C8.73986 4.50195 8.40407 4.83774 8.40407 5.25195C8.40407 5.66617 8.73986 6.00195 9.15407 6.00195V4.50195ZM14.7862 7.71625C15.2004 7.71625 15.5362 7.38047 15.5362 6.96625C15.5362 6.55204 15.2004 6.21625 14.7862 6.21625V7.71625ZM6.21484 6.21625C5.80063 6.21625 5.46484 6.55204 5.46484 6.96625C5.46484 7.38047 5.80063 7.71625 6.21484 7.71625V6.21625ZM8.32085 11.5372V14.3941H9.82085V11.5372H8.32085ZM11.1812 11.5372V14.3941H12.6812V11.5372H11.1812ZM12.0109 4.50195H9.15407V6.00195H12.0109V4.50195ZM14.7862 6.21625H6.21484V7.71625H14.7862V6.21625Z" fill="white"/>
    </svg>
    
  )
}
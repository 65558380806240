import React from 'react';
export const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.74005 6.97366H5.73363L7.73684 3.69568L9.74005 6.97366ZM8.76316 10.8947C8.76316 9.86033 9.5972 9.02629 10.6316 9.02629C11.666 9.02629 12.5 9.86033 12.5 10.8947C12.5 11.9291 11.666 12.7631 10.6316 12.7631C9.5972 12.7631 8.76316 11.9291 8.76316 10.8947ZM6.71053 9.28945V12.5H3.5V9.28945H6.71053Z"
        fill="url(#paint0_linear_14_23782)"
        stroke="url(#paint1_linear_14_23782)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_23782"
          x1="3"
          y1="7.99997"
          x2="13"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14_23782"
          x1="3"
          y1="7.99997"
          x2="13"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

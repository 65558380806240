import React from 'react';

export const Unit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.39233 15H1.45386C1.20312 15 1 14.7969 1 14.5461V2.45386C1 2.20313 1.20312 2 1.45386 2H6.39233C6.64307 2 6.84619 2.20313 6.84619 2.45386V14.5461C6.84619 14.7969 6.64307 15 6.39233 15ZM1.91089 14.0923H5.93848V2.90772H1.91089V14.0923Z"
        fill="url(#paint0_linear_10370_288508)"
      />
      <path
        d="M13.5493 7.84302H1.45701C1.20628 7.84302 1.00316 7.63989 1.00316 7.38916V2.45386C1.00316 2.20313 1.20628 2 1.45701 2H13.5493C13.8 2 14.0032 2.20313 14.0032 2.45386V7.39233C14.0032 7.63989 13.8 7.84302 13.5493 7.84302ZM1.91087 6.9353H13.0954V2.90772H1.91089L1.91087 6.9353ZM3.68822 13.137H1.45701C1.20628 13.137 1.00316 12.9338 1.00316 12.6831C1.00316 12.4324 1.20628 12.2292 1.45701 12.2292H3.68822C3.93895 12.2292 4.14207 12.4324 4.14207 12.6831C4.14207 12.9338 3.93895 13.137 3.68822 13.137ZM3.68822 10.4836H1.45701C1.20628 10.4836 1.00316 10.2805 1.00316 10.0298C1.00316 9.77905 1.20628 9.57593 1.45701 9.57593H3.68822C3.93895 9.57593 4.14207 9.77905 4.14207 10.0298C4.14207 10.2805 3.93895 10.4836 3.68822 10.4836Z"
        fill="url(#paint1_linear_10370_288508)"
      />
      <path
        d="M8.89329 5.13892C8.64256 5.13892 8.43943 4.93579 8.43943 4.68506V2.45386C8.43943 2.20313 8.64256 2 8.89329 2C9.14402 2 9.34715 2.20313 9.34715 2.45386V4.68506C9.34715 4.93579 9.14402 5.13892 8.89329 5.13892ZM11.5466 5.13892C11.2959 5.13892 11.0928 4.93579 11.0928 4.68506V2.45386C11.0928 2.20313 11.2959 2 11.5466 2C11.7973 2 12.0005 2.20313 12.0005 2.45386V4.68506C12.0005 4.93579 11.7973 5.13892 11.5466 5.13892Z"
        fill="url(#paint2_linear_10370_288508)"
      />
      <path
        d="M1.91089 2.90772H5.93848V14.0923H1.91089V2.90772ZM1.91089 2.90772H13.0954V6.9353H1.91087L1.91089 2.90772ZM6.39233 15H1.45386C1.20312 15 1 14.7969 1 14.5461V2.45386C1 2.20313 1.20312 2 1.45386 2H6.39233C6.64307 2 6.84619 2.20313 6.84619 2.45386V14.5461C6.84619 14.7969 6.64307 15 6.39233 15ZM13.5493 7.84302H1.45701C1.20628 7.84302 1.00316 7.63989 1.00316 7.38916V2.45386C1.00316 2.20313 1.20628 2 1.45701 2H13.5493C13.8 2 14.0032 2.20313 14.0032 2.45386V7.39233C14.0032 7.63989 13.8 7.84302 13.5493 7.84302ZM3.68822 13.137H1.45701C1.20628 13.137 1.00316 12.9338 1.00316 12.6831C1.00316 12.4324 1.20628 12.2292 1.45701 12.2292H3.68822C3.93895 12.2292 4.14207 12.4324 4.14207 12.6831C4.14207 12.9338 3.93895 13.137 3.68822 13.137ZM3.68822 10.4836H1.45701C1.20628 10.4836 1.00316 10.2805 1.00316 10.0298C1.00316 9.77905 1.20628 9.57593 1.45701 9.57593H3.68822C3.93895 9.57593 4.14207 9.77905 4.14207 10.0298C4.14207 10.2805 3.93895 10.4836 3.68822 10.4836ZM8.89329 5.13892C8.64256 5.13892 8.43943 4.93579 8.43943 4.68506V2.45386C8.43943 2.20313 8.64256 2 8.89329 2C9.14402 2 9.34715 2.20313 9.34715 2.45386V4.68506C9.34715 4.93579 9.14402 5.13892 8.89329 5.13892ZM11.5466 5.13892C11.2959 5.13892 11.0928 4.93579 11.0928 4.68506V2.45386C11.0928 2.20313 11.2959 2 11.5466 2C11.7973 2 12.0005 2.20313 12.0005 2.45386V4.68506C12.0005 4.93579 11.7973 5.13892 11.5466 5.13892Z"
        stroke="url(#paint3_linear_10370_288508)"
        strokeWidth="0.25"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10370_288508"
          x1="1"
          y1="8.49999"
          x2="14.0032"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10370_288508"
          x1="1"
          y1="8.49999"
          x2="14.0032"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10370_288508"
          x1="1"
          y1="8.49999"
          x2="14.0032"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10370_288508"
          x1="1"
          y1="8.49999"
          x2="14.0032"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const NextIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.76933 11.4438C8.3694 11.8247 8.35396 12.4577 8.73485 12.8576C9.11573 13.2576 9.74871 13.273 10.1486 12.8921L8.76933 11.4438ZM14.5236 8.72544C14.9236 8.34455 14.939 7.71158 14.5581 7.31165C14.1772 6.91172 13.5443 6.89628 13.1443 7.27716L14.5236 8.72544ZM13.1443 8.72544C13.5443 9.10633 14.1772 9.09089 14.5581 8.69096C14.939 8.29103 14.9236 7.65805 14.5236 7.27716L13.1443 8.72544ZM10.1486 3.1105C9.74871 2.72961 9.11573 2.74505 8.73485 3.14498C8.35396 3.54491 8.3694 4.17789 8.76933 4.55877L10.1486 3.1105ZM13.834 9.0013C14.3863 9.0013 14.834 8.55359 14.834 8.0013C14.834 7.44902 14.3863 7.0013 13.834 7.0013V9.0013ZM2.16732 7.0013C1.61503 7.0013 1.16732 7.44902 1.16732 8.0013C1.16732 8.55359 1.61503 9.0013 2.16732 9.0013V7.0013ZM10.1486 12.8921L14.5236 8.72544L13.1443 7.27716L8.76933 11.4438L10.1486 12.8921ZM14.5236 7.27716L10.1486 3.1105L8.76933 4.55877L13.1443 8.72544L14.5236 7.27716ZM13.834 7.0013L2.16732 7.0013V9.0013L13.834 9.0013V7.0013Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

import React from 'react';

export const UserBy = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5C13.375 4.5 14.5 5.625 14.5 7C14.5 8.375 13.375 9.5 12 9.5C10.625 9.5 9.5 8.375 9.5 7C9.5 5.625 10.625 4.5 12 4.5ZM12 17C15.375 17 19.25 18.6125 19.5 19.5H4.5C4.7875 18.6 8.6375 17 12 17ZM12 2C9.2375 2 7 4.2375 7 7C7 9.7625 9.2375 12 12 12C14.7625 12 17 9.7625 17 7C17 4.2375 14.7625 2 12 2ZM12 14.5C8.6625 14.5 2 16.175 2 19.5V22H22V19.5C22 16.175 15.3375 14.5 12 14.5Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

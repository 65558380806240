import React from 'react';

export const Length = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56318 10.9359C5.64984 11.0442 5.77443 11.0984 5.90172 11.0984C5.99651 11.0984 6.0913 11.0686 6.17255 11.0036C6.35943 10.8546 6.38922 10.5811 6.24026 10.3942L5.07026 8.93171H11.9332L10.7632 10.3942C10.6142 10.5811 10.644 10.8546 10.8309 11.0036C10.9121 11.0686 11.0069 11.0984 11.1017 11.0984C11.229 11.0984 11.3536 11.0415 11.4403 10.9359L13.1736 8.76921C13.3009 8.61212 13.3009 8.38733 13.1736 8.22754L11.4403 6.06087C11.2913 5.874 11.0178 5.84421 10.8309 5.99317C10.644 6.14212 10.6142 6.41567 10.7632 6.60254L11.9332 8.06504H5.07026L6.24026 6.60254C6.38922 6.41567 6.35943 6.14212 6.17255 5.99317C5.98568 5.84421 5.71214 5.874 5.56318 6.06087L3.82984 8.22754C3.70255 8.38462 3.70255 8.60942 3.82984 8.76921L5.56318 10.9359Z"
        fill="url(#paint0_linear_10370_288440)"
        stroke="url(#paint1_linear_10370_288440)"
        strokeWidth="0.5"
      />
      <path
        d="M14.5667 2H2.43333C2.195 2 2 2.195 2 2.43333V14.5667C2 14.805 2.195 15 2.43333 15H14.5667C14.805 15 15 14.805 15 14.5667V2.43333C15 2.195 14.805 2 14.5667 2ZM14.1333 14.1333H2.86667V2.86667H14.1333V14.1333Z"
        fill="url(#paint2_linear_10370_288440)"
        stroke="url(#paint3_linear_10370_288440)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10370_288440"
          x1="3.73438"
          y1="8.4984"
          x2="13.2691"
          y2="8.4984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10370_288440"
          x1="3.73438"
          y1="8.4984"
          x2="13.2691"
          y2="8.4984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10370_288440"
          x1="2"
          y1="8.49999"
          x2="15"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10370_288440"
          x1="2"
          y1="8.49999"
          x2="15"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

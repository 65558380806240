import React from "react";

export const BellIcon = ({ color, width, height }) => {
  return (
    <svg
      width={width || "10"}
      height={height || "13"}
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 7.18672V5.19922C8.7491 4.31367 8.43485 3.45701 7.86288 2.78095C7.29092 2.10489 6.49816 1.65306 5.625 1.50547V0.824219C5.625 0.658458 5.55915 0.499487 5.44194 0.382277C5.32473 0.265067 5.16576 0.199219 5 0.199219C4.83424 0.199219 4.67527 0.265067 4.55806 0.382277C4.44085 0.499487 4.375 0.658458 4.375 0.824219V1.50547C3.50184 1.65306 2.70908 2.10489 2.13712 2.78095C1.56515 3.45701 1.2509 4.31367 1.25 5.19922V7.18672C0.885282 7.31566 0.569374 7.55426 0.345587 7.8698C0.121799 8.18534 0.00108719 8.56238 0 8.94922V10.1992C0 10.365 0.0658481 10.524 0.183058 10.6412C0.300269 10.7584 0.45924 10.8242 0.625 10.8242H2.5875C2.73143 11.3538 3.04563 11.8214 3.48162 12.1547C3.91762 12.488 4.45119 12.6686 5 12.6686C5.54881 12.6686 6.08238 12.488 6.51838 12.1547C6.95437 11.8214 7.26857 11.3538 7.4125 10.8242H9.375C9.54076 10.8242 9.69973 10.7584 9.81694 10.6412C9.93415 10.524 10 10.365 10 10.1992V8.94922C9.99891 8.56238 9.8782 8.18534 9.65441 7.8698C9.43063 7.55426 9.11472 7.31566 8.75 7.18672ZM2.5 5.19922C2.5 4.53618 2.76339 3.90029 3.23223 3.43145C3.70107 2.96261 4.33696 2.69922 5 2.69922C5.66304 2.69922 6.29893 2.96261 6.76777 3.43145C7.23661 3.90029 7.5 4.53618 7.5 5.19922V7.07422H2.5V5.19922ZM5 11.4492C4.78186 11.4479 4.56786 11.3895 4.37927 11.2799C4.19068 11.1702 4.03407 11.0131 3.925 10.8242H6.075C5.96593 11.0131 5.80932 11.1702 5.62073 11.2799C5.43214 11.3895 5.21814 11.4479 5 11.4492ZM8.75 9.57422H1.25V8.94922C1.25 8.78346 1.31585 8.62449 1.43306 8.50728C1.55027 8.39007 1.70924 8.32422 1.875 8.32422H8.125C8.29076 8.32422 8.44973 8.39007 8.56694 8.50728C8.68415 8.62449 8.75 8.78346 8.75 8.94922V9.57422Z"
        fill={color || "url(#paint0_linear_14288_152300)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_14288_152300"
          x1="4.54019e-08"
          y1="6.4339"
          x2="10"
          y2="6.4339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import { Formik } from 'formik';
import { HemaDateFormat } from '../helpers/commonHelper';
import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { Button, Pagination, HemaValue, SingleSelect, FormText, NoPermissionAlert } from '../utils';
import { getAuditFiltersAction, getAuditAction } from '../Actions/audit';
import { ActionIcon, Add, Cancel, RowCollapsed, RowExpand, Search, WhatIcon, WhenIcon, WhereIcon, WhoIcon, CrossIcon } from '../HemeIconLibrary';
import FormContainer from '../components/Formik/formContainer';

const Audit = () => {
  const { detail, filters } = useSelector((state) => state.audit);
  const { uisettings, common } = useSelector((state) => state);
  const formikRef = useRef();
  const [filterCount, setFilterCount] = useState([{ filter: '', operator: '', value: '', id: Date.now() }]);
  const [activePage, setActivePage] = useState(1);
  const [activePageRows, setActivePageRows] = useState();
  const [formName, setformName] = useState();
  const [resultData, setResultData] = useState({});
  const [startSearching, setStartSearching] = useState(false);
  useEffect(() => {
    getAuditFiltersAction();
  }, []);

  useEffect(() => {
    (async () => {
      await formikRef.current?.validateForm();
    })();
  }, []);

  useEffect(() => {
    if (detail) {
      setResultData(detail);
    }
  }, [detail]);

  const updateData = (count) => {
    setActivePage(count);
    setResultData(null);
    const filterquerry = filterCount.map((data, counter) => {
      return `filter[${counter}].filter=${data?.filter?.id}&filter[${counter}].operator=${data?.operator?.id}&filter[${counter}].Value=${data?.value?.name}`;
    });

    getAuditAction(count, activePageRows || 10, filterquerry?.join('&'));
  };

  useEffect(() => {
    if (activePageRows) {
      updateData(1);
    }
  }, [activePageRows]);

  const ExpendedDataRowsForAssembly = ({ data }) => {
    console.log(data);

    return (
      <>
        <AuditCapture
          data={Object.keys(data?.oldValue || {})?.length < Object.keys(data?.newValue || {})?.length ? data.newValue : data.oldValue}
          newdata={Object.keys(data?.oldValue || {})?.length < Object.keys(data?.newValue || {})?.length ? data.oldValue : data.newValue}
          // reverse={Object.keys(data?.oldValue)?.length > Object.keys(data?.newValue)?.length ? true : false}
          original={data}
        />
        {data?.reason && (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={'Change Reason:'} color="text-[#000000]" className="w-full text-xs font-medium pl-[30px]" />
              <HemaValue text={data.reason} color="text-[#000000]" className="text-xs font-normal" />
            </div>
          </div>
        )}
      </>
    );
  };

  const addRowNew = async (type) => {
    const action1 = filters?.filters?.map((data) => (data.filter?.name === type ? data.filter : ''))?.filter((duplicate) => !!duplicate)?.[0];
    const operator1 = filters?.filters?.map((data) => (data.filter?.name === type ? data.operator : ''))?.filter((duplicate) => !!duplicate)?.[0];
    const value1 = filters?.filters?.map((data) => (data.filter?.name === type ? data.values : ''))?.filter((duplicate) => !!duplicate)?.[0];
    console.log(filterCount, type);
    formikRef?.current?.validateForm().then((data) => {
      if (Object.keys(data)?.length === 0) {
        setFilterCount([
          ...filterCount,
          {
            filter: action1 ? { ...action1, label: action1.name, value: action1.id } : '',

            operatorValue: operator1 || '',

            valueOption: value1 || '',
            id: Date.now(),
          },
        ]);
      } else {
        formikRef?.current?.submitForm();
      }
    });
  };

  return common.permissions?.Audit?.read ? (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Audit trail" sub="Track and review all activities and changes within the system here." />
      </div>

      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] px-4 mt-[27px] mb-[13px] inventory-tabs">
        <div className="flex items-center justify-between w-full gap-10 ">
          <HemaValue text="Search" color="text-[#000000]" className="text-xl font-bold" />

          <div className=" w-full flex items-start lg:item-center justify-center gap-10 mr-[106px] flex-wrap lg:flex-nowrap">
            <div onClick={() => addRowNew('Who')} className="flex items-center gap-[1px] cursor-pointer">
              <WhoIcon />
              <HemaValue text="Who" color="text-[#775FD5]" className="text-base font-semibold" />
            </div>
            <div onClick={() => addRowNew('Action')} className="flex items-center gap-[1px] cursor-pointer">
              <ActionIcon />
              <HemaValue text="Action" color="text-[#775FD5]" className="text-base font-semibold" />
            </div>
            <div onClick={() => addRowNew('What')} className="flex items-center gap-[1px] cursor-pointer">
              <WhatIcon />
              <HemaValue text="What" color="text-[#775FD5]" className="text-base font-semibold" />
            </div>
            <div onClick={() => addRowNew('When')} className="flex items-center gap-[1px] cursor-pointer">
              <WhenIcon />
              <HemaValue text="When" color="text-[#775FD5]" className="text-base font-semibold" />
            </div>
            <div onClick={() => addRowNew('Where')} className="flex items-center gap-[1px] cursor-pointer">
              <WhereIcon />
              <HemaValue text="Where" color="text-[#775FD5]" className="text-base font-semibold" />
            </div>
          </div>
        </div>
        <div className="w-full border-b border-dashed border-[#DEE2E6] mt-[15px] mb-[12px]  " />
        <Formik
          initialValues={{
            query: filterCount,
            typeId: '',
            operator: '',
            value: '',
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!filterCount[filterCount.length - 1]?.filter) {
              errors.typeId = 'Required';
            }
            if (!filterCount[filterCount.length - 1]?.operator) {
              errors.operator = 'Required';
            }
            if (!filterCount[filterCount.length - 1]?.value) {
              errors.value = 'Required';
            }
            // if (!values.name) {
            //   errors.name = 'Required';
            // }

            return errors;
          }}
          onSubmit={async (values) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            initialValues,
            setFieldValue,
            isSubmitting,
            validateForm,
            submitForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-[97%] flex items-center gap-4 ">
                <div className="w-[95%]">
                  <HemaValue text="Filter" color="text-[#000000]" className="text-xs font-medium" />
                </div>

                <div className="w-[95%]">
                  <HemaValue text="Operator" color="text-[#000000]" className="text-xs font-medium" />
                </div>
                <div className="w-[95%]">
                  <HemaValue text="Value" color="text-[#000000]" className="text-xs font-medium" />
                </div>
              </div>

              {filterCount?.map((fil, counter) => {
                return (
                  <div className="mt-[6px]">
                    <div className="flex items-center w-full gap-4">
                      <div className="w-full ">
                        <SingleSelect
                          placeholder={filterCount?.[counter]?.filter?.label || 'select'}
                          name="typeId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.typeId}
                          options={filters?.filters?.map((data) => data.filter) || []}
                          className="pr-[40px]"
                          crossIcon={filterCount?.[counter]?.filter?.label ? <CrossIcon /> : ''}
                          setFieldValueFull={(_, value) => {
                            if (!value) {
                              console.log(filterCount);
                              const setFilter = filterCount.map((data) => {
                                if (data.id === fil.id) {
                                  return {
                                    ...data,
                                    filter: '',
                                    operatorValue: '',
                                    valueOption: '',
                                    value: '',
                                    operator: '',
                                  };
                                } else {
                                  return data;
                                }
                              });

                              setFilterCount(setFilter);
                            } else {
                              const setFilter = filterCount.map((data) => {
                                if (data.id === fil.id) {
                                  return {
                                    ...data,
                                    filter: value,
                                    operatorValue: filters?.filters?.filter((data) => data.filter.id === value.id)?.[0].operator,
                                    valueOption: filters?.filters?.filter((data) => data.filter.id === value.id)?.[0].values,
                                  };
                                } else {
                                  return data;
                                }
                              });
                              console.log(errors);
                              setFilterCount(setFilter);
                            }
                          }}
                        />

                        {errors.typeId && touched.typeId && !filterCount?.[counter]?.filter?.label && <div className="error text-[red] text-[12px] pt-[2px]">{errors.typeId}</div>}
                      </div>

                      <div className="w-full ">
                        <SingleSelect
                          placeholder={filterCount?.[counter]?.operator?.label || 'select'}
                          name="operator"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.operator}
                          options={filterCount.filter((data) => data.id === fil.id)?.[0]?.operatorValue || []}
                          className="pr-[40px]"
                          crossIcon={filterCount?.[counter]?.operator?.label ? <CrossIcon /> : ''}
                          setFieldValueFull={(_, value) => {
                            if (!value) {
                              console.log(filterCount);
                              const setFilter = filterCount.map((data) => {
                                if (data.id === fil.id) {
                                  return {
                                    ...data,

                                    value: '',
                                    operator: '',
                                  };
                                } else {
                                  return data;
                                }
                              });

                              setFilterCount(setFilter);
                            } else {
                              const setFilter = filterCount.map((data) => {
                                if (data.id === fil.id) {
                                  return {
                                    ...data,
                                    operator: value,
                                  };
                                } else {
                                  return data;
                                }
                              });

                              setFilterCount(setFilter);
                            }
                          }}
                        />

                        {errors.operator && touched.operator && !filterCount?.[counter]?.operator?.label && (
                          <div className="error text-[red] text-[12px] pt-[2px]">{errors.operator}</div>
                        )}
                      </div>
                      <div className="w-full ">
                        {filterCount.filter((data) => data.id === fil.id)?.[0]?.valueOption ? (
                          <SingleSelect
                            placeholder={filterCount?.[counter]?.value?.name || 'select'}
                            name="value"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // value={values.typeId}
                            options={filterCount.filter((data) => data.id === fil.id)?.[0]?.valueOption || []}
                            className="pr-[40px]"
                            crossIcon={filterCount?.[counter]?.value?.name ? <CrossIcon /> : ''}
                            setFieldValueFull={(_, value) => {
                              if (!value) {
                                const setFilter = filterCount.map((data) => {
                                  if (data.id === fil.id) {
                                    return {
                                      ...data,

                                      value: '',
                                    };
                                  } else {
                                    return data;
                                  }
                                });

                                setFilterCount(setFilter);
                              } else {
                                const setFilter = filterCount.map((data) => {
                                  if (data.id === fil.id) {
                                    return {
                                      ...data,
                                      value: value,
                                    };
                                  } else {
                                    return data;
                                  }
                                });

                                setFilterCount(setFilter);
                              }
                            }}
                          />
                        ) : (
                          <FormText
                            placeholder={filterCount?.[counter]?.value?.name || 'Enter Name'}
                            name="value"
                            onChange={(e) => {
                              console.log(filterCount);
                              const setFilter = filterCount.map((data) => {
                                if (data.id === fil.id) {
                                  return {
                                    ...data,
                                    value: { name: e.target.value },
                                  };
                                } else {
                                  return data;
                                }
                              });

                              setFilterCount(setFilter);
                            }}
                            value={filterCount?.[counter]?.value?.name || ''}
                            onBlur={handleBlur}
                            className="pr-[40px]"
                            crossIcon={filterCount?.[counter]?.value?.name ? <CrossIcon /> : ''}
                            setFieldValue={(_, value) => {
                              if (!value) {
                                const setFilter = filterCount.map((data) => {
                                  if (data.id === fil.id) {
                                    return {
                                      ...data,

                                      value: '',
                                    };
                                  } else {
                                    return data;
                                  }
                                });
                                setFilterCount(setFilter);
                              }
                            }}
                          />
                        )}

                        {errors.value && touched.value && !filterCount?.[counter]?.value?.name && <div className="error text-[red] text-[12px] pt-[2px]">{errors.value}</div>}
                      </div>

                      {filterCount?.length > 1 && (
                        <div className="">
                          <Button
                            type="button"
                            text={''}
                            bg="bg-[#F1F5F7]"
                            color="text-white"
                            icon={<Cancel />}
                            cta={() => {
                              setFilterCount(filterCount?.filter((data) => data.id !== fil.id));
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="mt-[12px] mb-[15px]">
                <Button
                  type="button"
                  text="Add New"
                  bg="bg-white"
                  className="p-0"
                  color="text-black"
                  icon={<Add color="#775FD5" />}
                  cta={async () => {
                    validateForm().then((data) => {
                      if (Object.keys(data)?.length === 0) {
                        setFilterCount([
                          ...filterCount,
                          {
                            filter: '',
                            operator: '',
                            value: '',
                            id: Date.now(),
                          },
                        ]);
                      } else {
                        submitForm();
                      }
                    });
                  }}
                />
                <div className="w-full  py-[16px] flex items-center justify-between">
                  <Button
                    text="Search"
                    icon={<Search />}
                    color="text-white"
                    bg="bg-primary1"
                    type="submit"
                    disabled={
                      filterCount[filterCount.length - 1]?.filter && filterCount[filterCount.length - 1]?.operator && filterCount[filterCount.length - 1]?.value ? false : true
                    }
                    cta={() => {
                      setResultData(null);
                      setStartSearching(true);
                      const filterquerry = filterCount.map((data, counter) => {
                        return `${data?.filter?.id && `filter[${counter}].filter=${data?.filter?.id}`}&${
                          data?.operator?.id && `filter[${counter}].operator=${data?.operator?.id}`
                        }&${data?.value?.name && `filter[${counter}].Value=${data?.value?.name}`}`;
                      });

                      getAuditAction(1, activePageRows || 10, filterquerry?.join('&'));
                    }}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>

        {resultData ? (
          resultData.data?.length ? (
            <DataTable
              data={detail?.data || []}
              expandableIcon={{
                expanded: <RowExpand />,
                collapsed: <RowCollapsed />,
              }}
              className=" border-t-[1px] border-x-[1px]  border-solid border-[#DEE2E6] rounded-[4px] overflow-hidden  managment-table-st audit-table-design"
              columns={[
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'Who'} color="text-[#000000]" className="text-xs font-normal" />
                      </div>
                    </div>
                  ),

                  selector: (row) => (
                    <div className="flex gap-[10px]">
                      <HemaValue text={row?.user?.name || ''} />
                    </div>
                  ),
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'Action'} color="text-[#000000]" className="text-xs font-normal" />
                      </div>
                    </div>
                  ),
                  selector: (row) => (
                    <div className="">
                      <HemaValue text={row?.actionType?.name || ''} />
                    </div>
                  ),
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'What'} color="text-[#000000]" className="text-xs font-normal" />
                      </div>
                    </div>
                  ),

                  selector: (row) => (
                    <div className="">
                      <HemaValue text={row?.recordSearchKey || ''} />
                    </div>
                  ),
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'Where'} color="text-[#000000]" className="text-xs font-normal" />
                      </div>
                    </div>
                  ),

                  selector: (row) => (
                    <div className="">
                      <HemaValue text={row?.entityName || ''} />
                    </div>
                  ),
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'When'} color="text-[#000000]" className="text-xs font-normal" />
                      </div>
                    </div>
                  ),

                  selector: (row) => (
                    <div className="">
                      <HemaValue text={HemaDateFormat(row?.createdUtc) || ''} />
                    </div>
                  ),
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="py-[10px] w-full  px-[10px]">
                        <HemaValue text={''} className="pr-12 text-xs font-normal" color="text-[#000000]" />
                      </div>
                    </div>
                  ),
                  // cell: (row) => {
                  //   return (
                  //     <div className="flex-grow pr-6 four">
                  //       <div className="flex justify-end gap-[16px] meta ">
                  //         <Button
                  //           type="button"
                  //           icon={<Binocular />}
                  //           color="text-white"
                  //           bg="bg-bgActionDots"
                  //           cta={() => {
                  //             dispatch(editFormReducer(row));
                  //             setformName('Audit Trail Details');

                  //             dispatch(
                  //               setForm({
                  //                 state: true,
                  //                 type: 'audit-trail-detail',
                  //               })
                  //             );
                  //           }}
                  //         />
                  //       </div>
                  //     </div>
                  //   );
                  // },
                },
              ]}
              customStyles={{
                responsiveWrapper: {
                  style: { overflow: 'visible !important' },
                },
              }}
              expandableRows
              expandableRowsComponent={ExpendedDataRowsForAssembly}
              pagination
              paginationComponent={(e) => {
                return <Pagination e={e} updateData={updateData} activePage={activePage} setActivePageRows={setActivePageRows} totalRows={detail?.totalDataCount} />;
              }}
              paginationPerPage={activePageRows || 10}
              paginationTotalRows={detail?.totalDataCount}
            />
          ) : (
            startSearching && <Alert variant="warning">No Result Found.</Alert>
          )
        ) : (
          <div className="p-[20px]">
            {' '}
            <Skeleton count="5" />
            <br /> <Skeleton count="5" />
            <br />
            <Skeleton count="5" />
          </div>
        )}
      </div>

      {uisettings?.openform && <FormContainer formName={formName} setformName={setformName} />}
    </>
  ) : (
    <NoPermissionAlert />
  );
};

const AuditCapture = ({ data, newdata, reverse, borderRight, original }) => {
  console.log(data, newdata);
  return (
    <div className="flex flex-col bg-white ml-10 border-y-[1px] border-l !rounded-l-0 border-solid border-[#DEE2E6] main_audit_box mb-[2px]">
      <div className={`flex gap-[10px] py-[6px] pl-10 pr-[16px] border-b border-[#DEE2E6] audit-header ${borderRight && 'hidden'} `}>
        <HemaValue text={'Field Name'} color="text-[#000]" className="w-full text-xs font-medium" />
        <HemaValue text={'New Value'} color="text-[#000]" className="w-full text-xs font-medium" />
        <HemaValue text={'Old Value'} color="text-[#000]" className="w-full text-xs font-medium" />
      </div>
      <div>
        {data &&
          Object.keys(data || {})?.map((field) => {
            const matchFound = deepEqual(data?.[field], newdata?.[field]);
            if (matchFound && original?.entityName !== 'Item Category') {
              return '';
            } else if (
              data?.[field] === null ||
              (data?.[field] === null) === undefined ||
              data?.[field] === '' ||
              newdata?.[field] === null ||
              (newdata?.[field] === null) === undefined ||
              newdata?.[field] === ''
            ) {
              return (
                <div className={`flex gap-[10px] border-b border-[#DEE2E6]  !pl-10 pr-[16px] ${borderRight ? 'audit-body-1-2' : 'audit-body'}`}>
                  <HemaValue
                    color="text-[#595959]"
                    className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                    text={field}
                  />
                  <HemaValue
                    color="text-[#595959]"
                    className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                    text={data?.[field] || '---'}
                  />
                  <HemaValue color="text-[#595959]" className="w-full text-xs font-medium" text={newdata?.[field] || '---'} />
                </div>
              );
            } else if (Array.isArray(data?.[field])) {
              if ((data?.[field].length || 0) === 0 && (newdata?.[field]?.length || 0) === 0) {
                return;
              }
              return (
                <div className=" bg-hemaSecondary audit_box">
                  <div className="flex flex-col ">
                    {/* <HemaValue text={field} color="text-[#595959]" className=" pb-[10px]  w-[33%] text-[15px] bold" />
                     */}
                    <div className="audit_accordion">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="bg-primary1">
                            <div className="relative left-[-12px] ">
                              <div className="expand-icon">
                                <RowExpand />
                              </div>
                              <div className="not-expand-icon">
                                <RowCollapsed />
                              </div>
                            </div>
                            {/* <HemaValue
                            text={`${field} Details `}
                            className=" text-[15px] bold  w-[33%] text-xs font-medium"
                          /> */}
                            <div className="w-full flex gap-[10px]  ">
                              <HemaValue text={`${field} Details `} className=" text-[15px] bold  w-full text-xs font-medium" />
                              <HemaValue text={`---`} className=" text-[15px] bold  w-full text-xs font-medium" />
                              <HemaValue text={`---`} className=" text-[15px] bold  w-full text-xs font-medium" />
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {(data?.[field]?.length || 0) >= (newdata?.[field]?.length || 0)
                              ? data?.[field]?.map((loop, counter) => {
                                  return (
                                    <div className="object_audit_div ">
                                      <AuditCapture
                                        data={Object.keys(loop || {})?.length > Object.keys(newdata?.[field]?.[counter] || {})?.length ? loop : newdata?.[field]?.[counter]}
                                        newdata={Object.keys(loop || {})?.length > Object.keys(newdata?.[field]?.[counter] || {})?.length ? newdata?.[field]?.[counter] : loop}
                                        borderRight
                                        original={original}
                                        reverse
                                      />
                                      <hr />
                                    </div>
                                  );
                                })
                              : newdata?.[field]?.map((loop, counter) => {
                                  return (
                                    <div className={'object_audit_div '}>
                                      <AuditCapture
                                        data={Object.keys(loop || {})?.length > Object.keys(data?.[field]?.[counter] || {})?.length ? loop : data?.[field]?.[counter]}
                                        newdata={Object.keys(loop || {})?.length > Object.keys(data?.[field]?.[counter] || {})?.length ? data?.[field]?.[counter] : loop}
                                        borderRight
                                        original={original}
                                        reverse
                                      />
                                      <hr />
                                    </div>
                                  );
                                })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              );
            } else if (typeof data?.[field] === 'object') {
              return (
                <div className=" border-b border-[#DEE2E6]">
                  {/* <AuditCapture data={data?.[field]} /> */}
                  <div className="flex flex-col ">
                    {/* <HemaValue text={field} className=" text-[15px] bold pb-[10px] w-[33%] text-xs font-medium" />
                     */}
                    <div className="audit_accordion">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="relative left-[-12px] ">
                              <div className="expand-icon">
                                <RowExpand />
                              </div>
                              <div className="not-expand-icon">
                                <RowCollapsed />
                              </div>
                            </div>
                            {/* <HemaValue
                            text={`${field} Details`}
                            className=" text-[15px] bold  w-[33%] text-xs font-medium"
                          /> */}
                            <div className="w-full flex gap-[10px]  ">
                              <HemaValue text={`${field} Details `} className=" text-[15px] bold  w-full text-xs font-medium" />
                              <HemaValue text={`---`} className=" text-[15px] bold  w-full text-xs font-medium" />
                              <HemaValue text={`---`} className=" text-[15px] bold  w-full text-xs font-medium" />
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <AuditCapture
                              data={Object.keys(data?.[field] || {})?.length > Object.keys(newdata?.[field] || {})?.length ? data?.[field] : newdata?.[field]}
                              newdata={Object.keys(data?.[field] || {})?.length > Object.keys(newdata?.[field] || {})?.length ? newdata?.[field] : data?.[field]}
                              borderRight
                              original={original}
                              reverse
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={`flex gap-[10px] border-b border-[#DEE2E6] py-[6px] pl-10 pr-[16px] ${borderRight ? 'audit-body-1-2' : 'audit-body'}`}>
                  <HemaValue
                    text={field}
                    color="text-[#595959]"
                    className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                  />
                  {(!!reverse && original?.actionType?.name !== 'Delete') ||
                  original?.actionType?.name === 'Create' ||
                  (original?.actionType?.name !== 'Update' && reverse) ||
                  original?.actionType?.name === 'Other' ? (
                    <>
                      <HemaValue
                        color="text-[#595959]"
                        className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                        text={data?.[field] === false ? 'false' : data?.[field] === true ? 'true' : data?.[field] || '---'}
                      />
                      <HemaValue
                        color="text-[#595959]"
                        className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                        text={newdata?.[field] === false ? 'false' : newdata?.[field] === true ? 'true' : newdata?.[field] || '---'}
                      />
                    </>
                  ) : (
                    <>
                      <HemaValue
                        color="text-[#595959]"
                        className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                        text={newdata?.[field] === false ? 'false' : newdata?.[field] === true ? 'true' : newdata?.[field] || '---'}
                      />
                      <HemaValue
                        color="text-[#595959]"
                        className={`w-full text-xs font-medium break-words ${borderRight && 'border-r-[2px] border-solid border-[#DEE2E6] !py-[6px]'}`}
                        text={data?.[field] === false ? 'false' : data?.[field] === true ? 'true' : data?.[field] || '---'}
                      />
                    </>
                  )}

                  {/* //)} */}
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default Audit;

function deepEqual(value1, value2) {
  if (typeof value1 !== typeof value2) {
    return false;
  }

  if (typeof value1 !== 'object' || value1 === null || value2 === null) {
    return value1 === value2;
  }

  if (Array.isArray(value1)) {
    if (!Array.isArray(value2) || value1.length !== value2.length) {
      return false;
    }

    for (let i = 0; i < value1.length; i++) {
      if (!deepEqual(value1[i], value2[i])) {
        return false;
      }
    }

    return true;
  }

  const keys1 = Object.keys(value1);
  const keys2 = Object.keys(value2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(value1[key], value2[key])) {
      return false;
    }
  }

  return true;
}

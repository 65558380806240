import {
  FileIcon,
  Language,
  LabelName,
  LabelUnitType,
  LabelCategory,
  LabelUnitSize,
  LabelDescription,
  ChangeReason,
} from '../../../HemeIconLibrary';

export const createNewItemVersion = [
  {
    label: 'language',
    icon: <Language />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select language',
    required: true,
    name: 'languageId',
  },
  {
    label: 'name',
    icon: <LabelName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter Name',
    required: true,
    name: 'name',
  },
  {
    name: 'description',
    label: 'description',
    icon: <LabelDescription purple />,
    type: 'textarea',
    initialValue: '',
    placeholder: 'Enter Description',
    fluid: true,
    required: true,
  },
  {
    name: 'itemCategoryId',
    label: 'Category',
    icon: <LabelCategory />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select Item Type',
    options: [],
    required: true,
  },
  {
    name: 'unitTypeId',
    label: 'unit type',
    icon: <LabelUnitType />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select Unit Type',
    options: [],
  },
  {
    name: 'unitSize',
    label: 'unit size',
    icon: <LabelUnitSize />,
    type: 'number',
    initialValue: '',
    placeholder: 'Select Unit size',
    min: '1',
    step: '1',
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <LabelDescription purple />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    required: true,
  },
];

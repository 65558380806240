import { useEffect, useState, useRef } from "react"
import { getFullName } from "../../pages/Settings/Utils"
import { useDispatch } from "react-redux"
import { BlackSearch } from "../../HemeIconLibrary"
import "./style.scss"

const FilterColumn = (props) => {
  const dispatch = useDispatch()
  const {
    options,
    columnName,
    type,
    boolTrueText,
    boolFalseText,
    setRedux,
    reduxValues,
    callFilterData,
    searchFilter,
    filterData

  } = props
  const [allChecks, setAllChecks] = useState([])
  const [show, setShow] = useState(false)
  const [filteredOptions, setFilteredData] = useState(options);

  // Actually, we don’t use these two lines; they’re just there to prevent the warning about needing an `onChange` method for a controlled field. We can refine this approach and follow best practices later.
  const [isChecked, setIsChecked] = useState(false)
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  //

  const filterOptions = (filterText) => {
    const filtered = options?.filter(item => item?.toString().toLowerCase().includes(filterText?.toLowerCase()));
    setFilteredData(filtered);

  }


  useEffect(() => {
    setFilteredData(options)
    if (!setRedux && callFilterData) {
      callFilterData(columnName, allChecks)
    }
  }, [allChecks, options ])

  useEffect(() => {
    const closeModel = () => {
      const rootId = document.getElementById("root")
      if (rootId) {
        setShow(show)
      }
    }
    window.addEventListener("click", closeModel)
  }, [])

  
  return (
    <div
      className="w-full "
      onClick={(e) => {
        e.stopPropagation()
        setShow(!show)
      }}
    >
      <div className=" overflow-hidden relative h-[25px] cursor-pointer my-[10px] flex max-w-full justify-between border border-[#DEE2E6] rounded-[5px] py-[2.5px] px-[10px] items-center">
        <div className=" flex  gap-[10px]  items-center">
          <BlackSearch />

          <div className="text-[12px] flex items-center ">

            {searchFilter &&
              <input
                type="search"
                placeholder="Filter"
                onChange={(e) => { filterOptions(e.target.value) }}
                className="outline-none w-[50px]"
              />
            }

            <p className="text-[12px] leading-[18px] font-medium text-[#595959] m-0">
              Filter
            </p>
            {/* )} */}
            &nbsp;
            {!!allChecks.length && (
              <div className="flex items-center">
                <p className="m-0">(&nbsp;</p>
                <p className="overflow-hidden inline-block whitespace-nowrap text-ellipsis max-w-[80px] m-0">
                  {allChecks[0] === true
                    ? boolTrueText
                    : allChecks[0] === false
                      ? boolFalseText
                      : allChecks[0]}
                </p>
                <p className="m-0">&nbsp;)</p>
                <div className="flex">
                  {allChecks.length > 1 && "+" + String(allChecks.length - 1)}
                  &nbsp;
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => {
            setAllChecks([])
            dispatch(setRedux({ ...reduxValues, [columnName]: [] }))
          }}
          className="absolute px-[5px] bg-white z-[1] right-[0]"
        >
          {!!allChecks.length && "x"}
        </div>
      </div>
      <div
        className={`${show ? "block" : "hidden"
          } max-h-[320px] overflow-y-auto overflow-x-hidden absolute max-w-[-webkit-fill-available] w-auto  bg-white p-[10px] mt-[px] z-[999] border border-[#ccc] rounded-[5px] top-[80px]`}
      >
        {filteredOptions?.map((val, index) => {
          // if(searchFilter === true) {
          //   if (!val?.toLowerCase()?.includes(search?.toLowerCase()) && search) {
          //     return
          //   }
          // }
          return (
            <div
              key={index}
              className="pb-[7px] "
              onClick={(e) => {
                e.stopPropagation()
                if (allChecks.includes(val)) {
                  if (type === "boolean") {
                    setAllChecks(
                      allChecks.filter((data) => (data !== val ? true : false))
                    )
                  } else {
                    setAllChecks(allChecks.filter((data) => data !== val))
                  }
                  if (setRedux) {
                    dispatch(
                      setRedux({
                        ...reduxValues,
                        [columnName]: reduxValues[columnName].filter(
                          (data) => data !== val
                        ),
                      })
                    )
                  }
                } else {
                  if (type === "boolean") {
                    setAllChecks([...allChecks, !!val])
                  } else {
                    setAllChecks([...allChecks, val])
                  }
                  if (setRedux) {
                    if (reduxValues[columnName]) {
                      dispatch(
                        setRedux({
                          ...reduxValues,
                          [columnName]: [...reduxValues[columnName], val],
                        })
                      )
                    } else {
                      dispatch(
                        setRedux({ ...reduxValues, [columnName]: [val] })
                      )
                    }
                  }
                }

                if(filterData)
                filterData(val, columnName)
              }}
            >
              <div className="flex items-center gap-[10px] whitesapce-pre-wrap text-[12px] leading-[18px] font-medium text-[#595959]">
                <input type="checkbox" checked={allChecks.includes(val)} onChange={handleChange} />
                <p className="m-0">
                  {type === "boolean"
                    ? val
                      ? boolTrueText
                      : boolFalseText
                    : val || "N/A"}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FilterColumn

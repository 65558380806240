import React from 'react';

export const StudyName = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2V2.28571V6H6V2H2ZM10 2V2.28571V6H14V2H10ZM2.57143 2.57143H5.42857V5.42857H2.57143V2.57143ZM6.57143 2.57143V4.28571H7.71429V5.14286H6.57143V6.57143H4V7.71429H2.85714V9.42857H4.57143V8.28571H5.42857V9.42857H7.14286V7.14286H7.71429V8.28571H9.71429V9.14286H11.1429V10H8.85714V11.1429H7.71429V12.8571H9.42857V11.7143H11.1429V14H14V11.1429H11.7143V10.5714H14V7.71429H12.2857V8.57143H11.7143V6.57143H8.28571V5.71429H9.42857V3.71429H8.28571V2.57143H6.57143ZM10.5714 2.57143H13.4286V5.42857H10.5714V2.57143ZM3.14286 3.14286V4.85714H4.85714V3.14286H3.14286ZM11.1429 3.14286V4.85714H12.8571V3.14286H11.1429ZM2 10V10.2857V14H6V10H2ZM2.57143 10.5714H5.42857V13.4286H2.57143V10.5714ZM3.14286 11.1429V12.8571H4.85714V11.1429H3.14286Z"
        fill="url(#paint0_linear_5100_186231)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5100_186231"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

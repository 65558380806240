import {
  createKitBuilder,
  getAllBuilder,
  buildKitItemDetail,
  getAllKitItemList,
  buildNewKit,
  removeBuildKit,
} from "../Services/kitBuilder";
import {
  getAllTemplate,
  updateBuildKit,
  addnewTemplate,
  removeBuildKitreducer,
  setKitItems,
} from "../Store/reducers/kitBuilder";
import { caseInsensitiveSort } from "../helpers/commonHelper";

import { store } from "../Store";

const createKitBuilderAction = async (data) => {
  const result = await createKitBuilder(data);
  if (result.status === 200) {
    // let obj = JSON.parse(JSON.stringify(result.data?.item));
    // obj.id = result.data?.id;
    store.dispatch(addnewTemplate(result.data));
  }
  return result;
};

const getKitBuilderAction = async () => {
  const result = await getAllBuilder();
  if (result.status === 200) {
    store.dispatch(getAllTemplate(result.data?.sort(caseInsensitiveSort)));
    return result;
  }
};
const getAllKitItemListAction = async (id) => {
  const result = await getAllKitItemList(id);
  if (result.status === 200) {
    const items = Array.isArray(result.data?.items) ? result.data.items : [];
    store.dispatch(setKitItems(items.sort(caseInsensitiveSort)));
  } else {
    store.dispatch(setKitItems([]));
  }
};

const buildKitItemDetailAction = async (id, quantity) => {
  const result = await buildKitItemDetail(id, quantity);
  if (result.status === 200) {
    store.dispatch(updateBuildKit(result.data));
  }
  return result;
};

const buildNewKitAction = async (id, data) => {
  const result = await buildNewKit(id, data);
  if (result.status === 200) {
    //   store.dispatch(updateBuildKit(result.data))
  }
  return result;
};

const removeBuildKitAction = async (id, reason) => {
  const result = await removeBuildKit(id, reason);
  if (result.status === 200) {
    store.dispatch(removeBuildKitreducer(id));
  }
  return result;
};

export {
  removeBuildKitAction,
  createKitBuilderAction,
  getKitBuilderAction,
  getAllKitItemListAction,
  buildKitItemDetailAction,
  buildNewKitAction,
};

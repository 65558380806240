import React from "react";

const PassLock = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 6H11.375V4.28571C11.375 1.92 9.415 0 7 0C4.585 0 2.625 1.92 2.625 4.28571H4.375C4.375 2.86286 5.5475 1.71429 7 1.71429C8.4525 1.71429 9.625 2.86286 9.625 4.28571V6H1.75C0.7875 6 0 6.77143 0 7.71429V16.2857C0 17.2286 0.7875 18 1.75 18H12.25C13.2125 18 14 17.2286 14 16.2857V7.71429C14 6.77143 13.2125 6 12.25 6ZM12.25 16.2857H1.75V7.71429H12.25V16.2857ZM7 13.7143C7.9625 13.7143 8.75 12.9429 8.75 12C8.75 11.0571 7.9625 10.2857 7 10.2857C6.0375 10.2857 5.25 11.0571 5.25 12C5.25 12.9429 6.0375 13.7143 7 13.7143Z"
        fill="#605DAF"
      />
    </svg>
  );
};

export default PassLock;

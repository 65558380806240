import React from 'react';

export const TableSortIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4664 11.4404H8.53374C7.6723 11.4404 7.19033 10.5178 7.7228 9.88892L11.2772 5.80719C11.3788 5.69192 11.5039 5.5996 11.644 5.53637C11.784 5.47313 11.936 5.44043 12.0897 5.44043C12.2434 5.44043 12.3953 5.47313 12.5354 5.53637C12.6755 5.5996 12.8005 5.69192 12.9021 5.80719L16.2773 9.88892C16.8113 10.5178 16.3278 11.4404 15.4664 11.4404Z"
        fill="#C7C7C7"
      />
      <path
        d="M8.53423 13.4404H15.462C16.3234 13.4404 16.8069 14.363 16.2729 14.9918L12.9024 19.0737C12.8007 19.189 12.6757 19.2813 12.5356 19.3445C12.3956 19.4077 12.2436 19.4404 12.0899 19.4404C11.9363 19.4404 11.7843 19.4077 11.6443 19.3445C11.5042 19.2813 11.3792 19.189 11.2775 19.0737L7.7218 14.9918C7.1909 14.363 7.67284 13.4404 8.53423 13.4404Z"
        fill="#737373"
      />
    </svg>
  );
};

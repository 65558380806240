import React from 'react';

export const TabExpired = ({ purple }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46873 0.8125C2.90231 0.8125 0 3.71481 0 7.28123C0 10.5954 2.50692 13.3328 5.72331 13.7036C5.765 13.5951 5.79842 13.4842 5.85808 13.3822L6.07426 13.0116L9.63712 6.90221C9.98643 6.30349 10.6108 5.92746 11.2936 5.85918C11.3633 5.85199 11.4302 5.83251 11.5014 5.83251C11.6617 5.83251 11.8172 5.85906 11.9702 5.89427C12.2966 5.97334 12.6008 6.12441 12.8603 6.33788C12.4017 3.21779 9.7146 0.8125 6.46873 0.8125ZM5.74999 3.68749H7.18748V7.57884L4.82067 9.94566L3.80431 8.9293L5.74999 6.98363V3.68749ZM11.5 7.27C11.2588 7.27 11.0182 7.38866 10.8795 7.62657L7.09904 14.1065C6.81945 14.5859 7.16465 15.1875 7.71952 15.1875H15.2804C15.8353 15.1875 16.1805 14.5859 15.9009 14.1065L12.1205 7.62657C11.9817 7.38866 11.7411 7.27 11.5 7.27ZM10.7812 9.43748H12.2187V12.3125H10.7812V9.43748ZM10.7812 13.0312H12.2187V14.4687H10.7812V13.0312Z"
        fill={purple ? 'url(#paint0_linear_5413_182760)' : '#414753'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_5413_182760"
          x1="1.86436"
          y1="15.0787"
          x2="14.253"
          y2="15.2114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import {
  SiteName,
  Address,
  FirstName,
  Language,
  TrackingNumber,
  CityIcon,
  StateIcon,
  PostalIcon,
  LotNumberIcon,
  CountryFlag,
  Email,
  Telephone,
  IncotermIcon,
} from '../../../HemeIconLibrary'
import { countries } from 'country-flag-icons'
import ChangeReason from '../../../assets/images/change-reason.svg'

export const AddDepotSettings = [
  {
    type: 'heading-only',
    initialValue: 'Depot Details',
    fluid: true,
  },
  {
    label: 'Name',
    icon: <SiteName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter depot name',
    required: true,
    name: 'name',
    fluid: true,
  },
  {
    label: 'EORI',
    icon: <TrackingNumber />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'eori',
  },
  {
    label: 'Language',
    icon: <Language />,
    type: 'select',
    initialValue: '',
    fluid: false,
    placeholder: 'Select',
    required: true,
    name: 'languageId',
  },
  {
    label: 'Courier',
    icon: <LotNumberIcon />,
    type: 'select',
    initialValue: '',
    fluid: false,
    placeholder: 'Select',
    required: true,
    name: 'courierId',
  },
  {
    label: 'Courier Service Type',
    icon: <LotNumberIcon />,
    type: 'select',
    initialValue: '',
    fluid: false,
    placeholder: 'Select',
    required: false,
    name: 'Servicetype',
    options: [],
  },
  {
    type: 'heading-only',
    initialValue: 'Broker Details',
    fluid: true,
  },
  {
    label: 'Broker Contact Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'BrokerContactname',
  },
  {
    label: 'Broker Company Name',
    icon: <SiteName />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'BrokerCompanyname',
  },
  {
    label: 'Email',
    icon: <Email />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter Email',
    required: true,
    name: 'brokerEmail',
  },
  {
    label: 'Phone Number',
    icon: <Telephone />,
    type: 'number',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'brokerPhone',
    min: 1,
  },
  {
    label: 'Address 1',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: true,
    name: 'brokerAddress1',
  },
  {
    label: 'Address 2',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: false,
    name: 'brokerAddress2',
  },
  {
    label: 'City',
    icon: <CityIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter City',
    required: true,
    name: 'brokercity',
  },
  {
    label: 'State',
    icon: <StateIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter State',
    required: true,
    name: 'brokerstate',
  },
  {
    name: 'brokercountry',
    label: 'Country',
    icon: <CountryFlag />,
    type: 'singleSelect',
    required: true,
    fluid: false,
    initialValue: '',
    placeholder: 'Select country',
    options: [],
  },
  {
    label: 'Postal Code',
    icon: <PostalIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter postal code',
    required: true,
    name: 'borkerPostalcode',
  },
  {
    label: 'Broker EORI',
    icon: <TrackingNumber />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: false,
    name: 'brokerEORI',
  },
  {
    label: 'Broker Tax ID',
    icon: <IncotermIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: false,
    name: 'brokerTaxID',
  },
  {
    type: 'heading-only',
    initialValue: 'IOR Details',
    fluid: true,
  },
  {
    label: 'IOR Contact Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'IORContactname',
  },
  {
    label: 'IOR Company Name',
    icon: <SiteName />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'IORCompanyname',
  },
  {
    label: 'Email',
    icon: <Email />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter Email',
    required: true,
    name: 'IOREmail',
  },
  {
    label: 'Phone Number',
    icon: <Telephone />,
    type: 'number',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: true,
    name: 'IORPhone',
    min: 1,
  },
  {
    label: 'Address 1',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: true,
    name: 'IORAddress1',
  },
  {
    label: 'Address 2',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: false,
    name: 'IORAddress2',
  },
  {
    label: 'City',
    icon: <CityIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter City',
    required: true,
    name: 'IORcity',
  },
  {
    label: 'State',
    icon: <StateIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter State',
    required: true,
    name: 'IORstate',
  },
  {
    name: 'IORcountry',
    label: 'Country',
    icon: <CountryFlag />,
    type: 'singleSelect',
    required: true,
    fluid: false,
    initialValue: '',
    placeholder: 'Select country',
    options: [],
  },
  {
    label: 'Postal Code',
    icon: <PostalIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter postal code',
    required: true,
    name: 'IORPostalcode',
  },
  {
    label: 'IOR EORI',
    icon: <TrackingNumber />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: false,
    name: 'ioreori',
  },
  {
    label: 'IOR Tax ID',
    icon: <IncotermIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter',
    required: false,
    name: 'iorTaxID',
  },
]

export const ViewDepotSettings = [
  {
    type: 'heading-only',
    initialValue: 'Depot Details',
    fluid: true,
  },
  {
    label: 'Name',
    initialValue: '',
    name: 'name',
    fluid: true,
    type: 'table',
  },
  {
    label: 'EORI',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'eori',
    type: 'table',
  },
  {
    label: 'Language',
    initialValue: '',
    fluid: true,
    placeholder: 'Select',
    name: 'languageId',
    type: 'table',
  },
  {
    label: 'Courier',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'courier',
    type: 'table',
  },
  {
    label: 'Courier Service Type',
    initialValue: '',
    fluid: true,
    placeholder: 'Select',
    name: 'Servicetype',
    type: 'table',
  },
  {
    type: 'heading-only',
    initialValue: 'Broker Details',
    fluid: true,
  },
  {
    label: 'Broker Contact Name',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'BrokerContactname',
    type: 'table',
  },
  {
    label: 'Broker Company Name',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'BrokerCompanyname',
    type: 'table',
  },
  {
    label: 'Email',
    initialValue: '',
    placeholder: 'Enter',
    fluid: true,
    name: 'brokeremail',
    disabled: false,
    type: 'table',
  },
  {
    label: 'Phone Number',
    initialValue: '',
    placeholder: 'Phone Number',
    fluid: true,
    name: 'brokerphoneNo',
    type: 'table',
  },
  {
    label: 'Address 1',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'brokeersiteAddress1',
    type: 'table',
  },
  {
    label: 'Address 2',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'brokersiteAddress2',
    type: 'table',
  },
  {
    label: 'City',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter City',
    name: 'brokercity',
    type: 'table',
  },
  {
    label: 'State',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter State',
    name: 'brokerstate',
    type: 'table',
  },
  {
    label: 'Country',
    initialValue: '',
    fluid: true,
    placeholder: 'Select',
    name: 'brokercountry',
    type: 'table',
  },
  {
    label: 'Postal Code',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter postal code',
    name: 'brokerpostalcode',
    type: 'table',
  },
  {
    label: 'Broker EORI',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'brokerEORI',
    type: 'table',
  },
  {
    label: 'Broker Tax ID',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'brokerTaxID',
    type: 'table',
  },
  {
    type: 'heading-only',
    initialValue: 'IOR Details',
    fluid: true,
  },
  {
    label: 'IOR Contact Name',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'IORContactname',
    type: 'table',
  },
  {
    label: 'IOR Company Name',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'iorCompanyname',
    type: 'table',
  },
  {
    label: 'Email',
    initialValue: '',
    placeholder: 'Enter',
    fluid: true,
    name: 'ioremail',
    disabled: false,
    type: 'table',
  },
  {
    label: 'Phone Number',
    initialValue: '',
    placeholder: 'Phone Number',
    fluid: true,
    name: 'IORphoneNo',
    type: 'table',
  },
  {
    label: 'Address 1',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'IORsiteAddress1',
    type: 'table',
  },
  {
    label: 'Address 2',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'IORsiteAddress2',
    type: 'table',
  },
  {
    label: 'City',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter City',
    name: 'IORcity',
    type: 'table',
  },
  {
    label: 'State',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter State',
    name: 'IORstate',
    type: 'table',
  },
  {
    label: 'Country',
    initialValue: '',
    fluid: true,
    placeholder: 'Select',
    name: 'IORcountry',
    type: 'table',
  },
  {
    label: 'Postal Code',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter postal code',
    name: 'IORpostalcode',
    type: 'table',
  },
  {
    label: 'IOR EORI',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'ioreori',
    type: 'table',
  },
  {
    label: 'IOR Tax ID',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'iorTaxID',
    type: 'table',
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <img src={ChangeReason} alt="icon" />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    required: true,
    placeholder: 'Type reason',
  },
]

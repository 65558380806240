import React from 'react';
export const ParentLocation = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4597 14.6663C13.6441 14.6663 13.793 14.5173 13.793 14.333V11.9997H12.1264C12.1264 12.368 11.8281 12.6663 11.4597 12.6663C11.0914 12.6663 10.793 12.368 10.793 11.9997H9.12638V14.6663H13.4597ZM8.45964 4.85107H6.79297C6.79297 5.21941 6.49464 5.51774 6.1263 5.51774C5.75797 5.51774 5.45964 5.21941 5.45964 4.85107H3.79297L3.79305 11.333H8.45972L8.45964 4.85107ZM6.79305 11.9997C6.79305 12.368 6.49472 12.6663 6.12638 12.6663C5.75805 12.6663 5.45972 12.368 5.45972 11.9997H3.79305V14.333C3.79305 14.5173 3.94205 14.6663 4.12638 14.6663H8.45972V11.9997H6.79305ZM8.45972 3.99967V1.33301H4.12638C3.94205 1.33301 3.79305 1.48201 3.79305 1.66634V3.99967H8.45972ZM9.12638 6.66634H13.793V11.333H9.12638V6.66634ZM9.12638 5.99967H13.793V1.66634C13.793 1.48201 13.6441 1.33301 13.4597 1.33301H9.12638V5.99967Z"
        fill="url(#paint0_linear_1188_47721)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_47721"
          x1="3.79297"
          y1="7.99967"
          x2="13.793"
          y2="7.99967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { Location, LabelDate } from '../../../HemeIconLibrary';
// import { countries } from "country-flag-icons";

// let countryOptions = countries.map((item) => {
//   const Imageicon = ()=>{
//     return (<img
//     width={20}
//     height={20}
//     alt="United States"
//     src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item}.svg`}
//   />)
//   }
//   return {
//     id: item,
//     name: new Intl.DisplayNames(['en'], {type: 'region'}).of(item),
//     icon: <Imageicon/>
//   };
// });

export const NewShippingAddress = [
  // {
  //   label: 'Search for an address',
  //   icon: <Location />,
  //   type: 'text',
  //   initialValue: '',
  //   placeholder: 'Search for an address',
  //   required: false,
  //   fluid: true,
  //   name: 'searchAddress',
  // },
  {
    label: 'Street 1',
    icon: '',
    type: 'text',
    initialValue: '',
    placeholder: 'Street 1',
    required: true,
    fluid: false,
    name: 'street1',
  },
  {
    label: 'Street 2',
    icon: '',
    type: 'text',
    initialValue: '',
    placeholder: 'Street 2',
    required: false,
    fluid: false,
    name: 'street2',
  },
  {
    label: 'City',
    icon: '',
    type: 'text',
    initialValue: '',
    placeholder: 'City',
    required: true,
    fluid: false,
    name: 'city',
  },
  {
    label: 'State/Region',
    icon: '',
    type: 'text',
    initialValue: '',
    placeholder: 'State/Region',
    required: true,
    fluid: false,
    name: 'stateRegion',
  },
  {
    label: 'Postal Code',
    icon: '',
    type: 'text',
    initialValue: '',
    placeholder: 'Postal Code',
    required: true,
    fluid: false,
    name: 'postalCode',
  },
  {
    label: 'Select a Country',
    icon: <LabelDate />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select a Country',
    required: true,
    name: 'selectCountry',
    fluid: false,
    options: [],
  },
];

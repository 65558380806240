import { ChangeReason } from '../../../HemeIconLibrary';
export const deleteEmailTemplates = [
  {
    type: 'heading-only',
    initialValue: 'Email Templates Details',
    fluid: true,
  },
  {
    label: 'Email Template Name',
    initialValue: '',
    name: 'name',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Email Subject',
    initialValue: '',
    name: 'subject',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Email Template Type',
    initialValue: '',
    name: 'type',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Email Body',
    initialValue: '',
    name: 'emailBody',
    fluid: true,
    type: 'textarea',
    disabled: true,
    className: 'delete-email-templates-textarea',
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    placeholder: 'Type Reason',
    type: 'textarea',
    initialValue: '',
    fluid: true,
    required: true,
  },
];

import React from 'react';

export const Height = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9359 11.4368C11.0442 11.3502 11.0984 11.2256 11.0984 11.0983C11.0984 11.0035 11.0686 10.9087 11.0036 10.8274C10.8546 10.6406 10.5811 10.6108 10.3942 10.7597L8.93171 11.9297V5.06682L10.3942 6.23682C10.5811 6.38578 10.8546 6.35599 11.0036 6.16911C11.0686 6.08786 11.0984 5.99307 11.0984 5.89828C11.0984 5.77099 11.0415 5.64641 10.9359 5.55974L8.76921 3.82641C8.61212 3.69911 8.38733 3.69911 8.22754 3.82641L6.06087 5.55974C5.874 5.7087 5.84421 5.98224 5.99317 6.16911C6.14212 6.35599 6.41567 6.38578 6.60254 6.23682L8.06504 5.06682V11.9297L6.60254 10.7597C6.41567 10.6108 6.14212 10.6406 5.99317 10.8274C5.84421 11.0143 5.874 11.2879 6.06087 11.4368L8.22754 13.1702C8.38462 13.2974 8.60942 13.2974 8.76921 13.1702L10.9359 11.4368Z"
        fill="url(#paint0_linear_10370_288504)"
        stroke="url(#paint1_linear_10370_288504)"
        strokeWidth="0.5"
      />
      <path
        d="M14.5667 2H2.43333C2.195 2 2 2.195 2 2.43333V14.5667C2 14.805 2.195 15 2.43333 15H14.5667C14.805 15 15 14.805 15 14.5667V2.43333C15 2.195 14.805 2 14.5667 2ZM14.1333 14.1333H2.86667V2.86667H14.1333V14.1333Z"
        fill="url(#paint2_linear_10370_288504)"
        stroke="url(#paint3_linear_10370_288504)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10370_288504"
          x1="8.4984"
          y1="13.2656"
          x2="8.4984"
          y2="3.73094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10370_288504"
          x1="8.4984"
          y1="13.2656"
          x2="8.4984"
          y2="3.73094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10370_288504"
          x1="2"
          y1="8.49999"
          x2="15"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10370_288504"
          x1="2"
          y1="8.49999"
          x2="15"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import {
  SiteName,
  Address,
  FirstName,
  Email,
  Telephone,
  ChangeReason,
  CityIcon,
  StateIcon,
  PostalIcon,
  CountryFlag,
  TabShipping,
} from '../../../HemeIconLibrary'

export const AddTestingLabSettings = [
  {
    label: 'Name',
    icon: <SiteName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter Name',
    required: true,
    name: 'name',
    fluid: true,
  },
  {
    label: 'Address 1',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: true,
    name: 'address1',
  },
  {
    label: 'Address 2',
    icon: <Address />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    required: false,
    name: 'address2',
  },
  {
    label: 'City',
    icon: <CityIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter City',
    required: true,
    name: 'city',
  },
  {
    label: 'State',
    icon: <StateIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter State',
    required: true,
    name: 'stateAddress',
  },
  {
    label: 'Country',
    icon: <CountryFlag />,
    type: 'singleSelect',
    initialValue: '',
    fluid: false,
    placeholder: 'Select',
    required: true,
    name: 'countryId',
  },
  {
    label: 'Postal Code',
    icon: <PostalIcon />,
    type: 'text',
    initialValue: '',
    fluid: false,
    placeholder: 'Enter postal code',
    required: true,
    name: 'postalCode',
  },
  {
    label: 'Contact Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Contact Name',
    required: true,
    name: 'contactName',
  },
  {
    icon: <Email />,
    label: 'Email',
    type: 'text',
    initialValue: '',
    placeholder: 'Enter email',
    fluid: false,
    name: 'email',
    required: true,
    // disabled: true,
  },
  {
    label: 'Phone Number',
    icon: <Telephone />,
    type: 'number',
    initialValue: '',
    placeholder: 'Phone Number',
    required: true,
    fluid: false,
    name: 'phoneNo',
  },
  {
    label: 'Is Fulfillment Facility?',
    icon: <TabShipping purple w="18" h="18" />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select',
    required: true,
    fluid: true,
    name: 'Facility',
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
]

export const DeleteTestingLabSettings = [
  {
    type: 'heading-only',
    initialValue: 'Testing Lab Details',
    fluid: true,
  },
  {
    label: 'Name',
    type: 'table',
    initialValue: '',
    name: 'name',
    fluid: true,
  },
  {
    label: 'Address',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'address1',
  },
  {
    label: 'Address 2',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'address2',
  },
  {
    label: 'City',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'city',
  },
  {
    label: 'State',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'stateAddress',
  },
  {
    label: 'Country',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'countryId',
  },
  {
    label: 'Postal Code',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'postalCode',
  },
  {
    label: 'Contact Name',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'contactName',
  },
  {
    label: 'Email',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'email',
  },
  {
    label: 'Phone Number',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'phoneNo',
  },
  {
    label: 'Is Fulfillment Facility?',
    type: 'table',
    initialValue: '',
    fluid: true,
    name: 'Facility',
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    required: true,
    initialValue: '',
    placeholder: 'Type reason',
  },
]

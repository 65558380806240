import React from 'react';

export const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6 7.4C5.26863 7.4 5 7.66863 5 8C5 8.33137 5.26863 8.6 5.6 8.6H10.4C10.7314 8.6 11 8.33137 11 8C11 7.66863 10.7314 7.4 10.4 7.4H5.6ZM8 2C4.688 2 2 4.688 2 8C2 11.312 4.688 14 8 14C11.312 14 14 11.312 14 8C14 4.688 11.312 2 8 2ZM8 12.8C5.354 12.8 3.2 10.646 3.2 8C3.2 5.354 5.354 3.2 8 3.2C10.646 3.2 12.8 5.354 12.8 8C12.8 10.646 10.646 12.8 8 12.8Z"
        fill="white"
      />
    </svg>
  );
};

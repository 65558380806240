import React from 'react';

export const TabTask = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4878 17.1675L14.8259 19.5056L19.5021 14.8295M18.3382 10.1551H20.2869C21.4314 10.1184 22.3898 11.0145 22.4298 12.1589V22.1788C22.3904 23.3236 21.4318 24.2205 20.2869 24.1837H11.7132C10.5687 24.2205 9.61032 23.3243 9.57031 22.1799V12.1589C9.61032 11.0145 10.5687 10.1184 11.7132 10.1551H13.662M18.3369 10.1545C18.3369 8.8632 17.2901 7.81641 15.9989 7.81641C14.7076 7.81641 13.6608 8.8632 13.6608 10.1545H18.3369Z"
        stroke={purple ? 'url(#paint0_linear_5498_185603)' : '#414753'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_185603"
          x1="11.0687"
          y1="18.1168"
          x2="21.0253"
          y2="18.2415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const Copy = ({ color }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 1.75475C8.82495 1.7639 8.4197 1.80298 8.09202 1.96994C7.71569 2.16169 7.40973 2.46765 7.21799 2.84397C7.05103 3.17165 7.01194 3.5769 7.0028 4.25195M18.5 1.75475C19.1751 1.7639 19.5803 1.80298 19.908 1.96994C20.2843 2.16169 20.5903 2.46765 20.782 2.84397C20.949 3.17165 20.9881 3.5769 20.9972 4.25194M20.9972 13.2519C20.9881 13.927 20.949 14.3323 20.782 14.6599C20.5903 15.0363 20.2843 15.3422 19.908 15.534C19.5803 15.7009 19.1751 15.74 18.5 15.7492M21 7.75195V9.75195M13.0001 1.75195H15M4.2 21.752H11.8C12.9201 21.752 13.4802 21.752 13.908 21.534C14.2843 21.3422 14.5903 21.0363 14.782 20.6599C15 20.2321 15 19.6721 15 18.552V10.952C15 9.83185 15 9.2718 14.782 8.84397C14.5903 8.46765 14.2843 8.16169 13.908 7.96994C13.4802 7.75195 12.9201 7.75195 11.8 7.75195H4.2C3.0799 7.75195 2.51984 7.75195 2.09202 7.96994C1.71569 8.16169 1.40973 8.46765 1.21799 8.84397C1 9.2718 1 9.83185 1 10.952V18.552C1 19.6721 1 20.2321 1.21799 20.6599C1.40973 21.0363 1.71569 21.3422 2.09202 21.534C2.51984 21.752 3.07989 21.752 4.2 21.752Z"
        stroke={color || '#3D88E0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

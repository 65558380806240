import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useReactToPrint } from 'react-to-print'
import { RequistionForm } from './alldocumentForm/requistionForm'
import { ReturnAirWay } from './alldocumentForm/return-air-way'
import { AirWay } from './alldocumentForm/awb'

import { CustomInvoice } from './alldocumentForm/custom-invoice'
import { PackagingSlip } from './alldocumentForm/packaging-slip'
import { ProformaInvoice } from './alldocumentForm/pro-forma'
import { Alert } from '../../utils'
import Skeleton from 'react-loading-skeleton'
import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText'
import CustomInvoiceValidation from './formValidation/customInvoiceValidation'
import {
  showSuccessReducer,
  setFormLoaderReducer,
  setForm,
} from '../../Store/reducers/uiSettings'
import PDF from './pdf'
import DocumentEditor from '../documentEditor'
import {
  setBuildTemplate,
  setdocumentdetail,
} from '../../Store/reducers/uiSettings'
import { setAssemblyForStagingBuild } from '../../Store/reducers/orders'
import {
  getdocumentDetailAction,
  generateAlltypeDocumentAction,
  getshipmentDocumentDetailsAction,
  getpackingDocumentDetailAction,
  getAllbuildPreparationAction,
  generateShippmentDcoumentAction,
  getShipmentDetailAction,
  getAllShippingStatsAction,
  getAssemblyforItemAction,
  getcourierServiceType,
  getAllDocumentsForOrderAction,
} from '../../Actions/order'

import DocumentForm from './documetForm'

const RequestDocumentForm = (props) => {
  const { orders, uisettings, common } = useSelector((state) => state)
  const { documentdetail } = uisettings
  const dispatch = useDispatch()
  const [updatedData, setUpdatedData] = useState([])

  const [formName, setformName] = useState('')
  const [uploadFile, setUploadFile] = useState()

  const [openEditor, setopenEditor] = useState(false)
  const [isdocLoaded, setisdocLoaded] = useState(false)
  const [formValidation, setFormValidation] = useState()
  const [CTA, setCTA] = useState()
  const docRef = useRef(null)
  const pdfRef = useRef(null)
  const componentRef = useRef()

  useEffect(() => {
    if (common?.activeDropdownValue?.name === 'Courier') {
      getcourierServiceType(common?.activeDropdownValue?.id)
    }
  }, [common?.activeDropdownValue])
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const generateAlltypeDocument = (type) => {
    setCTA(() => async (payload, notused1, notused2, isAutoGenerate, file) => {
      dispatch(setFormLoaderReducer(true))
      
      console.log('generateAlltypeDocument', payload)

      //todo: re-enable this line for editing file
      // docRef.current?.documentEditor
      //   .saveAsBlob('Docx')
      //   .then(async (exportedDocument) => {

        if(isAutoGenerate){
          const formData = new FormData()
         // formData.append('file', exportedDocument)

         if (type === 'return-air-way-bill') {
          const payloadData = {
            reference: payload.reference,
            sitePhoneNumber: payload.phoneNo,
            siteAddress: payload.siteAddress,
            testingLabAddress: payload.labAddress,
            testingLabPhoneNumber: payload.labPhoneNmber,

            courierId: payload.Courier || '',
            shippingConditionId: payload.ShippingConditionId || '',
            testingLabRecipientName: payload.reqName || '',
            CourierServiceTypeId: payload.CourierServiceTypeId || '',
            SaturdayDelivery:
              payload.SaturdayDelivery === 'Yes' ? true : false,
            requesterName: payload.respientName,
          }
          formData.append('model', JSON.stringify(payloadData))
        }
        if (type === 'requisition-form') {
          const payloadData = {
            subjectNamePrefix: payload.subjectNamePrefix,
          }
          formData.append('model', JSON.stringify(payloadData))
        }
        if (type === 'customs-invoice') {
          const payloadData = {
            KitContents: payload.kitContent,
            DescriptionOfGoods: payload.descOfgoods,
            NumberOfItems: payload.items,
            TotalValue: payload.valueUsd,
            HtsCode: payload.htcCode,
            siteAddress: payload.siteAddress,
            testingLabAddress: payload.labAddress,
          }
          formData.append('model', JSON.stringify(payloadData))
        }

        const resp = await generateAlltypeDocumentAction(
          orders?.activeOrder?.id,
          uisettings?.buildTemplate?.orderItemDetail?.id,
          orders?.activeBuildStaging?.id,
          type,
          formData,
          isAutoGenerate
        )
        console.log('resp',resp )
        if (resp?.status === 200) {
          if (orders?.activeShipment?.id) {
            getShipmentDetailAction(
              orders.activeOrder?.id,
              orders?.activeShipment?.id,
            )
            getAllShippingStatsAction(orders.activeOrder?.id)
          }
          getAllDocumentsForOrderAction(orders.activeOrder?.id)
          dispatch(setdocumentdetail({ data: resp?.data, type: 'update' }))
          setopenEditor(false)
          dispatch(setFormLoaderReducer(false))
          getAllbuildPreparationAction('staging', orders?.activeOrder?.id)
          getAllbuildPreparationAction('building', orders.activeOrder?.id)
          dispatch(showSuccessReducer(`Assembly item  successfully built.`))
          const res = await getAssemblyforItemAction(
            orders?.activeOrder?.id,
            uisettings?.editForm?.id,
          )
          if (res.status === 200) {
            dispatch(
              setAssemblyForStagingBuild(res.data?.orderItemAssemblies),
            )
          }
        }
      }

    else
    {
    
      //todo: re-enable this code for editing file 
    // docRef.current?.documentEditor
    //   .saveAsBlob('Docx')
    //   .then(async (exportedDocument) => {
        const formData = new FormData()
        formData.append('file', file)







          if (type === 'return-air-way-bill') {
            const payloadData = {
              reference: payload.reference,
              sitePhoneNumber: payload.phoneNo,
              siteAddress: payload.siteAddress,
              testingLabAddress: payload.labAddress,
              testingLabPhoneNumber: payload.labPhoneNmber,

              courierId: payload.Courier || '',
              shippingConditionId: payload.ShippingConditionId || '',
              testingLabRecipientName: payload.reqName || '',
              CourierServiceTypeId: payload.CourierServiceTypeId || '',
              SaturdayDelivery:
                payload.SaturdayDelivery === 'Yes' ? true : false,
              requesterName: payload.respientName,
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          if (type === 'requisition-form') {
            const payloadData = {
              subjectNamePrefix: payload.subjectNamePrefix,
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          if (type === 'customs-invoice') {
            const payloadData = {
              KitContents: payload.kitContent,
              DescriptionOfGoods: payload.descOfgoods,
              NumberOfItems: payload.items,
              TotalValue: payload.valueUsd,
              HtsCode: payload.htcCode,
              siteAddress: payload.siteAddress,
              testingLabAddress: payload.labAddress,
            }
            formData.append('model', JSON.stringify(payloadData))
          }

          const resp = await generateAlltypeDocumentAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            orders?.activeBuildStaging?.id,
            type,
            formData,
            isAutoGenerate
          )
          if (resp?.status === 200) {
            if (orders?.activeShipment?.id) {
              getShipmentDetailAction(
                orders.activeOrder?.id,
                orders?.activeShipment?.id,
              )
              getAllShippingStatsAction(orders.activeOrder?.id)
            }
            getAllDocumentsForOrderAction(orders.activeOrder?.id)
            dispatch(setdocumentdetail({ data: resp?.data, type: 'update' }))
            setopenEditor(false)
            dispatch(setFormLoaderReducer(false))
            getAllbuildPreparationAction('staging', orders?.activeOrder?.id)
            getAllbuildPreparationAction('building', orders.activeOrder?.id)
            dispatch(showSuccessReducer(`Assembly item  successfully built.`))
            const res = await getAssemblyforItemAction(
              orders?.activeOrder?.id,
              uisettings?.editForm?.id,
            )
            if (res.status === 200) {
              dispatch(
                setAssemblyForStagingBuild(res.data?.orderItemAssemblies),
              )
            }
          }
    }
    })
  }

  const generatePackagingSlipDoc = (type) => {
    console.log('generatePackagingSlipDoc', type)
    setCTA(() => async (payload, notused1, notused2, isAutoGenerate, file) => {
      dispatch(setFormLoaderReducer(true))
      // docRef.current?.documentEditor
      //   .saveAsBlob('Docx')
      //   .then(async (exportedDocument) => {
        
          const formData = new FormData()
          if(!isAutoGenerate)
            {
              formData.append('file', file)
            }

         // formData.append('file', exportedDocument)
          if ((type = 'packing-slip')) {
            const payloadData = {
              ProjectName: payload.projName,
              ItemsDescription: payload.itemdesc,
            }
            formData.append('model', JSON.stringify(payloadData))
          }

          const resp = await getpackingDocumentDetailAction(
            orders?.activeOrder?.id,
            orders?.activeShipment?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            formData,
          )
          if (resp?.status === 200) {
            if (orders?.activeShipment?.id) {
              getShipmentDetailAction(
                orders.activeOrder?.id,
                orders?.activeShipment?.id,
              )
              getAllShippingStatsAction(orders.activeOrder?.id)
            }
            getAllDocumentsForOrderAction(orders.activeOrder?.id)
            dispatch(setdocumentdetail({ data: resp?.data, type: 'update' }))
            setopenEditor(false)
            dispatch(setFormLoaderReducer(false))
            dispatch(
              showSuccessReducer(`Packaging slip generated Successfully.`),
            )
          }
        
        
      })
        
    //})
  }
  const generateShipmentDocument = (type) => {
    console.log('generateShipmentDocument', type)
    setCTA(() => async (payload, notused1, notused2, isAutoGenerate, file) => {
      dispatch(setFormLoaderReducer(true))
      // docRef.current?.documentEditor
      //   .saveAsBlob('Docx')
      //   .then(async (exportedDocument) => {
          const formData = new FormData()

          if(!isAutoGenerate){
            formData.append('file', file)
          }
         // formData.append('file', exportedDocument)
          if (type === 'air-way-bill' || type === 'depot-air-way-bill') {
            const payloadData = {
              reference: payload.reference || '',
              sitePhoneNumber: payload.phoneNo || '',
              testingLabPhoneNumber: payload.labPhoneNmber || '',
              siteAddress: payload.siteAddress,
              testingLabAddress: payload.labAddress,
              courierId: payload.Courier || '',
              shippingConditionId: payload.ShippingConditionId || '',
              testingLabRecipientName: payload.reqName || '',
              CourierServiceTypeId: payload.CourierServiceTypeId || '',
              SaturdayDelivery:
                payload.SaturdayDelivery === 'Yes' ? true : false,
              requesterName: payload.respientName,
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          if (type === 'packing-slip') {
            const payloadData = {
              ProjectName: payload.projName || '',
              ItemsDescription: payload.itemdesc || '',
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          if (type === 'pro-forma') {
            const payloadData = {
              brokerInfo: payload.Broker || '',
              htsCode: payload.htsCode || '',
              ioRInfo: payload.importer || '',
              items: payload.items,
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          if (type === 'declaration-of-goods') {
            console.log('declaration-of-goods1', payload)
            const payloadData = {
              brokerInfo: payload.Broker || '',
              htsCode: payload.htsCode || '',
              ioRInfo: payload.importer || '',
              shipper: payload.shipper || '',
              respient: payload.respient || ''
              // items: payload.items,
            }
            formData.append('model', JSON.stringify(payloadData))
          }
          const resp = await generateShippmentDcoumentAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            type,
            formData,
          )
          if (resp?.status === 200) {
            if (orders?.activeShipment?.id) {
              getShipmentDetailAction(
                orders.activeOrder?.id,
                orders?.activeShipment?.id,
              )
              getAllShippingStatsAction(orders.activeOrder?.id)
            }
            getAllDocumentsForOrderAction(orders.activeOrder?.id)
            dispatch(setdocumentdetail({ data: resp?.data, type: 'update' }))
            setopenEditor(false)
            dispatch(setFormLoaderReducer(false))
            dispatch(showSuccessReducer(`Document generated Successfully.`))
          }
       // })
    })
  }
  //   const  loadFile = (file) => {
  //     let ajax = new XMLHttpRequest();
  //     ajax.open('POST', 'https://localhost:4000/api/documenteditor/Import', true);
  //     ajax.onreadystatechange = () => {
  //         if (ajax.readyState === 4) {
  //             if (ajax.status === 200 || ajax.status === 304) {
  //                 // open SFDT text in document editor
  //                 docRef.current?.documentEditor.open(ajax.responseText);
  //             }
  //         }
  //     };
  //     let formData = new FormData();
  //     formData.append('files', file);
  //     ajax.send(formData);
  // }

  useEffect(() => {
    ;(async () => {
      console.log('378 reqDocForm useEffect', uisettings)
      if (uisettings?.buildTemplate?.orderItemDetail) {
        setUpdatedData([])
        var docType = uisettings?.buildTemplate?.orderItemDetail?.buildType
        var res
        var docTypeName = uisettings?.buildTemplate?.orderItemDetail?.buildType
        if (docType === 'rawb') {
          setformName('Return Airway Bill')
          docTypeName = 'return-air-way-bill'
          res = await getdocumentDetailAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
            orders?.activeBuildStaging?.id,
          )
          if (res.status === 200) {
            if (res?.data?.courier?.id) {
              getcourierServiceType(res?.data?.courier?.id)
            }
          }
        } else if (docType === 'requistion-form') {
          setformName('Requisition Form')
          docTypeName = 'requisition-form'
          res = await getdocumentDetailAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
            orders?.activeBuildStaging?.id,
          )
        } else if (docType === 'customs-invoice') {
          setformName('Custom Invoice')
          docTypeName = 'customs-invoice'
          res = await getdocumentDetailAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
            orders?.activeBuildStaging?.id,
          )
        } else if (docType === 'packaging-slip') {
          setformName('Packaging Slip')
          docTypeName = 'packing-slip'
          res = await getpackingDocumentDetailAction(
            orders?.activeOrder?.id,
            orders?.activeShipment?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
          )
        } else if (docType === 'awb') {
          setformName('Airway Bill')
          docTypeName = 'air-way-bill'
          res = await getshipmentDocumentDetailsAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
          )
          if (res?.data?.courier?.id) {
            getcourierServiceType(res?.data?.courier?.id)
          }
        } else if (docType === 'depot-awb') {
          setformName('Depot Airway Bill')
          docTypeName = 'depot-air-way-bill'
          res = await getshipmentDocumentDetailsAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
          )
          if (res?.data?.courier?.id) {
            getcourierServiceType(res?.data?.courier?.id)
          }
        } else if (docType === 'pro-forma') {
          setformName('Pro Forma Invoice')
          docTypeName = 'pro-forma'
          res = await getshipmentDocumentDetailsAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
          )
        } else if (
          docType === 'shipment-menifest' ||
          docType === 'packing-slip'
        ) {
          setformName('Shipment Manifest')
          docTypeName = 'packing-slip'
          res = await getshipmentDocumentDetailsAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
          )
        } else if (docType === 'declaration-of-goods') {
          setformName('Declaration of Goods')
          docTypeName = 'declaration-of-goods'
          res = await getshipmentDocumentDetailsAction(
            orders?.activeOrder?.id,
            uisettings?.buildTemplate?.orderItemDetail?.id,
            docTypeName,
          )
        }

        if (res?.status === 200) {
          console.log('res?.data', res?.data)
          dispatch(setdocumentdetail({ data: res?.data, type: 'new' }))
          if (res?.data?.preGeneratedFile) {
            setopenEditor(false)
          } else {
            //setopenEditor(true)
            setopenEditor(false)
          }
          if (docType === 'requistion-form') {
            RequistionForm[1].initialValue = res?.data?.siteNumber
            RequistionForm[2].initialValue = res?.data?.siteName?.name
            RequistionForm[3].initialValue = res?.data?.siteAddress
            RequistionForm[4].initialValue = res?.data?.sitePiName
            RequistionForm[5].initialValue = res?.data?.subjectNamePrefix
            RequistionForm[7].initialValue = res?.data?.testingLabName
            RequistionForm[8].initialValue = res?.data?.testingLabAddress
            // disbaled
            RequistionForm[1].disabled = true
            RequistionForm[2].disabled = true
            RequistionForm[3].disabled = true
            RequistionForm[4].disabled = true
            RequistionForm[5].disabled = false
            RequistionForm[7].disabled = true
            RequistionForm[8].disabled = true

            setUpdatedData(RequistionForm)
            generateAlltypeDocument(docTypeName)
          } else if (docType === 'rawb') {
            ReturnAirWay[1].initialValue = res?.data?.courier?.id
            ReturnAirWay[1].options = common?.allCuriors || []
            ReturnAirWay[1].placeholder =
              res?.data?.courier?.name || 'select Courier'

            ReturnAirWay[2].placeholder =
              res?.data?.courierServiceType?.name ||
              'select courier service type'
            ReturnAirWay[2].initialValue = res?.data?.courierServiceType?.id

            ReturnAirWay[3].placeholder =
              res?.data?.shipmentCondition?.name || 'select Shipping condition'
            ReturnAirWay[3].initialValue = res?.data?.shipmentCondition?.id
            ReturnAirWay[3].options = common?.allShipping || []

            ReturnAirWay[4].placeholder = res?.data?.saturdayDelivery
              ? res?.data?.saturdayDelivery
                ? 'Yes'
                : 'No'
              : 'select saturday delivery'
            ReturnAirWay[4].initialValue = res?.data?.saturdayDelivery
              ? 'Yes'
              : 'No'
            ReturnAirWay[4].options = [
              { id: 'Yes', name: 'Yes' },
              { id: 'No', name: 'No' },
            ]
            ReturnAirWay[5].initialValue = res?.data?.reference

            ReturnAirWay[7].initialValue = res?.data?.requesterName
            ReturnAirWay[8].initialValue = res?.data?.siteNumber
            ReturnAirWay[9].initialValue = res?.data?.siteName
            ReturnAirWay[10].initialValue = res?.data?.siteAddress
            ReturnAirWay[11].initialValue = res?.data?.sitePhoneNumber

            ReturnAirWay[13].initialValue = res?.data?.testingLabRecipientName
            ReturnAirWay[14].initialValue = res?.data?.testingLabName
            ReturnAirWay[15].initialValue = res?.data?.testingLabAddress
            ReturnAirWay[16].initialValue = res?.data?.testingLabPhoneNumber

            //disable
            ReturnAirWay[1].disabled = orders.type === 2 ? false : true
            ReturnAirWay[2].disabled = false
            ReturnAirWay[3].disabled = orders.type === 2 ? false : true
            ReturnAirWay[4].disabled = false
            ReturnAirWay[5].disabled = true

            ReturnAirWay[7].disabled = false
            ReturnAirWay[8].disabled = true
            ReturnAirWay[9].disabled = true
            ReturnAirWay[10].disabled = false
            ReturnAirWay[11].disabled = false

            ReturnAirWay[13].disabled = false
            ReturnAirWay[14].disabled = true
            ReturnAirWay[15].disabled = false
            ReturnAirWay[16].disabled = false

            setUpdatedData(ReturnAirWay)
            generateAlltypeDocument(docTypeName)
          } else if (docType === 'customs-invoice') {
            CustomInvoice[1].initialValue = res?.data?.studyName?.name
            CustomInvoice[2].initialValue = res?.data?.studyCode
            CustomInvoice[3].initialValue = res?.data?.kitContents
            CustomInvoice[4].initialValue = res?.data?.htsCode
            CustomInvoice[5].initialValue = res?.data?.descriptionOfGoods
            CustomInvoice[6].initialValue = res?.data?.numberOfItems
            CustomInvoice[7].initialValue = res?.data?.totalValue
            CustomInvoice[8].initialValue = res?.data?.countryOfOrigin
            CustomInvoice[10].initialValue = res?.data?.siteNumber
            CustomInvoice[11].initialValue = res?.data?.siteName
            CustomInvoice[12].initialValue = res?.data?.siteAddress
            CustomInvoice[13].initialValue = res?.data?.piName
            CustomInvoice[15].initialValue = res?.data?.testingLab
            CustomInvoice[16].initialValue = res?.data?.testingLabAddress

            //disabled
            CustomInvoice[1].disabled = true
            CustomInvoice[2].disabled = true
            CustomInvoice[3].disabled = false
            CustomInvoice[4].disabled = orders.type === 2 ? false : true
            CustomInvoice[5].disabled = false
            CustomInvoice[6].disabled = false
            CustomInvoice[7].disabled = false
            CustomInvoice[8].disabled = true
            CustomInvoice[10].disabled = true
            CustomInvoice[11].disabled = true
            CustomInvoice[12].disabled = false
            CustomInvoice[13].disabled = true
            CustomInvoice[15].disabled = true
            CustomInvoice[16].disabled = false
            setFormValidation(() => (error, values) => {
              CustomInvoiceValidation(error, values)
            })
            setUpdatedData(CustomInvoice)
            generateAlltypeDocument(docTypeName)
          } else if (docType === 'packaging-slip') {
            PackagingSlip[1].initialValue = res?.data?.projectName
            PackagingSlip[2].initialValue = res?.data?.studyName
            PackagingSlip[3].initialValue = res?.data?.itemsDescription
            PackagingSlip[4].initialValue = res?.data?.contents

            PackagingSlip[1].disabled = false
            PackagingSlip[1].required = false
            PackagingSlip[2].disabled = true
            PackagingSlip[3].disabled = false
            PackagingSlip[3].required = false
            PackagingSlip[4].disabled = true
            setUpdatedData(PackagingSlip)
            setFormValidation((error, values) => (error, values) => {
              if (values.projName?.length > 100) {
                return (error.projName = 'Max 100 characters allowed')
              }
              if (values.itemdesc?.length > 100) {
                return (error.itemdesc = 'Max 100 characters allowed')
              }
              return error
            })

            generatePackagingSlipDoc(docTypeName)
          } else if (docType === 'awb' || docType === 'depot-awb') {
            console.log(`'(docType === 'awb' || docType === 'depot-awb') {'`)
            AirWay[1].initialValue = res?.data?.courier?.id
            AirWay[1].options = common?.allCuriors || []
            AirWay[1].placeholder = res?.data?.courier?.name || 'select Courier'

            AirWay[2].placeholder =
              res?.data?.courierServiceType?.name ||
              'select courier service type'
            AirWay[2].initialValue = res?.data?.courierServiceType?.id

            AirWay[3].placeholder =
              res?.data?.shipmentCondition?.name || 'select Shipping condition'
            AirWay[3].initialValue = res?.data?.shipmentCondition?.id
            AirWay[3].options = common?.allShipping

            AirWay[4].placeholder = res?.data?.saturdayDelivery
              ? res?.data?.saturdayDelivery
                ? 'Yes'
                : 'No'
              : 'select Saturday delivery'
            AirWay[4].initialValue = res?.data?.saturdayDelivery ? 'Yes' : 'No'
            AirWay[4].options = [
              { id: 'Yes', name: 'Yes' },
              { id: 'No', name: 'No' },
            ]

            AirWay[5].initialValue = res?.data?.reference

            AirWay[7].initialValue = res?.data?.testingLabRecipientName
            AirWay[8].initialValue = res?.data?.testingLabName
            AirWay[9].initialValue = res?.data?.testingLabAddress
            AirWay[10].initialValue = res?.data?.testingLabPhoneNumber

            AirWay[12].initialValue = res?.data?.requesterName
            AirWay[13].initialValue = res?.data?.siteNumber
            AirWay[14].initialValue = res?.data?.siteName
            AirWay[15].initialValue = res?.data?.siteAddress
            AirWay[16].initialValue = res?.data?.sitePhoneNumber

            //disable
            AirWay[1].disabled = orders.type === 2 ? false : true
            AirWay[2].disabled = false
            AirWay[3].disabled = orders.type === 2 ? false : true
            AirWay[4].disabled = false
            AirWay[5].disabled = true

            AirWay[7].disabled = false
            AirWay[8].disabled = true
            AirWay[9].disabled = false
            AirWay[10].disabled = false

            AirWay[10].disabled = false
            AirWay[14].disabled = true
            AirWay[15].disabled = false
            AirWay[16].disabled = false

            setUpdatedData(AirWay)
            generateShipmentDocument(docTypeName)
          } else if (
            docType === 'pro-forma' ||
            docType === 'declaration-of-goods'
          ) {
            ProformaInvoice[1].initialValue = res?.data?.studyName?.name
            ProformaInvoice[2].initialValue = res?.data?.testingLabAddress
            ProformaInvoice[3].initialValue = res?.data?.brokerInfo
            ProformaInvoice[4].initialValue = res?.data?.ioRInfo
            ProformaInvoice[5].initialValue = res?.data?.siteAddress
            ProformaInvoice[6].initialValue = res?.data?.contents
            ProformaInvoice[7].initialValue = res?.data?.htsCode
            ProformaInvoice[8].initialValue = res?.data?.countryOfOrigin

            ProformaInvoice[1].disabled = true
            ProformaInvoice[2].disabled = false
            ProformaInvoice[3].disabled = true
            ProformaInvoice[4].disabled = true
            ProformaInvoice[5].disabled = false
            ProformaInvoice[6].disabled = true
            ProformaInvoice[7].disabled = true
            ProformaInvoice[8].disabled = true
            setUpdatedData(ProformaInvoice)
            generateShipmentDocument(docTypeName)
          } else if (
            docType === 'shipment-menifest' ||
            docType === 'packing-slip'
          ) {
            PackagingSlip[1].initialValue = res?.data?.projectName
            PackagingSlip[2].initialValue = res?.data?.studyName
            PackagingSlip[3].initialValue = res?.data?.itemsDescription
            PackagingSlip[4].initialValue = res?.data?.contents
            //disabled
            PackagingSlip[1].disabled = false
            PackagingSlip[1].required = false
            PackagingSlip[2].disabled = true
            PackagingSlip[3].disabled = false
            PackagingSlip[3].required = false
            PackagingSlip[4].disabled = true

            setUpdatedData(PackagingSlip)
            generateShipmentDocument(docTypeName)
          }
        }
      }
    })()
  }, [uisettings?.buildTemplate?.orderItemDetail])

  return (
    <>
      <HemaHeadingWithSubText
        HeadingFontSize="30px"
        HeadingLineHeight="38px"
        heading={formName}
      />
      <HemaHeadingWithSubText
        sub={`Kit Request ${orders?.activeOrder?.orderCode} / ${orders?.activeOrder?.items?.[0]?.item.name}`}
      />
      <Alert type="error" />
      <div className="m-auto flex gap-[16px]  justify-between pt-[19px] h-[calc(100%-50px)] doc-template-hema">
        <div className="flex flex-col w-full overflow-auto ">
          {documentdetail ? (
            openEditor && documentdetail ? (
              <div className="h-full">
                <DocumentEditor
                  docFile={
                    documentdetail ? documentdetail?.file?.storageUrl : null
                  }
                  uploadFile={uploadFile}
                  setisdocLoaded={setisdocLoaded}
                  docRef={docRef}
                  uisettings={uisettings}
                />
              </div>
            ) : (
              <div
                ref={componentRef}
                style={{ height: '100%' }}
                className="z-0"
              >
                <PDF
                  id="testetrte"
                  key={documentdetail?.preGeneratedFile?.id}
                  uploadFile={
                    documentdetail?.preGeneratedFile
                      ? documentdetail?.preGeneratedFile?.storageUrl
                      : documentdetail?.storageUrl
                  }
                  ref={pdfRef}
                />
              </div>
            )
          ) : (
            <div className="w-full">
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
            </div>
          )}
        </div>
        <div className=" pt-[24px] pb-[34px] px-[16px] bg-white ">
          <div className="mb-[16px]">
            <HemaHeadingWithSubText
              heading="Document Details"
              HeadingFontSize="26px"
            />
          </div>

          <DocumentForm
            data={updatedData}
            setBuildTemplate={setBuildTemplate}
            cta={CTA}
            itemDetails={uisettings?.buildTemplate?.orderItemDetail}
            docRef={docRef}
            handlePrint={handlePrint}
            setopenEditor={setopenEditor}
            openEditor={openEditor}
            loadFile={setUploadFile}
            pdfRef={pdfRef}
            formValidation={formValidation}
          />
        </div>
      </div>
    </>
  )
}

export default RequestDocumentForm

import React from 'react';
import { Button } from '../../../utils';

const Heading = ({ buttonData, heading, border, mb }) => {
  return (
    <div
      className={`${border && 'border-none'} ${
        mb && ' mb-[10px] '
      } w-full flex items-center justify-between pb-[9px] border-b border-dashed border-[#DEE2E6] mb-[0px]  `}
    >
      <h2 className="m-0 text-base font-bold text-primary1">{heading}</h2>
      <div className="flex items-center gap-2">
        {buttonData
          ?.filter((data) => !data.hide)
          ?.map((item) => (
            <Button
              type={item.type}
              text={item.text}
              cta={item.cta}
              icon={item.icon}
              className={` pl-[10px] pr-[10px] py-[8px] border text-primary1 border-primary1 ${item.className}`}
            />
          ))}
      </div>
    </div>
  );
};

export default Heading;

import React from 'react';
export const Minus = ({ color }) => {
  return (
    <svg
      width="11"
      height="3"
      viewBox="0 0 11 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71515 2.03649V0.466886L6.28485 0.466797V2.03649H4.71515ZM0.875 2.03658V0.466886H10.125V2.03658H0.875Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

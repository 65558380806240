import React from 'react';

export const PackageWeight = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.85169 2.66406C3.20395 2.66406 2.6665 3.20151 2.6665 3.84925V12.1455C2.6665 12.7933 3.20395 13.3307 3.85169 13.3307H12.148C12.7957 13.3307 13.3332 12.7933 13.3332 12.1455V3.84925C13.3332 3.20151 12.7957 2.66406 12.148 2.66406H3.85169ZM3.85169 3.84925H6.81465V6.21962H9.18502V3.84925H12.148V12.1455H3.85169V3.84925Z"
        fill="url(#paint0_linear_9495_32668)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9495_32668"
          x1="2.6665"
          y1="7.99739"
          x2="13.3332"
          y2="7.99739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Alert, Dropdown, Spinner } from 'react-bootstrap';

import { HemaHeadingWithSubText, Button, NoDataComponent, Pagination, HemaValue, FilterColumn } from '../../utils';
import { setType, setActiveOrder, setDefaultValues, setActiveShipment } from '../../Store/reducers/orders';
import { HemaDateFormat } from '../../helpers/commonHelper';
import { Add, Binocular, DrobdownArrow, TableSortIcon } from '../../HemeIconLibrary';
import { sortedData } from '../../helpers/sort';
import { editFormReducer } from '../../Store/reducers/uiSettings';
import { setDashbaordFilter } from '../../Store/reducers/orders';
import { setDropdownValue } from '../../Store/reducers/common';

import { getOrderDetailAction } from '../../Actions/order';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { setAllItemsReducer } from '../../Store/reducers/items';
import { useSearchParams } from 'react-router-dom';

const RequestTable = ({ activeStage, data, setShowDashboard, showDashboard }) => {
  const [orderDetail, setOrderDetial] = useState(null);
  const dispatch = useDispatch();
  const { orders, common } = useSelector((state) => state);
  const [btnLaoder, setbtnLoader] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const filterData = (filterText, columnName) => {

    switch (columnName) {
      case 'orderCode':
        {
          const filtered = filteredData?.filter(item => item?.orderCode?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'RequestType':
        {
          const filtered = filteredData?.filter(item => item?.orderType?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'owner':
        {
          const filtered = filteredData?.filter(item => item?.owner?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'study':
        {
          const filtered = filteredData?.filter(item => item?.study?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'site':
        {
          const filtered = filteredData?.filter(item => item?.site?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'requestedDate':
        {
          const filtered = filteredData?.filter(item => item?.requestedDate?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'date':
        {
          const filtered = filteredData?.filter(item => item?.neededDate?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'fulfillmentFacility':
        {
          const filtered = filteredData?.filter(item => item?.fulfillmentFacility?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      case 'status':
        {
          const filtered = filteredData?.filter(item => item?.status?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()));
          setFilteredData(filtered);
          break;
        }
      default:
        { }
    }
  }

  useEffect(() => {
    setFilteredData(data)
    if (activeStage) {
      console.log(activeStage);
      setOrderDetial(data?.filter((status) => status.status?.name === activeStage));
    } else {
      setOrderDetial(data);
    }
  }, [data, activeStage]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    (async () => {
      const tabToOpen = searchParams.get('order');
      if (tabToOpen && showDashboard) {
        const result = await getOrderDetailAction(tabToOpen);
        if (result.status === 200) {
          setShowDashboard(false);
          dispatch(setType(result?.data?.orderType?.id));
        }
      } else {
        setShowDashboard(true);
      }
    })();
  }, [searchParams]);

  const customStyles = {
    headCells: {
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#000000',
      },
    },
    responsiveWrapper: {
      style: { overflow: 'visible !important' },
    },
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (orders?.dashboardFilter && data?.length) {
      if (Object.keys(orders?.dashboardFilter)?.length) {
        const filterResult = data?.filter((port) => {
          if (
            (orders?.dashboardFilter?.orderCode?.length ? orders?.dashboardFilter?.orderCode.includes(port?.orderCode) : true) &&
            (orders?.dashboardFilter?.RequestType?.length ? orders?.dashboardFilter?.RequestType?.includes(port?.orderType?.name) : true) &&
            (orders?.dashboardFilter?.owner?.length ? orders?.dashboardFilter?.owner?.includes(port?.owner?.name) : true) &&
            (orders?.dashboardFilter?.requestedDate?.length ? orders?.dashboardFilter?.requestedDate.includes(port?.requestedDate?.split('T')?.[0]) : true) &&
            (orders?.dashboardFilter?.study?.length ? orders?.dashboardFilter?.study?.includes(port?.study?.name) : true) &&
            (orders?.dashboardFilter?.site?.length ? orders?.dashboardFilter?.site?.includes(port?.siteCode) : true) &&
            (orders?.dashboardFilter?.date?.length ? orders?.dashboardFilter?.date?.includes(port?.neededDate?.split('T')?.[0]) : true) &&
            (orders?.dashboardFilter?.fulfillmentFacility?.length ? orders?.dashboardFilter?.fulfillmentFacility?.includes(port?.fulfillmentFacility) : true) &&
            (orders?.dashboardFilter?.status?.length ? orders?.dashboardFilter?.status?.includes(port?.status?.name) : true)
          ) {
            return true;
          } else {
            return false;
          }
        });

        setOrderDetial(filterResult);
      } else {
        setOrderDetial(data);
      }
    }
  }, [JSON.stringify(orders?.dashboardFilter)]);

  return (
    <div className="w-full h-auto bg-white pb-[10px] rounded-[8px] ">
      <div className="w-full flex items-center justify-between px-[16px] pt-[16px] pb-[10px] border-[#DEE2E6] border-b-[1px] mb-[16px]">
        <HemaHeadingWithSubText HeadingFontSize="16px" HeadingLineHeight="24px" heading="Request Queue" />
        {(common?.permissions?.['Kit Order Process']?.write || common?.permissions?.['Kit Order Process']?.special) && (
          <Dropdown className="hemato-dropdown-btn">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <Add color="#ffffff" /> Create Kit Request
              <div className="rotate-90">
                <DrobdownArrow color="#ffffff" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {common?.permissions?.['Kit Order Process']?.write && (
                <Dropdown.Item
                  onClick={() => {
                    setShowDashboard(false);
                    dispatch(editFormReducer());
                    dispatch(setActiveOrder());
                    dispatch(setActiveShipment());
                    dispatch(setType(1));
                    dispatch(setAllItemsReducer([]));
                    dispatch(setDefaultValues());
                    dispatch(setDropdownValue());
                  }}
                >
                  <HemaValue color="#232323" className="text-sm font-[400]" text="Controlled Kit Request" />
                </Dropdown.Item>
              )}
              {common?.permissions?.['Kit Order Process']?.special && (
                <Dropdown.Item
                  onClick={() => {
                    setShowDashboard(false);
                    dispatch(editFormReducer());
                    dispatch(setActiveOrder());
                    dispatch(setType(2));
                    dispatch(setAllItemsReducer([]));
                    dispatch(setDefaultValues());
                    dispatch(setDropdownValue());
                    dispatch(setActiveShipment());
                  }}
                >
                  <HemaValue color="#232323" className="text-sm font-[400]" text="Uncontrolled Kit Request" />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {/* table */}
      {orderDetail ? (
        true > 0 ? (
          <DataTable
            pagination
            customStyles={customStyles}
            className="hdxa-rdt-filter responsive_table managment-table-st"
            data={orderDetail}
            responsive
            // noDataComponent={
            //   <NoDataComponent
            //     setActiveFilterTab={setfilterbuilder}
            //     activeFilterOriginalData={borderDetail}
            //     resetRedux={setKitTemplateFilter}
            //   />
            // }
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Order Code" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <FilterColumn
                        columnName="orderCode"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.orderCode)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line" text={row?.orderCode} />
                  </div>
                ),
                sortId: 'id',
                sortable: true,
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Request Type" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="RequestType"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.orderType?.name)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line" text={row?.orderType?.name} />
                  </div>
                ),

                sortId: 'orderType.name',
                sortable: true,
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Request Owner" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <FilterColumn
                        columnName="owner"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.owner?.name)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line" text={row?.owner?.name} />
                  </div>
                ),
                sortId: 'owner.name',
                sortable: true,
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Study Name" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="study"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.study?.name)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line" text={row?.study?.name} />
                  </div>
                ),
                sortable: true,
                sortId: 'study.name',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Site Code" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <FilterColumn
                        columnName="site"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.siteCode)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line " text={row?.siteCode} />
                  </div>
                ),
                sortable: true,
                sortId: 'site.name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Requested Date" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <FilterColumn
                        columnName="requestedDate"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.requestedDate?.split('T')?.[0])))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line " text={HemaDateFormat(row?.requestedDate)} />
                  </div>
                ),
                sortable: true,
                sortId: 'requestedDate',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Needed by Date" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="date"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.neededDate?.split('T')?.[0])))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line " text={HemaDateFormat(row?.neededDate)} />
                  </div>
                ),
                sortable: true,
                sortId: 'neededDate',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal whitespace-nowrap" text="Fulfillment Facility" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <FilterColumn
                        columnName="fulfillmentFacility"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.fulfillmentFacility)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div className="block items-center gap-[10px] w-full cursor-pointer ">
                    <HemaValue className="text-xs whitespace-pre-line " text={row?.fulfillmentFacility} />
                  </div>
                ),
                sortable: true,
                sortId: 'fulfillmentFacility',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue color="text-[#000000]" className="text-xs font-normal" text="Status" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="status"
                        setRedux={setDashbaordFilter}
                        reduxValues={orders?.dashboardFilter || []}
                        options={Array.from(new Set(filteredData?.map((filter) => filter.status?.name)))}
                        searchFilter={true}
                        filterData={filterData}
                      />
                    </div>
                  </div>
                ),
                style: {
                  '[data-tag="allowRowEvents"]': {
                    width: '100%',
                  },
                },
                selector: (row, index) => (
                  <div
                    className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${(row?.status?.name === 'New' && 'bg-[#E6F2F6]') ||
                      (row?.status?.name === 'In Process' && 'bg-[#F6EEE6]') ||
                      (row?.status?.name === 'Shipped' && 'bg-[#F6E6F2]') ||
                      (row?.status?.name === 'Completed' && 'bg-[#E6F6EB]') ||
                      (row?.status?.name === 'Rejected' && 'bg-[#f5e2e1]')
                      }`}
                  >
                    <div
                      className={`w-2 h-2 rounded-full ${(row?.status?.name === 'New' && 'bg-[#06B6D4]') ||
                        (row?.status?.name === 'In Process' && 'bg-[#F97316]') ||
                        (row?.status?.name === 'Shipped' && 'bg-[#C92286]') ||
                        (row?.status?.name === 'Completed' && 'bg-[#065528]') ||
                        (row?.status?.name === 'Rejected' && 'bg-[#f50b0b]')
                        }`}
                    />

                    <HemaValue
                      className="text-[12px] leading-[18px]"
                      color={
                        (row?.status?.name === 'New' && 'text-[#06B6D4]') ||
                        (row?.status?.name === 'In Process' && 'text-[#F97316]') ||
                        (row?.status?.name === 'Shipped' && 'text-[#C92286]') ||
                        (row?.status?.name === 'Completed' && 'text-[#065528]') ||
                        (row?.status?.name === 'Rejected' && 'text-[#f50b0b]')
                      }
                      text={row?.status?.name}
                    />
                  </div>
                ),

                sortable: true,
                sortId: 'status.name',
              },
              {
                right: true,
                name: (
                  <div className="w-full ">
                    <div className="py-[10px] flex items-center justify-end w-full border-b-[1px] border-solid border-[#DEE2E6] pl-[10px] pr-[30px]">
                      <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal" />
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="ml-auto cursor-pointer pr-[20px]">
                      <Button
                        icon={btnLaoder === row.id ? <Spinner variant="primary" size="sm" /> : <Binocular />}
                        color="text-white"
                        bg="bg-bgActionDots"
                        cta={async () => {
                          dispatch(setActiveShipment());
                          setbtnLoader(row.id);
                          await getOrderDetailAction(row.id);
                          setbtnLoader(false);
                          setShowDashboard(false);
                          dispatch(setType(row?.orderType?.id));
                          navigate(`?order=${row.id}`);
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
            onSort={(row, direction, sorted) => {
              setOrderDetial(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <Alert variant="warning" class="text-center">
            There are no kit orders to show. Please add by clicking on Create Kit Request.
          </Alert>
        )
      ) : (
        <div className="p-[20px]">
          <Skeleton count="4" />
          <br />
          <br />
          <Skeleton count="4" />
          <br />
          <br />
          <Skeleton count="4" />
        </div>
      )}
    </div>
  );
};

export default RequestTable;

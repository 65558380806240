import React from 'react';

function PortalUser({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.66797C8.89733 8.66797 8 9.5653 8 10.668V21.3346C8 22.4373 8.89733 23.3346 10 23.3346H22C23.1027 23.3346 24 22.4373 24 21.3346V10.668C24 9.5653 23.1027 8.66797 22 8.66797H10ZM9.33333 12.0013H22.6667V21.3346C22.6667 21.7026 22.3673 22.0013 22 22.0013H10C9.63267 22.0013 9.33333 21.7026 9.33333 21.3346V12.0013ZM11.8333 18.0013C11.19 18.0013 10.6667 18.5246 10.6667 19.168V20.168C10.6667 20.8113 11.19 21.3346 11.8333 21.3346H20.1667C20.81 21.3346 21.3333 20.8113 21.3333 20.168V19.168C21.3333 18.5246 20.81 18.0013 20.1667 18.0013H11.8333Z"
        fill={color || 'url(#paint0_linear_4637_49731)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_4637_49731"
          x1="16.0013"
          y1="23.2378"
          x2="16.0013"
          y2="8.76408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7006A" />
          <stop offset="1" stopColor="#EAC4BE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PortalUser;

import React from "react";

const EyeIcon = () => {
  return (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0007 1.9987C13.159 1.9987 15.9756 3.7737 17.3506 6.58203C15.9756 9.39036 13.159 11.1654 10.0007 11.1654C6.84232 11.1654 4.02565 9.39036 2.65065 6.58203C4.02565 3.7737 6.84232 1.9987 10.0007 1.9987ZM10.0007 0.332031C5.83398 0.332031 2.27565 2.9237 0.833984 6.58203C2.27565 10.2404 5.83398 12.832 10.0007 12.832C14.1673 12.832 17.7256 10.2404 19.1673 6.58203C17.7256 2.9237 14.1673 0.332031 10.0007 0.332031ZM10.0007 4.4987C11.1507 4.4987 12.084 5.43203 12.084 6.58203C12.084 7.73203 11.1507 8.66536 10.0007 8.66536C8.85065 8.66536 7.91732 7.73203 7.91732 6.58203C7.91732 5.43203 8.85065 4.4987 10.0007 4.4987ZM10.0007 2.83203C7.93398 2.83203 6.25065 4.51536 6.25065 6.58203C6.25065 8.6487 7.93398 10.332 10.0007 10.332C12.0673 10.332 13.7506 8.6487 13.7506 6.58203C13.7506 4.51536 12.0673 2.83203 10.0007 2.83203Z"
        fill="#989898"
      />
    </svg>
  );
};

export default EyeIcon;

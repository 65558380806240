import React from 'react';
export const PackageIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 10.8H6.3C6.53869 10.8 6.76761 10.7052 6.9364 10.5364C7.10518 10.3676 7.2 10.1387 7.2 9.9C7.2 9.6613 7.10518 9.43239 6.9364 9.2636C6.76761 9.09482 6.53869 9 6.3 9H4.5C4.2613 9 4.03239 9.09482 3.8636 9.2636C3.69482 9.43239 3.6 9.6613 3.6 9.9C3.6 10.1387 3.69482 10.3676 3.8636 10.5364C4.03239 10.7052 4.2613 10.8 4.5 10.8ZM9.9 12.6H4.5C4.2613 12.6 4.03239 12.6948 3.8636 12.8636C3.69482 13.0324 3.6 13.2613 3.6 13.5C3.6 13.7387 3.69482 13.9676 3.8636 14.1364C4.03239 14.3052 4.2613 14.4 4.5 14.4H9.9C10.1387 14.4 10.3676 14.3052 10.5364 14.1364C10.7052 13.9676 10.8 13.7387 10.8 13.5C10.8 13.2613 10.7052 13.0324 10.5364 12.8636C10.3676 12.6948 10.1387 12.6 9.9 12.6ZM15.3 0H2.7C1.98392 0 1.29716 0.284464 0.790812 0.790812C0.284464 1.29716 0 1.98392 0 2.7V15.3C0 16.0161 0.284464 16.7028 0.790812 17.2092C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.2092C17.7155 16.7028 18 16.0161 18 15.3V2.7C18 1.98392 17.7155 1.29716 17.2092 0.790812C16.7028 0.284464 16.0161 0 15.3 0ZM10.8 1.8V4.761L9.441 4.005C9.30418 3.92601 9.14898 3.88442 8.991 3.88442C8.83302 3.88442 8.67782 3.92601 8.541 4.005L7.2 4.761V1.8H10.8ZM16.2 15.3C16.2 15.5387 16.1052 15.7676 15.9364 15.9364C15.7676 16.1052 15.5387 16.2 15.3 16.2H2.7C2.46131 16.2 2.23239 16.1052 2.0636 15.9364C1.89482 15.7676 1.8 15.5387 1.8 15.3V2.7C1.8 2.46131 1.89482 2.23239 2.0636 2.0636C2.23239 1.89482 2.46131 1.8 2.7 1.8H5.4V6.3C5.40095 6.45706 5.44298 6.61114 5.52193 6.74693C5.60087 6.88271 5.71398 6.99547 5.85 7.074C5.98682 7.15299 6.14202 7.19458 6.3 7.19458C6.45798 7.19458 6.61318 7.15299 6.75 7.074L9 5.823L11.259 7.083C11.3935 7.15923 11.5454 7.19953 11.7 7.2C11.9387 7.2 12.1676 7.10518 12.3364 6.9364C12.5052 6.76761 12.6 6.53869 12.6 6.3V1.8H15.3C15.5387 1.8 15.7676 1.89482 15.9364 2.0636C16.1052 2.23239 16.2 2.46131 16.2 2.7V15.3Z"
        fill={color ||  "url(#paint0_linear_11719_278369)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_11719_278369"
          x1="2.0974"
          y1="11.3272"
          x2="16.0327"
          y2="11.5493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

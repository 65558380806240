/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openform: false,
  formName: "",
  editForm: null,
  showError: null,
  showSuccess: null,
  formLoader: false,
  buildTemplate: {},
  uploadProgress: null,
  documentdetail: {},
  sidebarOpen: null,
};

export const uiSettings = createSlice({
  name: "ui-settings",
  initialState,
  reducers: {
    setForm: (state, action) => {
      (state.openform = action.payload.state),
        (state.formName = action.payload.type);
      if (action.payload.state === false) {
        state.showError = null;
      } else {
        state.showError = null;
        state.showSuccess = null;
      }
    },

    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setSideBar: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    editFormReducer: (state, action) => {
      state.editForm = action.payload;
    },
    setFormCloseReducer: (state) => {
      state.editForm = null;
      state.openform = null;
      state.formName = "";
    },
    showErrorReducer: (state, action) => {
      state.showError = action.payload;
      // setTimeout(()=>{
      //   state.showError = "";
      // },5000)
    },
    setFormName: (state, action) => {
      state.formName = action.payload;
    },
    showSuccessReducer: (state, action) => {
      state.showSuccess = action.payload;
      // setTimeout(()=>{
      //   alert()
      //   state.showSuccess = null;
      // },5000)
    },
    setFormLoaderReducer: (state, action) => {
      state.formLoader = action.payload;
    },
    setBuildTemplate: (state, action) => {
      state.buildTemplate = action.payload;
    },
    setdocumentdetail: (state, action) => {
      if (state.documentdetail?.file?.id && action.payload.type === "update") {
        state.documentdetail = {
          ...state.documentdetail,
          preGeneratedFile: action.payload.data,
        };
      } else if (action.payload.type === "new") {
        state.documentdetail = action.payload.data;
      } else {
        state.documentdetail = action.payload.data;
      }
    },
  },
});

export const {
  setUploadProgress,
  setdocumentdetail,
  setForm,
  setFormName,
  editFormReducer,
  showErrorReducer,
  setBuildTemplate,
  setFormCloseReducer,
  showSuccessReducer,
  setFormLoaderReducer,
  setSideBar,
} = uiSettings.actions;

export default uiSettings.reducer;

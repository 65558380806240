import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  CloseSideBar,
  Home,
  Library,
  Setting,
  OpenSideBar,
  InManagement,
  InSetting,
  StudySetting,
  DrobdownArrow,
  SideRoleManagement,
  PortalUser,
  SystemUser,
  CompanyLocation,
  StudyManagement,
  SiteManage,
  StorageLocations,
  ItemCatagories,
  AssemblySideIcon,
  DocSideIcon,
  KitSideIcon,
  SideMenuPin,
  LOIcon,
  CourierIcon,
  TestingLab,
  PackageIcon,
  LargeEmailIcon,
  AudutTrail,
  Reports,
} from "../../HemeIconLibrary";
import UserPurple from "../../HemeIconLibrary/settingsIcons/userPurple";
import {
  setFormCloseReducer,
  setSideBar,
} from "../../Store/reducers/uiSettings";
import { setShowOrderConfig } from "../../Store/reducers/settings";

const Sidebar = ({ setShowDashboard, isCollapse, setisCollapse }) => {
  const { permissions } = useSelector((state) => state.common);
  const { uisettings } = useSelector((state) => state);

  const [dropdown, setDropdown] = useState(false);

  const [sidebarData, setSidebarData] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const sidebarDataMenu = [
      {
        name: "Dashboard",
        path: "/",
        icon: <Home />,
        show: permissions?.["Kit Order Process"]?.read ? true : false,
        cta: () => setShowDashboard(true),
      },
      {
        name: "Inventory Management",
        path: "/inventory-management",
        icon: <InManagement />,
        show: permissions?.["Inventory Management"]?.read ? true : false,
      },
      {
        name: "Template Builder",
        path: "#",
        icon: <Library />,
        show:
          permissions?.["Kit Templates"]?.read ||
          permissions?.["Document Templates"]?.read ||
          permissions?.["Assemblies"]?.read ||
          permissions?.["Email Template Builder"]?.read
            ? true
            : false,
        dropdownItems: [
          {
            name: "Kit Template Builder",
            path: "/kit-builders?type=Template",
            icon: <KitSideIcon width={30} />,
            show: permissions?.["Kit Templates"]?.read ? true : false,
          },
          {
            name: "Document Template Builder",
            path: "/kit-builders?type=Document",
            icon: <DocSideIcon width={20} />,
            show: permissions?.["Document Templates"]?.read ? true : false,
          },
          {
            name: "Assembly Builder",
            path: "/kit-builders?type=Assemblies",
            icon: <AssemblySideIcon />,
            show: permissions?.["Assemblies"]?.read ? true : false,
          },
          {
            name: "Email Template Builder",
            path: "/kit-builders?type=emailTemplates",
            icon: <LargeEmailIcon />,
            show: permissions?.["Email Template Builder"]?.read ? true : false,
          },
        ],
      },
      {
        name: "Logistics Orchestrator",
        path: "/logistic-orchestrator",
        icon: <LOIcon />,
        show: permissions?.["Logistics Orchestrator"]?.read ? true : false,
      },
      // {
      //   name: 'Kit Order Process',
      //   path: '',
      //   icon: <PieChart />,
      // },
      {
        name: "System Settings",
        path: "#",
        icon: <Setting color={undefined} />,
        show:
          permissions?.["Company Locations"]?.read ||
          permissions?.["System User Management"]?.read ||
          permissions?.["Portal User Management"]?.read ||
          permissions?.["Role Management"]?.read ||
          permissions?.["Courier Settings"]?.read ||
          permissions?.["Depot Settings"]?.read ||
          permissions?.["Testing Lab Settings"]?.read ||
          permissions?.["Package Settings"]?.read
            ? true
            : false,
        dropdownItems: [
          {
            name: "Company Locations",
            path: "/settings?type=CompanyLocation",
            icon: <CompanyLocation />,
            show: permissions?.["Company Locations"]?.read ? true : false,
          },
          {
            name: "System User Management",
            path: "/settings?type=SystemUser",
            icon: <SystemUser />,
            show: permissions?.["System User Management"]?.read ? true : false,
          },
          {
            name: "Portal User Management",
            path: "/settings?type=PortalUser",
            icon: <PortalUser />,
            show: permissions?.["Portal User Management"]?.read ? true : false,
          },
          {
            name: "Role Management",
            path: "/settings?type=RoleManagment",
            icon: <SideRoleManagement />,
            show: permissions?.["Role Management"]?.read ? true : false,
          },
          {
            name: "Courier Settings",
            path: "/settings?type=CourierSettings",
            icon: <CourierIcon />,
            show: permissions?.["Courier Settings"]?.read ? true : false,
          },
          {
            name: "Depot Settings",
            path: "/settings?type=DepotSettings",
            icon: <InManagement />,
            show: permissions?.["Depot Settings"]?.read ? true : false,
          },
          {
            name: "Testing Lab Settings",
            path: "/settings?type=testingLabSetting",
            icon: <TestingLab />,
            show: permissions?.["Testing Lab Settings"]?.read ? true : false,
          },
          {
            name: "Package Settings",
            path: "/settings?type=packageSettings",
            icon: <PackageIcon />,
            show: permissions?.["Package Settings"]?.read ? true : false,
          },
          {
            name: "Application Settings",
            path: "/settings?type=applicationSettings",
            icon: <Setting />,
            show: permissions?.["Kit Order Process"]?.special ? true : false,
          },
        ],
      },
      {
        name: "Study Settings",
        path: "#",
        icon: <StudySetting />,
        show:
          permissions?.["Sponsor Management"]?.read ||
          permissions?.["Study Management"]?.read ||
          permissions?.["Site Management"]?.read
            ? true
            : false,
        dropdownItems: [
          {
            name: "Sponsor Management",
            path: "/sponsors-management",
            icon: <UserPurple />,
            show: permissions?.["Sponsor Management"]?.read ? true : false,
          },
          {
            name: "Study Management",
            path: "/study-management",
            icon: <StudyManagement />,
            show: permissions?.["Study Management"]?.read ? true : false,
          },
          {
            name: "Site Management",
            path: "/site-management",
            icon: <SiteManage />,
            show: permissions?.["Site Management"]?.read ? true : false,
          },
        ],
      },
      {
        name: "Inventory Settings",
        path: "#",
        icon: <InSetting />,
        show:
          permissions?.["Storage Locations"]?.read ||
          permissions?.["Item Categoriest"]?.read
            ? true
            : false,
        dropdownItems: [
          {
            name: "Storage Locations",
            path: "/storage?type=Storage Location",
            icon: <StorageLocations />,
            show: permissions?.["Storage Locations"]?.read ? true : false,
          },
          {
            name: "Item Categories",
            path: "/storage?type=Item Categories",
            icon: <ItemCatagories />,
            show: permissions?.["Item Categories"]?.read ? true : false,
          },
        ],
      },
      {
        name: "Reports",
        path: "#",
        icon: <Reports />,
        show: permissions?.["Audit"]?.read ? true : false,
        dropdownItems: [
          {
            name: "Audit Trail",
            path: "/audit-trail",
            icon: <AudutTrail />,
            show: permissions?.["Audit"]?.read ? true : false,
          },
          {
            name: "Supplies Report",
            path: "/supplies-report",
            icon: <AudutTrail />,
            show: permissions?.["Audit"]?.read ? true : false,
          },
        ],
      },
    ];
    setSidebarData(sidebarDataMenu);
  }, [permissions]);
  // useEffect(()=>{
  //   setSidebarData(sidebarDataMenu)
  // },[JSON.stringify(pemrissions)])
  return (
    <div className="fixed z-[3] top-0 left-0">
      <div
        className={`h-[calc(100vh-0px)] bg-white  ${
          isCollapse
            ? " w-[216px] absolute  hema-sidebar"
            : " w-[70px] relative"
        } transition-all`}
      >
        <ul className="pt-[97px] pb-[28px] pl-0 flex flex-col gap-[23px] h-full relative">
          {/* Pin icon */}
          <div
            onClick={() => {
              if (localStorage.getItem("sidebarOpen") === "true") {
                localStorage.setItem("sidebarOpen", "false");
                setisCollapse(!isCollapse);
                dispatch(setSideBar(!isCollapse));
              } else {
                localStorage.setItem("sidebarOpen", "true");
                setisCollapse(!isCollapse);
                dispatch(setSideBar(!isCollapse));
              }
            }}
            className="absolute top-[76px] right-[4px] z-[2] cursor-pointer"
          >
            <SideMenuPin />
          </div>
          {sidebarData?.map((item, index) => (
            <div  key ={index}>
              {item.show && (
                <li
                 
                  className="relative w-full"
                  onMouseLeave={() => setDropdown()}
                >
                  <NavLink
                    exact="true"
                    //activeClassName="active"
                    to={item.path}
                    className={
                      location.pathname?.toLocaleLowerCase()?.trim() ===
                      item.path
                        ? isCollapse
                          ? " nav-link is-collapse-active mx-0 hover:mx-0"
                          : "nav-link is-active mx-[11px] center"
                        : isCollapse
                        ? "nav-link mx-0"
                        : "nav-link mx-[11px] center"
                    }
                    onClick={() => {
                      setisCollapse(false);
                      dispatch(setFormCloseReducer());
                      dispatch(setShowOrderConfig(false));
                      localStorage.removeItem("activeLocation");
                      if (item?.cta) {
                        item?.cta();
                      }
                      setisCollapse(!!uisettings.sidebarOpen);
                    }}
                    onMouseEnter={() => setDropdown(index)}
                  >
                    <div className="flex items-center">
                      {item.icon}
                      {isCollapse && (
                        <span
                          className={`${
                            location.pathname?.toLocaleLowerCase()?.trim() ===
                            item.path
                              ? "is-collapse-active"
                              : "text-[#2D3B48]"
                          } font-medium text-[12px] ml-[12px] sidebar-text`}
                        >
                          {item.name}
                        </span>
                      )}
                    </div>
                    {isCollapse && item.dropdownItems ? (
                      dropdown === index ? (
                        <DrobdownArrow />
                      ) : (
                        <div className="rotate-90">
                          <DrobdownArrow color="#2D3B48" />
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </NavLink>
                  {item.dropdownItems && (
                    <div
                      className={` min-w-[241px] h-auto absolute rounded-[10px]  top-0  pl-[24px] sideBar-animation
                      z-[-1]
                       ${
                         dropdown === index ? "right-[-230px]" : "right-[215px]"
                       }`}
                    >
                      <div
                        className={`w-full rounded-[10px] bg-white   sidebar-dropdown `}
                        onMouseLeave={() => setDropdown()}
                      >
                        {item.dropdownItems.map((items, i) => {
                          return (
                            items.show && (
                              <NavLink
                                key= {i}
                                onClick={() => {
                                  setisCollapse(false);
                                  dispatch(setFormCloseReducer());
                                  dispatch(setShowOrderConfig(false));
                                  localStorage.removeItem("activeLocation");
                                  setisCollapse(!!uisettings.sidebarOpen);
                                }}
                                to={items.path}
                                className="nav-link sidebar-drop-text"
                              >
                                <div className="flex items-center">
                                  <div className="w-[25px]">{items.icon}</div>
                                  <span className="font-medium text-[12px] ml-[12px] text-[#2D3B48]">
                                    {items.name}
                                  </span>
                                </div>
                              </NavLink>
                            )
                          );
                        })}
                      </div>
                    </div>
                  )}
                </li>
              )}
            </div>
          ))}
        </ul>
        <div
          className="absolute bottom-1 right-[-12px] cursor-pointer"
          onClick={() => {
            setisCollapse(!isCollapse);
            if (isCollapse) {
              localStorage.setItem("sidebarOpen", "false");
              dispatch(setSideBar(false));
            }
          }}
        >
          {isCollapse ? <CloseSideBar /> : <OpenSideBar />}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;

import React from 'react';

export const TabInfo = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8ZM16 10.6667C18.9457 10.6667 21.3333 13.0543 21.3333 16C21.3333 18.9457 18.9457 21.3333 16 21.3333C13.0543 21.3333 10.6667 18.9457 10.6667 16C10.6667 13.0543 13.0543 10.6667 16 10.6667ZM16 12C15.2637 12 14.6667 12.597 14.6667 13.3333C14.6667 14.0697 15.2637 14.6667 16 14.6667C16.7363 14.6667 17.3333 14.0697 17.3333 13.3333C17.3333 12.597 16.7363 12 16 12ZM16 15.6667C15.4477 15.6667 15 16.1143 15 16.6667V19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19V16.6667C17 16.1143 16.5523 15.6667 16 15.6667Z"
        fill={purple ? 'url(#paint0_linear_3619_96668)' : '#414753'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3619_96668"
          x1="16.0013"
          y1="23.8943"
          x2="16.0013"
          y2="8.10485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

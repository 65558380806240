import React from "react";
export const CourierIcon = ({ color }) => {
  return (
    <svg width="23" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 4.6L10.5 8.2L15 6.4M1.5 4.6L6 2.8M1.5 4.6V15.4004L10.5 19.0004L19.5 15.4004V4.6M6 2.8L10.5 1L19.5 4.6M6 2.8L15 6.4M19.5 4.6L15 6.4M15 6.4V9.35735M10.5018 18.9998V8.19979"
        stroke={color || "url(#paint0_linear_5200_305556)"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_5200_305556" x1="3.5974" y1="12.3274" x2="17.5327" y2="12.5496" gradientUnits="userSpaceOnUse">
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

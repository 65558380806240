import React from 'react';

export const TabDamaged = ({ purple }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4667 13.8H15.9333V4.73333C16.0748 4.73333 16.2104 4.67714 16.3105 4.57712C16.4105 4.4771 16.4667 4.34145 16.4667 4.2V1.53333C16.4667 1.39188 16.4105 1.25623 16.3105 1.15621C16.2104 1.05619 16.0748 1 15.9333 1H2.06667C1.92522 1 1.78956 1.05619 1.68954 1.15621C1.58952 1.25623 1.53333 1.39188 1.53333 1.53333V4.2C1.53333 4.34145 1.58952 4.4771 1.68954 4.57712C1.78956 4.67714 1.92522 4.73333 2.06667 4.73333V13.8H1.53333C1.39188 13.8 1.25623 13.8562 1.15621 13.9562C1.05619 14.0562 1 14.1919 1 14.3333V16.4667C1 16.6081 1.05619 16.7438 1.15621 16.8438C1.25623 16.9438 1.39188 17 1.53333 17H16.4667C16.6081 17 16.7438 16.9438 16.8438 16.8438C16.9438 16.7438 17 16.6081 17 16.4667V14.3333C17 14.1919 16.9438 14.0562 16.8438 13.9562C16.7438 13.8562 16.6081 13.8 16.4667 13.8ZM15.4 3.66667H11.1333V2.06667H15.4V3.66667ZM7.93333 2.06667H10.0667V5.592L9.17067 5.29333C9.05996 5.25594 8.94004 5.25594 8.82933 5.29333L7.93333 5.592V2.06667ZM2.6 2.06667H6.86667V3.66667H2.6V2.06667ZM3.13333 7.768L4.39733 8.536L4.27467 9.86933C4.26143 9.97524 4.28028 10.0827 4.32877 10.1778C4.37726 10.2728 4.45317 10.3512 4.54667 10.4027L6.68 11.5973L7.21333 10.664L5.368 9.624L5.448 8.78667H7.24533V7.72H5.112L3.13333 6.52V4.73333H6.86667V6.33333C6.86706 6.41795 6.88758 6.50125 6.92653 6.57637C6.96548 6.65149 7.02174 6.71626 7.09067 6.76533C7.15905 6.81489 7.23822 6.84751 7.32167 6.86049C7.40512 6.87347 7.49046 6.86645 7.57067 6.84L9 6.36L10.4293 6.84C10.4847 6.85663 10.5422 6.8656 10.6 6.86667C10.7115 6.86824 10.8204 6.83258 10.9093 6.76533C10.9783 6.71626 11.0345 6.65149 11.0735 6.57637C11.1124 6.50125 11.1329 6.41795 11.1333 6.33333V4.73333H14.8667V8.12L12.8933 9.32H10.76V10.3867H12.5573L12.6373 11.224L10.7867 12.248L11.32 13.1813L13.4533 11.9867C13.5468 11.9352 13.6227 11.8568 13.6712 11.7618C13.7197 11.6667 13.7386 11.5592 13.7253 11.4533L13.6027 10.12L14.8667 9.368V13.8H3.13333V7.768ZM15.9333 15.9333H2.06667V15.6667H15.9333V15.9333Z"
        fill={purple ? 'url(#paint0_linear_5413_182760)' : '#414753'}
        stroke={purple ? 'url(#paint0_linear_5413_182760)' : '#414753'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_5413_182760"
          x1="9.0974"
          y1="18.3148"
          x2="23.0327"
          y2="18.5382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
export const LostInventory = () => {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3358 0.242188C17.0028 0.289187 11.2356 2.46331 8.83575 11.1875L5.61895 10.2227L8.61895 17.2227L15.619 13.2227L12.0799 12.1621C14.2529 6.87555 19.7034 5.23401 19.9158 5.19922L19.9002 5.20117L17.3358 0.242188ZM4.91192 18.6875C4.40017 18.6875 3.88836 18.8829 3.49786 19.2734L0.669734 22.1016C-0.111266 22.8826 -0.111266 24.1487 0.669734 24.9297L4.91192 29.1719C5.69292 29.9529 6.95905 29.9529 7.74005 29.1719L10.5682 26.3438C11.3502 25.5618 11.3492 24.2966 10.5682 23.5156L6.32598 19.2734C5.93548 18.8829 5.42367 18.6875 4.91192 18.6875ZM3.49786 22.1016L4.62872 23.2344C4.70072 23.0824 4.78692 22.9346 4.91192 22.8086C5.49792 22.2226 6.44606 22.2226 7.03106 22.8086C7.61706 23.3946 7.61706 24.3447 7.03106 24.9297C6.90606 25.0547 6.75923 25.1389 6.60723 25.2109L7.73809 26.3438L6.32598 27.7578L2.0838 23.5156L3.49786 22.1016Z"
        fill="url(#paint0_linear_3263_64500)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_64500"
          x1="0.0839845"
          y1="14.9999"
          x2="19.9158"
          y2="14.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

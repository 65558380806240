import React from 'react';

export const PreviousIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.23067 11.4438C7.6306 11.8247 7.64604 12.4577 7.26515 12.8576C6.88427 13.2576 6.25129 13.273 5.85136 12.8921L7.23067 11.4438ZM1.47636 8.72544C1.07643 8.34455 1.06099 7.71158 1.44188 7.31165C1.82276 6.91172 2.45574 6.89628 2.85567 7.27716L1.47636 8.72544ZM2.85567 8.72544C2.45574 9.10633 1.82276 9.09089 1.44188 8.69096C1.06099 8.29103 1.07643 7.65805 1.47636 7.27716L2.85567 8.72544ZM5.85136 3.1105C6.25129 2.72961 6.88427 2.74505 7.26515 3.14498C7.64604 3.54491 7.6306 4.17789 7.23067 4.55877L5.85136 3.1105ZM2.16602 9.0013C1.61373 9.0013 1.16602 8.55359 1.16602 8.0013C1.16602 7.44902 1.61373 7.0013 2.16602 7.0013V9.0013ZM13.8327 7.0013C14.385 7.0013 14.8327 7.44902 14.8327 8.0013C14.8327 8.55359 14.385 9.0013 13.8327 9.0013V7.0013ZM5.85136 12.8921L1.47636 8.72544L2.85567 7.27716L7.23067 11.4438L5.85136 12.8921ZM1.47636 7.27716L5.85136 3.1105L7.23067 4.55877L2.85567 8.72544L1.47636 7.27716ZM2.16602 7.0013L13.8327 7.0013V9.0013L2.16602 9.0013V7.0013Z"
        fill={color || '#605DAF'}
      />
    </svg>
  );
};

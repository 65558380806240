import React from 'react';
import ChangeReasonIcon from '../../../assets/images/change-reason.svg';

export const RemoveShippingItems = [
  {
    type: 'heading-only',
    initialValue: 'Shipment Details',
    fluid: true,
  },
  {
    label: 'Shipment Name',
    initialValue: '',
    name: 'shipment-name',
    fluid: true,
    type: 'table',
  },
  {
    name: 'recordItemId',
    label: '',
    selectRows: true,
    initialValue: '',
    fluid: true,
    type: 'ScreeningShippingTable',
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <img src={ChangeReasonIcon} alt="ChangeReasonIcon" />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
];

export const DeleteShippingItems = [
  {
    type: 'heading-only',
    initialValue: 'Shipment Details',
    fluid: true,
  },
  {
    label: 'Shipment Name',
    initialValue: '',
    name: 'shipment-name',
    fluid: true,
    type: 'table',
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <img src={ChangeReasonIcon} alt="ChangeReasonIcon" />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
];

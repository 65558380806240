import SubjectImg from '../../../assets/images/subject.svg';
import userIcon from '../../../assets/images/userIcon.svg';
import CcIcon from '../../../assets/images/Cc-Icon.svg';
import BccIcon from '../../../assets/images/Bcc-icon.svg';
import EmainIcon from '../../../assets/images/email-icon.svg';

export const RequestAcknowledgmentView = [
  {
    label: 'To',
    icon: <img src={userIcon} alt="subject" />,
    type: 'text',
    initialValue: '',
    placeholder: '',
    fluid: true,
    name: 'to',
    required: true,
    disabled: true,
  },
  {
    label: 'Cc',
    icon: <img src={CcIcon} alt="subject" />,
    type: 'email',
    initialValue: '',
    placeholder: 'Enter email address',
    fluid: false,
    name: 'cc',
    disabled: true,
  },
  {
    label: 'Bcc',
    icon: <img src={BccIcon} alt="subject" />,
    fluid: false,
    initialValue: '',
    placeholder: 'Enter email address',
    type: 'email',
    name: 'bcc',
    disabled: true,
  },
  {
    label: 'Subject',
    icon: <img src={SubjectImg} alt="subject" />,
    type: 'text',
    initialValue: '',
    placeholder: '',
    fluid: true,
    name: 'subject',
    required: true,
    disabled: true,
  },
  {
    icon: <img src={EmainIcon} alt="subject" />,
    label: 'Email Body',
    type: 'textarea',
    initialValue: '',
    placeholder: '',
    fluid: true,
    name: 'body',
    required: true,
    disabled: true,
  },
];

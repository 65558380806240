import React from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
// import { AuditTrialComp } from '../../../pages/Audit';
import { Button, HemaValue } from '../../../utils';
import { Cancel } from '../../../HemeIconLibrary';
import { setForm } from '../../../Store/reducers/uiSettings';
import { useSelector } from 'react-redux';

const AuditTrailDetails = () => {
  const dispatch = useDispatch();
  const { uisettings } = useSelector((state) => state);
  let changes;
  try {
    changes = JSON.parse(uisettings?.editForm);
  } catch (e) {
    changes = uisettings?.editForm;
  }

  return (
    <div className="w-full min-h-[92%] flex flex-col justify-between">
      {/* {Array.isArray(changes) ? (
        changes?.map((data) => {

          if(Array.isArray(data)) {
            return <>test</>
           } else {


           return (
             <AuditTrialComp
               data={

                 mergeObjects(
                   Object.keys(data)?.map((field) => {
                     if (typeof data?.[field] === 'object') {
                       return { [field]: data?.[field] };
                     } else {
                       return { [field]: [data?.[field]] };
                     }
                   })
                 )
               }
             />
           )};
        })
      ) : (
        <AuditTrialComp data={mergeObjects(
          Object.keys(changes)?.map((field) => {
            if (typeof changes?.[field] === 'object') {
              return { [field]: changes?.[field] };
            } else {
              return { [field]: [changes?.[field]] };
            }
          })
        )} />
      )}

      <div className="flex gap-[8px] justify-end my-[20px]">
        <Button
          cta={() => {
            dispatch(setForm({ state: false, type: '' }));
          }}
          type="button"
          text="Cancel"
          bg="bg-white"
          border="border-primary1"
          color="text-primary1"
          icon={<Cancel />}
        />
      </div> */}
    </div>
  );
};

function mergeObjects(inputArray) {
  const mergedObject = {};
  console.log(inputArray)
  for (const object of inputArray) {
    for (const [key, valueArray] of Object.entries(object)) {
      mergedObject[key] = valueArray;
    }
  }

  return [mergedObject];
}

const detectType =  (data)=>{

  //1.

}

export default AuditTrailDetails;

import React from "react";

export const Logout = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1108_3489" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1108_3489)">
        <path
          d="M13.0208 13.3128C12.8958 13.1739 12.8333 13.0212 12.8333 12.8545C12.8333 12.6878 12.8958 12.542 13.0208 12.417L14.6042 10.8337H8.22917C8.04861 10.8337 7.89945 10.7745 7.78167 10.6562C7.66333 10.5384 7.60417 10.3892 7.60417 10.2087C7.60417 10.0281 7.66333 9.87866 7.78167 9.76033C7.89945 9.64255 8.04861 9.58366 8.22917 9.58366H14.6042L13.0208 7.97949C12.8958 7.85449 12.8333 7.70866 12.8333 7.54199C12.8333 7.37533 12.8958 7.22949 13.0208 7.10449C13.1458 6.97949 13.2917 6.91699 13.4583 6.91699C13.625 6.91699 13.7639 6.97255 13.875 7.08366L16.4792 9.68783C16.5486 9.75727 16.6008 9.83699 16.6358 9.92699C16.6703 10.0175 16.6875 10.1114 16.6875 10.2087C16.6875 10.3059 16.6703 10.3995 16.6358 10.4895C16.6008 10.58 16.5486 10.66 16.4792 10.7295L13.875 13.3337C13.75 13.4725 13.6078 13.535 13.4483 13.5212C13.2883 13.5073 13.1458 13.4378 13.0208 13.3128ZM4.5 17.5003C4.08333 17.5003 3.72917 17.3545 3.4375 17.0628C3.14583 16.7712 3 16.417 3 16.0003V4.41699C3 4.00033 3.14583 3.64616 3.4375 3.35449C3.72917 3.06283 4.08333 2.91699 4.5 2.91699H9.47917C9.64583 2.91699 9.79167 2.97588 9.91667 3.09366C10.0417 3.21199 10.1042 3.36144 10.1042 3.54199C10.1042 3.72255 10.0417 3.87171 9.91667 3.98949C9.79167 4.10783 9.64583 4.16699 9.47917 4.16699H4.5C4.44444 4.16699 4.38889 4.19477 4.33333 4.25033C4.27778 4.30588 4.25 4.36144 4.25 4.41699V16.0003C4.25 16.0559 4.27778 16.1114 4.33333 16.167C4.38889 16.2225 4.44444 16.2503 4.5 16.2503H9.47917C9.64583 16.2503 9.79167 16.3092 9.91667 16.427C10.0417 16.5453 10.1042 16.6948 10.1042 16.8753C10.1042 17.0559 10.0417 17.2053 9.91667 17.3237C9.79167 17.4414 9.64583 17.5003 9.47917 17.5003H4.5Z"
          fill="#775FD5"
        />
      </g>
    </svg>
  );
};

import { HemaValue } from '../../utils';
import { LockOpen, CloseLock } from '../../HemeIconLibrary';
import { HemaDateFormat } from "../../helpers/commonHelper";

export const KitShippingReqCol = [
  {
    name: (
      <HemaValue
        text={' Shipment'}
        className="text-xs font-normal "
        color="text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <div className="flex gap-[10px]">
        {!row?.isLocked ? <LockOpen /> : <CloseLock />}
        <HemaValue className="text-xs font-normal " text={row?.name} />
      </div>
    ),
    sortId: 'Shipment',
  },
  {
    name: (
      <HemaValue
        text={'Status'}
        className="text-xs font-normal "
        color="text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <div
        className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${
          (row?.status?.name === 'New' && 'bg-[#E6F2F6]') ||
          (row?.status?.name === 'In Progress' && 'bg-[#F6EEE6]') ||
          (row?.status?.name === 'Shipped' && 'bg-[#F6E6F2]') ||
          (row?.status?.name === 'Completed' && 'bg-[#E6F6EB]') ||
          (row?.status?.name === 'Rejected' && 'bg-[#f5e2e1]')
        }`}
      >
        <div
          className={`w-2 h-2 rounded-full ${
            (row?.status?.name === 'New' && 'bg-[#06B6D4]') ||
            (row?.status?.name === 'In Progress' && 'bg-[#F97316]') ||
            (row?.status?.name === 'Shipped' && 'bg-[#C92286]') ||
            (row?.status?.name === 'Completed' && 'bg-[#065528]') ||
            (row?.status?.name === 'Rejected' && 'bg-[#f50b0b]')
          }`}
        />

        <HemaValue
          className="text-xs font-normal "
          color={
            (row?.status?.name === 'New' && 'text-[#06B6D4]') ||
            (row?.status?.name === 'In Progress' && 'text-[#F97316]') ||
            (row?.status?.name === 'Shipped' && 'text-[#C92286]') ||
            (row?.status?.name === 'Completed' && 'text-[#065528]') ||
            (row?.status?.name === 'Rejected' && 'text-[#f50b0b]')
          }
          text={row?.status?.name}
        />
      </div>
    ),
    sortId: 'Status',
  },
  {
    name: (
      <HemaValue
        text={'Stage'}
        className="text-xs font-normal "
        color="text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <HemaValue className="text-xs font-normal " text={row?.stage?.name} />
    ),
    sortId: 'Stage',
  },
  {
    name: (
      <HemaValue
        text={'Tracking Number'}
        className="text-xs font-normal "
        color="text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <div className="flex ">
        {row?.trackingNumber ? (
          <a
            className="no-underline"
            href={
              row?.courier?.id === 1
                ? `https://www.fedex.com/fedextrack/?action=track&tracknumbers=${row?.trackingNumber}`
                : `https://www.dhl.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${row?.trackingNumber}`
            }
            target="_blank"
          >
            <HemaValue
              className="text-xs font-normal "
              text={row?.trackingNumber}
            />
          </a>
        ) : (
          <HemaValue text={'---'} className="pl-[10px]" />
        )}
        <p className="my-0 mx-[6px] text-[#000]">|</p>
        {row?.depotTrackingNumber ? (
          <a
            className="no-underline"
            href={
              row?.courier?.id === 1
                ? `https://www.fedex.com/fedextrack/?action=track&tracknumbers=${row?.depotTrackingNumber}`
                : `https://www.dhl.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${row?.depotTrackingNumber}`
            }
            target="_blank"
          >
            ,&nbsp;
            <HemaValue
              className="text-xs font-normal "
              text={row?.depotTrackingNumber || '---'}
            />
          </a>
        ) : (
          <HemaValue text={'---'} />
        )}
      </div>
    ),
    sortId: 'readToShipQuantity',
  },
  {
    name: <HemaValue text={'Shipping Date'} className="text-xs font-normal " color="text-[#000000]" />,
    selector: (row) => <HemaValue className="text-xs" text={HemaDateFormat(row?.shippedUtc)} />,
  },
  {
    name: <HemaValue text={'Shipper'} className="text-xs font-normal " color="text-[#000000]" />,
    selector: (row) => <HemaValue className="text-xs" text={HemaDateFormat(row?.shippedBy?.name)} />,
  },
  {
    name: (
      <HemaValue
        text={'Packages'}
        className="text-xs font-normal "
        color="text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <HemaValue
        className="text-xs font-normal "
        text={row?.numberOfPackages}
      />
    ),
    sortId: 'readToShipQuantity',
  },
];

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { approveAction, rejectAction, getAllShippingStatsAction, getAllbuildPreparationAction, getAllOrderAction } from "../../../Actions/order";

import { HemaLabel, Button, FormTextarea, Alert, FormTags, FormText, HemaValue } from "../../../utils";
import { StudyAlias, Cancel, FirstName, Confirm, NameKit, Email, Format, Edit, OneTime, SendIcon, CrossIcon } from "../../../HemeIconLibrary";

import { setForm, showSuccessReducer } from "../../../Store/reducers/uiSettings";
import SubjuctImg from "../../../assets/images/subject.svg";
import Rjection from "../../../assets/images/rejection.svg"

const Acknowledgment = ({ approve, setformName }) => {
  const formikRef = useRef();
  const { orders, uisettings } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [previousEmail, setPreviousEmail] = useState([]);
  const [askEditForm, setAskEditForm] = useState(false);
  const [permanentUpdate, setPermanentUpdate] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  useEffect(() => {
    (async () => {
      await formikRef.current?.validateForm();
    })();
  }, [JSON.stringify(formikRef?.current)]);
  useEffect(() => {
    if (orders.acknowledgedetails?.to) {
      setPreviousEmail(orders.acknowledgedetails?.to);
    }
  }, [orders.acknowledgedetails?.to]);
  return (
    <>
      <div className={askEditForm ? "block h-full" : "hidden"}>
        <div className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
          <div>
            <div className={"w-full"}>
              <div className="mb-[30px]">
                <h4 className="pb-[20px] text-[26px] leading-normal font-semibold text-[#2C2C2C]">Edit</h4>
                <HemaValue
                  text={"You are about to make changes to a template. Would you like to save those changes permanently or as a one-time edit?"}
                  color="text-[#212429]"
                  className={`mb-[10px] text-base font-normal `}
                />
              </div>
            </div>
          </div>

          <div className="flex gap-[8px] justify-end my-[20px]">
            <Button
              type="button"
              text="Cancel"
              bg="bg-white"
              border="border-primary1"
              color="text-primary1"
              icon={<Cancel />}
              cta={() => {
                if (uisettings.formName === "reject-acknowledge") {
                  setformName("Request more information");
                } else {
                  setformName("Send Acknowledgment");
                }
                setAskEditForm(false);
              }}
            />

            <Button
              type="button"
              text="Permanent"
              bg="bg-primary1"
              color="text-white"
              icon={<Confirm color="#ffffff" />}
              cta={() => {
                setformName("Edit Acknowledgment");
                setPermanentUpdate(true);
                setAskEditForm(false);
                setAllowEdit(true);
              }}
            />

            <Button
              type="submit"
              text="One-Time"
              bg="bg-primary1"
              color="text-white"
              icon={<OneTime />}
              cta={() => {
                setformName("Edit Acknowledgment");
                setPermanentUpdate(false);
                setAskEditForm(false);
                setAllowEdit(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className={askEditForm ? "hidden" : "block h-full"}>
        <Formik
          initialValues={{
            subject: orders.acknowledgedetails?.subject || "",
            body: orders.acknowledgedetails?.body || "",
            permanentUpdate: permanentUpdate,
            to: [...previousEmail, ...to],

            cc: cc,
            bcc: bcc,
            rejectReason: "",
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.subject) {
              errors.subject = "Required";
            }

            if (!values.body) {
              errors.body = "Required";
            }
            if (!values.rejectReason && !approve) {
              errors.rejectReason = "Required";
            }

            if (values.to?.length === 0) {
              errors.to = "add atleast one user required";
            }
            if (to?.length > 0) {
              to?.map((c) => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(c.email)) {
                  errors.to = "Invalid email address";
                }
              });
            }
            if (cc?.length > 0) {
              cc?.map((c) => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(c.email)) {
                  errors.cc = "Please enter correct email format";
                }
              });
            }
            if (bcc?.length > 0) {
              bcc?.map((c) => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(c.email)) {
                  errors.bcc = "Please enter correct email format";
                }
              });
            }

            return errors;
          }}
          onSubmit={async (values) => {
            if (approve) {
              const approve = await approveAction(orders?.activeOrder?.id || orders.allOrders[0]?.id, values);

              if (approve.status === 200) {
                getAllbuildPreparationAction("building", orders?.activeOrder?.id || orders.allOrders[0]?.id);
                getAllbuildPreparationAction("staging", orders?.activeOrder?.id || orders.allOrders[0]?.id);
                getAllShippingStatsAction(orders?.activeOrder?.id || orders.allOrders[0]?.id);
                dispatch(setForm({ state: false, type: "" }));
                dispatch(showSuccessReducer(`Request Acknowledgement successfully sent.`));
              }
            } else {
              const approve = await rejectAction(orders?.activeOrder?.id || orders.allOrders[0]?.id, values);

              if (approve.status === 200) {
                dispatch(setForm({ state: false, type: "" }));
                dispatch(showSuccessReducer(`Request Acknowledgement  rejected.`));
              }
            }
            getAllOrderAction();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            initialValues,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className={"w-full"}>
                  <div className="mb-[30px] ">
                    <HemaLabel text={"To"} icon={<FirstName />} required={true} classNames={`mb-[10px]`} />
                    <div className="border-[1px] border-[#ccc] rounded-[5px] border-solid mt-[10px]">
                      {orders.acknowledgedetails?.to?.length && (
                        <FormTags
                          classNames="hideborder hideinput"
                          name="to"
                          disabled={orders?.activeOrder?.type.id === 1 ? true : false}
                          value={previousEmail.map((data) => data.email)}
                          formikRef={formikRef}
                          setFieldValue={(_, dataArry) => {
                            setPreviousEmail(
                              dataArry.map((dual) => {
                                return { email: dual };
                              }),
                            );
                          }}
                        ></FormTags>
                      )}
                      <FormTags
                        placeholder={"Type email"}
                        name="to"
                        classNames="hideborder"
                        setFieldValue={(name, data) => {
                          setTo(
                            data.map((dual) => {
                              return { email: dual };
                            }),
                          );
                        }}
                        value={to.map((data) => data.email)}
                        formikRef={formikRef}
                      ></FormTags>
                    </div>
                    {errors.to && touched.to && <div className="error text-[red] text-[12px] pt-[2px]">{errors.to}</div>}
                  </div>
                </div>

                <div className="w-full flex items-start gap-[16px]">
                  <div className="grow-[1] mb-[30px]">
                    <HemaLabel text={"Cc"} icon={<NameKit />} className={`mb-[10px]`} />
                    <FormTags
                      placeholder={"Enter email address"}
                      name="cc"
                      setFieldValue={(name, data) => {
                        setCc(
                          data.map((dual) => {
                            return { email: dual };
                          }),
                        );
                      }}
                      className="w-full"
                      value={cc.map((data) => data.email)}
                      formikRef={formikRef}
                    ></FormTags>
                    {errors.cc && touched.cc && <div className="error text-[red] text-[12px] pt-[2px]">{errors.cc}</div>}
                  </div>
                  <div className=" grow-[1] mb-[30px]">
                    <HemaLabel text={"Bcc"} icon={<Format />} className={`mb-[10px]`} />
                    <FormTags
                      placeholder={"Enter email address"}
                      name="bcc"
                      setFieldValue={(name, data) => {
                        setBcc(
                          data.map((dual) => {
                            return { email: dual };
                          }),
                        );
                      }}
                      value={bcc?.map((data) => data.email)}
                      formikRef={formikRef}
                    ></FormTags>
                    {errors.bcc && touched.bcc && <div className="error text-[red] text-[12px] pt-[2px]">{errors.bcc}</div>}
                  </div>
                </div>
                <div className={"w-full"}>
                  <div className="mb-[30px]">
                    <HemaLabel text={"Subject"} icon={<img src={SubjuctImg} alt="subject" />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormText
                        placeholder={"Enter Subject"}
                        name="subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subject}
                        disabled={allowEdit ? false : true}
                        className="pr-[40px]"
                        crossIcon={true ? <CrossIcon /> : ""}
                        setFieldValue={setFieldValue}
                      />
                      {/* {uisettings.formName !== 'reject-acknowledge' && ( */}
                      <div className="absolute top-[10px] right-[10px]">{!allowEdit && <Edit color="#000" />}</div>
                      {/* )} */}
                    </div>

                    {errors.subject && <div className="error text-[red] text-[12px] pt-[2px]">{errors.subject}</div>}
                  </div>
                  {uisettings.formName === "reject-acknowledge" && (<div className="mb-[30px]">
                    <HemaLabel text={"Rejection Reason"} icon={<img src={Rjection} alt="Rejection Reason" />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormTextarea
                        className="!min-h-[100px] pr-[40px] "
                        placeholder={"Enter Rejection Reason"}
                        name="rejectReason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rejectReason}
                        setFieldValue={setFieldValue}
                      />               
                    </div>
                    {errors.subject && <div className="error text-[red] text-[12px] pt-[2px]">{errors.subject}</div>}
                  </div>)}
                  <div className="mb-[30px]">
                    <HemaLabel text={"Email Body"} icon={<Email />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormTextarea
                        className="!min-h-[250px] pr-[40px] "
                        placeholder={"Email Body"}
                        name="body"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.body}
                        setFieldValue={setFieldValue}
                        disabled={allowEdit ? false : true}
                        crossIcon={true ? <CrossIcon /> : ""}
                      />
                      {/* {uisettings.formName !== 'reject-acknowledge' && ( */}
                      <div className="absolute top-[10px] right-[10px]">{!allowEdit && <Edit color="#000" />}</div>
                      {/* )} */}
                    </div>

                    {errors.body && <div className="error text-[red] text-[12px] pt-[2px]">{errors.body}</div>}
                  </div>
                </div>
                {/* {!approve && ( */}
                {!approve && uisettings.formName !== "reject-acknowledge" && (
                  <div className={"w-full"}>
                    <div className="mb-[30px]">
                      <HemaLabel text={"Reject Reason"} icon={<StudyAlias color="blue" />} required={true} className={`mb-[10px]`} />
                      <FormTextarea placeholder={"Enter reason"} name="rejectReason" onChange={handleChange} onBlur={handleBlur} value={values.rejectReason}></FormTextarea>

                      {errors.rejectReason && touched.rejectReason && <div className="error text-[red] text-[12px] pt-[2px]">{errors.rejectReason}</div>}
                    </div>
                  </div>
                )}
              </div>

              <Alert type="error" />
              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    if (allowEdit) {
                      if (uisettings.formName === "reject-acknowledge") {
                        setformName("Request Acknowledgment");
                      } else {
                        setformName("Send Acknowledgment");
                      }
                      setAllowEdit(false);
                    } else {
                      dispatch(setForm({ state: false, type: "" }));
                    }
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="button"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <>
                    {!allowEdit && (
                      <Button
                        type="button"
                        // disabled={
                        //   compareArrays(initialValues, values)
                        //     ? true
                        //     : Object.keys(errors)?.length
                        //     ? true
                        //     : false
                        // }
                        text="Edit"
                        bg="bg-primary1"
                        color="text-white"
                        icon={<Edit color="#ffffff" />}
                        cta={() => {
                          setAskEditForm(true);
                          setformName("");
                        }}
                      />
                    )}
                    <Button
                      type="submit"
                      //  disabled={Object.keys(errors)?.length ? true : false}
                      text="Send"
                      bg="bg-primary1"
                      color="text-white"
                      icon={<SendIcon />}
                    />
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Acknowledgment;

import React from "react";

export const Logistics = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.43182 3.44043C1.64241 3.44043 1 4.11343 1 4.94043V10.6071C1 11.4341 1.64241 12.1071 2.43182 12.1071H2.65927C2.86908 12.8732 3.54554 13.4404 4.34091 13.4404C5.13628 13.4404 5.81274 12.8732 6.02255 12.1071H8.79545C9.05891 12.1071 9.27273 11.8834 9.27273 11.6071V4.94043C9.27273 4.11343 8.63032 3.44043 7.84091 3.44043H2.43182ZM9.90909 4.77376V11.6071C9.90909 12.6136 10.6983 13.4404 11.6591 13.4404C12.4545 13.4404 13.1309 12.8732 13.3407 12.1071H13.5682C14.3589 12.1071 15 11.4354 15 10.6071V8.486C15 8.276 14.9578 8.06829 14.8763 7.87663L13.9379 5.66504C13.7082 5.12304 13.1955 4.77376 12.6298 4.77376H9.90909ZM11.1818 5.77376H12.6298C12.8185 5.77376 12.9891 5.88997 13.0654 6.07064L13.5887 7.30501C13.6822 7.52535 13.5285 7.77376 13.2985 7.77376H11.1818C11.0062 7.77376 10.8636 7.62443 10.8636 7.44043V6.1071C10.8636 5.9231 11.0062 5.77376 11.1818 5.77376ZM4.34091 10.7738C4.78522 10.7738 5.13636 11.1416 5.13636 11.6071C5.13636 12.0726 4.78522 12.4404 4.34091 12.4404C3.8966 12.4404 3.54545 12.0726 3.54545 11.6071C3.54545 11.1416 3.8966 10.7738 4.34091 10.7738ZM11.6591 10.7738C12.1034 10.7738 12.4545 11.1416 12.4545 11.6071C12.4545 12.0726 12.1034 12.4404 11.6591 12.4404C11.2148 12.4404 10.8636 12.0726 10.8636 11.6071C10.8636 11.1416 11.2148 10.7738 11.6591 10.7738Z"
        fill="url(#paint0_linear_6162_135080)"
      />
      <defs>
        <linearGradient id="paint0_linear_6162_135080" x1="1" y1="8.44042" x2="15" y2="8.44042" gradientUnits="userSpaceOnUse">
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
export const LoginEmail = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9 0H2.1C1.54305 0 1.0089 0.237053 0.615076 0.65901C0.221249 1.08097 0 1.65326 0 2.25V9.75C0 10.3467 0.221249 10.919 0.615076 11.341C1.0089 11.7629 1.54305 12 2.1 12H11.9C12.457 12 12.9911 11.7629 13.3849 11.341C13.7788 10.919 14 10.3467 14 9.75V2.25C14 1.65326 13.7788 1.08097 13.3849 0.65901C12.9911 0.237053 12.457 0 11.9 0ZM2.1 1.5H11.9C12.0857 1.5 12.2637 1.57902 12.395 1.71967C12.5263 1.86032 12.6 2.05109 12.6 2.25L7 5.91L1.4 2.25C1.4 2.05109 1.47375 1.86032 1.60503 1.71967C1.7363 1.57902 1.91435 1.5 2.1 1.5ZM12.6 9.75C12.6 9.94891 12.5263 10.1397 12.395 10.2803C12.2637 10.421 12.0857 10.5 11.9 10.5H2.1C1.91435 10.5 1.7363 10.421 1.60503 10.2803C1.47375 10.1397 1.4 9.94891 1.4 9.75V3.96L6.636 7.3875C6.74241 7.45333 6.86312 7.48798 6.986 7.48798C7.10888 7.48798 7.22959 7.45333 7.336 7.3875L12.6 3.96V9.75Z"
        fill="#605DAF"
      />
    </svg>
  );
};

import React from 'react';

export const ActionIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3552 12.6006C24.2807 12.4293 24.1597 12.2823 24.006 12.1763C23.8522 12.0702 23.6718 12.0094 23.4852 12.0006H18.9052L20.1752 7.26059C20.2157 7.11226 20.2214 6.95661 20.1919 6.80572C20.1624 6.65483 20.0985 6.51278 20.0052 6.39059C19.9121 6.26957 19.7924 6.1715 19.6555 6.1039C19.5186 6.03629 19.3679 6.00095 19.2152 6.00059H12.2152C11.9895 5.99285 11.7677 6.06179 11.5862 6.19616C11.4046 6.33053 11.2738 6.52242 11.2152 6.74059L8.53523 16.7406C8.49478 16.8889 8.48908 17.0446 8.51856 17.1955C8.54804 17.3463 8.61192 17.4884 8.70523 17.6106C8.79941 17.7329 8.92065 17.8318 9.05945 17.8995C9.19824 17.9672 9.35082 18.0018 9.50523 18.0006H13.3752L11.5652 24.7406C11.5059 24.9579 11.5217 25.1889 11.6101 25.3961C11.6985 25.6034 11.8543 25.7747 12.0522 25.8823C12.2501 25.9899 12.4786 26.0276 12.7005 25.9892C12.9225 25.9507 13.125 25.8385 13.2752 25.6706L24.1752 13.6706C24.305 13.5285 24.391 13.3519 24.423 13.1621C24.4549 12.9723 24.4314 12.7773 24.3552 12.6006ZM14.5652 21.2806L15.6352 17.2806C15.6757 17.1323 15.6814 16.9766 15.6519 16.8257C15.6224 16.6748 15.5585 16.5328 15.4652 16.4106C15.3721 16.2896 15.2524 16.1915 15.1155 16.1239C14.9786 16.0563 14.8279 16.021 14.6752 16.0206H10.8352L12.9752 8.00059H17.9052L16.6352 12.7406C16.5945 12.8916 16.5897 13.0502 16.6213 13.2034C16.653 13.3566 16.7201 13.5003 16.8174 13.6228C16.9147 13.7454 17.0393 13.8434 17.1814 13.909C17.3234 13.9746 17.4789 14.006 17.6352 14.0006H21.2052L14.5652 21.2806Z"
        fill="#775FD5"
      />
    </svg>
  );
};

import React from 'react';
export const Caret = ({ color }) => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81489 0.244261L5.59363 3.10175C5.84124 3.27795 5.98828 3.56307 5.98828 3.86697C5.98828 4.17087 5.84124 4.45598 5.59363 4.63219L1.81489 7.75588C1.49522 8.01167 1.06098 8.07143 0.684092 7.91152C0.307204 7.7516 0.0484481 7.39779 0.0102692 6.99016L0.0102692 1.00799C0.0491018 0.600715 0.30811 0.247526 0.684876 0.0880765C1.06164 -0.0713727 1.4955 -0.0114073 1.81489 0.244261Z"
        fill={color || '#D9D9D9'}
      />
    </svg>
  );
};

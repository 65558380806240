import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../utils';
import { Danger, ArrowForward } from '../../../HemeIconLibrary';
import nonActiveStudy from '../../../assets/images/non-active.svg';

const NonActiveLogistic = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-[10px] mt-[108px]">
      <img src={nonActiveStudy} alt="" className="" />
      <p className="text-lg leading-[22px] font-semibold text-textColor2  w-[550px] text-center m-0">
        The logistics orchestrator is not active for this study. Please activate
        it in Study Management.
      </p>
      <Button
        text="Go to Study Management"
        cta={() => {
          navigate('/study-management');
        }}
        bg="bg-primary1"
        border="border-primary1"
        color="text-white"
        icon={<ArrowForward />}
        className="h-[48px] justify-center font-semibold"
      />
    </div>
  );
};

export default NonActiveLogistic;

import React from 'react';

export const InManagement = ({ w, h, color }) => {
  return (
    <svg
      width={w || '24'}
      height={h || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.50781C11.4257 2.50781 10.8515 2.69464 10.377 3.06836H10.376L4.42969 7.75391C3.52774 8.46472 3 9.55089 3 10.6992V19.7441C3 20.7016 3.79252 21.4941 4.75 21.4941H19.25C20.2075 21.4941 21 20.7016 21 19.7441V10.6992C21 9.55089 20.4723 8.46472 19.5703 7.75391L13.623 3.06836C13.1485 2.69464 12.5743 2.50781 12 2.50781ZM12 4.00391C12.2449 4.00391 12.4899 4.08529 12.6953 4.24707L18.6426 8.93262C19.1843 9.35981 19.5 10.0098 19.5 10.6992V19.7441C19.5 19.8917 19.3975 19.9941 19.25 19.9941H18V16.25C18 15.2925 17.2075 14.5 16.25 14.5H15.5V11.25C15.5 10.2925 14.7075 9.5 13.75 9.5H10.25C9.29252 9.5 8.5 10.2925 8.5 11.25V14.5H7.75C6.79252 14.5 6 15.2925 6 16.25V19.9941H4.75C4.60248 19.9941 4.5 19.8917 4.5 19.7441V10.6992C4.5 10.0095 4.81537 9.35883 5.35742 8.93164L11.3047 4.24707C11.5101 4.08529 11.7551 4.00391 12 4.00391ZM10.25 11H13.75C13.8975 11 14 11.1025 14 11.25V14.5H10V11.25C10 11.1025 10.1025 11 10.25 11ZM7.75 16H11.25V19.9941H7.5V16.25C7.5 16.1025 7.60248 16 7.75 16ZM12.75 16H16.25C16.3975 16 16.5 16.1025 16.5 16.25V19.9941H12.75V16Z"
        fill={color || 'url(#paint0_linear_4431_204236)'}
        stroke={color || 'url(#paint1_linear_4431_204236)'}
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4431_204236"
          x1="3"
          y1="12.001"
          x2="21"
          y2="12.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4431_204236"
          x1="3"
          y1="12.001"
          x2="21"
          y2="12.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

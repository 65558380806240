import React from "react";
export const CaretDown = ({color='#6B6D70'}) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75574 1.81562L4.89825 5.59437C4.72205 5.84197 4.43693 5.98901 4.13303 5.98901C3.82913 5.98901 3.54402 5.84197 3.36781 5.59437L0.244121 1.81562C-0.0116663 1.49595 -0.0714331 1.06171 0.0884833 0.684824C0.2484 0.307937 0.602212 0.049181 1.00984 0.0110016L6.99201 0.0110016C7.39928 0.0498343 7.75247 0.308843 7.91192 0.685609C8.07137 1.06238 8.01141 1.49623 7.75574 1.81562Z"
        fill={color}
      />
    </svg>
  );
};

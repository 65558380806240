import React from 'react';

import { TagsInput } from 'react-tag-input-component';

const Tags = ({ placeholder, name, setFieldValue, value, disabled, classNames, formikRef }) => {
  //console.log(value)
  return (
    <div className={`hema-tag-input ${classNames}`}>
      <TagsInput
        value={value || []}
        onChange={(e) => {
          if (setFieldValue) {
            setFieldValue(name, e);
          }
        }}
        name={name}
        placeHolder={placeholder}
        disabled={disabled}
        classNames={classNames}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            formikRef?.current?.setFieldTouched(name, true);
          }
        }}
      />
    </div>
  );
};

export default Tags;

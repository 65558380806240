import React from 'react';

export const RedDeleteFIle = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.19922 8.2H4.39922M5.99922 11.4H4.39922M10.7992 5H4.39922M13.9992 9V4.84C13.9992 3.49587 13.9992 2.82381 13.7376 2.31042C13.5075 1.85883 13.1404 1.49168 12.6888 1.26158C12.1754 1 11.5033 1 10.1592 1H5.03922C3.69509 1 3.02303 1 2.50964 1.26158C2.05805 1.49168 1.6909 1.85883 1.4608 2.31042C1.19922 2.82381 1.19922 3.49587 1.19922 4.84V13.16C1.19922 14.5041 1.19922 15.1762 1.4608 15.6896C1.6909 16.1412 2.05805 16.5083 2.50964 16.7384C3.02303 17 3.69509 17 5.03922 17H7.59922M10.7992 12.2L14.7992 16.2M14.7992 12.2L10.7992 16.2"
        stroke="url(#paint0_linear_4263_143578)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_4263_143578" x1="1.19922" y1="9" x2="14.7992" y2="9" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const RedMinus = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM13.5 9.9H9.9H8.1H4.5V8.1H8.1H9.9H13.5V9.9Z"
        fill="#F87FB1"
      />
    </svg>
  );
};

import React from 'react';

export const Assemblies = ({ color, purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9199 9.51434L15.4943 8.81749L15.0671 9.51336L13.1066 12.7066L12.6391 13.4682H13.5328H17.4435H18.3348L17.8702 12.7076L15.9199 9.51434ZM8.41949 19.0366H7.91949V19.5366V23.5787V24.0787H8.41949H12.4616H12.9616V23.5787V19.5366V19.0366H12.4616H8.41949ZM10.8268 14.4893L15.4932 6.8534L20.1595 14.4893H10.8268ZM17.0037 21.5577C17.0037 19.3176 18.811 17.5103 21.0511 17.5103C23.2911 17.5103 25.0984 19.3176 25.0984 21.5577C25.0984 23.7978 23.2911 25.6051 21.0511 25.6051C18.811 25.6051 17.0037 23.7978 17.0037 21.5577ZM18.0248 21.5577C18.0248 23.2284 19.3804 24.584 21.0511 24.584C22.7217 24.584 24.0774 23.2284 24.0774 21.5577C24.0774 19.887 22.7217 18.5314 21.0511 18.5314C19.3804 18.5314 18.0248 19.887 18.0248 21.5577ZM13.9826 18.0156V25.0998H6.89844V18.0156H13.9826Z"
        fill={
          (color && color) ||
          (purple && 'url(#paint0_linear_5498_185010)') ||
          '#414753'
        }
        stroke={
          (color && color) ||
          (purple && 'url(#paint1_linear_5498_185010)') ||
          '#414753'
        }
      />
      {purple && (
        <defs>
          <linearGradient
            id="paint0_linear_5498_185010"
            x1="8.63567"
            y1="18.6127"
            x2="23.5004"
            y2="18.8379"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#775FD5" />
            <stop offset="1" stopColor="#F03A85" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5498_185010"
            x1="8.63567"
            y1="18.6127"
            x2="23.5004"
            y2="18.8379"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#775FD5" />
            <stop offset="1" stopColor="#F03A85" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};

import { ChangeReason, Email, Role, FirstName, CheckReady } from '../../../HemeIconLibrary';

export const createUserField = [
  {
    label: 'First Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter first name',
    required: true,
    name: 'firstName',
  },
  {
    label: 'Last Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter last name',
    required: true,
    name: 'lastName',
  },
  {
    label: 'Email',
    icon: <Email />,
    type: 'email',
    initialValue: '',
    placeholder: 'Enter Email',
    required: true,
    name: 'email',
  },
  {
    name: 'roleId',
    label: 'Role',
    icon: <Role />,
    type: 'singleSelect',
    required: true,
    initialValue: '',
    placeholder: 'Select Role',
    options: [],
  },
  {
    name: 'isActive',
    label: 'Active',
    icon: <CheckReady purple />,
    type: 'switch',
    fluid: true,
    initialValue: '',
    required: false,
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
];

export const bulkFileImport = [
  {
    label: 'Please download and use the template to bulk upload users.',
    type: 'download-bulk',
    downloadType: null,
    noCapital: true,
    fluid: true,
    onClick: () => {},
    buttonText: 'Download Sample Template',
  },
  {
    name: 'userFile',
    // label: 'Upload Excel',
    // icon: <Upload />,
    type: 'upload-image',
    initialValue: undefined,
    placeholder: 'Excel',
    fluid: true,
    required: true,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  },
];

import { createNewStudy, getAllStudies, editSudy } from "../Services/study";
import { store } from "../Store";
import {
  setAllStudiesReducer,
  addNewStudyReducer,
  editStudyReducer,
} from "../Store/reducers/study";
import { caseInsensitiveSort } from '../helpers/commonHelper';
import { downloadCsv } from '../Services/suppliesReport';

const getAllStudyAction = async (data) => {
  const result = await getAllStudies(data);
  store.dispatch(setAllStudiesReducer(result?.data.sort(caseInsensitiveSort)));
};
const createNewStudyAction = async (data) => {
  const result = await createNewStudy(data);
  if (result?.data) {
    store.dispatch(addNewStudyReducer(result?.data));
  }
  return result;
};
const updateStudyAction = async (sid, data) => {
  const result = await editSudy(sid, data);
  store.dispatch(editStudyReducer({ id: sid, data: result.data }));
  return result;
};
const downloadSuppliesReportAction = async(studyId, fromDate, toDate, fileName) =>{
  await downloadCsv(studyId, fromDate, toDate, fileName)
}

export { createNewStudyAction, getAllStudyAction, updateStudyAction, downloadSuppliesReportAction };

import React from 'react';

export const CountryFlag = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.11068 1.65625C3.11068 1.29258 2.81345 1 2.44401 1C2.07457 1 1.77734 1.29258 1.77734 1.65625V2.75V10.584V11.9375V14.3438C1.77734 14.7074 2.07457 15 2.44401 15C2.81345 15 3.11068 14.7074 3.11068 14.3438V11.6094L5.34123 11.0598C6.4829 10.7781 7.69123 10.9094 8.74401 11.4262C9.97179 12.0305 11.3968 12.1043 12.6801 11.6285L13.644 11.273C13.9912 11.1445 14.2218 10.8191 14.2218 10.4527V2.80742C14.2218 2.17852 13.5496 1.76836 12.9773 2.05L12.7107 2.18125C11.4246 2.81563 9.91068 2.81563 8.62457 2.18125C7.64957 1.7 6.53012 1.57969 5.47179 1.83945L3.11068 2.42188V1.65625ZM3.11068 3.77539L5.79401 3.11367C6.54401 2.93047 7.33568 3.01523 8.02734 3.3543C9.55234 4.10352 11.3246 4.16641 12.8885 3.54023V10.152L12.2107 10.4008C11.2746 10.7453 10.2329 10.6934 9.33846 10.2531C7.99957 9.59414 6.46901 9.43008 5.01623 9.78555L3.11068 10.2559V3.77539Z"
        fill="url(#paint0_linear_8401_299279)"
        stroke="url(#paint1_linear_8401_299279)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8401_299279"
          x1="1.77734"
          y1="7.99999"
          x2="14.2218"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8401_299279"
          x1="1.77734"
          y1="7.99999"
          x2="14.2218"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

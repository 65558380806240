import React from 'react';

export const ShipmentName = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 14.6693C12.8511 14.6693 13.0001 14.5203 13.0001 14.3359V12.0026H11.3334C11.3334 12.3709 11.0351 12.6693 10.6667 12.6693C10.2984 12.6693 10.0001 12.3709 10.0001 12.0026H8.33342V14.6693H12.6667ZM7.66667 4.854H6C6 5.22234 5.70167 5.52067 5.33333 5.52067C4.965 5.52067 4.66667 5.22234 4.66667 4.854H3L3.00008 11.3359H7.66675L7.66667 4.854ZM6.00008 12.0026C6.00008 12.3709 5.70175 12.6693 5.33341 12.6693C4.96508 12.6693 4.66675 12.3709 4.66675 12.0026H3.00008V14.3359C3.00008 14.5203 3.14908 14.6693 3.33341 14.6693H7.66675V12.0026H6.00008ZM7.66675 4.0026V1.33594H3.33341C3.14908 1.33594 3.00008 1.48494 3.00008 1.66927V4.0026H7.66675ZM8.33342 6.66927H13.0001V11.3359H8.33342V6.66927ZM8.33342 6.0026H13.0001V1.66927C13.0001 1.48494 12.8511 1.33594 12.6667 1.33594H8.33342V6.0026Z"
        fill="url(#paint0_linear_9495_250406)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9495_250406"
          x1="3"
          y1="8.0026"
          x2="13.0001"
          y2="8.0026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const EditCountry = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4996 2C9.01456 2 6.99956 4.015 6.99956 6.5C6.99956 9.044 9.55697 12.2276 10.816 13.6406C11.18 14.0496 11.8192 14.0496 12.1832 13.6406C13.4422 12.2276 15.9996 9.044 15.9996 6.5C15.9996 4.015 13.9846 2 11.4996 2ZM11.4996 5C12.3276 5 12.9996 5.672 12.9996 6.5C12.9996 7.328 12.3276 8 11.4996 8C10.6716 8 9.99956 7.328 9.99956 6.5C9.99956 5.672 10.6716 5 11.4996 5ZM18.5367 5.86719C18.2152 5.93323 17.9591 6.21331 17.9546 6.57031C17.9506 6.91631 17.9374 7.24461 17.9234 7.34961C17.9124 7.41961 17.9032 7.48859 17.8902 7.55859C17.2362 11.3566 13.3896 15.3131 12.9136 15.7891C12.1326 16.5701 10.8665 16.5701 10.0855 15.7891C9.7055 15.4091 7.1775 12.8126 5.8355 9.84961V9.84766C5.6555 9.44866 5.49671 9.04472 5.36871 8.63672C5.23871 8.22272 4.70853 8.1145 4.41753 8.4375C3.29153 9.6865 2.34077 10.9416 2.15777 11.3086C1.87677 11.8736 1.98881 12.2801 2.15581 12.5371L4.99956 17.2773V19C4.99956 19.389 5.2315 19.7462 5.5855 19.9102C9.0005 21.4912 10.4102 22.0547 11.0992 22.0547C11.5202 22.0547 11.6732 21.8433 11.8492 21.5273C12.0222 21.2773 12.7742 20.4623 13.2242 20.0703C13.7452 20.3033 14.7383 20.8767 15.2613 21.1777C16.2173 21.7287 16.5331 21.9058 16.8101 21.9688C17.6831 22.1628 18.9418 21.3132 19.6168 20.7852C20.3498 20.2112 19.9366 19.4883 19.4136 18.5723C19.3186 18.4053 19.1854 18.1681 19.0894 17.9961C19.3894 17.5691 20.0684 16.791 20.5054 16.291C21.8884 14.707 22.4056 14.114 21.7066 13.293L20.3609 11.9473C21.5619 10.5373 21.5919 10.4365 21.6519 10.2305C21.9499 9.20847 22.5005 7.3277 20.9605 6.5957C20.7375 6.4607 20.2306 6.23895 18.8726 5.87695C18.7576 5.8462 18.6438 5.84517 18.5367 5.86719ZM7.99956 16C8.55156 16 8.99956 16.448 8.99956 17C8.99956 17.552 8.55156 18 7.99956 18C7.44756 18 6.99956 17.552 6.99956 17C6.99956 16.448 7.44756 16 7.99956 16Z"
        fill="url(#paint0_linear_4955_82091)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4955_82091"
          x1="1.98633"
          y1="12.0273"
          x2="22.0168"
          y2="12.0273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

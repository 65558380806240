import React from 'react';

export const CheckAction = ({ color }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50018 12.8668L2.63518 8.00177L0.978516 9.64677L7.50018 16.1684L21.5002 2.16844L19.8552 0.523438L7.50018 12.8668Z"
        fill={color || 'url(#paint0_linear_4071_39379)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_4071_39379"
          x1="0.978516"
          y1="8.34594"
          x2="21.5002"
          y2="8.34594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const CreateSiteValidation = (errors, values, store) => {
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) &&
    values.email
  ) {
    errors.email = "Please enter correct email format";
  }
  if (values?.name?.length > 100) {
    errors.name = "max 100 character allowed";
  }
  if (values?.siteCode?.length > 50) {
    errors.siteCode = "max 50 character allowed";
  }
  if (values?.address1?.length > 150) {
    errors.address1 = "max 150 character allowed";
  }
  if (values?.address2?.length > 150) {
    errors.address2 = "max 150 character allowed";
  }
  if (values?.city?.length > 50) {
    errors.city = "max 50 character allowed";
  }
  if (values?.stateProvince?.length > 50) {
    errors.stateProvince = "max 50 character allowed";
  }
  if (values?.postalCode?.length > 20) {
    errors.postalCode = "max 20 character allowed";
  }
  if (values?.firstName?.length > 100) {
    errors.firstName = "max 100 character allowed";
  }
  if (values?.lastName?.length > 100) {
    errors.lastName = "max 100 character allowed";
  }
  if (values?.department?.length > 100) {
    errors.department = "max 100 character allowed";
  }

  return errors;
};

export default CreateSiteValidation;

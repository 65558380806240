import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import LogOff from '../../assets/images/logOff.svg'
// import Close from '../../assets/images/closeTimer.svg';
import LoadTimer from '../../assets/images/loadTimer.svg'
import { Next } from '../../HemeIconLibrary'
import { Button, HemaLabel } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { refreshTokenAction } from '../../Actions/auth'
import { useDispatch } from 'react-redux'
import {
  setRefreshTokenTime,
  setShowRefreshToken,
} from '../../Store/reducers/common'
const Index = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className="timer_modal_box">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="timer_modal"
        centered
        backdrop="static"
      >
        {/* <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <img
              src={Close}
              onClick={() => props?.onHide()}
              alt="close"
              className="cursor-pointer"
            />
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="flex flex-col gap-[25px] justify-center items-center">
            <img className="w-[36px] h-[48px]" src={LoadTimer} alt="load" />
            <div className="flex flex-col gap-[5px] justify-center items-center">
              <p className="text-[18px] leading-[120%] font-medium tracking-[-0.36px] m-0 text-[#F5488F]">
                Session Timeout
              </p>
              <p className="text-[52px] font-semibold leading-[62px] tracking-[-1.04px] m-0 text-[#232323]">
                <Timer />
              </p>
            </div>
            <HemaLabel
              textSize
              text="Click “Continue” to keep working or click “Log Off” to end your session now"
              color="text-[#737373]"
              className="text-[16px] font-normal leading-[26px] text-center"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="timer_btns">
            <Button
              type="button"
              icon={<img src={LogOff} alt="logOff" />}
              text="Log Off"
              className="px-[16px]"
              bg="bg-white"
              border="border-primary1"
              color="text-primary1"
              cta={() => {
                localStorage.removeItem('hema-token')
                localStorage.removeItem('hema-token-refresh')
                navigate('/login')

                dispatch(setShowRefreshToken(false))
              }}
            />
            <Button
              type="button"
              cta={async () => {
                props?.onHide()
                const refreshToken = await refreshTokenAction()
                dispatch(setRefreshTokenTime())
                dispatch(setShowRefreshToken(false))
                if (refreshToken.status === 200) {
                  localStorage.setItem('hema-token', refreshToken.data.token)
                  localStorage.setItem(
                    'hema-token-refresh',
                    refreshToken.data.refreshToken,
                  )
                } else {
                  localStorage.removeItem('hema-token')
                  localStorage.removeItem('hema-token-refresh')
                  window.location.reload()
                }
              }}
              icon={<Next />}
              bg="bg-primary1"
              border="border-primary1"
              color="text-white"
              text="Continue"
              className="px-[16px]"
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Timer() {
  const [seconds, setSeconds] = useState(60) // 60 seconds
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  useEffect(() => {
    if (seconds === 0) {
      localStorage.removeItem('hema-token')
      localStorage.removeItem('hema-token-refresh')

      dispatch(setShowRefreshToken(false))
      navigate('/login')
    }
  }, [seconds])
  return (
    <div>
      {minutes > 0 && <span>{minutes} min</span>}&nbsp;
      {remainingSeconds > 0 && <span>{remainingSeconds} sec</span>}
    </div>
  )
}

export default Index

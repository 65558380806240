import { Format, LabelName, LabelCategory, LabelUnitType, StudyName } from '../../../HemeIconLibrary';

export const CustomInvoice = [
  {
    label: '',
    type: 'heading-only',
    initialValue: 'General Information',
  },
  {
    label: 'Study Name (Protocol Name)',
    icon: <StudyName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Protocol Name',

    fluid: true,
    name: 'studyName',
  },
  {
    label: 'Study Code (Protocol Code)',
    icon: <StudyName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Study Code',

    fluid: true,
    name: 'studyCode',
  },
  {
    label: 'Kit Contents',
    icon: <LabelCategory />,
    type: 'text',
    initialValue: '',
    placeholder: 'Testing Lab Address',
    required: false,
    fluid: true,
    name: 'kitContent',
  },
  {
    label: 'HS/HTS Code',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter',
    required: false,
    fluid: true,
    name: 'htcCode',
  },
  {
    label: 'Description of Goods',
    icon: <LabelUnitType />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter',
    fluid: true,
    name: 'descOfgoods',
  },
  {
    label: 'Number of Items',
    icon: <LabelCategory />,
    type: 'number',
    initialValue: '',
    placeholder: 'items',
    required: false,
    fluid: true,
    name: 'items',
    min:1
  },
  {
    label: 'Total Value (USD)',
    icon: <Format />,
    type: 'number',
    initialValue: '',
    placeholder: 'Number of Items',
    required: false,
    fluid: true,
    name: 'valueUsd',
    min:1
  },
  {
    label: 'Country of Origin',
    icon: <LabelCategory />,
    type: 'text',
    initialValue: '',
    placeholder: 'Country of Origin',
    required: false,
    fluid: true,
    name: 'country',
  },
  {
    label: '',
    type: 'heading-only',
    initialValue: 'Shipper Information',
  },

  {
    label: 'Site Number',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Number',
    required: false,
    fluid: true,
    name: 'siteNumber',
  },
  {
    label: 'Site Name',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Name',
    required: false,
    fluid: true,
    name: 'siteName',
  },
  {
    label: 'Site Address',
    icon: <LabelCategory />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Address',
    required: false,
    fluid: true,
    name: 'siteAddress',
  },
  {
    label: 'Principal Investigator',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Principal Investigator',
    required: false,
    fluid: true,
    name: 'Pi',
  },
  {
    label: '',
    type: 'heading-only',
    initialValue: 'Recipient Information',
  },
  {
    label: 'Testing Lab Name',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Testing Lab Name',
    required: false,
    fluid: true,
    name: 'labName',
  },
  {
    label: 'Testing Lab Address',
    icon: <Format />,
    type: 'text',
    initialValue: '',
    placeholder: 'Testing Lab Address',
    required: false,
    fluid: true,
    name: 'labAddress',
  },
];

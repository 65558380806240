import {
  ChangeReason,
  TickOrange,
  NameKit,
  SiteName,
  Address,
  Email,
  FirstName,
  Telephone,
  CheckReady,
  AddRegion,
} from '../../../HemeIconLibrary'

export const createNewSite = [
  {
    label: 'Study Code',
    icon: <NameKit />,
    type: 'singleSelect',
    initialValue: '',
    placeholder: 'Select study',
    required: true,
    name: 'studyId',
    fluid: true,
    options: [],
  },
  {
    label: 'Site Name',
    icon: <SiteName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter site name',
    required: true,
    fluid: true,
    name: 'name',
  },
  {
    label: 'Site Code',
    icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter site code',
    required: true,
    fluid: true,
    name: 'siteCode',
  },
  {
    label: 'Address 1',
    icon: <Address purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter site address',
    required: true,
    fluid: true,
    name: 'address1',
  },
  {
    label: 'Address 2',
    icon: <Address purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter site address',

    fluid: true,
    name: 'address2',
  },
  {
    label: 'Department',
    initialValue: '',
    name: 'department',
    placeholder: 'Enter department',
    icon: <Address purple />,
    fluid: true,
    type: 'text',
  },
  {
    label: 'City',
    icon: <Address purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter city',
    required: true,
    name: 'city',
  },
  {
    label: 'State',
    icon: <AddRegion width="15px" />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter state',
    required: false,
    name: 'stateProvince',
  },
  {
    label: 'Country',
    icon: <AddRegion width="15px" />,
    type: 'singleSelect',
    initialValue: '',
    placeholder: 'Select country',
    required: true,
    name: 'countryId',
    options: [],
  },
  {
    label: 'Postal Code',
    icon: <Address purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter postal code',
    required: false,
    name: 'postalCode',
  },
  {
    label: 'Phone Number',
    icon: <Telephone />,
    type: 'number',
    initialValue: '',
    fluid: true,
    placeholder: 'Enter',
    name: 'phone',
    min: 1,
  },
  {
    name: 'isActive',
    label: 'Active',
    icon: <CheckReady purple />,
    type: 'switch',
    initialValue: true,
    fluid: true,
    placeholder: '',
  },

  {
    label: 'First Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter first name',
    required: true,
    name: 'firstName',
  },
  {
    label: 'Last Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter last name',
    required: true,
    name: 'lastName',
  },
  {
    label: 'Email',
    icon: <Email />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter Email',
    required: false,
    name: 'email',
    fluid: true,
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    placeholder: 'Type Reason',
    fluid: true,
    initialValue: '',
    required: true,
  },
]

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';

import {
  Button,
  FormSearch,
  HemaValue,
  Pagination,
  NoDataComponent,
} from '../../../utils';
import { CopyColor, Edit, TableSortIcon } from '../../../HemeIconLibrary';
import deleteIcon from '../../../assets/images/deleteIcon.svg';

import { sortedData } from '../../../helpers/sort';
import { setForm, editFormReducer } from '../../../Store/reducers/uiSettings';
import { setShowOrderConfig } from '../../../Store/reducers/settings';

const RoleManagmentList = () => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { userRole } = settings;
  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(userRole, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(userRole);
      }
    })();
  }, [searchQuery, userRole]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="px-[16px]">
          <FormSearch
            w="w-[400px]"
            searchQuery={searchQuery}
            setsearchQuery={setsearchQuery}
          />
        </div>
        {!userRole ? (
          <SkelatonCoponent />
        ) : userRole?.length > 0 ? (
          dataList?.length > 0 ? (
            <DataTable
              data={dataList}
              customStyles={{
                rows: {
                  style: {
                    // paddingRight: "20px",
                  },
                },
              }}
              className="border-t border-[#DEE2E6] mt-[10px] rounded-t-none managment-table-st role-mana-table-style"
              columns={[
                {
                  name: (
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[20px]">
                      <HemaValue
                        text={'Roles'}
                        color="text-[#000000]"
                        className="text-xs font-normal"
                      />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                  ),
                  sortable: true,

                  selector: (row) => (
                    <HemaValue
                      text={row?.name}
                      color="text-textColor"
                      className="text-xs font-medium"
                    />
                  ),
                  sortId: 'name',
                },

                {
                  name: (
                    <div className="w-full flex items-center justify-start gap-1 py-[8px] ">
                      <HemaValue
                        text={'Actions'}
                        color="text-[#000000]"
                        className="text-xs font-normal "
                      />
                    </div>
                  ),
                  style: {
                    paddingRight: '75px',
                  },
                  cell: (row) => {
                    return (
                      <div className="flex-grow four ">
                        <div className="flex justify-center gap-[16px] meta ">
                          {common?.permissions?.['Role Management']?.update && (
                            <Button
                              icon={<Edit color="#775FD5" />}
                              padding={false}
                              color="text-[#dc2626]"
                              bg="bg-bgActionDots"
                              cta={async () => {
                                dispatch(editFormReducer(row));
                                dispatch(
                                  setForm({ state: false, type: 'edit role' })
                                );
                                dispatch(setShowOrderConfig(true));
                              }}
                            />
                          )}
                          {common?.permissions?.['Role Management']?.write && (
                            <Button
                              icon={<CopyColor color="#5DD099" />}
                              padding={false}
                              color="text-[#dc2626]"
                              bg="bg-bgActionDots"
                              cta={async () => {
                                dispatch(editFormReducer(row));
                                dispatch(
                                  setForm({ state: false, type: 'copy role' })
                                );
                                dispatch(setShowOrderConfig(true));
                              }}
                            />
                          )}

                          {common?.permissions?.['Role Management']?.delete && (
                            <Button
                              icon={
                                <img
                                  className="min-w-[16px] height-[18px]"
                                  src={deleteIcon}
                                  alt="delete icon"
                                />
                              }
                              padding={false}
                              color="text-[#dc2626]"
                              bg="bg-bgActionDots"
                              cta={async () => {
                                dispatch(editFormReducer(row));
                                dispatch(
                                  setForm({ state: false, type: 'delete role' })
                                );
                                dispatch(setShowOrderConfig(true));
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  },
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              pagination
              onSort={(row, direction, sorted) => {
                setDataList(sortedData(row.sortId, direction, sorted));
              }}
              paginationComponent={(e) => {
                return <Pagination e={e} />;
              }}
            />
          ) : (
            <BootstrapAlert variant="warning" className="mt-3 text-center">
              There is no data found.
            </BootstrapAlert>
          )
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            No Roles to show. Please add by clicking on Add New Role.
          </BootstrapAlert>
        )}
      </>
    </div>
  );
};

export default RoleManagmentList;

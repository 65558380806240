import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Tab, Tabs, NavDropdown } from 'react-bootstrap'
import { Alert as AlertBootstrap } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { filter } from 'smart-array-filter'
import DataTable from 'react-data-table-component'
import ChangeReasonIcon from '../assets/images/change-reason.svg'
import { ConfirmKitBuilder } from '../components/Formik/AllForms/confirmKitBuilder'
import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText'
import {
  Button,
  FormSearch,
  Alert,
  HemaValue,
  Pagination,
  FilterColumn,
  NoPermissionAlert,
  HemaLabel,
  FormSelect,
  NoDataComponent,
  FormTextarea,
} from '../utils'
import Spinner from 'react-bootstrap/Spinner'

import {
  Add,
  RowExpand,
  RowCollapsed,
  AddInventoryPurple,
  ExpiryAlertPurple,
  CopyDocumentPurple,
  DeletePurple,
  CreateTranslation,
  CreateItemPurple,
  CopyColor,
  DeleteColor,
  KitTemplate,
  DocTemplate,
  Delete,
  ViewIcon,
  Art,
  Copy,
  Assemblies,
  DotsIcon,
  Next,
  Binocular,
  FileIcon,
  TableSortIcon,
  Edit,
  DeleteBin,
  LargeEmailIcon,
} from '../HemeIconLibrary'

import { addAssembly } from '../components/Formik/AllForms/addAssembly'
import Heading from '../pages/logistOrchestra/logisticComponents/heading'

import FormContainer from '../components/Formik/formContainer'
import { itemValidator } from '../components/Formik/allFormValidation'
import { Remove } from '../components/Formik/AllForms/remove'
import {
  setForm,
  setFormName,
  editFormReducer,
  setFormCloseReducer,
  showSuccessReducer,
  setFormLoaderReducer,
} from '../Store/reducers/uiSettings'
import { createNewItem } from '../components/Formik/AllForms/createNewItem'
import { addDocTemplate } from '../components/Formik/AllForms/createDocTemplate'
import { addDocTemplateLang } from '../components/Formik/AllForms/createDocTranslation'

import { deleteEmailTemplates } from '../components/Formik/AllForms/deleteEmailTemplates'
import { Confirm, Cancel } from '../HemeIconLibrary'
import {
  getAllItemAction,
  uploadFileItemAction,
  updateNewItemAction,
} from '../Actions/inventory'
import {
  droptownItemsForAssemblyAction,
  getAllInventoryLanguages,
  getTypesAction,
} from '../Actions/common'
import { getAssemblyInboundConditionAction } from '../Actions/logistic'
import { setDropdownValue } from '../Store/reducers/common'
import DocumentEditor from '../components/documentEditor'
import {
  createKitBuilderAction,
  getKitBuilderAction,
  removeBuildKitAction,
} from '../Actions/kitBuilder'
import {
  getTestingLabsAction,
  getAssembliesTypeAction,
  createAssemblyAction,
  getAllAssembliesAction,
  removeAssemblyAction,
  removeAssemblyItemAction,
} from '../Actions/assembly'
import {
  getAlldocTemplateAction,
  createDocTemplateAction,
  removeDocTemplateAction,
  getdocTemplateType,
  createDocTempVersionAction,
  updateDocTemplateFileAction,
  removeDocVersionAction,
  mergfieldsByType,
  getdocCopyNameAction,
  editDocNameAction,
  editDocTemplateVersionAction,
} from '../Actions/documentTemplate'

import {
  getAllEmailTemplateAction,
  createEmailTemplateAction,
  removeEmailTemplateAction,
} from '../Actions/emailTemplate'

import { sortedData } from '../helpers/sort'
import { setKitTemplateFilter } from '../Store/reducers/kitBuilder'
import { setdocTemplateFilter } from '../Store/reducers/documentTemplate'
import { setassemblyFilter } from '../Store/reducers/assembly'
import { setEmailTemplatesFilter } from '../Store/reducers/emailTemplate'
import { getSystemUserAction } from '../Actions/settings'
import { updatekitItemDrodpown } from '../Store/reducers/items'

export const ExpendedDataRows = ({ data }) => {
  return (
    <div className=" w-full py-[10px]  border-b border-[#DEE2E6]">
      <DataTable
        customStyles={{
          table: {
            style: { background: 'transparent !important', border: 'none' },
          },
          head: { style: { display: 'none' } },

          rows: {
            style: {
              borderBottom: 'none !important',
              paddingLeft: '50px',
              paddingRight: '100px',
            },
          },
          responsiveWrapper: {
            style: { overflow: 'visible !important' },
          },
        }}
        data={data.items}
        columns={[
          {
            selector: (row, index) => (
              <HemaValue
                className="text-xs"
                text={`${index + 1}. ${row?.name}`}
              />
            ),
          },
          {
            selector: (row) => (
              <HemaValue className="text-xs" text={row?.itemPerKit} />
            ),
          },
          {
            selector: (row) => (
              <HemaValue className="text-xs" text={row?.availableToBuild} />
            ),
          },
        ]}
      />
    </div>
  )
}

const KitBuilder = () => {
  const dispatch = useDispatch()
  const {
    uisettings,
    builder,
    common,
    docTemplate,
    settings,
    assembly,
    emailTemplate,
  } = useSelector((state) => state)
  const { kitTemplateFilter } = builder
  const { docTemplateFilter } = docTemplate
  const { assemblyFilter } = assembly
  const { emailTemplatesFilter } = emailTemplate

  /* form states */
  const [CTA, setCTA] = useState()
  const [formName, setformName] = useState()
  const [formIcon, setFormIcon] = useState()
  const [formValidation, setFormValidation] = useState()
  const [updatedData, setUpdatedData] = useState()
  // const [editFormState, setEditFormState] = useState();
  const [activeTab, setactiveTab] = useState('Template')
  const [filterbuilder, setfilterbuilder] = useState(null)
  const [filterAssembly, setFilterAssembly] = useState(null)
  const [filterDocument, setFilterDocument] = useState(null)
  const [filterEmailTemplate, setFilterEmailTemplate] = useState(null)
  const [searchQuery, setsearchQuery] = useState('')
  const [searchQueryAssembly, setsearchQueryAssembly] = useState('')
  const [searchQueryDocument, setSearchQueryDocument] = useState('')
  const [searchQueryEmail, setSearchQueryEmail] = useState('')
  const [allItemsInTemplate, setAllItemsInTemplate] = useState()
  const [dropdownItemList, setDropDownItemList] = useState()
  // const [editorValue, seteditorValue] = useState('');
  const [openEditor, setopenEditor] = useState('')
  const [isdocLoaded, setisdocLoaded] = useState(false)
  const [okBtnText, setokBtnText] = useState()
  const [okBtnIcon, setokBtnIcon] = useState()
  const [cancelBtnText, setCancelBtnText] = useState()
  const [cancelBtnIcon, setSancelBtnIcon] = useState()
  const [formikValues, setFormikValues] = useState()
  const [sortIcon, setSortIcon] = useState()
  const [pageSub, setPageSub] = useState('Manage your templates here.')
  const [changeReasonDoc, setChangeReasonDoc] = useState()

  const docRef = useRef(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    const tabToOpen = searchParams.get('type')
    if (
      ['Template', 'Document', 'Assemblies', 'emailTemplates'].includes(
        tabToOpen,
      )
    ) {
      setactiveTab(tabToOpen)
    }
    if (tabToOpen === 'Template') {
      setPageSub('Manage your kit templates here.')
    } else if (tabToOpen === 'Document') {
      setPageSub('Manage your document templates here.')
    } else if (tabToOpen === 'Assemblies') {
      setPageSub('Manage your assemblies here.')
    } else {
      setPageSub('Manage your email templates here.')
    }
  }, [searchParams])

  const editInventoryCTA = (id) => {
    setCTA(() => async (data) => {
      const imageToUpload = data.fileId
      let payload = JSON.parse(JSON.stringify(data))
      payload.fileId = undefined
      dispatch(setFormLoaderReducer(true))
      const createNewIem = await updateNewItemAction(id, {
        ...payload,
        lowInventoryThreshold: !payload?.lowInventoryAlert
          ? undefined
          : payload.lowInventoryThreshold,
        userIds: !payload?.lowInventoryAlert
          ? undefined
          : payload?.userIds?.map((data) => data.id) || [],
        unitTypeId: payload?.unitTypeId ? payload.unitTypeId : undefined,
      })
      if (createNewIem?.data?.id) {
        if (imageToUpload) {
          const imageCreation = await uploadFileItemAction(
            createNewIem.data?.id,
            imageToUpload,
          )
          // if (imageCreation.data.id) {
          dispatch(setFormCloseReducer())
          // }
        } else {
          dispatch(setFormCloseReducer())
        }
        dispatch(showSuccessReducer(`${data.name} Kit created.`))
        dispatch(setFormLoaderReducer(false))
      }
    })
    setFormValidation((error, values) => (error, values) => {
      itemValidator(error, values)
    })
  }

  // control item thresold switch
  useEffect(() => {
    if (common.switch.threshold) {
      createNewItem[7].required = true
      createNewItem[7].disabled = false
      createNewItem[8].disabled = false
      createNewItem[8].required = true
      ConfirmKitBuilder[2].disabled = false
    } else {
      createNewItem[7].required = false
      createNewItem[7].disabled = true
      createNewItem[8].disabled = true
      createNewItem[8].required = false
      createNewItem[8].initialValue = []
      ConfirmKitBuilder[2].disabled = true
      ConfirmKitBuilder[2].required = false
    }
  }, [common.switch])

  const createKitBuilderCTA = (item, state) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))

      let createNewIem = await createKitBuilderAction(data)
      if (createNewIem?.status === 200) {
        // getKitBuilderAction();
        let ItemId = createNewIem.data.id
        //createNewIem.data.item.id = createNewIem.data?.id;
        dispatch(
          updatekitItemDrodpown({
            ...createNewIem?.data,
            itemCategory: { id: 1, name: 'H-Kit' },
          }),
        )
        // dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${data.name} Kit created.`))
        createNewItem[4].options = common?.allTypes
        createNewItem[1].placeholder = 'H-kit'
        createNewItem[1].initialValue = 1
        createNewItem[2].required = true
        createNewItem[0].disabled = true
        createNewItem[1].disabled = true
        createNewItem[0].initialValue = data.name
        createNewItem[5].initialValue = false
        createNewItem[7].disabled = true
        createNewItem[7].required = false
        createNewItem[8].disabled = true
        createNewItem[8].required = false
        createNewItem[8].initialValue = []
        createNewItem[8].options = settings?.systemUsers?.map((data) => {
          return {
            id: data.id,
            name: data.firstName + ' ' + data.lastName,
          }
        })
        setokBtnText('Create')
        setokBtnIcon(<Confirm />)
        setformName('Create New Item')
        setFormIcon(<CreateItemPurple />)
        // createNewItem = createNewItem?.filter((item) => item.name !== "change_reason");
        setUpdatedData(
          createNewItem?.filter((item) => item.name !== 'change_reason'),
        )
        editInventoryCTA(ItemId)
        dispatch(setFormName('additem'))
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  const deleteKitTemplate = (item) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))
      const deleteNewIem = await removeBuildKitAction(
        item.id,
        data.change_reason,
      )
      if (deleteNewIem?.status === 200) {
        dispatch(showSuccessReducer(`${item?.name}  deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  const deleteAssemblyCTA = (item, type, parent) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))

      var deleteNewIem
      if (type === 'item') {
        deleteNewIem = await removeAssemblyItemAction(
          item?.id,
          data.change_reason,
          parent,
        )
      } else {
        deleteNewIem = await removeAssemblyAction(item?.id, data.change_reason)
      }

      if (deleteNewIem?.status === 200) {
        dispatch(showSuccessReducer(`${data?.itemTodelete}  deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  const deleteDocumentVersionCTA = (item, docId) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))

      var deleteNewIem = await removeDocVersionAction(docId, item?.id)

      if (deleteNewIem?.status === 200) {
        dispatch(showSuccessReducer(`${item?.name} Translation deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  const deleteDocumentTemplateCTA = (item, type, parent) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))

      var deleteNewIem = await removeDocTemplateAction(
        item?.id,
        data.change_reason.trim(),
        parent,
      )
      if (deleteNewIem?.status === 200) {
        dispatch(showSuccessReducer(`${item?.name} Successfully Deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  //callallEnventoryRequiredAPI
  useEffect(() => {
    getKitBuilderAction()
    getAllItemAction()
    getTypesAction()
    getTestingLabsAction()
    getAssembliesTypeAction()
    getAllAssembliesAction()
    getAssemblyInboundConditionAction()
    droptownItemsForAssemblyAction()
    getdocTemplateType()
    getAlldocTemplateAction()
    getAllInventoryLanguages()
    getSystemUserAction()
    getAllEmailTemplateAction()
  }, [])

  //search for kit builder
  useEffect(() => {
    ;(async () => {
      if (searchQuery) {
        const searchResult = filter(builder?.allTemplates, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        })
        setfilterbuilder(searchResult)
      } else {
        setfilterbuilder(builder?.allTemplates)
      }
    })()
  }, [searchQuery, builder?.allTemplates])

  //search for kit assembly
  useEffect(() => {
    ;(async () => {
      if (searchQueryAssembly) {
        const searchResult = filter(assembly.allAssemblies, {
          keywords: searchQueryAssembly, // search for any field that contains the "Do" string

          caseSensitive: false,
        })
        setFilterAssembly(searchResult)
      } else {
        setFilterAssembly(assembly.allAssemblies)
      }
    })()
  }, [searchQueryAssembly, assembly.allAssemblies])

  //search for kit document
  useEffect(() => {
    ;(async () => {
      if (searchQueryDocument) {
        const searchResult = filter(docTemplate?.alldocTemplate, {
          keywords: searchQueryDocument, // search for any field that contains the "Do" string

          caseSensitive: false,
        })
        setFilterDocument(searchResult)
      } else {
        setFilterDocument(docTemplate?.alldocTemplate)
      }
    })()
  }, [searchQueryDocument, docTemplate?.alldocTemplate])

  //search for email template
  useEffect(() => {
    ;(async () => {
      if (searchQueryEmail) {
        const searchResult = filter(emailTemplate?.allEmailTemplates, {
          keywords: searchQueryEmail, // search for any field that contains the "Do" string

          caseSensitive: false,
        })

        setFilterEmailTemplate(searchResult)
      } else {
        setFilterEmailTemplate(emailTemplate?.allEmailTemplates)
      }
    })()
  }, [searchQueryEmail, emailTemplate?.allEmailTemplates])

  // create assemblies
  const createAssemblyCTA = () => {
    setCTA(() => async (data, items) => {
      dispatch(setFormLoaderReducer(true))
      const createNewIem = await createAssemblyAction({
        ...data,
        testingLabId: data?.testingLabId || null,
        inboundShippingConditionId: data?.inboundShippingConditionId || null,
        items: items?.map((item) => {
          if (item.customItem) {
            return {
              name: item.name,
              quantity: item.qty || item.quantity,
            }
          } else {
            return {
              ...item,
              quantity: item.qty || item.quantity,
              itemId: item?.item?.id || item.itemId,
            }
          }
        }),
      })
      if (createNewIem?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Assembly ${data.name} created.`))
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  // create Email Template

  const createEmailTemplateCTA = (item) => {
    setCTA(() => async (data) => {
      const result = createEmailTemplateAction(data)

      if (result?.status === 200) {
        dispatch(showSuccessReducer(`${item?.name} Translation created.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  // delete Email Template

  const deleteEmailTemplatesCTA = (item) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))

      const deleteEmail = await removeEmailTemplateAction(
        item.id,
        data.change_reason,
      )

      if (deleteEmail?.status === 200) {
        dispatch(showSuccessReducer(` Email template deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  // create Doctemplate
  const createDocTemplateCTA = (item, mode) => {
    if (mode !== 'view-doc-template') {
      setCTA(() => async (data) => {
        setFormikValues(data)
        dispatch(setFormLoaderReducer(true))
        if (mode === 'copy-document-template') {
          if (item?.editMode) {
            await editDocNameAction(item.id, {
              name: data.name,
              templateTypeId: item.type?.id,
              versionId: null,
            })
          }
          await mergfieldsByType(data.templateTypeId)
          setopenEditor(item)
        } else {
          await mergfieldsByType(data.templateTypeId)
          if (mode !== 'copy-document-template') {
            setopenEditor({ ...data })
          } else {
            setopenEditor('')
          }
        }
        dispatch(setFormLoaderReducer(false))
      })
    } else {
      setopenEditor(item)
    }
  }
  const handleSaveChangesClicked = () => {
    docRef.current?.documentEditor
      .saveAsBlob('Docx')
      .then(async (exportedDocument) => {
        // onSave(exportedDocument);
        let createNewIem
        if (uisettings?.formName === 'create-document-language') {
          createdocTemplateVersion(exportedDocument)
        } else {
          dispatch(setFormLoaderReducer(true))
          if (uisettings?.formName === 'copy-document-template') {
            if (openEditor.editMode || openEditor.editTranslate) {
              updateDocTemplateFileCTA(
                exportedDocument,
                openEditor,
                openEditor.editMode ? 'edit' : 'editTranslate',
              )
              setopenEditor('')
              droptownItemsForAssemblyAction()
              dispatch(setFormLoaderReducer(false))
              dispatch(setForm({ state: false, type: '' }))
            } else {
              createNewIem = await createDocTemplateAction(
                { ...openEditor, name: formikValues?.name },
                'copy-document-template',
              )
            }
          } else {
            createNewIem = await createDocTemplateAction(
              { ...openEditor },
              'create-document-template',
            )
          }
          if (createNewIem?.status === 200 && !openEditor.editMode) {
            updateDocTemplateFileCTA(
              exportedDocument,
              createNewIem?.data,
              'create',
            )
            setopenEditor('')
            droptownItemsForAssemblyAction()
            dispatch(setFormLoaderReducer(false))
            dispatch(setForm({ state: false, type: '' }))
          }
        }
      })
  }

  const updateDocTemplateFileCTA = async (
    exportedDocument,
    createDoc,
    type,
  ) => {
    dispatch(setFormLoaderReducer(true))
    const formData = new FormData()
    formData.append('file', exportedDocument)
    let createNewDoc
    if (type === 'editTranslate') {
      createNewDoc = await editDocTemplateVersionAction(
        createDoc?.translate?.id,
        createDoc.id,
        formData,
        changeReasonDoc,
      )
    } else if (type === 'edit') {
      createNewDoc = await updateDocTemplateFileAction(
        createDoc?.id,
        formData,
        changeReasonDoc,
        type,
      )
    } else if (type === 'create') {
      createNewDoc = await updateDocTemplateFileAction(
        createDoc?.id,
        formData,
        '',
        type,
      )
    }

    if (createNewDoc?.status === 200) {
      dispatch(setFormCloseReducer())
      if (uisettings?.formName === 'copy-document-template') {
        dispatch(
          showSuccessReducer(
            `${createNewDoc.data?.name} Document ${
              type ? 'updated' : 'created'
            }.`,
          ),
        )
      } else {
        dispatch(
          showSuccessReducer(`${createNewDoc.data?.name} Document added.`),
        )
      }
      setopenEditor('')
    }
    dispatch(setFormLoaderReducer(false))
  }

  const openEditorforVersion = (edit) => {
    setCTA(() => async (data, items) => {
      dispatch(
        setForm({
          state: false,
          type: 'create-document-language',
        }),
      )
      if (edit) {
        setopenEditor({ ...edit, versionId: data.versionId })
      } else {
        setopenEditor('')
      }
    })
  }
  const createdocTemplateVersion = async (exportedDocument) => {
    // setCTA(() => async (data) => {

    const formData = new FormData()
    formData.append('file', exportedDocument)
    dispatch(setFormLoaderReducer(true))
    const res = await createDocTempVersionAction(
      openEditor?.id,
      openEditor?.versionId,
      formData,
    )
    if (res?.status === 200) {
      dispatch(setFormCloseReducer())
      dispatch(
        showSuccessReducer(`${openEditor?.name} new translation created.`),
      )
      setopenEditor('')
    }
    dispatch(setFormLoaderReducer(false))
    // });
  }
  const getdocumentCopyName = async (docId) => {
    const res = await getdocCopyNameAction(docId)
    if (res?.status === 200) {
      return res
    }
  }

  const ExpendedDataRowsForAssembly = ({ data }) => {
    const dispatch = useDispatch()
    return (
      <div className=" w-full py-[10px]  border-b border-[#DEE2E6]">
        <DataTable
          customStyles={{
            table: {
              style: { background: 'transparent !important', border: 'none' },
            },
            head: { style: { display: 'none' } },
            rows: {
              style: {
                borderBottom: 'none !important',
                paddingLeft: '50px',
                //paddingRight: '100px',
              },
            },
            responsiveWrapper: {
              style: { overflow: 'visible !important' },
            },
          }}
          data={data.items}
          columns={[
            {
              selector: (row, index) => (
                <HemaValue className="text-xs" text={`${row?.item?.name}`} />
              ),
            },
            {
              selector: (row) => <HemaValue text={''} />,
            },
            {
              selector: (row) => <HemaValue text={''} />,
            },
            {
              selector: (row) => <HemaValue text={''} />,
            },
            {
              selector: (row) => (
                <HemaValue className="text-xs" text={row?.quantity} />
              ),
            },
            {
              cell: (row) => {
                return (
                  <div className="flex-grow pr-6 four">
                    <div className="flex justify-end gap-[5px] meta ">
                      {common?.permissions?.['Assemblies']?.delete && (
                        <Button
                          icon={<DeleteColor />}
                          color="text-white"
                          bg="bg-bgActionDots"
                          cta={() => {
                            Remove[0].label = 'Assembly Name'
                            Remove[0].initialValue = row?.item?.name
                            setUpdatedData(Remove)
                            setokBtnText('Confirm')
                            setokBtnIcon(<Confirm />)
                            setformName('Delete Assembly Item')
                            setFormIcon(<DeletePurple />)
                            dispatch(
                              setForm({
                                state: true,
                                type: 'deleteItem',
                              }),
                            )
                            deleteAssemblyCTA(row, 'item', data?.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              },
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            },
          ]}
        />
      </div>
    )
  }

  const ExpendedDataRowsForDocTemplate = ({ data }) => {
    const dispatch = useDispatch()
    return (
      <div className=" w-full py-[10px]  border-b border-[#DEE2E6]">
        <DataTable
          customStyles={{
            table: {
              style: { background: 'transparent !important', border: 'none' },
            },
            head: { style: { display: 'none' } },
            rows: {
              style: {
                borderBottom: 'none !important',
                paddingLeft: '50px',
                //paddingRight: '100px',
              },
            },
            responsiveWrapper: {
              style: { overflow: 'visible !important' },
            },
          }}
          data={data.translations}
          columns={[
            {
              selector: (row, index) => (
                <HemaValue className="text-xs" text={`${row?.name}`} />
              ),
            },

            {
              cell: (row) => {
                return (
                  <div className="flex-grow pr-[45px] four">
                    <div className="flex justify-end gap-[16px] meta ">
                      <Button
                        icon={<Binocular />}
                        color="text-white"
                        bg="bg-bgActionDots"
                        cta={() => {
                          dispatch(editFormReducer(row))
                          setformName('View Document Teamplate ')
                          setokBtnText('ok')
                          setFormIcon(<ViewIcon />)
                          addDocTemplate[0].disabled = true
                          addDocTemplate[0].initialValue = data?.name
                          addDocTemplate[1].placeholder = data?.type?.name
                          addDocTemplate[1].initialValue = data?.type?.id
                          addDocTemplate[1].disabled = true
                          setUpdatedData(addDocTemplate)
                          dispatch(
                            setForm({
                              state: true,
                              type: 'view-doc-template',
                            }),
                          )
                          createDocTemplateCTA(row, 'view-doc-template')
                        }}
                      />
                      {common?.permissions?.['Document Templates']?.update && (
                        <Button
                          icon={<Edit color="#775FD5" />}
                          color="text-white"
                          bg="bg-bgActionDots"
                          className="p-2"
                          cta={async () => {
                            dispatch(editFormReducer(row))
                            dispatch(
                              setForm({
                                state: false,
                                type: 'copy-document-template',
                              }),
                            )
                            await mergfieldsByType(data?.type?.id)

                            setopenEditor({
                              ...row,
                              translate: data,
                              editTranslate: true,
                            })
                          }}
                        />
                      )}
                      {common?.permissions?.['Document Templates']?.delete && (
                        <Button
                          icon={<Delete color="#F87FB1" />}
                          color="text-white"
                          bg="bg-bgActionDots"
                          className="p-1"
                          cta={() => {
                            Remove[0].label = 'Version Name'
                            Remove[0].initialValue = row?.name
                            setUpdatedData(Remove)
                            setokBtnText('Confirm')
                            setokBtnIcon(<Confirm />)
                            setformName('Delete Document Version')
                            setFormIcon(<DeletePurple />)
                            dispatch(
                              setForm({
                                state: true,
                                type: 'deleteItem',
                              }),
                            )
                            deleteDocumentVersionCTA(row, data?.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              },
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            },
          ]}
        />
      </div>
    )
  }

  // sorting for kit template
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.name?.toLowerCase()
    const b = rowB.name?.toLowerCase()

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const availableNumberSort = (rowA, rowB) => {
    const a = rowA.availableToBuild
    const b = rowB.availableToBuild

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const caseInsensitiveInboundCondition = (rowA, rowB) => {
    const a = rowA.inboundShippingCondition?.name?.toLowerCase() || ''
    const b = rowB.inboundShippingCondition?.name?.toLowerCase() || ''

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const caseInsensitiveTestingLab = (rowA, rowB) => {
    const a = rowA.testingLab?.name?.toLowerCase() || ''
    const b = rowB.testingLab?.name?.toLowerCase() || ''

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }
  //filter for kit builder
  useEffect(() => {
    if (Object.keys(kitTemplateFilter)?.length) {
      const filterResult = builder?.allTemplates?.filter((port) => {
        if (
          (kitTemplateFilter?.name?.length
            ? kitTemplateFilter?.name.includes(port.name)
            : true) &&
          (kitTemplateFilter?.availableToBuild?.length
            ? kitTemplateFilter.availableToBuild?.includes(
                port.availableToBuild,
              )
            : true) &&
          (kitTemplateFilter?.qtyPerItem?.length
            ? kitTemplateFilter.qtyPerItem?.includes(
                port.items?.[0]?.itemPerKit,
              )
            : true)
          // (kitTemplateFilter.role?.length ? kitTemplateFilter.role?.includes(port.role.name) : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setfilterbuilder(filterResult)
    } else {
      setfilterbuilder(builder?.allTemplates)
    }
  }, [JSON.stringify(kitTemplateFilter)])
  // filter document template
  useEffect(() => {
    if (Object.keys(docTemplateFilter)?.length) {
      const filterResult = docTemplate?.alldocTemplate?.filter((port) => {
        if (
          (docTemplateFilter?.name?.length
            ? docTemplateFilter?.name.includes(port.name)
            : true) &&
          (docTemplateFilter?.type?.length
            ? docTemplateFilter?.type?.includes(port.type.name)
            : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setFilterDocument(filterResult)
    } else {
      setFilterDocument(docTemplate?.alldocTemplate)
    }
  }, [JSON.stringify(docTemplateFilter)])

  // filter email template
  useEffect(() => {
    if (Object.keys(emailTemplatesFilter)?.length) {
      const filterResult = emailTemplate?.allEmailTemplates?.filter((port) => {
        if (
          (emailTemplatesFilter?.name?.length
            ? emailTemplatesFilter?.name.includes(port.name)
            : true) &&
          (emailTemplatesFilter?.type?.length
            ? emailTemplatesFilter?.type?.includes(port.type.name)
            : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setFilterEmailTemplate(filterResult)
    } else {
      setFilterEmailTemplate(emailTemplate?.allEmailTemplates)
    }
  }, [JSON.stringify(emailTemplatesFilter)])

  // filter assebley template
  useEffect(() => {
    if (Object.keys(assemblyFilter)?.length) {
      const filterResult = assembly?.allAssemblies?.filter((port) => {
        if (
          (assemblyFilter?.name?.length
            ? assemblyFilter?.name.includes(port.name)
            : true) &&
          (assemblyFilter?.testingLabName?.length
            ? assemblyFilter?.testingLabName.includes(port.testingLab?.name)
            : true) &&
          (assemblyFilter?.assemblyType?.length
            ? assemblyFilter?.assemblyType.includes(port.assemblyType?.name)
            : true) &&
          (assemblyFilter?.inboundShippingConditionName?.length
            ? assemblyFilter?.inboundShippingConditionName.includes(
                port.inboundShippingCondition?.name,
              )
            : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setFilterAssembly(filterResult)
    } else {
      setFilterAssembly(assembly?.allAssemblies)
    }
  }, [JSON.stringify(assemblyFilter)])

  if (
    !(
      common?.permissions?.['Document Templates']?.read ||
      common?.permissions?.['Kit Templates']?.read ||
      common?.permissions?.['Assemblies']?.read
    )
  ) {
    return <NoPermissionAlert />
  }

  if (openEditor?.id || openEditor?.name) {
    return (
      <>
        <div className="flex gap-[10px] justify-between items-end mb-[30px]">
          <HemaHeadingWithSubText heading="Document Editor" />
        </div>
        <Alert type="error" />

        <div className="flex gap-[20px]">
          <div className={docTemplate.mergeField ? 'w-[75%]' : 'w-[100%]'}>
            <DocumentEditor
              docFile={openEditor ? openEditor?.file?.storageUrl : null}
              setisdocLoaded={setisdocLoaded}
              docRef={docRef}
              uisettings={uisettings}
            />
            {(openEditor.editMode || openEditor.editTranslate) && (
              <div className="mt-[20px] gap-[15px] items-center w-full block">
                <HemaLabel
                  text={'Change Reason'}
                  icon={<img src={ChangeReasonIcon} alt="icon" />}
                  required={true}
                  className={`mb-[10px]`}
                />

                <FormTextarea
                  placeholder="write edit reason"
                  onChange={(e) => {
                    setChangeReasonDoc(e.target.value)
                  }}
                  value={changeReasonDoc}
                />
                {(openEditor.editMode || openEditor.editTranslate) &&
                  !changeReasonDoc && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {'Required'}
                    </div>
                  )}
              </div>
            )}
          </div>
          {(uisettings?.formName === 'create-document' ||
            uisettings?.formName === 'copy-document-template' ||
            uisettings?.formName === 'create-document-language') &&
            docTemplate.mergeField && (
              <div className="bg-[#fff] p-[20px] w-[25%]">
                <h3>Document Details</h3>
                <Heading heading="General Information" />
                <HemaLabel
                  text={'Merge Fields'}
                  className="mt-[30px] mb-[10px]"
                  icon={<FileIcon />}
                />
                <FormSelect
                  options={docTemplate.mergeField}
                  setFieldValueFull={(name, field) => {
                    let fieldCode = `MERGEFIELD  ${field.mergeFieldKey}  \\* MERGEFORMAT `
                    let fieldResult = `«${field?.name}»`
                    docRef.current?.documentEditor.editor.insertField(
                      fieldCode,
                      fieldResult,
                    )
                    docRef.current?.documentEditor.updateFields()
                  }}
                  placeholder="Select"
                />
              </div>
            )}
        </div>
        <div className="flex gap-[8px] justify-end my-[20px]">
          <Button
            cta={() => {
              setopenEditor(false)
              dispatch(setForm({ state: false, type: '' }))
            }}
            type="button"
            text={
              uisettings.formName === 'view-doc-template' ? 'Close' : 'Cancel'
            }
            bg="bg-white"
            border="border-primary1"
            color="text-primary1"
            icon={<Cancel />}
          />
          {uisettings?.formName !== 'view-doc-template' &&
            (uisettings.formLoader ? (
              <Button
                type="submit"
                bg="bg-primary1"
                text={
                  <>
                    <Spinner animation="grow" size="sm" variant="light" />
                    <Spinner animation="grow" size="sm" variant="light" />
                    <Spinner animation="grow" size="sm" variant="light" />
                  </>
                }
              />
            ) : (
              <Button
                type="button"
                cta={async () => {
                  handleSaveChangesClicked()
                }}
                text={
                  openEditor.editMode || openEditor.editTranslate
                    ? 'Update & Close'
                    : 'Save & Close'
                }
                bg="bg-primary1"
                color="text-white"
                icon={<Confirm />}
                disabled={
                  (openEditor.editMode || openEditor.editTranslate) &&
                  !changeReasonDoc
                    ? true
                    : false
                }
              />
            ))}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="flex gap-[10px] justify-between items-end">
          <HemaHeadingWithSubText heading="Template Builder" sub={pageSub} />
        </div>

        <Alert />
        <div className="bg-white rounded-[5px] px-0 pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs tabs_head_space">
          <Tabs
            activeKey={activeTab}
            id="controlled-tab-example"
            className="mb-3"
            onSelect={(key) => {
              setactiveTab(key)
              if (key === 'Template') {
                navigate('/kit-builders?type=Template')
                setPageSub('Manage your kit templates here.')
              } else if (key === 'Document') {
                navigate('/kit-builders?type=Document')
                setPageSub('Manage your document templates here.')
              } else if (key === 'Assemblies') {
                navigate('/kit-builders?type=Assemblies')
                setPageSub('Manage your assemblies here.')
              } else {
                navigate('/kit-builders?type=emailTemplates')
                setPageSub('Manage your email templates here.')
              }
            }}
          >
            {common?.permissions?.['Kit Templates']?.read && (
              <Tab
                eventKey="Template"
                title={
                  <div className="flex items-center title-icon gap-[7px]">
                    {activeTab === 'Template' ? (
                      <KitTemplate purple />
                    ) : (
                      <KitTemplate />
                    )}
                    Kit Templates
                  </div>
                }
              >
                <>
                  <div className="flex justify-between mt-[36px] px-[16px] mb-[16px]">
                    <FormSearch
                      w="w-[400px]"
                      searchQuery={searchQuery}
                      setsearchQuery={setsearchQuery}
                    />
                    {common?.permissions?.['Kit Templates']?.write && (
                      <Button
                        text="Create Kit Template"
                        icon={<Add color="white" />}
                        color="text-white"
                        bg="bg-primary1"
                        cta={() => {
                          dispatch(editFormReducer(null))

                          setformName('Create Kit Template')
                          setFormIcon(<AddInventoryPurple />)
                          dispatch(
                            setForm({
                              state: true,
                              type: 'create-kit-template',
                            }),
                          )
                          createKitBuilderCTA()
                        }}
                      />
                    )}
                  </div>
                  {filterbuilder ? (
                    filterbuilder?.length > 0 ||
                    Object.keys(kitTemplateFilter)?.length ? (
                      <DataTable
                        expandableRowDisabled={(row, index) => {
                          return row.id ? false : true
                        }}
                        expandableIcon={{
                          expanded: <RowExpand />,
                          collapsed: <RowCollapsed />,
                        }}
                        className="hdxa-rdt-filter border-t-[1px] border-solid border-t-[#DEE2E6]  builder-table-st"
                        columns={[
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px]  px-[10px]">
                                  <HemaValue
                                    text={'Kit Template Name'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />
                                  <span className="short-icon">
                                    <TableSortIcon />
                                  </span>
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6]  px-[10px]">
                                  <FilterColumn
                                    columnName="name"
                                    setRedux={setKitTemplateFilter}
                                    reduxValues={kitTemplateFilter || []}
                                    options={Array.from(
                                      new Set(
                                        builder?.allTemplates?.map(
                                          (filter) => filter.name,
                                        ),
                                      ),
                                    )}
                                  />
                                </div>
                              </div>
                            ),
                            sortable: true,
                            filterable: true,
                            selector: (row, index) => (
                              <HemaValue className="text-xs" text={row.name} />
                            ),
                            sortFunction: caseInsensitiveSort,
                            sortId: 'name',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Quantity Per Kit'}
                                    className="text-xs font-normal"
                                    color="text-[#000000]"
                                  />
                                  <span className="short-icon">
                                    <TableSortIcon />
                                  </span>
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]"></div>
                              </div>
                            ),
                            sortable: false,
                            selector: (row, index) => (
                              <>
                                <HemaValue
                                  className="text-xs"
                                  text={row?.qtyPerItem}
                                />
                              </>
                            ),
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Available to Build'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />
                                  <span className="short-icon">
                                    <TableSortIcon />
                                  </span>
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] pl-[10px] pr-[20px]">
                                  <FilterColumn
                                    columnName="availableToBuild"
                                    setRedux={setKitTemplateFilter}
                                    reduxValues={kitTemplateFilter || []}
                                    options={Array.from(
                                      new Set(
                                        builder?.allTemplates?.map(
                                          (filter) => filter.availableToBuild,
                                        ),
                                      ),
                                    )}
                                  />
                                </div>
                              </div>
                            ),
                            sortable: true,
                            selector: (row, index) => (
                              <HemaValue
                                className="text-xs"
                                text={row.availableToBuild}
                              />
                            ),
                            sortFunction: availableNumberSort,
                            sortId: 'availableToBuild',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <HemaValue
                                    text={'Actions'}
                                    className="pr-12 text-xs font-normal"
                                    color="text-[#000000]"
                                  />
                                </div>
                              </div>
                            ),
                            cell: (row, index) => {
                              return (
                                <div className="flex-grow four pr-7">
                                  <div className="flex justify-end gap-[10px] meta ">
                                    {common?.permissions?.['Kit Templates']
                                      ?.special && (
                                      <Button
                                        icon={<Add color="#775FD5" />}
                                        color="text-white"
                                        bg="bg-bgActionDots"
                                        cta={() => {
                                          dispatch(editFormReducer(row))
                                          // inventoryKitBuilder[0].initialValue = row.name;
                                          // setformName('Build Kit');
                                          setformName(`Build Kit`)
                                          setokBtnText('Build')
                                          setokBtnIcon(<Add color="#ffffff" />)
                                          setFormIcon(<AddInventoryPurple />)
                                          dispatch(
                                            setForm({
                                              state: true,
                                              type: 'edit-kit-template',
                                            }),
                                          )
                                          setUpdatedData([])
                                        }}
                                      />
                                    )}
                                    <NavDropdown
                                      title={
                                        <Button
                                          icon={<DotsIcon />}
                                          color="text-white"
                                          bg="bg-bgActionDots"
                                          className="flex items-center justify-center w-full"
                                        />
                                      }
                                      disabled={
                                        common?.permissions?.['Kit Templates']
                                          ?.write ||
                                        common?.permissions?.['Kit Templates']
                                          ?.delete
                                          ? false
                                          : true
                                      }
                                      className="hema-ellipse-dropdown w-7 "
                                      // id="navbarScrollingDropdown"
                                    >
                                      {common?.permissions?.['Kit Templates']
                                        ?.write && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={() => {
                                            dispatch(editFormReducer(row))
                                            createKitBuilderCTA()
                                            setformName('Copy Kit Template')
                                            setFormIcon(<AddInventoryPurple />)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'copy-kit-template',
                                              }),
                                            )
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Copy color="#775FD5" />
                                            <HemaValue text="Copy" />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                      {common?.permissions?.['Kit Templates']
                                        ?.delete && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={() => {
                                            dispatch(editFormReducer(row))
                                            Remove[0].label =
                                              'Kit Template Name'
                                            Remove[0].initialValue = row?.name
                                            setUpdatedData(Remove)
                                            setokBtnText('Confirm')
                                            setokBtnIcon(<Confirm />)
                                            setformName('Delete Kit Template')
                                            setFormIcon(<DeletePurple />)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'deleteItem',
                                              }),
                                            )
                                            deleteKitTemplate(row)
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Delete color="#775FD5" />
                                            <HemaValue text="Delete" />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                    </NavDropdown>
                                  </div>
                                </div>
                              )
                            },
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                          },
                        ]}
                        noDataComponent={
                          <NoDataComponent
                            setActiveFilterTab={setfilterbuilder}
                            activeFilterOriginalData={builder?.allTemplates}
                            resetRedux={setKitTemplateFilter}
                          />
                        }
                        pagination={true}
                        data={filterbuilder}
                        expandableRows
                        expandableRowsComponent={ExpendedDataRows}
                        onSort={(row, direction, sorted) => {
                          setfilterbuilder(
                            sortedData(row.sortId, direction, sorted)?.filter(
                              (data) => Object.keys(data)?.length,
                            ),
                          )
                        }}
                        paginationComponent={(e) => {
                          return <Pagination e={e} />
                        }}
                        customStyles={{
                          responsiveWrapper: {
                            style: { overflow: 'visible !important' },
                          },
                        }}
                      />
                    ) : (
                      <AlertBootstrap
                        variant="warning"
                        className="text-center mt-[20px]"
                      >
                        There are no records to display.
                      </AlertBootstrap>
                    )
                  ) : (
                    <>
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                    </>
                  )}
                </>
              </Tab>
            )}

            {common?.permissions?.['Assemblies']?.read && (
              <Tab
                eventKey="Assemblies"
                title={
                  <div className="flex items-center title-icon gap-[7px]">
                    {activeTab === 'Assemblies' ? (
                      <Assemblies purple />
                    ) : (
                      <Assemblies />
                    )}{' '}
                    Assemblies
                  </div>
                }
              >
                <>
                  <div className="flex justify-between mt-[36px] px-[16px] mb-[16px]">
                    <FormSearch
                      w="w-[400px]"
                      searchQuery={searchQueryAssembly}
                      setsearchQuery={setsearchQueryAssembly}
                    />
                    {common?.permissions?.['Assemblies']?.write && (
                      <Button
                        text="Create Assembly"
                        icon={<Add color="white" />}
                        color="text-white"
                        bg="bg-primary1"
                        cta={() => {
                          setformName('Create Assembly')
                          dispatch(setDropdownValue())
                          dispatch(editFormReducer())
                          setokBtnText('Create')
                          setokBtnIcon(<Add color="#fff" />)
                          setFormIcon(<AddInventoryPurple />)
                          addAssembly[1].options = common.allAssemblyTypes
                          addAssembly[1].disabled = false
                          addAssembly[2].disabled = false
                          addAssembly[3].disabled = false
                          addAssembly[2].options = common.allTestingLabs
                          addAssembly[3].options = common.allShipping
                          addAssembly[3].initialValue = ''
                          addAssembly[0].initialValue = ''
                          addAssembly[1].initialValue = ''
                          addAssembly[1].placeholder = 'Select'
                          addAssembly[2].initialValue = ''
                          addAssembly[2].placeholder = 'Select'
                          addAssembly[3].placeholder = 'Select'
                          setAllItemsInTemplate([])
                          setUpdatedData(addAssembly)
                          setFormValidation(
                            (error, values) => (error, values) => {
                              if (values.name?.length > 100) {
                                error.name = 'Max 100 characters allowed'
                              }
                              return error
                            },
                          )
                          dispatch(
                            setForm({ state: true, type: 'create-assembly' }),
                          )
                          setDropDownItemList('assemblyItem')
                          createAssemblyCTA()
                        }}
                      />
                    )}
                  </div>
                </>
                {filterAssembly ? (
                  filterAssembly?.length > 0 ||
                  Object.keys(assemblyFilter)?.length ? (
                    <DataTable
                      expandableRowDisabled={(row, index) => {
                        return row.id ? false : true
                      }}
                      noDataComponent={
                        <NoDataComponent
                          setActiveFilterTab={setFilterAssembly}
                          activeFilterOriginalData={assembly.allAssemblies}
                          resetRedux={setassemblyFilter}
                        />
                      }
                      className="hdxa-rdt-filter border-t-[1px] border-solid border-t-[#DEE2E6] builder-table-st"
                      expandableIcon={{
                        expanded: <RowExpand />,
                        collapsed: <RowCollapsed />,
                      }}
                      columns={[
                        {
                          name: (
                            <div className="w-full">
                              <div className="w-fit flex items-center gap-1 py-[8px]  px-[10px]">
                                <HemaValue
                                  text={'Assembly Name'}
                                  className="text-xs font-normal whitespace-pre "
                                  color="text-[#000000]"
                                />{' '}
                                <span className="short-icon">
                                  <TableSortIcon />
                                </span>
                              </div>
                              <div className="w-full border-t-[1px] border-solid border-[#DEE2E6]  px-[10px]">
                                <FilterColumn
                                  columnName="name"
                                  setRedux={setassemblyFilter}
                                  reduxValues={assemblyFilter || []}
                                  options={Array.from(
                                    new Set(
                                      assembly?.allAssemblies?.map(
                                        (filter) => filter.name,
                                      ),
                                    ),
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          sortable: true,
                          selector: (row, index) => (
                            <HemaValue
                              className="text-xs capitalize cursor-pointer"
                              text={row?.name}
                            />
                          ),

                          sortId: 'name',
                        },
                        {
                          name: (
                            <div className="w-full">
                              <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                <HemaValue
                                  text={'Assembly Type'}
                                  className="text-xs font-normal whitespace-pre"
                                  color="text-[#000000]"
                                />{' '}
                                <span className="short-icon">
                                  <TableSortIcon />
                                </span>
                              </div>
                              <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                <FilterColumn
                                  columnName="assemblyType"
                                  setRedux={setassemblyFilter}
                                  reduxValues={assemblyFilter || []}
                                  options={Array.from(
                                    new Set(
                                      assembly?.allAssemblies?.map(
                                        (filter) => filter.assemblyType?.name,
                                      ),
                                    ),
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          sortable: true,
                          selector: (row, index) => (
                            <HemaValue
                              className="text-xs cursor-pointer"
                              text={row?.assemblyType?.name}
                            />
                          ),

                          sortId: 'assemblyType.name',
                        },
                        {
                          name: (
                            <div className="w-full">
                              <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                <HemaValue
                                  text={'Testing Lab'}
                                  className="text-xs font-normal whitespace-pre"
                                  color="text-[#000000]"
                                />
                                <span className="short-icon">
                                  <TableSortIcon />
                                </span>
                              </div>
                              <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                <FilterColumn
                                  columnName="testingLabName"
                                  setRedux={setassemblyFilter}
                                  reduxValues={assemblyFilter || []}
                                  options={Array.from(
                                    new Set(
                                      assembly?.allAssemblies?.map(
                                        (filter) => filter.testingLab?.name,
                                      ),
                                    ),
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          sortable: true,
                          sortFunction: caseInsensitiveTestingLab,
                          selector: (row, index) => (
                            <HemaValue
                              className="text-xs cursor-pointer"
                              text={row?.testingLab?.name || 'N/A'}
                            />
                          ),
                          sortId: 'testingLab.name',
                        },

                        {
                          name: (
                            <div className="w-full">
                              <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                <HemaValue
                                  text={'Inbound Condition'}
                                  className="text-xs font-normal whitespace-pre "
                                  color="text-[#000000]"
                                />{' '}
                                <span className="short-icon">
                                  <TableSortIcon />
                                </span>
                              </div>
                              <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                <FilterColumn
                                  columnName="inboundShippingConditionName"
                                  setRedux={setassemblyFilter}
                                  reduxValues={assemblyFilter || []}
                                  options={Array.from(
                                    new Set(
                                      assembly?.allAssemblies?.map(
                                        (filter) =>
                                          filter.inboundShippingCondition?.name,
                                      ),
                                    ),
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          sortFunction: caseInsensitiveInboundCondition,
                          sortable: true,
                          selector: (row, index) => (
                            <HemaValue
                              className="text-xs cursor-pointer"
                              text={
                                row?.inboundShippingCondition?.name || 'N/A'
                              }
                            />
                          ),

                          sortId: 'inboundShippingCondition.name',
                        },
                        {
                          name: (
                            <div className="w-full">
                              <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                <HemaValue
                                  text={'Quantity Per Assembly'}
                                  className="text-xs font-normal whitespace-pre"
                                  color="text-[#000000]"
                                />
                                <span className="short-icon">
                                  <TableSortIcon />
                                </span>
                              </div>
                              <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px] cursor-pointer"></div>
                            </div>
                          ),
                          sortable: false,
                        },
                        {
                          name: (
                            <div className="w-full">
                              <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                <HemaValue
                                  text={'Actions'}
                                  className="pr-12 text-xs font-normal"
                                  color="text-[#000000]"
                                />
                              </div>
                            </div>
                          ),
                          cell: (row, index) => {
                            return (
                              <div className="flex-grow pr-6 four">
                                <div className="flex justify-end gap-[16px] meta ">
                                  {common?.permissions?.['Assemblies']
                                    ?.write && (
                                    <Button
                                      icon={<CopyColor color="#775FD5" />}
                                      color="text-white"
                                      bg="bg-bgActionDots"
                                      cta={() => {
                                        dispatch(editFormReducer(row))
                                        dispatch(setDropdownValue())
                                        setformName('Copy Assembly')
                                        setokBtnText('Create')
                                        setokBtnIcon(<Add color="#fff" />)
                                        setFormIcon(<ExpiryAlertPurple />)
                                        addAssembly[0].initialValue =
                                          row.name + ' Copy'
                                        dispatch(
                                          setDropdownValue(row?.assemblyType),
                                        )
                                        addAssembly[1].options =
                                          common.allAssemblyTypes
                                        addAssembly[1].disabled = false
                                        addAssembly[2].options =
                                          common.allTestingLabs
                                        addAssembly[2].disabled =
                                          row?.assemblyType?.name ===
                                          'Return Kit Assembly'
                                            ? false
                                            : true
                                        addAssembly[3].disabled =
                                          row?.assemblyType?.name ===
                                          'Return Kit Assembly'
                                            ? false
                                            : true
                                        addAssembly[3].options =
                                          common.allShipping
                                        addAssembly[1].initialValue =
                                          row?.assemblyType?.id
                                        if (
                                          row?.assemblyType?.name ===
                                          'Return Kit Assembly'
                                        ) {
                                          addAssembly[2].initialValue =
                                            row?.testingLab?.id
                                          addAssembly[3].initialValue =
                                            row?.inboundShippingCondition?.id
                                        } else {
                                          addAssembly[2].initialValue = ''
                                          addAssembly[3].initialValue = ''
                                          addAssembly[2].required = false
                                          addAssembly[3].required = false
                                        }
                                        setAllItemsInTemplate(
                                          row?.items?.map((data) => {
                                            return {
                                              ...data,
                                              name: data.item?.name,
                                            }
                                          }),
                                        )
                                        setUpdatedData(addAssembly)
                                        setFormValidation(
                                          (error, values) =>
                                            (error, values) => {
                                              if (values.name?.length > 100) {
                                                error.name =
                                                  'Max 100 characters allowed'
                                              }
                                              return error
                                            },
                                        )
                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: 'create-assembly',
                                          }),
                                        )
                                        createAssemblyCTA()
                                      }}
                                    />
                                  )}
                                  {common?.permissions?.['Assemblies']
                                    ?.delete && (
                                    <Button
                                      icon={<DeleteColor color="red" />}
                                      color="text-white"
                                      bg="bg-bgActionDots"
                                      cta={() => {
                                        dispatch(editFormReducer(row))
                                        Remove[0].label = 'Assembly Name'
                                        Remove[0].initialValue = row?.name
                                        setUpdatedData(Remove)
                                        setformName('Delete Assembly')
                                        setFormIcon(<DeletePurple />)
                                        setokBtnText('Confirm')
                                        setokBtnIcon(<Confirm />)
                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: 'deleteItem',
                                          }),
                                        )
                                        deleteAssemblyCTA(row)
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                          },
                          ignoreRowClick: true,
                          allowOverflow: true,
                          button: true,
                        },
                      ]}
                      data={filterAssembly}
                      expandableRows
                      pagination={true}
                      expandableRowsComponent={ExpendedDataRowsForAssembly}
                      onSort={(row, direction, sorted) => {
                        setFilterAssembly(
                          sortedData(row.sortId, direction, sorted)?.filter(
                            (data) => Object.keys(data)?.length,
                          ),
                        )
                      }}
                      paginationComponent={(e) => {
                        return <Pagination e={e} />
                      }}
                    />
                  ) : (
                    <AlertBootstrap
                      variant="warning"
                      className="mt-3 text-center"
                    >
                      There are no records to display.
                    </AlertBootstrap>
                  )
                ) : (
                  <>
                    <br />
                    <Skeleton count={4} />
                    <br />
                    <Skeleton count={4} />
                    <br />
                    <Skeleton count={4} />
                  </>
                )}
              </Tab>
            )}
            {common?.permissions?.['Document Templates']?.read && (
              <Tab
                eventKey="Document"
                title={
                  <div className="flex items-center title-icon gap-[7px] ">
                    {activeTab === 'Document' ? (
                      <DocTemplate purple />
                    ) : (
                      <DocTemplate />
                    )}
                    Document Templates
                  </div>
                }
              >
                <>
                  <div className="flex justify-between mt-[36px] px-[16px] mb-[10px]">
                    <FormSearch
                      w="w-[400px]"
                      searchQuery={searchQueryDocument}
                      setsearchQuery={setSearchQueryDocument}
                    />
                    {common?.permissions?.['Document Templates']?.write && (
                      <Button
                        text="Create Document Template"
                        icon={<Add color="white" />}
                        color="text-white"
                        bg="bg-primary1"
                        cta={() => {
                          setformName('Create Document Template')
                          setFormIcon(<AddInventoryPurple />)
                          addDocTemplate[0].initialValue = ''
                          addDocTemplate[0].disabled = false
                          addDocTemplate[1].disabled = false
                          addDocTemplate[1].placeholder = 'Select Document Type'
                          addDocTemplate[1].options =
                            docTemplate?.alldocTemplateType
                          addDocTemplate[1].initialValue = ''
                          setokBtnIcon(<Next />)
                          setokBtnText('Next')
                          setUpdatedData(addDocTemplate)
                          dispatch(
                            setForm({ state: true, type: 'create-document' }),
                          )
                          setFormValidation(
                            (error, values) => (error, values) => {
                              if (values.name?.length > 100) {
                                error.name = 'Max 100 characters allowed'
                              }
                              return error
                            },
                          )
                          createDocTemplateCTA()
                        }}
                      />
                    )}
                  </div>
                  {filterDocument ? (
                    filterDocument?.length > 0 ||
                    Object.keys(docTemplateFilter)?.length ? (
                      <DataTable
                        expandableRowDisabled={(row, index) => {
                          return row.id ? false : true
                        }}
                        noDataComponent={
                          <NoDataComponent
                            setActiveFilterTab={setFilterDocument}
                            activeFilterOriginalData={
                              docTemplate?.alldocTemplate
                            }
                            resetRedux={setdocTemplateFilter}
                          />
                        }
                        className="hdxa-rdt-filter border-t-[1px] border-solid border-t-[#DEE2E6] builder-table-st"
                        expandableIcon={{
                          expanded: <RowExpand />,
                          collapsed: <RowCollapsed />,
                        }}
                        columns={[
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Document Template Name'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />{' '}
                                  {sortIcon === 'asc' ? (
                                    <span className="rotate-180 short-icon">
                                      <TableSortIcon />
                                    </span>
                                  ) : (
                                    <span className="short-icon ">
                                      <TableSortIcon />
                                    </span>
                                  )}
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <div className="w-[290px]">
                                    <FilterColumn
                                      columnName="name"
                                      setRedux={setdocTemplateFilter}
                                      reduxValues={docTemplateFilter || []}
                                      options={Array.from(
                                        new Set(
                                          docTemplate?.alldocTemplate?.map(
                                            (filter) => filter.name,
                                          ),
                                        ),
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            ),
                            sortable: true,
                            selector: (row, index) => (
                              <HemaValue
                                className="text-xs capitalize"
                                text={row?.name}
                              />
                            ),

                            sortId: 'name',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Document Template Type'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />
                                  {sortIcon === 'asc' ? (
                                    <span className="rotate-180 short-icon">
                                      <TableSortIcon />
                                    </span>
                                  ) : (
                                    <span className="short-icon ">
                                      <TableSortIcon />
                                    </span>
                                  )}
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <div className="w-[290px]">
                                    <FilterColumn
                                      columnName="type"
                                      setRedux={setdocTemplateFilter}
                                      reduxValues={docTemplateFilter || []}
                                      options={Array.from(
                                        new Set(
                                          docTemplate?.alldocTemplate?.map(
                                            (filter) => filter.type.name,
                                          ),
                                        ),
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            ),
                            sortable: true,

                            selector: (row, index) => (
                              <HemaValue
                                className="text-xs"
                                text={row?.type?.name}
                              />
                            ),

                            sortId: 'type.name',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <HemaValue
                                    text={'Actions'}
                                    className="pr-12 text-xs font-normal"
                                    color="text-[#000000]"
                                  />
                                </div>
                              </div>
                            ),
                            cell: (row, index) => {
                              return (
                                <div className="flex-grow four pr-[18px]">
                                  <div className="flex justify-end gap-[16px] meta ">
                                    <Button
                                      icon={<Binocular />}
                                      color="text-white"
                                      bg="bg-bgActionDots"
                                      cta={() => {
                                        dispatch(editFormReducer(row))
                                        setformName('View Document Teamplate ')
                                        setFormIcon(<ViewIcon />)
                                        addDocTemplate[0].disabled = true
                                        addDocTemplate[0].initialValue =
                                          row.name
                                        addDocTemplate[1].placeholder =
                                          row?.type?.name
                                        addDocTemplate[1].initialValue =
                                          row?.type?.id
                                        addDocTemplate[1].disabled = true
                                        setUpdatedData(addDocTemplate)
                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: 'view-doc-template',
                                          }),
                                        )
                                        createDocTemplateCTA(
                                          row,
                                          'view-doc-template',
                                        )
                                      }}
                                    />

                                    <NavDropdown
                                      title={
                                        <Button
                                          icon={<DotsIcon />}
                                          color="text-white"
                                          bg="bg-bgActionDots"
                                          className="flex items-center justify-center w-full"
                                        />
                                      }
                                      disabled={
                                        common?.permissions?.[
                                          'Document Templates'
                                        ]?.write ||
                                        common?.permissions?.[
                                          'Document Templates'
                                        ]?.delete
                                          ? false
                                          : true
                                      }
                                      className="hema-ellipse-dropdown w-7"
                                      // id="navbarScrollingDropdown"
                                    >
                                      {common?.permissions?.[
                                        'Document Templates'
                                      ]?.write && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={async () => {
                                            const res =
                                              await getdocumentCopyName(row?.id)

                                            dispatch(
                                              editFormReducer({
                                                ...row,
                                                name: res?.data,
                                              }),
                                            )
                                            setformName(
                                              'Copy Document Template',
                                            )
                                            setokBtnText('Next')
                                            setokBtnIcon(<Next />)
                                            setFormIcon(<CopyDocumentPurple />)
                                            addDocTemplate[0].disabled = false
                                            addDocTemplate[0].initialValue =
                                              res?.data
                                            addDocTemplate[1].placeholder =
                                              row?.type?.name
                                            addDocTemplate[1].initialValue =
                                              row?.type?.id
                                            addDocTemplate[1].disabled = true
                                            setUpdatedData(addDocTemplate)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'copy-document-template',
                                              }),
                                            )
                                            setFormValidation(
                                              (error, values) =>
                                                (error, values) => {
                                                  if (
                                                    values.name?.length > 100
                                                  ) {
                                                    error.name =
                                                      'Max 100 characters allowed'
                                                  }
                                                  return error
                                                },
                                            )
                                            createDocTemplateCTA(
                                              { ...row, name: res?.data },
                                              'copy-document-template',
                                            )
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Copy color="#775FD5" />
                                            <HemaValue
                                              color="text-[#232323]"
                                              text="Copy"
                                            />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                      {common?.permissions?.[
                                        'Document Templates'
                                      ]?.update && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={async () => {
                                            setChangeReasonDoc('')
                                            dispatch(editFormReducer(row))
                                            setformName(
                                              'Edit Document Template',
                                            )
                                            setFormIcon(<AddInventoryPurple />)
                                            addDocTemplate[0].initialValue =
                                              row.name
                                            addDocTemplate[0].disabled = false
                                            addDocTemplate[1].disabled = true
                                            addDocTemplate[1].placeholder =
                                              row?.type?.name

                                            addDocTemplate[1].initialValue =
                                              row?.type?.id
                                            setokBtnIcon(<Next />)
                                            setokBtnText('Update & Next')
                                            setUpdatedData(addDocTemplate)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'copy-document-template',
                                              }),
                                            )
                                            setFormValidation(
                                              (error, values) =>
                                                (error, values) => {
                                                  if (
                                                    values.name?.length > 100
                                                  ) {
                                                    error.name =
                                                      'Max 100 characters allowed'
                                                  }
                                                  return error
                                                },
                                            )
                                            createDocTemplateCTA(
                                              { ...row, editMode: true },
                                              'copy-document-template',
                                            )
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Edit color="#775FD5" />
                                            <HemaValue
                                              color="text-[#232323]"
                                              text="Edit"
                                            />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                      {common?.permissions?.[
                                        'Document Templates'
                                      ]?.write && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={async () => {
                                            dispatch(editFormReducer(row))
                                            setformName(
                                              'Create New Translation ',
                                            )
                                            setokBtnText('Next')
                                            setokBtnIcon(<Next />)
                                            setFormIcon(<CreateTranslation />)
                                            addDocTemplateLang[0].disabled = true
                                            addDocTemplateLang[0].initialValue =
                                              row.name
                                            addDocTemplateLang[1].options =
                                              common?.allInventorylanguages
                                            addDocTemplateLang[1].initialValue =
                                              ''
                                            setUpdatedData(addDocTemplateLang)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'create-document-language',
                                              }),
                                            )
                                            await mergfieldsByType(
                                              row?.type?.id,
                                            )
                                            openEditorforVersion(row)
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Art color="#775FD5" />
                                            <HemaValue
                                              color="text-[#232323]"
                                              text="Create new translation"
                                            />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                      {common?.permissions?.[
                                        'Document Templates'
                                      ]?.delete && (
                                        <NavDropdown.Item
                                          className="mb-1"
                                          onClick={() => {
                                            dispatch(editFormReducer(row))
                                            Remove[0].label =
                                              'Document Template Name'
                                            Remove[0].initialValue = row?.name
                                            setUpdatedData(Remove)
                                            setformName(
                                              'Delete Document Template',
                                            )
                                            setokBtnText('Confirm')
                                            setokBtnIcon(<Confirm />)
                                            setFormIcon(<DeletePurple />)
                                            dispatch(
                                              setForm({
                                                state: true,
                                                type: 'deleteItem',
                                              }),
                                            )
                                            deleteDocumentTemplateCTA(row)
                                          }}
                                        >
                                          <div className="flex gap-[10px] items-center">
                                            <Delete color="#775FD5" />
                                            <HemaValue
                                              color="text-[#232323]"
                                              text="Delete"
                                            />
                                          </div>
                                        </NavDropdown.Item>
                                      )}
                                    </NavDropdown>
                                  </div>
                                </div>
                              )
                            },
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                          },
                        ]}
                        customStyles={{
                          responsiveWrapper: {
                            style: { overflow: 'visible !important' },
                          },
                        }}
                        data={filterDocument}
                        expandableRows
                        expandableRowsComponent={ExpendedDataRowsForDocTemplate}
                        onSort={(row, direction, sorted) => {
                          setSortIcon(direction)
                          setFilterDocument(
                            sortedData(row.sortId, direction, sorted)?.filter(
                              (data) => Object.keys(data)?.length,
                            ),
                          )
                        }}
                        pagination={true}
                        paginationComponent={(e) => {
                          return <Pagination e={e} />
                        }}
                      />
                    ) : (
                      <AlertBootstrap
                        variant="warning"
                        className="text-center mt-[16px]"
                      >
                        There are no records to display.
                      </AlertBootstrap>
                    )
                  ) : (
                    <>
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                    </>
                  )}
                </>
              </Tab>
            )}

            {/* Email template Tab */}

            {common?.permissions?.['Email Template Builder']?.read && (
              <Tab
                eventKey="emailTemplates"
                title={
                  <div className="flex items-center title-icon gap-[7px] ">
                    {activeTab === 'emailTemplates' ? (
                      <LargeEmailIcon />
                    ) : (
                      <LargeEmailIcon color="#414753" />
                    )}
                    Email Templates
                  </div>
                }
              >
                <>
                  <div className="flex justify-between mt-[36px] px-[16px] mb-[10px]">
                    <FormSearch
                      w="w-[400px]"
                      searchQuery={searchQueryEmail}
                      setsearchQuery={setSearchQueryEmail}
                    />
                    {common?.permissions?.['Email Template Builder']?.write && (
                      <Button
                        text="Create Email Template"
                        icon={<Add color="white" />}
                        color="text-white"
                        bg="bg-primary1"
                        cta={() => {
                          setformName('Create Email Templates')
                          dispatch(editFormReducer())
                          dispatch(
                            setForm({
                              state: true,
                              type: 'create-email-template',
                            }),
                          )

                          createEmailTemplateCTA()
                        }}
                      />
                    )}
                  </div>
                  {filterEmailTemplate ? (
                    filterEmailTemplate?.length > 0 ||
                    Object.keys(emailTemplatesFilter)?.length ? (
                      <DataTable
                        noDataComponent={
                          <NoDataComponent
                            setActiveFilterTab={setFilterEmailTemplate}
                            activeFilterOriginalData={
                              emailTemplate?.allEmailTemplates
                            }
                            resetRedux={setEmailTemplatesFilter}
                          />
                        }
                        className="hdxa-rdt-filter border-t-[1px] border-solid border-t-[#DEE2E6] builder-table-st"
                        columns={[
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Name'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />{' '}
                                  {sortIcon === 'asc' ? (
                                    <span className="rotate-180 short-icon">
                                      <TableSortIcon />
                                    </span>
                                  ) : (
                                    <span className="short-icon ">
                                      <TableSortIcon />
                                    </span>
                                  )}
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <div className="w-[290px]">
                                    <FilterColumn
                                      columnName="name"
                                      setRedux={setEmailTemplatesFilter}
                                      reduxValues={emailTemplatesFilter || []}
                                      options={Array.from(
                                        new Set(
                                          emailTemplate?.allEmailTemplates?.map(
                                            (filter) => filter.name,
                                          ),
                                        ),
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            ),
                            sortable: true,
                            selector: (row, index) => (
                              <HemaValue
                                className="text-xs capitalize"
                                text={row?.name}
                              />
                            ),

                            sortId: 'name',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                                  <HemaValue
                                    text={'Email Template Type'}
                                    className="text-xs font-normal "
                                    color="text-[#000000]"
                                  />
                                  {sortIcon === 'asc' ? (
                                    <span className="rotate-180 short-icon">
                                      <TableSortIcon />
                                    </span>
                                  ) : (
                                    <span className="short-icon ">
                                      <TableSortIcon />
                                    </span>
                                  )}
                                </div>
                                <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <div className="w-[290px]">
                                    <FilterColumn
                                      columnName="type"
                                      setRedux={setEmailTemplatesFilter}
                                      reduxValues={emailTemplatesFilter || []}
                                      options={Array.from(
                                        new Set(
                                          emailTemplate?.allEmailTemplates?.map(
                                            (filter) => filter.type?.name,
                                          ),
                                        ),
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            ),
                            sortable: true,

                            selector: (row) => (
                              <HemaValue
                                className="text-xs"
                                text={row?.type?.name || 'NA'}
                              />
                            ),

                            sortId: 'type.name',
                          },
                          {
                            name: (
                              <div className="w-full">
                                <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                                  <HemaValue
                                    text={'Actions'}
                                    className="pr-12 text-xs font-normal"
                                    color="text-[#000000]"
                                  />
                                </div>
                              </div>
                            ),
                            cell: (row, index) => {
                              return (
                                <div className="flex-grow four pr-[18px]">
                                  <div className="flex justify-end gap-[16px] meta ">
                                    {common?.permissions?.[
                                      'Email Template Builder'
                                    ]?.update && (
                                      <Button
                                        icon={<Edit color="#775FD5" />}
                                        color="text-white"
                                        bg="bg-bgActionDots"
                                        cta={() => {
                                          setformName('Edit Email Templates')
                                          dispatch(editFormReducer(row))
                                          dispatch(
                                            setForm({
                                              state: true,
                                              type: 'create-email-template',
                                            }),
                                          )
                                        }}
                                      />
                                    )}
                                    {common?.permissions?.[
                                      'Email Template Builder'
                                    ]?.delete && (
                                      <Button
                                        icon={<DeleteBin color="#F87FB1" />}
                                        color="text-white"
                                        bg="bg-bgActionDots"
                                        cta={() => {
                                          dispatch(editFormReducer(row))
                                          deleteEmailTemplates[0].label = ''
                                          deleteEmailTemplates[1].initialValue =
                                            row.name
                                          deleteEmailTemplates[2].initialValue =
                                            row.subject
                                          deleteEmailTemplates[3].initialValue =
                                            row.type?.name
                                          deleteEmailTemplates[4].initialValue =
                                            row.body
                                          deleteEmailTemplates[5].initialValue =
                                            ''
                                          setUpdatedData(deleteEmailTemplates)
                                          setformName('Delete Email Templates')
                                          setFormIcon(<DeletePurple />)
                                          setokBtnText('Confirm')
                                          setokBtnIcon(<Confirm />)
                                          dispatch(
                                            setForm({
                                              state: true,
                                              type: 'delete-email-template',
                                            }),
                                          )
                                          deleteEmailTemplatesCTA(row)
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            },
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                          },
                        ]}
                        customStyles={{
                          responsiveWrapper: {
                            style: { overflow: 'visible !important' },
                          },
                        }}
                        data={filterEmailTemplate}
                        onSort={(row, direction, sorted) => {
                          setSortIcon(direction)
                          setFilterEmailTemplate(
                            sortedData(row.sortId, direction, sorted)?.filter(
                              (data) => Object.keys(data)?.length,
                            ),
                          )
                        }}
                        pagination={true}
                        paginationComponent={(e) => {
                          return <Pagination e={e} />
                        }}
                      />
                    ) : (
                      <AlertBootstrap
                        variant="warning"
                        className="text-center mt-[16px]"
                      >
                        There are no records to display.
                      </AlertBootstrap>
                    )
                  ) : (
                    <>
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                      <br />
                      <Skeleton count={4} />
                    </>
                  )}
                </>
              </Tab>
            )}
          </Tabs>
        </div>

        {uisettings?.openform && (
          <FormContainer
            cta={CTA}
            formType={updatedData}
            formName={formName}
            icon={formIcon}
            formValidation={formValidation}
            setUpdatedData={setUpdatedData}
            setformName={setformName}
            setFormIcon={setFormIcon}
            allItemsInTemplate={allItemsInTemplate}
            dropDownItemList={dropdownItemList}
            cancelBtnIcon={cancelBtnIcon}
            cancelBtnText={cancelBtnText}
            okBtnIcon={okBtnIcon}
            okBtnText={okBtnText}
            setokBtnIcon={setokBtnIcon}
            setokBtnText={setokBtnText}
          />
        )}
      </>
    )
  }
}

export default KitBuilder

import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

import {
  protocolDetailIdAction,
  regionDetailIdAction,
  addKitTemplateAssemblyCopyAction,
  getKitTemplateAssemblyCopyAction,
  deleteKitAction,
  deleteKitAssemblyAction,
  getAllCountryForRegionDetailAction,
  getAllSitesForCountryDetailAction,
  deleteDocumentAction,
} from "../../../Actions/logistic";
import TableData from "./tableData";
import ShippingRules from "./shippingRules";
import KitConfiguration from "./kitConfiguration";
import NonActiveLogistic from "./nonActiveLogistic";
import Heading from "../logisticComponents/heading";
import {
  Edit,
  Protocol,
  Assign,
  Add,
  AddInKit,
  Apply,
  CountryInfo,
  ExpiryAlertPurple,
  CreateItemPurple,
  CreateSite,
  EyeIcon,
  AddRegion,
  SiteManage,
  EditCountry,
} from "../../../HemeIconLibrary";

import {
  setForm,
  editFormReducer,
  setFormLoaderReducer,
  showSuccessReducer,
  setFormCloseReducer,
} from "../../../Store/reducers/uiSettings";

import { HemaLabel, HemaValue, Button, Alert } from "../../../utils";

import noneSelectedImg from "../../../assets/images/non-selected.svg";
import DocumentsSection from "./documentsSection";
import { setSelectedProtocolDocuments } from "../../../Store/reducers/logistic";

const Container = (props) => {
  const {
    active,
    setCTA,
    setformName,
    setFormIcon,
    setFormValidation,
    setUpdatedData,
    activeDetail,
    activeState,
    setAllItemsInTemplate,
    allItemsInTemplate,
    activeRegion,
    activeCountry,
    loader,
  } = props;

  const dispatch = useDispatch();
  const { logistic, common, uisettings } = useSelector((state) => state);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = async (state) => {
    //console.log('state.selectedRows', state, logistic?.selectedProtocolDetail?.logisticBuilderDocuments)
    setSelectedRows(state.selectedRows);
    var newValue =
      logistic?.selectedProtocolDetail?.logisticBuilderDocuments?.map(
        (document, counter) => {
          const isSelected = state.selectedRows?.some(
            (row) => row.id === document.id
          );
          const docdata = {
            ...document,
            checked: isSelected,
          };
          return docdata;
        }
      );
    dispatch(setSelectedProtocolDocuments(newValue));
  };

  // CTA Item verison
  const copyAssebblyCTA = (itemsfromKit, kit) => {
    setCTA(() => async (data, items) => {
      dispatch(setFormLoaderReducer(true));
      const assName = data?.name;
      const copyAssemblyReturn = await addKitTemplateAssemblyCopyAction({
        logisticBuilderKitId: kit?.id,
        name: assName,
        inboundShippingConditionId: data?.inboundShippingConditionId,
        testingLabId: data?.testingLabId,
        items: items?.map((item) => {
          return {
            name: item.item?.name,
            itemId: item.item?.id || item?.itemId || item.id,
            quantity: item.qty || item.quantity,
          };
        }),
        changeReason: data.change_reason,
      });
      if (copyAssemblyReturn?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${assName} assembly added.`));
        await protocolDetailIdAction(logistic?.selectedProtocol?.id);
      }
      dispatch(setFormLoaderReducer(false));
    });
    setFormValidation(() => (error, values) => {
      if (values?.name.length > 100) {
        error.name = "Max 100 characters allowed";
      }
      return error;
    });
  };

  const deleteKitConfig = () => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const delteKitConfig = await deleteKitAction(
        data?.kitId,
        data?.chanereason
      );
      if (delteKitConfig?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(` kit configuration deleted.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const deleteDocument = () => {
    setCTA(() => async (data) => {
      console.log("deleteDocument", data);
      dispatch(setFormLoaderReducer(true));
      const delteDocumentConfig = await deleteDocumentAction(
        data?.DocumentId,
        data?.chanereason
      );
      if (delteDocumentConfig?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(` document deleted.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const deleteKitAssemblyConfig = (kit) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const delteKitConfig = await deleteKitAssemblyAction(
        data?.kitId,
        kit?.id,
        data?.change_reason
      );
      if (delteKitConfig?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(` kit configuration deleted.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  if (!active) {
    return (
      <div className="w-full pl-[36px] pt-[13px] pr-[16px] pb-[32px]">
        <div className="h-full">
          <div className="w-full flex items-center justify-center text-center  mx-auto mt-[211px]">
            <div className="">
              <img src={noneSelectedImg} alt="" className="mx-auto mb-[14px]" />
              <span className="font-semibold text-[22px] leading-[26px] text-[#F54C3B] mb-1">
                Oops...
              </span>
              <p className="text-lg leading-[21px] font-semibold text-textColor2  w-[350px]">
                It looks like you haven't selected a study from the menu.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (active?.id === 0) {
    return (
      <div className="w-full pl-[36px] pt-[13px] pr-[16px] pb-[32px]">
        <div className="h-full">
          <div className="flex items-center gap-[5px] py-[5px] ">
            <Protocol />
            <h4 className="text-xl font-bold leading-[30px] text-mainheadingColor m-0">
              Study Information
            </h4>
          </div>
          <div className="flex items-center justify-center w-full mx-auto text-center">
            <NonActiveLogistic />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pl-[36px] pt-[13px] pr-[16px] pb-[32px] overflow-auto">
      <div className="h-full">
        {loader ? (
          <div className="">
            <Skeleton count="4" />
            <br />
            <Skeleton count="4" />
            <br />
            <Skeleton count="4" />
            <br />
          </div>
        ) : activeState === "study" ? (
          <div className="">
            <div className="flex items-center justify-between">
              <div className="mb-[26px]">
                <div className="flex items-center gap-[5px] py-[5px] ">
                  <Protocol />
                  <h4 className="text-xl font-bold leading-[30px] text-mainheadingColor m-0">
                    Study Information
                  </h4>
                </div>
                <div className="flex items-center">
                  <HemaValue
                    className="min-w-[250px]"
                    text={`Sites waiting to be configured for this study:  ${
                      logistic?.selectedProtocolDetail?.unconfiguredSites
                        ?.length || 0
                    }`}
                  />
                  {common.permissions?.["Logistics Orchestrator"]?.read && (
                    <Button
                      border="primary1"
                      className="p-[8px] min-w-10 min-h-10 border-none border-0"
                      icon={<EyeIcon color="#605DAF" />}
                      cta={() => {
                        dispatch(
                          editFormReducer(logistic?.selectedProtocolDetail)
                        );
                        setformName(`Unconfigured Sites`);
                        setFormIcon(<CreateSite />);
                        dispatch(
                          setForm({
                            state: true,
                            type: "un-configured-site",
                          })
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              {logistic?.selectedProtocolDetail?.qc?.status?.id !== 2 &&
                common.permissions?.["Logistics Orchestrator"]?.update && (
                  <div className="flex items-center gap-2">
                    <Button
                      border="primary1"
                      className="p-[12px] min-w-10 min-h-10"
                      icon={<Edit color="#605DAF" />}
                      cta={() => {
                        dispatch(
                          editFormReducer(logistic?.selectedProtocolDetail)
                        );
                        setformName(`Edit Study`);
                        setFormIcon(<ExpiryAlertPurple />);
                        dispatch(
                          setForm({
                            state: true,
                            type: "assign-shipping-rule",
                          })
                        );
                      }}
                    />
                  </div>
                )}
            </div>
            <Heading heading="Study Details" mb />
            <div className="mb-[29px]">
              <TableData
                tableHeading={["Sponsor Name", "Study Code"]}
                sponserName={activeDetail?.sponsor?.name}
                studyName={activeDetail?.studyCode}
              />
            </div>

            <Heading heading="Shipping Rules" mb />

            <div className="mt-[22px] mb-[11px]">
              <ShippingRules
                //  shipping={common.allShipping}
                shipping={common.allShipping?.map((data) => {
                  return {
                    shippingConditionId:
                      logistic?.selectedProtocolDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.shippingCondition || data,
                    inBoundCourierId:
                      logistic?.selectedProtocolDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.inBoundCourier,
                    outBoundCourierId:
                      logistic?.selectedProtocolDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.outBoundCourier,
                  };
                })}
                assigner
                readOnly
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          // if (logistic.selectedrule?.length > 0) {
                          setformName(`Apply Shipping Rules`);
                          setFormIcon(<Apply />);
                          dispatch(
                            setForm({
                              state: true,
                              type: "assign-shipping-study-level",
                            })
                          );
                          // }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
            <Heading heading="Kit Configuration" mb />

            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedProtocolDetail?.logisticBuilderKits?.length >
              0 ? (
                <div className="w-full border border-solid rounded border-[#DEE2E6]">
                  <KitConfiguration
                    allKits={logistic.selectedProtocolDetail}
                    addAssemblyAction={
                      logistic.selectedProtocolDetail?.qc?.status.id !== 2
                        ? (kit) => {
                            return (
                              common?.permissions?.["Logistics Orchestrator"]
                                ?.write && (
                                <Button
                                  type="button"
                                  cta={() => {
                                    dispatch(editFormReducer(kit));
                                    setformName(`Add Assembly`);
                                    setFormIcon(<CreateItemPurple />);
                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: "addKitToLogisticAssemlyOnly",
                                      })
                                    );
                                  }}
                                  icon={
                                    <Add
                                      color="#775FD5"
                                      width="16px"
                                      height="16px"
                                    />
                                  }
                                  // btntext="New Assembly"
                                  // borderText
                                  className="p-0 pt-[5px] "
                                />
                              )
                            );
                          }
                        : []
                    }
                    setformName={setformName}
                    setFormIcon={setFormIcon}
                    dispatch={dispatch}
                    setForm={setForm}
                    editFormReducer={editFormReducer}
                    setUpdatedData={setUpdatedData}
                    createAssemblyCTA={(data, kit) => {
                      copyAssebblyCTA(data, kit);
                    }}
                    setAllItemsInTemplate={setAllItemsInTemplate}
                    common={common}
                    getKitTemplateAssemblyCopyAction={
                      getKitTemplateAssemblyCopyAction
                    }
                    deleteKitConfig={deleteKitConfig}
                    deleteKitAssemblyConfig={deleteKitAssemblyConfig}
                    assigner
                    logistic={logistic}
                    level="study"
                  />
                </div>
              ) : (
                <TableError />
              )}
            </div>

            <div className="mb-[33px]">
              <Heading
                border
                buttonData={
                  logistic.selectedProtocolDetail?.qc?.status.id !== 2
                    ? [
                        {
                          type: "primary",
                          text: "Kit Template",
                          icon: <Add color=" #605DAF" />,
                          className: `${
                            common?.permissions?.["Logistics Orchestrator"]
                              ?.write
                              ? "block"
                              : "hidden"
                          }`,
                          cta: () => {
                            setformName(`Add Kit Template(s)`);
                            setFormIcon(<CreateItemPurple />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "addKitToLogistic",
                              })
                            );
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.write
                            ? false
                            : true,
                        },
                        {
                          type: "primary",
                          text: "Apply",
                          icon: <Assign />,
                          cta: () => {
                            if (
                              logistic?.selectedProtocolDetail
                                ?.logisticBuilderKits?.length > 0
                            ) {
                              if (
                                logistic?.selectedProtocolDetail?.logisticBuilderKits?.find(
                                  (f) => f.checked === true
                                )
                              ) {
                                setformName(`Apply Kit Configuration`);
                                setFormIcon(<Apply />);
                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "assign-kit-study-level",
                                  })
                                );
                              } else {
                                window.scrollTo(0, 0);
                                dispatch(
                                  showSuccessReducer(
                                    `Please select any kit first to Apply..!!`
                                  )
                                );
                              }
                            } else {
                              window.scrollTo(0, 0);
                              dispatch(
                                showSuccessReducer(
                                  `There is no kit available to apply..!!`
                                )
                              );
                            }
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.update
                            ? false
                            : true,
                        },
                      ]
                    : []
                }
              />
            </div>

            <Heading heading="Documents" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedProtocolDetail?.logisticBuilderDocuments
                ?.length > 0 ? (
                <div className="w-full border border-solid rounded border-[#DEE2E6]">
                  <DocumentsSection
                    allDocuments={
                      logistic?.selectedProtocolDetail?.logisticBuilderDocuments
                    }
                    logistic={logistic}
                    assigner
                    deleteDocument={deleteDocument}
                    handleRowSelected={handleRowSelected}
                    setformName={setformName}
                    setFormIcon={setFormIcon}
                    dispatch={dispatch}
                    setForm={setForm}
                    editFormReducer={editFormReducer}
                    setUpdatedData={setUpdatedData}
                    level="study"
                  />
                </div>
              ) : (
                <TableError />
              )}
            </div>
            <div className="mb-[33px]">
              <Heading
                border
                buttonData={
                  logistic.selectedProtocolDetail?.qc?.status.id !== 2
                    ? [
                        {
                          type: "primary",
                          text: "Add New Document",
                          icon: <Add color=" #605DAF" />,
                          className: `${
                            common?.permissions?.["Logistics Orchestrator"]
                              ?.write
                              ? "block"
                              : "hidden"
                          }`,
                          cta: (p) => {
                            console.log(
                              "logistic, common, uisettings",
                              logistic,
                              common,
                              uisettings,
                              p
                            );
                            setformName(`Add New Document`);
                            setFormIcon(<CreateItemPurple />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "document-upload",
                              })
                            );
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.write
                            ? false
                            : true,
                        },
                        {
                          type: "primary",
                          text: "Assign",
                          icon: <Assign />,
                          cta: () => {
                            if (selectedRows?.length > 0) {
                              setformName(`Assign Document`);
                              setFormIcon(<Apply />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "assign-document-study-level",
                                })
                              );
                            } else {
                              window.scrollTo(0, 0);
                              dispatch(
                                showSuccessReducer(
                                  `There is no document available to assign..!!`
                                )
                              );
                            }
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.update
                            ? false
                            : true,
                        },
                      ]
                    : []
                }
              />
            </div>

            <Heading heading="Initial Request" mb />

            <div className="flex gap-[50px]">
              <HemaLabel text="Select Kit Assembly" />
              <HemaValue
                text={
                  logistic.selectedProtocolDetail?.initialRequestAssembly
                    ?.name || "N/A"
                }
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          if (
                            logistic?.selectedProtocolDetail
                              ?.initialRequestAssembly
                          ) {
                            setformName(`Apply Initial Request`);
                            setFormIcon(<Apply />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "assign-initial-kit-study-level",
                              })
                            );
                          } else {
                            window.scrollTo(0, 0);
                            dispatch(
                              showSuccessReducer(`There is no kit to apply..!!`)
                            );
                          }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
          </div>
        ) : activeState === "region" ? (
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[5px] py-[5px] mb-[26px]">
                <AddRegion />
                <h4 className="text-xl font-bold leading-[30px] text-mainheadingColor m-0">
                  Region Information
                </h4>
              </div>

              <div className="flex items-center gap-2">
                {logistic.selectedProtocolDetail?.qc?.status.id !== 2 &&
                  common.permissions?.["Logistics Orchestrator"]?.update && (
                    <Button
                      border="primary1"
                      className="p-[12px] w-10 h-10"
                      icon={<Edit color="#605DAF" />}
                      cta={() => {
                        dispatch(
                          editFormReducer(logistic?.selectedRegionDetail)
                        );
                        setformName(`Edit Region`);
                        setFormIcon(<ExpiryAlertPurple />);
                        dispatch(
                          setForm({
                            state: true,
                            type: "edit-region",
                          })
                        );
                      }}
                    />
                  )}
              </div>
            </div>
            <Heading heading="Region Details" mb />
            <div className="mb-[29px]">
              <TableData
                tableHeading={["Region", "Countries"]}
                sponserName={logistic?.selectedRegionDetail?.name}
                studyName={logistic?.selectedRegionDetail?.countries || []}
              />
            </div>

            <Heading heading="Shipping Rules" mb />

            <div className="mt-[22px] mb-[11px]">
              <ShippingRules
                //  shipping={common.allShipping}
                shipping={common.allShipping?.map((data) => {
                  return {
                    shippingConditionId:
                      logistic?.selectedRegionDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.shippingCondition || data,
                    inBoundCourierId:
                      logistic?.selectedRegionDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.inBoundCourier,
                    outBoundCourierId:
                      logistic?.selectedRegionDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.outBoundCourier,
                  };
                })}
                assigner
                readOnly
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          setformName(`Apply Shipping Rules`);
                          setFormIcon(<Apply />);
                          dispatch(
                            setForm({
                              state: true,
                              type: "assign-shipping-region-level",
                            })
                          );
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
            <Heading heading="Kit Configuration" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedRegionDetail?.logisticBuilderKits?.length >
              0 ? (
                <KitConfiguration
                  allKits={logistic.selectedRegionDetail}
                  setformName={setformName}
                  setFormIcon={setFormIcon}
                  dispatch={dispatch}
                  setForm={setForm}
                  editFormReducer={editFormReducer}
                  setUpdatedData={setUpdatedData}
                  setAllItemsInTemplate={setAllItemsInTemplate}
                  common={common}
                  getKitTemplateAssemblyCopyAction={
                    getKitTemplateAssemblyCopyAction
                  }
                  assigner
                  logistic={logistic}
                  level="region"
                />
              ) : (
                <div className="w-full border border-solid border-[ #DEE2E6] rounded ">
                  <div className="w-full  flex items-center justify-between gap-[16px] pl-[40px] py-[11px] pr-[131px] border-b-[1px]  border-b-[#DEE2E6]">
                    <HemaValue
                      className="text-xs font-normal"
                      text="Name"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Testing Lab"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Outbound Condition"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Inbound Condition(s)"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Actions"
                      color="text-black"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center p-[11px]">
                    <HemaValue
                      className="text-xs "
                      text="There are no kits to show. Please assign kits from a higher level."
                      color="text-[#595959]"
                    />
                  </div>
                </div>
              )}
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          if (
                            logistic?.selectedRegionDetail?.logisticBuilderKits?.find(
                              (f) => f.checked === true
                            )
                          ) {
                            setformName(`Apply Kit Configuration`);
                            setFormIcon(<Apply />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "assign-kit-region-level",
                              })
                            );
                          } else {
                            window.scrollTo(0, 0);
                            dispatch(
                              showSuccessReducer(`There is no kit to apply..!!`)
                            );
                          }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
            <Heading heading="Documents" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedRegionDetail?.logisticBuilderDocuments
                ?.length > 0 ? (
                <div className="w-full border border-solid rounded border-[#DEE2E6]">
                  <DocumentsSection
                    allDocuments={
                      logistic?.selectedRegionDetail?.logisticBuilderDocuments
                    }
                    logistic={logistic}
                    assigner
                    deleteDocument={deleteDocument}
                    handleRowSelected={handleRowSelected}
                    setformName={setformName}
                    setFormIcon={setFormIcon}
                    dispatch={dispatch}
                    setForm={setForm}
                    editFormReducer={editFormReducer}
                    setUpdatedData={setUpdatedData}
                    level="region"
                  />
                </div>
              ) : (
                <TableError />
              )}
            </div>
            <div className="mb-[33px]">
              <Heading
                border
                buttonData={
                  logistic.selectedProtocolDetail?.qc?.status.id !== 2
                    ? [
                        {
                          type: "primary",
                          text: "Assign",
                          icon: <Assign />,
                          cta: () => {
                            if (selectedRows?.length > 0) {
                              setformName(`Assign Document`);
                              setFormIcon(<Apply />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "assign-document-region-level",
                                })
                              );
                            } else {
                              window.scrollTo(0, 0);
                              dispatch(
                                showSuccessReducer(
                                  `There is no document available to assign..!!`
                                )
                              );
                            }
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.update
                            ? false
                            : true,
                        },
                      ]
                    : []
                }
              />
            </div>
            <Heading heading="Initial Request" mb />
            <div className="flex gap-[50px]">
              <HemaLabel text="Select Kit Assembly" />
              <HemaValue
                text={
                  logistic.selectedRegionDetail?.initialRequestAssembly?.name ||
                  "N/A"
                }
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          if (
                            logistic?.selectedRegionDetail
                              ?.initialRequestAssembly
                          ) {
                            setformName(`Apply Initial Request`);
                            setFormIcon(<Apply />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "assign-initial-kit-region-level",
                              })
                            );
                          } else {
                            window.scrollTo(0, 0);
                            dispatch(
                              showSuccessReducer(`There is no kit to apply..!!`)
                            );
                          }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
          </div>
        ) : activeState === "country" ? (
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[5px] py-[5px] mb-[26px]">
                <CountryInfo />
                <h4 className="text-xl font-bold leading-[30px] text-mainheadingColor m-0">
                  Country Information
                </h4>
              </div>
              <div className="flex items-center gap-2">
                {logistic.selectedProtocolDetail?.qc?.status.id !== 2 &&
                  common.permissions?.["Logistics Orchestrator"]?.update && (
                    <Button
                      border="primary1"
                      className="p-[12px] w-10 h-10"
                      icon={<Edit color="#605DAF" />}
                      cta={() => {
                        dispatch(
                          editFormReducer(logistic?.selectedCountryDetail)
                        );
                        setformName(`Edit Country`);
                        setFormIcon(<EditCountry />);
                        dispatch(
                          setForm({
                            state: true,
                            type: "add-new-country",
                          })
                        );
                      }}
                    />
                  )}
              </div>
            </div>
            {console.log(
              "logistic?.selectedCountryDetail",
              logistic?.selectedCountryDetail
            )}
            <Heading heading="Country Details" mb />
            <div className="mb-[29px]">
              <div className="flex flex-col gap-4">
                <div className="items-center columns-2">
                  {[
                    {
                      label: "Country",
                      value:
                        logistic?.selectedCountryDetail?.country?.name || "N/A",
                    },

                    {
                      label: "Outbound HS/HTS Code",
                      value:
                        logistic?.selectedCountryDetail?.outgoingHtsCode ||
                        "N/A",
                    },
                    {
                      label: "Ship Through Depot",
                      value:
                        logistic?.selectedCountryDetail?.shippingDepot?.name ||
                        "N/A",
                    },
                    {
                      label: "Language",
                      value:
                        logistic?.selectedCountryDetail?.language?.name ||
                        "N/A",
                    },
                    {
                      label: "Inbound HS/HTS Code",
                      value:
                        logistic?.selectedCountryDetail?.incomingHtsCode ||
                        "N/A",
                    },
                    {
                      label: "Incoterm",
                      value: logistic?.selectedCountryDetail?.incoterm || "N/A",
                    },
                    {
                      label: "EORI",
                      value: logistic?.selectedCountryDetail?.eorI || "N/A",
                    },
                  ].map((info) => {
                    return (
                      <div className="mb-[16px] columns-2">
                        <HemaLabel
                          className="w-[150px] whitespace-nowrap"
                          textSize="text-[14px]"
                          text={info.label}
                        />
                        <HemaValue
                          className="min-w-[250px]"
                          text={info.value || "NA"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Heading heading="Communication Plan Details" mb />
            <div className="mb-[29px]">
              <div className="flex flex-col gap-4">
                <div className="items-center columns-2">
                  {[
                    {
                      label: "Broker Contact",
                      value:
                        logistic?.selectedCountryDetail?.broker?.contact ||
                        "N/A",
                    },
                    {
                      label: "Broker Company Name",
                      value:
                        logistic?.selectedCountryDetail?.broker?.companyName ||
                        "N/A",
                    },
                    {
                      label: "Broker Email",
                      value:
                        logistic?.selectedCountryDetail?.broker?.email || "N/A",
                    },
                    {
                      label: "Broker Telephone",
                      value:
                        logistic?.selectedCountryDetail?.broker?.phone || "N/A",
                    },
                    {
                      label: "Broker Fax",
                      value:
                        logistic?.selectedCountryDetail?.broker?.fax || "N/A",
                    },
                    {
                      label: "Broker Address:",
                      value:
                        logistic?.selectedCountryDetail?.broker?.address ||
                        "N/A",
                    },
                    {
                      label: "Broker EORI",
                      value:
                        logistic?.selectedCountryDetail?.broker?.eori || "N/A",
                    },
                    {
                      label: "Broker Tax ID",
                      value:
                        logistic?.selectedCountryDetail?.broker?.taxId || "N/A",
                    },

                    {
                      label: "IOR Contact",
                      value:
                        logistic?.selectedCountryDetail?.ioR?.contact
                          ?.split(" ")
                          ?.join(",") || "N/A",
                    },
                    {
                      label: "IOR Company Name",
                      value:
                        logistic?.selectedCountryDetail?.ioR?.companyName ||
                        "N/A",
                    },
                    {
                      label: "IOR Email",
                      value:
                        logistic?.selectedCountryDetail?.ioR?.email || "N/A",
                    },
                    {
                      label: "IOR EORI",
                      value:
                        logistic?.selectedCountryDetail?.ioR?.eori || "N/A",
                    },
                    {
                      label: "IOR Tax ID",
                      value:
                        logistic?.selectedCountryDetail?.ioR?.taxId || "N/A",
                    },
                    {
                      label: "Post Shipping Required",
                      value: logistic?.selectedCountryDetail
                        ?.isPostShippingRequired
                        ? "True"
                        : "False",
                    },
                    {
                      label: "Post Shipping Contact",
                      value:
                        logistic?.selectedCountryDetail?.postShippingContact
                          ?.split(" ")
                          ?.join(",") || "N/A",
                    },
                    {
                      label: "Green Light Required",
                      value:
                        logistic?.selectedCountryDetail?.greenLightRequiredType
                          ?.name || "N/A",
                    },
                    {
                      label: "Green Light Contact(s)",
                      value:
                        logistic?.selectedCountryDetail?.greenLightContact
                          ?.split(" ")
                          ?.join(",") || "N/A",
                    },
                    {
                      label: "Select Kit Assembly",
                      value:
                        logistic?.selectedCountryDetail?.shippingAssembly
                          ?.name || "N/A",
                    },
                  ].map((info) => {
                    return (
                      <div className="mb-[16px] columns-2">
                        <HemaLabel
                          className="w-[150px] whitespace-nowrap"
                          isLower={true}
                          textSize="text-[14px]"
                          text={info.label}
                        />
                        <HemaValue
                          className="max-w-[320px] overflow-hidden text-ellipsis "
                          text={info.value || "N/A"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Heading heading="Shipping Rules" mb />

            <div className="mt-[22px] mb-[11px]">
              <ShippingRules
                //  shipping={common.allShipping}
                shipping={common.allShipping?.map((data) => {
                  return {
                    shippingConditionId:
                      logistic?.selectedCountryDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.shippingCondition || data,
                    inBoundCourierId:
                      logistic?.selectedCountryDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.inBoundCourier,
                    outBoundCourierId:
                      logistic?.selectedCountryDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.outBoundCourier,
                  };
                })}
                assigner
                readOnly
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          setformName(`Apply Shipping Rules`);
                          setFormIcon(<Apply />);
                          dispatch(
                            setForm({
                              state: true,
                              type: "assign-shipping-country-level",
                            })
                          );
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
            <Heading heading="Kit Configuration" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedCountryDetail?.logisticBuilderKits?.length >
              0 ? (
                <KitConfiguration
                  allKits={logistic.selectedCountryDetail}
                  logistic={logistic}
                  setformName={setformName}
                  setFormIcon={setFormIcon}
                  dispatch={dispatch}
                  setForm={setForm}
                  editFormReducer={editFormReducer}
                  setUpdatedData={setUpdatedData}
                  setAllItemsInTemplate={setAllItemsInTemplate}
                  common={common}
                  getKitTemplateAssemblyCopyAction={
                    getKitTemplateAssemblyCopyAction
                  }
                  assigner
                  level="country"
                />
              ) : (
                <div className="w-full border border-solid border-[ #DEE2E6] rounded ">
                  <div className="w-full  flex items-center justify-between gap-[16px] pl-[40px] py-[11px] pr-[131px] border-b-[1px]  border-b-[#DEE2E6]">
                    <HemaValue
                      className="text-xs font-normal"
                      text="Name"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Testing Lab"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Outbound Condition"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Inbound Condition(s)"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Actions"
                      color="text-black"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center p-[11px]">
                    <HemaValue
                      className="text-xs "
                      text="There are no kits to show. Please assign kits from a higher level."
                      color="text-[#595959]"
                    />
                  </div>
                </div>
              )}
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          if (
                            logistic?.selectedCountryDetail?.logisticBuilderKits?.find(
                              (f) => f.checked === true
                            )
                          ) {
                            setformName(`Apply Kit Configuration`);
                            setFormIcon(<Apply />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "assign-kit-country-level",
                              })
                            );
                          } else {
                            window.scrollTo(0, 0);
                            dispatch(
                              showSuccessReducer(`There is no kit to apply..!!`)
                            );
                          }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />

            <Heading heading="Documents" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedCountryDetail?.logisticBuilderDocuments
                ?.length > 0 ? (
                <div className="w-full border border-solid rounded border-[#DEE2E6]">
                  <DocumentsSection
                    allDocuments={
                      logistic?.selectedCountryDetail?.logisticBuilderDocuments
                    }
                    logistic={logistic}
                    assigner
                    deleteDocument={deleteDocument}
                    handleRowSelected={handleRowSelected}
                    setformName={setformName}
                    setFormIcon={setFormIcon}
                    dispatch={dispatch}
                    setForm={setForm}
                    editFormReducer={editFormReducer}
                    setUpdatedData={setUpdatedData}
                    level="country"
                  />
                </div>
              ) : (
                <TableError />
              )}
            </div>
            <div className="mb-[33px]">
              <Heading
                border
                buttonData={
                  logistic.selectedProtocolDetail?.qc?.status.id !== 2
                    ? [
                        {
                          type: "primary",
                          text: "Assign",
                          icon: <Assign />,
                          cta: () => {
                            if (selectedRows?.length > 0) {
                              setformName(`Assign Document`);
                              setFormIcon(<Apply />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "assign-document-country-level",
                                })
                              );
                            } else {
                              window.scrollTo(0, 0);
                              dispatch(
                                showSuccessReducer(
                                  `There is no document available to assign..!!`
                                )
                              );
                            }
                          },
                          hide: common.permissions?.["Logistics Orchestrator"]
                            ?.update
                            ? false
                            : true,
                        },
                      ]
                    : []
                }
              />
            </div>

            <Heading heading="Initial Request" mb />
            <div className="flex gap-[50px]">
              <HemaLabel text="Select Kit Assembly" />
              <HemaValue
                text={
                  logistic.selectedCountryDetail?.initialRequestAssembly
                    ?.name || "N/A"
                }
              />
            </div>
            <Heading
              border
              buttonData={
                logistic.selectedProtocolDetail?.qc?.status.id !== 2
                  ? [
                      {
                        type: "primary",
                        text: "Apply",
                        icon: <Assign />,
                        cta: () => {
                          if (
                            logistic?.selectedCountryDetail
                              ?.initialRequestAssembly
                          ) {
                            setformName(`Apply Initial Request`);
                            setFormIcon(<Apply />);
                            dispatch(
                              setForm({
                                state: true,
                                type: "assign-initial-kit-country-level",
                              })
                            );
                          } else {
                            window.scrollTo(0, 0);
                            dispatch(
                              showSuccessReducer(
                                `There is not kit to apply..!!`
                              )
                            );
                          }
                        },
                        hide: common.permissions?.["Logistics Orchestrator"]
                          ?.update
                          ? false
                          : true,
                      },
                    ]
                  : []
              }
            />
          </div>
        ) : activeState === "site" ? (
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[5px] py-[5px] mb-[26px]">
                <CreateSite />
                <h4 className="text-xl font-bold leading-[30px] text-mainheadingColor m-0">
                  Site Information
                </h4>
              </div>
              <div className="flex items-center gap-2">
                {logistic.selectedProtocolDetail?.qc?.status.id !== 2 &&
                  common.permissions?.["Logistics Orchestrator"]?.update && (
                    <Button
                      border="primary1"
                      className="p-[12px] w-10 h-10"
                      icon={<Edit color="#605DAF" />}
                      cta={() => {
                        dispatch(editFormReducer(logistic?.selectedSiteDetail));
                        setformName(`Edit Site`);
                        setFormIcon(<ExpiryAlertPurple />);
                        dispatch(
                          setForm({
                            state: true,
                            type: "add-new-site",
                          })
                        );
                      }}
                    />
                  )}
              </div>
            </div>
            <Heading heading="Site Details" mb />
            <div className="mb-[29px]">
              <div className="flex flex-col gap-4">
                <div className="items-center columns-2">
                  {[
                    {
                      label: "Site Name",
                      value: logistic?.selectedSiteDetail?.siteName || "N/A",
                    },
                    {
                      label: "Site Code",
                      value: logistic?.selectedSiteDetail?.siteCode || "N/A",
                    },

                    {
                      label: "Principal Investigator",
                      value:
                        logistic?.selectedSiteDetail?.principleInvestigator
                          ?.name || "N/A",
                    },
                    {
                      label: "Site Address",
                      value:
                        logistic?.selectedSiteDetail?.siteAddress?.name ||
                        "N/A",
                    },
                  ].map((info) => {
                    return (
                      <div className="mb-[16px] columns-2">
                        <HemaLabel
                          className="w-[150px] whitespace-nowrap"
                          textSize="text-[14px]"
                          text={info.label}
                        />
                        <HemaValue
                          className="min-w-[250px] break-all "
                          text={info.value || "N/A"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <Heading heading="Shipping Rules" mb />

            <div className="mt-[22px] mb-[29px]">
              <ShippingRules
                //  shipping={common.allShipping}
                shipping={common.allShipping?.map((data) => {
                  return {
                    shippingConditionId:
                      logistic?.selectedSiteDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.shippingCondition || data,
                    inBoundCourierId:
                      logistic?.selectedSiteDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.inBoundCourier,
                    outBoundCourierId:
                      logistic?.selectedSiteDetail?.shippingRules?.filter(
                        (check) => check?.shippingCondition?.id === data.id
                      )?.[0]?.outBoundCourier,
                  };
                })}
                assigner
                readOnly
              />
            </div>

            <Heading heading="Kit Configuration" mb />
            <div className="mt-[22px] mb-[29px]">
              {logistic?.selectedSiteDetail?.logisticBuilderKits?.length > 0 ? (
                <KitConfiguration
                  allKits={logistic?.selectedSiteDetail}
                  logistic={logistic}
                  level="site"
                />
              ) : (
                <div className="w-full border border-solid border-[ #DEE2E6] rounded ">
                  <div className="w-full  flex items-center justify-between gap-[16px] pl-[40px] py-[11px] pr-[131px] border-b-[1px]  border-b-[#DEE2E6]">
                    <HemaValue
                      className="text-xs font-normal"
                      text="Name"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Testing Lab"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Outbound Condition"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Inbound Condition(s)"
                      color="text-black"
                    />
                    <HemaValue
                      className="text-xs font-normal"
                      text="Actions"
                      color="text-black"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center p-[11px]">
                    <HemaValue
                      className="text-xs "
                      text="There are no kits to show. Please assign kits from a higher level."
                      color="text-[#595959]"
                    />
                  </div>
                </div>
              )}
            </div>

            <Heading heading="Documents" mb />
            <div className="mt-[22px] mb-[11px]">
              {logistic?.selectedSiteDetail?.logisticBuilderDocuments?.length >
              0 ? (
                <div className="w-full border border-solid rounded border-[#DEE2E6]">
                  <DocumentsSection
                    allDocuments={
                      logistic?.selectedSiteDetail?.logisticBuilderDocuments
                    }
                    logistic={logistic}
                    assigner
                    deleteDocument={deleteDocument}
                    handleRowSelected={handleRowSelected}
                    setformName={setformName}
                    setFormIcon={setFormIcon}
                    dispatch={dispatch}
                    setForm={setForm}
                    editFormReducer={editFormReducer}
                    setUpdatedData={setUpdatedData}
                    level="site"
                  />
                </div>
              ) : (
                <TableError />
              )}
            </div>
            <div className="mb-[33px]"></div>

            <Heading heading="Initial Request" mb />
            <div className="flex gap-[50px]">
              <HemaLabel text="Select Kit Assembly" />
              <HemaValue
                text={
                  logistic.selectedSiteDetail?.initialRequestAssembly?.name ||
                  "N/A"
                }
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Container;

const TableError = () => {
  return (
    <div className="w-full border border-solid border-[ #DEE2E6] rounded ">
      <div className="w-full  flex items-center justify-between gap-[16px] pl-[40px] py-[11px] pr-[131px] border-b-[1px]  border-b-[#DEE2E6]">
        <HemaValue
          className="text-xs font-normal"
          text="Name"
          color="text-black"
        />
        <HemaValue
          className="text-xs font-normal"
          text="Testing Lab"
          color="text-black"
        />
        <HemaValue
          className="text-xs font-normal"
          text="Outbound Condition"
          color="text-black"
        />
        <HemaValue
          className="text-xs font-normal"
          text="Inbound Condition(s)"
          color="text-black"
        />
        <HemaValue
          className="text-xs font-normal"
          text="Actions"
          color="text-black"
        />
      </div>
      <div className="w-full flex items-center justify-center p-[11px]">
        <HemaValue
          className="text-xs "
          text="There are no kits to show. Please assign kits from a higher level."
          color="text-[#595959]"
        />
      </div>
    </div>
  );
};

import React from 'react';

export const WhatIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9714 13.1597C14.1827 12.5592 14.5996 12.0529 15.1483 11.7304C15.6971 11.4079 16.3423 11.29 16.9697 11.3976C17.597 11.5052 18.1661 11.8314 18.576 12.3183C18.9859 12.8053 19.2102 13.4216 19.2093 14.0581C19.2093 15.855 16.514 16.7534 16.514 16.7534M16.5859 20.3472H16.5949M8.5 12.2087V19.5013C8.5 19.8092 8.5 19.9631 8.54536 20.1004C8.58548 20.2218 8.65108 20.3333 8.73776 20.4274C8.83573 20.5337 8.97028 20.6084 9.23939 20.7579L15.8878 24.4515C16.1426 24.5931 16.27 24.6638 16.4049 24.6916C16.5243 24.7161 16.6475 24.7161 16.7669 24.6916C16.9018 24.6638 17.0292 24.5931 17.284 24.4515L23.9324 20.7579C24.2015 20.6084 24.3361 20.5337 24.4341 20.4274C24.5207 20.3333 24.5863 20.2218 24.6265 20.1004C24.6718 19.9631 24.6718 19.8092 24.6718 19.5013V12.2087C24.6718 11.9008 24.6718 11.7469 24.6265 11.6096C24.5863 11.4882 24.5207 11.3767 24.4341 11.2826C24.3361 11.1763 24.2015 11.1016 23.9324 10.9521L17.284 7.2585C17.0292 7.11695 16.9018 7.04617 16.7669 7.01842C16.6475 6.99386 16.5243 6.99386 16.4049 7.01842C16.27 7.04617 16.1426 7.11695 15.8878 7.2585L9.23939 10.9521C8.97028 11.1016 8.83573 11.1763 8.73776 11.2826C8.65108 11.3767 8.58548 11.4882 8.54536 11.6096C8.5 11.7469 8.5 11.9008 8.5 12.2087Z"
        stroke="#775FD5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

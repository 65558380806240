import React from "react";

export const HideEye = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0007 2.97786C13.159 2.97786 15.9756 4.75286 17.3506 7.5612C16.859 8.57786 16.1673 9.45286 15.3423 10.1612L16.5173 11.3362C17.6756 10.3112 18.5923 9.02786 19.1673 7.5612C17.7256 3.90286 14.1673 1.3112 10.0007 1.3112C8.94232 1.3112 7.92565 1.47786 6.96732 1.7862L8.34232 3.1612C8.88398 3.05286 9.43398 2.97786 10.0007 2.97786ZM9.10898 3.92786L10.834 5.65286C11.309 5.8612 11.6923 6.24453 11.9007 6.71953L13.6257 8.44453C13.6923 8.1612 13.7423 7.8612 13.7423 7.55286C13.7506 5.4862 12.0673 3.8112 10.0007 3.8112C9.69232 3.8112 9.40065 3.85286 9.10898 3.92786ZM1.67565 1.20286L3.90898 3.4362C2.55065 4.50286 1.47565 5.91953 0.833984 7.5612C2.27565 11.2195 5.83398 13.8112 10.0007 13.8112C11.2673 13.8112 12.484 13.5695 13.6006 13.1279L16.4506 15.9779L17.6256 14.8029L2.85065 0.0195312L1.67565 1.20286ZM7.92565 7.45286L10.1006 9.62786C10.0673 9.6362 10.034 9.64453 10.0007 9.64453C8.85065 9.64453 7.91732 8.7112 7.91732 7.5612C7.91732 7.51953 7.92565 7.49453 7.92565 7.45286ZM5.09232 4.61953L6.55065 6.07786C6.35898 6.5362 6.25065 7.0362 6.25065 7.5612C6.25065 9.62786 7.93398 11.3112 10.0007 11.3112C10.5257 11.3112 11.0257 11.2029 11.4757 11.0112L12.2923 11.8279C11.559 12.0279 10.7923 12.1445 10.0007 12.1445C6.84232 12.1445 4.02565 10.3695 2.65065 7.5612C3.23398 6.36953 4.08398 5.3862 5.09232 4.61953Z"
        fill="#232323"
      />
    </svg>
  );
};

import { ChangeReason, LabelQuantity, Location } from '../../../HemeIconLibrary';

export const moveInventoryForm = [
  {
    label: 'Item Name',
    initialValue: '',
    name: 'name',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Location',
    initialValue: '',
    name: 'location',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Lot Number',
    initialValue: '',
    name: 'lot',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Expiration Date',
    initialValue: '',
    name: 'expire',
    fluid: true,
    type: 'table',
  },
  {
    name: 'qty',
    label: 'Quantity',
    icon: <LabelQuantity />,
    type: 'number',
    initialValue: '',
    placeholder: 'Enter quantity',
    required: true,
    showTotal: true,
  },
  // {
  //   name: "locationId",
  //   label: "New Storage Location",
  //   icon: <Location />,
  //   type: "text",
  //   initialValue: "1",
  //   placeholder: "",
  //   required: true,
  // },
  {
    label: 'New Storage Location',
    icon: <Location />,
    type: 'Storage-Locations',
    placeholder: 'Browse',
    required: true,
    name: 'locationId',
    fluid: true,
  },
  {
    name: 'change_reason',
    label: 'Change Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    required: true,
    placeholder: 'Type reason',
  },
];

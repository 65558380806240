import React from 'react';

export const MergeField = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0246 6.00977V10.2457C10.0235 10.4455 9.94338 10.6367 9.8017 10.7776C9.66002 10.9185 9.46833 10.9976 9.26852 10.9976H5.01172V6.95387C5.01172 6.82954 5.03628 6.70643 5.08398 6.59162C5.13169 6.4768 5.2016 6.37254 5.28971 6.28481C5.37782 6.19709 5.4824 6.12764 5.59742 6.08044C5.71245 6.03324 5.83566 6.00922 5.96 6.00977H10.0246Z"
        fill="url(#paint0_linear_10378_290476)"
        stroke="url(#paint1_linear_10378_290476)"
        strokeWidth="2"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0149 6.01034V4.69863M5.01033 10.9982H1.76029C1.55865 10.9982 1.36527 10.9181 1.22268 10.7755C1.0801 10.6329 1 10.4395 1 10.2379V2.75613C0.999998 2.65648 1.01969 2.55782 1.05795 2.46581C1.09621 2.3738 1.15228 2.29026 1.22294 2.21999C1.29359 2.14972 1.37744 2.09412 1.46966 2.05636C1.56188 2.01861 1.66065 1.99946 1.76029 2.00001H9.24625C9.44678 2.00001 9.6391 2.07967 9.7809 2.22147C9.9227 2.36327 10.0024 2.55559 10.0024 2.75613V6.01034M14.0252 6.95427V14.0559C14.0252 14.3063 13.9258 14.5464 13.7487 14.7235C13.5717 14.9005 13.3315 15 13.0811 15H5.97951C5.72873 15 5.48816 14.9007 5.31045 14.7237C5.13273 14.5468 5.03234 14.3067 5.03123 14.0559V10.9855H9.26715C9.46696 10.9855 9.65865 10.9064 9.80033 10.7655C9.94201 10.6246 10.0222 10.4334 10.0233 10.2336V6.01017H13.0895C13.3384 6.01237 13.5764 6.11281 13.7517 6.28963C13.9269 6.46644 14.0252 6.70532 14.0252 6.95427Z"
        stroke="url(#paint2_linear_10378_290476)"
        strokeWidth="2"
        stroke-miterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10378_290476"
          x1="5.01172"
          y1="8.50369"
          x2="10.0246"
          y2="8.50369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10378_290476"
          x1="5.01172"
          y1="8.50369"
          x2="10.0246"
          y2="8.50369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10378_290476"
          x1="1"
          y1="8.49999"
          x2="14.0252"
          y2="8.49999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const Persevere = () => {
  return (
    <svg
      width="56"
      height="46"
      viewBox="0 0 56 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.9993 40.1055H29.5646V42.2085C29.5646 42.609 29.2642 42.9095 28.8637 42.9095H27.1612C26.7607 42.9095 26.4602 42.609 26.4602 42.2085V40.1055H2.02557C1.52486 40.1055 1.07422 40.506 1.07422 41.0568C1.07422 41.5575 1.47479 42.0082 2.02557 42.0082H24.6076V42.7092C24.6076 43.8608 25.559 44.8121 26.7106 44.8121H29.3644C30.516 44.8121 31.4673 43.8608 31.4673 42.7092V42.0082H54.0494C54.5501 42.0082 55.0007 41.6076 55.0007 41.0568C54.9507 40.506 54.5 40.1055 53.9993 40.1055Z"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.994 36.6984C43.0313 36.7986 34.5693 29.2879 28.01 37.2492C21.4506 29.2879 12.9886 36.7986 4.02592 36.6984C3.7255 38.2006 1.72266 40.1032 1.72266 40.1032H28.06H54.3974C54.3473 40.1032 52.2944 38.2006 51.994 36.6984Z"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4746 7.90954C34.622 -1.05318 21.4032 -1.05318 19.5506 7.90954C18.5492 13.9681 25.0083 18.775 25.5091 25.2842H28.0126H30.5162C30.8667 19.0253 37.3759 14.2185 36.4746 7.90954Z"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1165 24.8832C29.1165 24.8832 30.0678 17.1722 31.8203 13.6673L29.7173 14.168L27.9648 12.5156L26.2124 14.168L24.1094 13.6673C25.8619 17.1722 26.8132 24.8832 26.8132 24.8832"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3086 10.2148L25.2599 11.5668"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7209 10.2148L30.7695 11.5668"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0156 8.21094V10.2138"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4609 25.3359V29.0412L28.0146 30.8938L30.5181 29.0412V25.3359H25.4609Z"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4609 28.114L30.5181 27.6133"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8606 40.438L24.5636 40.438C24.449 40.6464 24.6428 42.0349 24.6428 42.2099C24.6428 42.4286 24.9866 43.288 25.2209 43.7255C25.4553 44.163 25.9866 44.3036 26.4866 44.6005C26.9866 44.8974 26.9866 44.6942 28.2209 44.6005C29.4553 44.5067 28.9084 44.6005 29.2834 44.6005C29.6584 44.6005 29.9709 44.3505 30.3303 44.1474C30.6897 43.9442 30.8928 43.6474 31.1741 43.288C31.4553 42.9286 31.5803 42.5692 31.5803 42.2099C31.5803 41.8505 31.6584 41.366 31.5803 40.7098C31.5022 40.0535 31.4241 40.5535 31.2522 40.3348C31.0803 40.116 30.9084 40.241 30.7678 40.241C30.6272 40.241 29.6887 40.1005 28.8137 40.3348C27.9387 40.5692 26.6741 40.4598 26.4866 40.5535C26.3366 40.6285 26.4814 41.3973 26.0647 41.6473L25.9866 41.366L25.9075 40.7098H25.3459L24.8606 40.438Z"
        fill="white"
      />
    </svg>
  );
};

import React from 'react';

export const BlackBarcode = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1794 12.8205V21H3V12.8205H11.1794ZM17.7308 19.3654V21H16.0897V19.3654H17.7308ZM20.987 19.3654V21H19.3654V19.3654H20.987ZM17.7308 12.8531V14.4877H19.3654V12.8205H21V17.7308H16.0897V16.0897H14.4551V21H12.8205V12.8205L17.7308 12.8531ZM9.54486 14.4551H4.63459V19.3524H9.54486V14.4551ZM7.91027 16.0897V17.7308H6.26917V16.0897H7.91027ZM11.1664 3V11.1794H3V3H11.1664ZM20.987 3V11.1794H12.8205V3H20.987ZM9.54486 4.63459H4.63459V9.54486H9.54486V4.63459ZM19.3654 4.63459H14.4551V9.54486H19.3654V4.63459ZM7.91027 6.26917V7.91027H6.26917V6.26917H7.91027ZM17.7308 6.26917V7.91027H16.0897V6.26917H17.7308Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import { Alert as BootstrapAlert, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import {
  Cancel,
  Confirm,
  ChangeReason,
  CheckReady,
  FirstName,
  CrossIcon,
  Add,
} from '../../../HemeIconLibrary';
// Utils
import HemaHeadingWithSubText from '../../../utils/HemaHeadingWithSubText';
import {
  Alert,
  HemaLabel,
  HemaValue,
  FormText,
  FormSwitch,
  Button,
  FormTextarea,
} from '../../../utils';
import { showSuccessReducer } from '../../../Store/reducers/uiSettings';
// Actions
import {
  getPerimssionAction,
  createNewRoleAction,
  deleteRoleAction,
  updateRoleAction,
} from '../../../Actions/settings';
import { setShowOrderConfig } from '../../../Store/reducers/settings';
import { compareArrays } from '../../../helpers/commonHelper';

const RoleChanges = () => {
  const dispatch = useDispatch();
  const { uisettings, settings, common } = useSelector((state) => state);
  const formikRef = useRef();
  useEffect(() => {
    getPerimssionAction();
  }, []);
  useEffect(() => {
    if (uisettings?.formName === 'add-new-role') {
      formikRef?.current?.resetForm();
    }
  }, [uisettings?.formName]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText
          heading={
            uisettings.formName === 'edit role'
              ? 'Edit Role'
              : uisettings?.formName === 'delete role'
              ? 'Delete Role'
              : uisettings?.formName === 'copy role'
              ? 'Copy Role'
              : uisettings?.formName === 'add-new-role'
              ? 'Add New Role'
              : 'Add New Role'
          }
          sub="Manage your user management here."
        />
      </div>
      <Alert type="error" />

      <div>
        <Formik
          enableReinitialize
          initialValues={{
            name:
              uisettings?.formName === 'copy role'
                ? uisettings.editForm?.name + ' Copy'
                : uisettings.editForm?.name || '',
            permissions:
              uisettings.editForm?.permissions || settings.permission || [],
            changeReason: '',
          }}
          innerRef={formikRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Required';
            }
            if (values.name.length > 100 && values.name) {
              errors.name = 'Max 100 characters allowed';
            }

            if (
              values?.permissions?.filter(
                (data) => data.read || data.special || data.update || data.write
              )?.length === 0
            ) {
              errors.permissions = 'Select atleast one permission';
            }
            if (
              !values.changeReason &&
              (uisettings?.formName === 'delete role' ||
                uisettings?.formName === 'edit role')
            ) {
              errors.changeReason = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, {resetForm}) => {
            console.log(values);
            if (uisettings.editForm && uisettings?.formName === 'edit role') {
              const result = await updateRoleAction(
                uisettings.editForm?.id,
                values,
                common?.user?.role?.id
              );
              if (result.status === 200) {
                dispatch(setShowOrderConfig(false));
                dispatch(showSuccessReducer(`${values.name} role is updated`));
              }
            } else if (
              uisettings.editForm &&
              uisettings?.formName === 'delete role'
            ) {
              const result = await deleteRoleAction(
                uisettings.editForm?.id,
                values?.changeReason
              );
              if (result.status === 200) {
                dispatch(setShowOrderConfig(false));
                dispatch(showSuccessReducer(`${values.name} is removed`));
              }
            } else {
              const result = await createNewRoleAction(values);
              if (result.status === 200) {
                dispatch(setShowOrderConfig(false));
                dispatch(
                  showSuccessReducer(`${values.name} is added as a new role`)
                );
                resetForm({ values: '' });
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            initialValues,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="">
              <div className="bg-white rounded-[5px] px-[16px] py-[15px] mt-[27px] mb-[10px] inventory-tabs">
                <div className="flex gap-[10px] w-[400px]">
                  <HemaLabel
                    text="Role Name"
                    color="text-[#454545]"
                    textSize="text-xs"
                    className="font-medium whitespace-nowrap"
                    required={true}
                    icon={<FirstName />}
                  />
                  <FormText
                    type="text"
                    name="name"
                    placeholder="Enter Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.name}
                    disabled={
                      uisettings?.formName === 'delete role' ? true : false
                    }
                    crossIcon={values?.name ? <CrossIcon /> : ''}
                  />
                </div>
                {errors.name && touched.name && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="bg-white rounded-[5px] pt-[21px] mt-[0] mb-[13px] inventory-tabs permission-table">
                <div className=" flex items-center gap-[3px] pb-[21px] px-[16px] border-b-[1px] border-solid border-[#DEE2E6]">
                  <div className="flex items-center gap-0">
                    <CheckReady purple />
                    <HemaValue
                      text={'Permission Assignment'}
                      color="text-[#2C2C2C]"
                      className="font-[700] text-xl"
                    />
                  </div>
                  <sup className="font-medium text-xs leading-[18px] text-[#F20000]">
                    *
                  </sup>
                </div>
                {!settings?.permission ? (
                  <SkelatonCoponent />
                ) : settings?.permission?.length > 0 ? (
                  <DataTable
                    className="role_setting_table"
                    data={values.permissions}
                    columns={[
                      {
                        style: {
                          minWidth: '400px !important',
                          maxWidth: 'auto !important',
                        },
                        name: (
                          <HemaValue
                            text={'Area'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <HemaValue
                            text={row?.name}
                            color="text-[#595959]"
                            className="text-xs font-medium"
                          />
                        ),
                      },
                      // aaa
                      {
                        name: <HemaValue text={''} />,

                        selector: (row) => <HemaValue text={''} />,
                      },
                      {
                        name: <HemaValue text={''} />,

                        selector: (row) => <HemaValue text={''} />,
                      },
                      {
                        name: <HemaValue text={''} />,

                        selector: (row) => <HemaValue text={''} />,
                      },
                      {
                        name: <HemaValue text={''} />,

                        selector: (row) => <HemaValue text={''} />,
                      },

                      //
                      {
                        name: (
                          <HemaValue
                            text={'Create'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <div className="pl-[0px]">
                            <FormSwitch
                              disabled={
                                uisettings?.formName === 'delete role'
                                  ? true
                                  : false
                              }
                              setFieldValue={(name, value) => {
                                setFieldValue(
                                  'permissions',
                                  values.permissions?.map((data) => {
                                    if (data.id === row.id) {
                                      return {
                                        ...row,
                                        write: value,
                                        read: value ? true : row.read,
                                      };
                                    } else {
                                      return data;
                                    }
                                  })
                                );
                              }}
                              name="permissions"
                              initialValue={row?.write}
                            />
                          </div>
                        ),
                      },
                      {
                        name: (
                          <HemaValue
                            text={'Read'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <div className="pl-[0px]">
                            <FormSwitch
                              setFieldValue={(name, value) => {
                                setFieldValue(
                                  'permissions',
                                  values.permissions?.map((data) => {
                                    if (data.id === row.id) {
                                      return { ...row, read: value };
                                    } else {
                                      return data;
                                    }
                                  })
                                );
                              }}
                              disabled={
                                uisettings?.formName === 'delete role'
                                  ? true
                                  : row.write ||
                                    row.update ||
                                    row.delete ||
                                    row.special
                              }
                              name="permissions"
                              initialValue={row?.read}
                            />
                          </div>
                        ),
                      },
                      {
                        name: (
                          <HemaValue
                            text={'Update'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <div className="pl-[0px]">
                            <FormSwitch
                              setFieldValue={(name, value) => {
                                setFieldValue(
                                  'permissions',
                                  values.permissions?.map((data) => {
                                    if (data.id === row.id) {
                                      return {
                                        ...row,
                                        update: value,
                                        read: value ? true : row.read,
                                      };
                                    } else {
                                      return data;
                                    }
                                  })
                                );
                              }}
                              disabled={
                                uisettings?.formName === 'delete role'
                                  ? true
                                  : false
                              }
                              name="permissions"
                              initialValue={row?.update}
                            />
                          </div>
                        ),
                      },
                      {
                        name: (
                          <HemaValue
                            text={'Delete'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <div className="pl-[8px]">
                            <FormSwitch
                              setFieldValue={(name, value) => {
                                setFieldValue(
                                  'permissions',
                                  values.permissions?.map((data) => {
                                    if (data.id === row.id) {
                                      return {
                                        ...row,
                                        delete: value,
                                        read: value ? true : row.read,
                                      };
                                    } else {
                                      return data;
                                    }
                                  })
                                );
                              }}
                              disabled={
                                uisettings?.formName === 'delete role'
                                  ? true
                                  : false
                              }
                              name="permissions"
                              initialValue={row?.delete}
                            />
                          </div>
                        ),
                      },
                      {
                        name: (
                          <HemaValue
                            text={'Special'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        ),

                        selector: (row) => (
                          <FormSwitch
                            setFieldValue={(name, value) => {
                              setFieldValue(
                                'permissions',
                                values.permissions?.map((data) => {
                                  if (data.id === row.id) {
                                    return {
                                      ...row,
                                      special: value,
                                      read: value ? true : row.read,
                                    };
                                  } else {
                                    return data;
                                  }
                                })
                              );
                            }}
                            disabled={
                              uisettings?.formName === 'delete role'
                                ? true
                                : false
                            }
                            name="permissions"
                            initialValue={row?.special}
                          />
                        ),
                      },
                    ]}
                  />
                ) : (
                  <BootstrapAlert
                    variant="warning"
                    className="mt-3 text-center"
                  >
                    No Area Exists for permission
                  </BootstrapAlert>
                )}
                {errors.permissions && touched.permissions && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.permissions}
                  </div>
                )}
              </div>

              <div className="w-full  bg-white p-[16px] rounded-[5px]">
                {uisettings?.formName === 'add-new-role' ||
                uisettings?.formName === 'copy role' ? (
                  ''
                ) : (
                  <div className=" gap-[15px] items-center w-full block mb-[16px]">
                    <HemaLabel
                      text={'Change Reason'}
                      icon={<ChangeReason />}
                      required={true}
                      className={`mb-[10px]`}
                    />

                    <FormTextarea
                      placeholder="Type reason"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={'changeReason'}
                      value={values?.changeReason}
                    />

                    {errors.changeReason && touched.changeReason && (
                      <div className="error text-[red] text-[12px] pt-[2px]">
                        {errors.changeReason}
                      </div>
                    )}
                  </div>
                )}

                <div className="flex gap-[8px] justify-end ">
                  <Button
                    cta={() => {
                      dispatch(setShowOrderConfig(false));
                    }}
                    type="button"
                    text="Cancel"
                    bg="bg-white"
                    border="border-primary1"
                    color="text-primary1"
                    icon={<Cancel />}
                  />

                  {isSubmitting ? (
                    <Button
                      type="button"
                      bg="bg-primary1"
                      text={
                        <>
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                        </>
                      }
                    />
                  ) : (
                    <Button
                      type="submit"
                      text={
                        uisettings?.formName === 'copy role' ||
                        uisettings?.formName === 'add-new-role'
                          ? 'Create'
                          : uisettings?.formName === 'delete role'
                          ? 'Confirm'
                          : 'Update'
                      }
                      bg="bg-primary1"
                      color="text-white"
                      icon={
                        uisettings?.formName === 'copy role' ||
                        uisettings?.formName === 'add-new-role' ? (
                          <Add color="#fff" />
                        ) : (
                          <Confirm />
                        )
                      }
                      disabled={
                        uisettings?.formName === 'delete role'
                          ? values.changeReason
                            ? false
                            : true
                          : uisettings?.formName === 'copy role'
                          ? false
                          : compareArrays(initialValues, values)
                          ? true
                          : Object.keys(errors)?.length
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RoleChanges;

import React from 'react';

export const LockFile = ({ color }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2008 7.2C15.2008 7.02 15.2008 7.02 15.1108 6.93V6.84C15.1108 6.75 15.0208 6.66 14.9308 6.57L8.63078 0.27C8.54078 0.18 8.45078 0.18 8.36078 0.0899999H8.27078C8.18078 0.0899999 8.09078 0.09 8.00078 0H4.40078C2.42078 0 0.800781 1.62 0.800781 3.6V14.4C0.800781 16.38 2.42078 18 4.40078 18H11.6008C13.5808 18 15.2008 16.38 15.2008 14.4V7.2ZM12.1408 6.3H10.7008C9.71078 6.3 8.90078 5.49 8.90078 4.5V3.06L12.1408 6.3ZM11.6008 16.2H4.40078C3.41078 16.2 2.60078 15.39 2.60078 14.4V3.6C2.60078 2.61 3.41078 1.8 4.40078 1.8H7.10078V4.5C7.10078 6.48 8.72078 8.1 10.7008 8.1H13.4008V14.4C13.4008 15.39 12.5908 16.2 11.6008 16.2Z"
        fill={color || 'url(#paint0_linear_4071_92142)'}
      />
      <path
        d="M9.98226 9.27C9.62226 8.91 9.08226 8.91 8.72226 9.27L8.00226 9.99L7.28226 9.27C6.92226 8.91 6.38226 8.91 6.02226 9.27C5.66226 9.63 5.66226 10.17 6.02226 10.53L6.74226 11.25L6.02226 11.97C5.66226 12.33 5.66226 12.87 6.02226 13.23C6.20226 13.41 6.47226 13.5 6.65226 13.5C6.83226 13.5 7.10226 13.41 7.28226 13.23L8.00226 12.51L8.72226 13.23C8.90226 13.41 9.17226 13.5 9.35226 13.5C9.53226 13.5 9.80226 13.41 9.98226 13.23C10.3423 12.87 10.3423 12.33 9.98226 11.97L9.26226 11.25L9.98226 10.53C10.3423 10.17 10.3423 9.63 9.98226 9.27Z"
        fill={color || 'url(#paint1_linear_4071_92142)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_4071_92142"
          x1="0.800781"
          y1="9"
          x2="15.2008"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4071_92142"
          x1="0.800781"
          y1="9"
          x2="15.2008"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};

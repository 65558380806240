import { Courier, FirstName, ItemName, PhoneNumber, Address, SiteName, ChangeReason } from '../../../HemeIconLibrary';

export const ReturnAirWay = [
  {
    label: '',
    initialValue: 'General Information',
    fluid: true,
    type: 'heading-only',
  },
  {
    label: 'Courier',
    icon: <Courier />,
    type: 'select',
    initialValue: '',
    placeholder: 'Courier',
    required: true,
    fluid: true,
    name: 'Courier',
  },
  {
    label: 'Courier Service Type',
    icon: <Courier />,
    type: 'select',
    initialValue: '',
    placeholder: 'Courier',
    required: true,
    fluid: true,
    name: 'CourierServiceTypeId',
  },
  {
    label: 'Inbound Condition',
    icon: <ItemName purple />,
    type: 'select',
    initialValue: '',
    placeholder: 'Inbound Condition',
    required: true,
    fluid: true,
    name: 'ShippingConditionId',
  },
  {
    label: 'Saturday Delivery',
    icon: <ItemName purple />,
    type: 'select',
    initialValue: '',
    placeholder: 'select saturday delivery',
    required: true,
    fluid: true,
    name: 'SaturdayDelivery',
  },

  {
    label: 'Reference (STUDY - SITE NUMBER)',
    icon: <PhoneNumber />,
    type: 'text',
    initialValue: '',
    placeholder: 'Reference (STUDY - SITE NUMBER)',
    required: true,
    fluid: true,
    name: 'reference',
  },
  {
    label: '',
    initialValue: 'Shipper Information',
    fluid: true,
    type: 'heading-only',
  },

  {
    label: 'Contact Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Contact Name',
    required: true,
    fluid: true,
    name: 'respientName',
  },
  {
    label: 'Site Number',
    icon: <SiteName purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Number',
    required: false,
    fluid: true,
    name: 'siteNumber',
    hidden: true,
  },
  {
    label: 'Site Name',
    icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Company Name',
    required: true,
    name: 'labName',
  },
  {
    label: 'Address',
    icon: <Address purple />,
    type: 'textarea',
    initialValue: '',
    placeholder: 'Site Address',
    fluid: true,
    required: true,
    name: 'siteAddress',
  },
  {
    label: 'Phone Number',
    icon: <PhoneNumber />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Phone Number',
    required: true,
    name: 'labPhoneNmber',
  },
  {
    label: '',
    initialValue: 'Recipient Information',
    fluid: true,
    type: 'heading-only',
  },
  {
    label: 'Contact Name',
    icon: <FirstName />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Contact Name',
    required: true,
    name: 'reqName',
  },

  {
    label: 'Company Name',
    icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    placeholder: 'SiteName',
    required: false,
    fluid: true,
    name: 'siteName',
  },
  {
    label: 'Address',
    icon: <Address purple />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    placeholder: 'Address',
    required: false,
    name: 'labAddress',
  },
  {
    label: 'Phone Number',
    icon: <PhoneNumber />,
    type: 'text',
    initialValue: '',
    placeholder: 'Phone Number',
    required: true,
    fluid: true,
    name: 'phoneNo',
  },
];

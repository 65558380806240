import React from 'react';

export const Courier = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22727 3.91016C2.55064 3.91016 2 4.46079 2 5.13743V9.77379C2 10.4504 2.55064 11.0011 3.22727 11.0011H3.42223C3.60206 11.6279 4.18189 12.092 4.86364 12.092C5.54538 12.092 6.12521 11.6279 6.30504 11.0011H8.68182C8.90764 11.0011 9.09091 10.8181 9.09091 10.592V5.13743C9.09091 4.46079 8.54027 3.91016 7.86364 3.91016H3.22727ZM9.63636 5.00107V10.592C9.63636 11.4155 10.3128 12.092 11.1364 12.092C11.8181 12.092 12.3979 11.6279 12.5778 11.0011H12.7727C13.4505 11.0011 14 10.4515 14 9.77379V8.03835C14 7.86653 13.9638 7.69659 13.894 7.53977L13.0897 5.73029C12.8928 5.28684 12.4533 5.00107 11.9684 5.00107H9.63636ZM10.7273 5.81925H11.9684C12.1301 5.81925 12.2763 5.91433 12.3418 6.06214L12.7903 7.07209C12.8705 7.25236 12.7387 7.45561 12.5415 7.45561H10.7273C10.5767 7.45561 10.4545 7.33343 10.4545 7.18288V6.09197C10.4545 5.94143 10.5767 5.81925 10.7273 5.81925ZM4.86364 9.91016C5.24447 9.91016 5.54545 10.2111 5.54545 10.592C5.54545 10.9728 5.24447 11.2738 4.86364 11.2738C4.4828 11.2738 4.18182 10.9728 4.18182 10.592C4.18182 10.2111 4.4828 9.91016 4.86364 9.91016ZM11.1364 9.91016C11.5172 9.91016 11.8182 10.2111 11.8182 10.592C11.8182 10.9728 11.5172 11.2738 11.1364 11.2738C10.7555 11.2738 10.4545 10.9728 10.4545 10.592C10.4545 10.2111 10.7555 9.91016 11.1364 9.91016Z"
        fill="url(#paint0_linear_5100_143373)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5100_143373"
          x1="2"
          y1="8.00106"
          x2="14"
          y2="8.00106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { setDropdownValue } from '../../Store/reducers/common';
const SingleSelect = (props) => {
  const { options, placeholder, setFieldValue, name, onChange, value, disabled, setFieldValueFull, className, dispatch, notRefresh, onChangeNew, formikRef } = props;
  const [selectOptions, setselectOptions] = useState([]);
  const [selectLabel, setSelectLabel] = useState(value !== '' ? value : placeholder);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#232323',
      fontWeight: '400',
      padding: '10px',
      backgroundColor: state.isSelected ? '#F1F1F1' : state.isFocused ? '#F1F1F1' : 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#232323',
      fontWeight: '500',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#232323',
      fontWeight: '500',
    }),
  };

  useEffect(() => {
    let label = placeholder;
    if (value !== '') {
      const selected = options?.find((ele) => ele.id === value);
      if (selected) {
        label = selected.name;
      }
    }
    if (!notRefresh) {
      setSelectLabel(label);
    }
    console.log(label);
  }, [placeholder, value]);
  useEffect(() => {
    if (options) {
      setselectOptions(
        options?.map((op) => {
          return {
            label: op.name,
            value: op.id,
            ...op,
          };
        }),
      );
    }
  }, [props.options]);
 // console.log('rror kit 1', formikRef);
  return (
    <div className="relative">
      <Select
        styles={customStyles}
        options={selectOptions}
        placeholder={selectLabel}
        value={placeholder}
        onChange={(e) => {
          if (e?.value) {
            if (setFieldValueFull) {
              setFieldValueFull(name, e);
            } else if (setFieldValue) {
              setFieldValue(name, e.value);
            }
          } else {
            if (setFieldValueFull) {
              setFieldValueFull(name, '');
            } else if (setFieldValue) {
              setFieldValue(name, '');
            }
          }

          if (dispatch) {
            dispatch(setDropdownValue({ ...e, name }));
          }
          if (onChangeNew) {
            onChangeNew();
          }
        }}
        isClearable
        isSearchable
        isDisabled={disabled}
        className="hema-single-select"
      />
      {props.crossIcon && !disabled && (
        <span
          onClick={() => {
            if (formikRef) {
              formikRef?.current?.setFieldTouched(name, true);
            }
            if (setFieldValue) {
              setFieldValue(name, '');
            } else if (setFieldValueFull) {
              setFieldValueFull(name, '');
            }
            if (onChangeNew) {
              onChangeNew();
            }

            if (dispatch) {
              dispatch(setDropdownValue({}));
            }
          }}
          className={`absolute ${name === 'shipper' ? 'top-[13.5px] right-[37.5px]' : 'top-[13px] right-[30px]'} cursor-pointer`}
        >
          {props.crossIcon}
        </span>
      )}
    </div>
  );
};

export default SingleSelect;

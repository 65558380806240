import React from 'react';

export const RedFIleIcon = ({ color }) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3977 3.60465V12.6C10.3977 15.0301 8.42771 17 5.99766 17C3.5676 17 1.59766 15.0301 1.59766 12.6V3.93333C1.59766 2.3133 2.91096 1 4.53099 1C6.15102 1 7.46432 2.3133 7.46432 3.93333V12.5566C7.46432 13.3666 6.80767 14.0233 5.99766 14.0233C5.18764 14.0233 4.53099 13.3666 4.53099 12.5566V4.72093"
        stroke={color || 'url(#paint0_linear_4263_34780)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4263_34780"
          x1="1.59766"
          y1="9"
          x2="10.3977"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE318A" />
          <stop offset="1" stopColor="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};

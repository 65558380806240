import React from "react";

export const Reports = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="layers-three-01">
        <path
          id="Vector"
          d="M15.0902 4.27255C15.0902 2.56549 13.7064 1.18164 11.9993 1.18164C10.2923 1.18164 8.90842 2.56549 8.90842 4.27255M15.0902 4.27255H8.90842M15.0902 4.27255L17.6672 4.27236C19.1801 4.22373 20.4471 5.40849 20.5 6.92127V20.1674C20.4479 21.6808 19.1807 22.8664 17.6672 22.8178H6.33282C4.81989 22.8664 3.55288 21.6817 3.5 20.1689V6.92127C3.55288 5.40849 4.81989 4.22373 6.33282 4.27236L8.90842 4.27255M7.36263 13.5454L10.4535 16.6363L16.6354 10.4545"
          stroke="url(#paint0_linear_13502_95194)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13502_95194"
          x1="3.5"
          y1="12.0004"
          x2="20.5"
          y2="12.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

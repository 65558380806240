import React from 'react';

export const LastActivity = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1812 2.79531H19.1586V0.75H17.1133V2.79531H6.88672V0.75H4.84141V2.79531H3.81875C2.69383 2.79531 1.77344 3.7157 1.77344 4.84062V21.2031C1.77344 22.328 2.69383 23.2484 3.81875 23.2484H20.1812C21.3062 23.2484 22.2266 22.328 22.2266 21.2031V4.84062C22.2266 3.7157 21.3062 2.79531 20.1812 2.79531ZM20.1812 21.2031H3.81875V9.95391H20.1812V21.2031ZM20.1812 7.90859H3.81875V4.84062H20.1812V7.90859Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Pdf, SelectUpload, DeletePurple, Delete } from '../../HemeIconLibrary';
import FormCheckbox from './checkbox';
import HemaLabel from '../HemaLabel';
import HemaValue from '../HemaValue';
import { deleteFileAction } from '../../Actions/inventory';
import {
  showErrorReducer,
  showSuccessReducer,
} from '../../Store/reducers/uiSettings';

const ImageUpload = (props) => {
  const openFile = useRef();
  const [imgData, setImgData] = useState();
  const [filedata, setFileData] = useState();
  const dispatch = useDispatch();
  const { uisettings } = useSelector((state) => state);
  const { setFieldValue, name, value, storageUrl, onClear, accept } = props;
  const [loader, setLoader] = useState(false);
  const renderImage = () => {
    if (imgData && value) {
      return (
        <div
          className={`flex items-center  gap-3  grow-[1]  ${
            name === 'fileId' ? 'justify-start ' : 'justify-between'
          }`}
        >
          {name === 'fileId' && (
            <div
              className={`flex gap-[5px] items-center ${
                name === 'fileId' &&
                ' p-[6px] border border-solid border-[#DEE2E6] rounded-[5px] '
              }`}
            >
              <img
                className={` ${
                  name === 'fileId' && 'w-[52px] h-[52px] rounde-[3px] '
                }`}
                src={imgData || value || storageUrl}
                alt=""
              />
            </div>
          )}
          <div
            className="cursor-pointer"
            onClick={() => {
              setImgData();
              setFileData();
              setFieldValue(name, '');
            }}
          >
            <Delete color="#F87FB1" />
          </div>
        </div>
      );
    } else if (storageUrl) {
      return (
        <div className="flex items-center justify-between grow-[1]">
          <div className="flex gap-[5px] items-center">
            <img className="w-[52px]" src={storageUrl} alt="" />
          </div>
          <div
            className="cursor-pointer"
            onClick={async () => {
              if (uisettings.formName === 'editItem') {
                setLoader(true);
                const result = await deleteFileAction(uisettings?.editForm?.id);
                setLoader(false);
                if (result.status === 200) {
                  onClear();
                  dispatch(
                    showSuccessReducer('Image is removed from this item.')
                  );
                }
              }
            }}
          >
            {loader ? <Spinner variant="sm" /> : <Delete color="#F87FB1"  />}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {(name === 'docFile' ||
        name === 'notebookdocs' ||
        name === 'StudyDocument' ||
        name === 'fileId' ||
        name === 'userFile') && (
        <div className="flex justify-center ">
          <input
            ref={openFile}
            type="file"
            {...props}
            className="hidden"
            onChange={(e) => {
              var fsize = e.target.files[0].size;
              if (fsize >= 15728640 && name === 'userFile') {
                // 15mb file isze
                dispatch(showErrorReducer('Max file size exceeded.'));
                setFieldValue(name, '');
                return;
              }
              if (
                fsize >= 15728640 &&
                (name === 'docFile' || name === 'fileId')
              ) {
                //2mb file size
                dispatch(showErrorReducer('Max file size exceeded.'));
                setFieldValue(name, '');
                return;
              }
              if (fsize >= 15728640 && name === 'notebookdocs') {
                dispatch(showErrorReducer('Max file size exceeded.'));
                setFieldValue(name, '');
                return;
              }
              if (fsize >= 15728640 && name === 'StudyDocument') {
                dispatch(showErrorReducer('Max file size exceeded.'));
                setFieldValue(name, '');
                return;
              }
              if (accept) {
                if (
                  !accept?.includes(e.target.files?.[0]?.type) &&
                  (name === 'userFile' ||
                    name === 'docFile' ||
                    name === 'fileId')
                ) {
                  dispatch(showErrorReducer('Invalid File selected.'));
                  setFieldValue(name, '');
                  return;
                }
              }
              const fileReader = new FileReader();
              fileReader.onload = () => {
                setImgData(fileReader.result);
              };
              fileReader.readAsDataURL(e.target.files[0]);
              setFileData(e.target.files[0]);
              setFieldValue(name, e.target.files[0]);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
            value={undefined}
          />
          <div
            onClick={() => openFile.current.click()}
            className=" min-h-[64px] grow-[1] cursor-pointer justify-center  px-[10px] py-[8px] flex flex-col items-center  border-[#DEE2E6] border-solid  font-medium text-[12px] leading-[15px] border rounded-[5px]"
          >
            {name === 'userFile' ? (
              <>
                <SelectUpload purple />
                <div className="font-medium text-[12px] text-subheadingColor leading-[18px]">
                  {/* Drag and Drop files here or <span className="text-[#605DAF]">Browse</span> */}
                  Click to upload or drag and drop
                </div>
                <div className="font-medium text-[12px] leading-[18px] text-[#F54C3B] mb-[6px]">
                  Max: 15MB, Files Supported: XLS, XLSX
                </div>
                <div className="flex flex-col items-center gap-1">
                  <div className="text-center">{filedata?.name}</div>
                  {renderImage()}
                </div>
              </>
            ) : name === 'notebookdocs' ? (
              <>
                <div className="w-[40px] h-[40px] bg-[#F2F4F7]  border-[6px] border-[#DEE2E6] border-solid rounded-full flex items-center justify-center">
                  <SelectUpload purple />
                </div>
                <div className="font-medium text-[12px] leading-[18px] text-[#232323] ">
                  Click to upload
                </div>
                <div className="font-medium text-[12px] leading-[18px] text-[#F54C3B] mb-[6px]">
                  PDF, Word Documents , Images etc. (max. 15.0 MB)
                </div>
                <div className="flex items-center">
                  {value?.name}
                  {renderImage()}
                </div>
              </>
            ) : name === 'StudyDocument' ? (
              <>
                <div className="w-[40px] h-[40px] bg-[#F2F4F7]  border-[6px] border-[#DEE2E6] border-solid rounded-full flex items-center justify-center">
                  <SelectUpload purple />
                </div>
                <div className="font-medium text-[12px] leading-[18px] text-[#232323] ">
                  Click to upload
                </div>
                <div className="font-medium text-[12px] leading-[18px] text-[#F54C3B] mb-[6px]">
                  PDF, Word Documents , Images etc. (max. 15.0 MB)
                </div>
                <div className="flex items-center">
                  {value?.name}
                  {renderImage()}
                </div>
              </>
            ) : (
              <>
                <SelectUpload />
                <div className="font-medium text-xs text-[#232323] my-[2px]">
                  Upload the image
                </div>
                <div className="font-medium text-xs text-[#F54C3B] mb-[6px]">
                  {' '}
                  {name === 'docFile'
                    ? 'PDF,word documents,jpeg, jpg, png (max, 2.0 MB)'
                    : 'Max 2MB, Support: jpeg, jpg, png, gif'}
                </div>
                {name === 'docFile' && (
                  <div className="flex flex-col items-center gap-1">
                    <div className="mt-1 text-center">{filedata?.name}</div>
                    {renderImage()}
                  </div>
                )}
              </>
            )}
          </div>

          {name === 'fileId' && (
            <div
              className={`flex flex-col-reverse gap-1 ml-4 ${
                !filedata?.name && !storageUrl && 'hidden'
              }`}
            >
              <p className="font-medium text-xs text-[#232323] m-0">
                {filedata?.name}
              </p>

              {renderImage()}
            </div>
          )}
        </div>
      )}

      {(name === 'attachFile' || name === 'userFile') && (
        <div className="h-[64p] mt-3 cursor-pointer px-[10px] flex flex-col gap-3 justify-between items-center font-medium text-[12px] leading-[15px] ">
          {value?.length
            ? value?.map((v, index) => {
                return (
                  <div
                    className={` w-full flex justify-between rounded-[5px] border-solid p-[9px]  ${
                      v?.checked
                        ? 'border-[1px]  border-[#605DAF]'
                        : 'border-[1px]  border-[#DEE2E6]'
                    } `}
                    onClick={() => {
                      setFieldValue(
                        'attachFile',
                        value?.map((v, counter) => {
                          if (counter === index) {
                            return {
                              ...v,
                              checked: !v?.checked,
                            };
                          } else {
                            return {
                              ...v,
                              checked: false,
                            };
                          }
                        })
                      );
                    }}
                  >
                    <div className="flex ">
                      <Pdf />
                      <div className="ml-5">
                        <HemaLabel
                          text={v?.pdfFile?.friendlyName}
                          className="mb-[10px]"
                        />
                        <HemaValue text={v?.pdfFile?.uniqueName} />
                      </div>
                    </div>
                    <div className=" w-5 h-5 rounded-full border-[1px] overflow-hidden border-[#DEE2E6]">
                      {/* <div className="container"> */}
                      <FormCheckbox
                        checked={v?.checked}
                        type="checkbox"
                        className="file-checkbox "
                      />
                      {/* <span className="checkmark"></span> */}
                      {/* </div> */}
                    </div>
                  </div>
                );
              })
            : name !== 'userFile' && (
                <HemaValue
                  className="text-center"
                  text="There are no existing document available here"
                />
              )}
        </div>
      )}
    </>
  );
};
export default ImageUpload;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';
import { Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent } from '../../../utils';
import { ExpiryAlertPurple, DeletePurple, Edit, TableSortIcon, ChangeReason } from '../../../HemeIconLibrary';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import { sortedTableData } from '../../../helpers/sortedData';
import { sortedData } from '../../../helpers/sort';

import { addPortalUserFields } from '../../../components/Formik/AllForms/addPortalUserFields';
import { AddCourier, DeleteCourier } from '../../../components/Formik/AllForms/courierSettings';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { setallCouriersFilter } from '../../../Store/reducers/settings';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../../../Store/reducers/uiSettings';

// Actions
import { getCourierSettingsAction, updateCourierSettingsAction, deleteCourierAction } from '../../../Actions/settings';

// Utils
import { getFullName } from '../Utils';

const CourierSettings = ({ sites, setCTA, setformName, setFormIcon, setUpdatedData, setokBtnIcon, setokBtnText }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { isLoading, allCouriers, allCouriersFilter } = settings;

  // useEffect(() => {
  //   getCourierSettingsAction();
  // }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  //senpnarch for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(allCouriers, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(allCouriers);
      }
    })();
  }, [searchQuery, allCouriers]);

  const editSystemCourier = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await updateCourierSettingsAction(row.id, payload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Courier successfully edited.`));
        // getPortalUserAction();
      }
    });
  };

  const deleteCourierSetting = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deleteCourierAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`Courier deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  useEffect(() => {
    if (Object.keys(allCouriersFilter)?.length) {
      const filterResult = allCouriers?.filter((port) => {
        if (
          (allCouriersFilter.name?.length ? allCouriersFilter.name.includes(port.name) : true) &&
          (allCouriersFilter.accountNumber?.length ? allCouriersFilter.accountNumber?.includes(port.accountNumber) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setDataList(filterResult);
    } else {
      setDataList(allCouriers);
    }
  }, [JSON.stringify(allCouriersFilter)]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(allCouriersFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={allCouriers} resetRedux={setallCouriersFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Name'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="name"
                          setRedux={setallCouriersFilter}
                          reduxValues={allCouriersFilter || []}
                          options={Array.from(new Set(allCouriers?.map((filter) => filter.name)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.name} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Account Number'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="accountNumber"
                          setRedux={setallCouriersFilter}
                          reduxValues={allCouriersFilter || []}
                          options={Array.from(new Set(allCouriers.map((filter) => filter.accountNumber)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.accountNumber || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'accountNumber',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <HemaValue text={'Actions'} color="text-[#000000]" className="text-xs font-normal pr-7" />{' '}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.['Courier Settings']?.update && (
                          <Button
                            icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row));
                              setformName('Edit Courier');
                              setokBtnIcon();
                              setokBtnText('Update');
                              setFormIcon(<ExpiryAlertPurple />);
                              AddCourier[0].initialValue = row?.name;
                              AddCourier[1].initialValue = row?.accountNumber;
                              setUpdatedData(AddCourier);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'edit-courier',
                                }),
                              );
                              editSystemCourier(row);
                            }}
                          />
                        )}
                        {/* <Button
                            icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.['Courier Settings']?.delete && (
                          <Button
                            icon={<img src={deleteIcon} />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              DeleteCourier[0].initialValue = row?.name;
                              DeleteCourier[1].initialValue = row?.accountNumber;
                              setUpdatedData(DeleteCourier);
                              setokBtnIcon();
                              setokBtnText('Confirm');
                              setformName('Delete Courier');
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              );
                              deleteCourierSetting(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: 'visible !important' },
                },
              },

              cells: {
                style: { overflow: 'visible !important' },
              },

              responsiveWrapper: {
                style: { overflow: 'visible !important' },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <NoDataComponent text="There are no courier to show. Add by clicking on Add Courier." />
        )}
      </>
    </div>
  );
};

export default CourierSettings;

import React from 'react';

export const FirstName = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5039 4.73445C10.5039 6.04078 9.38462 7.09977 8.00391 7.09977C6.62319 7.09977 5.50391 6.04078 5.50391 4.73445C5.50391 3.42813 6.62319 2.36914 8.00391 2.36914C9.38462 2.36914 10.5039 3.42813 10.5039 4.73445Z"
        stroke="url(#paint0_linear_1986_75286)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74963 11.5981C3.73665 11.1841 3.3905 10.859 2.97649 10.872C2.56248 10.8849 2.23738 11.2311 2.25037 11.6451L3.74963 11.5981ZM6.07727 14.3688V13.6188C6.07056 13.6188 6.06385 13.6189 6.05714 13.6191L6.07727 14.3688ZM9.92273 14.3688L9.94286 13.6191C9.93615 13.6189 9.92944 13.6188 9.92273 13.6188V14.3688ZM13.7496 11.6451C13.7626 11.2311 13.4375 10.8849 13.0235 10.872C12.6095 10.859 12.2634 11.1841 12.2504 11.5981L13.7496 11.6451ZM12.2504 11.5199C12.2634 11.9339 12.6095 12.259 13.0235 12.246C13.4375 12.2331 13.7626 11.8869 13.7496 11.4729L12.2504 11.5199ZM9.92273 8.74921V9.49921C9.92944 9.49921 9.93615 9.49912 9.94286 9.49894L9.92273 8.74921ZM6.07727 8.74921L6.05714 9.49894C6.06385 9.49912 6.07056 9.49921 6.07727 9.49921V8.74921ZM2.25037 11.4729C2.23738 11.8869 2.56248 12.2331 2.97649 12.246C3.3905 12.259 3.73665 11.9339 3.74963 11.5199L2.25037 11.4729ZM2.25037 11.6451C2.31351 13.6582 4.07282 15.1729 6.09741 15.1185L6.05714 13.6191C4.77938 13.6534 3.78448 12.7093 3.74963 11.5981L2.25037 11.6451ZM6.07727 15.1188H9.92273V13.6188H6.07727V15.1188ZM9.90259 15.1185C11.9272 15.1729 13.6865 13.6582 13.7496 11.6451L12.2504 11.5981C12.2155 12.7093 11.2206 13.6534 9.94286 13.6191L9.90259 15.1185ZM13.7496 11.4729C13.6865 9.4598 11.9272 7.9451 9.90259 7.99948L9.94286 9.49894C11.2206 9.46462 12.2155 10.4087 12.2504 11.5199L13.7496 11.4729ZM9.92273 7.99921H6.07727V9.49921H9.92273V7.99921ZM6.09741 7.99948C4.07282 7.9451 2.31351 9.4598 2.25037 11.4729L3.74963 11.5199C3.78448 10.4087 4.77938 9.46462 6.05714 9.49894L6.09741 7.99948Z"
        fill="url(#paint1_linear_1986_75286)"
      />

      <defs>
        <linearGradient
          id="paint0_linear_1986_75286"
          x1="5.5"
          y1="4.36531"
          x2="10.5"
          y2="4.36531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1986_75286"
          x1="3"
          y1="11.1898"
          x2="13"
          y2="11.1898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

export const ActionCopy = ({ width, height, color }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 9.28022C19.9907 9.19856 19.9729 9.11811 19.9467 9.04022V8.96022C19.9039 8.86882 19.8469 8.78481 19.7778 8.71133L14.4444 3.37799C14.371 3.30885 14.287 3.25184 14.1956 3.20911C14.169 3.20534 14.1421 3.20534 14.1156 3.20911C14.0253 3.15732 13.9255 3.12408 13.8222 3.11133H10.2222C9.51498 3.11133 8.8367 3.39228 8.3366 3.89238C7.83651 4.39247 7.55556 5.07075 7.55556 5.778V6.66688H6.66667C5.95942 6.66688 5.28115 6.94784 4.78105 7.44793C4.28095 7.94803 4 8.62631 4 9.33355V18.2224C4 18.9297 4.28095 19.608 4.78105 20.1081C5.28115 20.6082 5.95942 20.8891 6.66667 20.8891H13.7778C14.485 20.8891 15.1633 20.6082 15.6634 20.1081C16.1635 19.608 16.4444 18.9297 16.4444 18.2224V17.3336H17.3333C18.0406 17.3336 18.7189 17.0526 19.219 16.5525C19.719 16.0524 20 15.3741 20 14.6669V9.33355C20 9.33355 20 9.33355 20 9.28022ZM14.6667 6.14244L16.9689 8.44466H15.5556C15.3198 8.44466 15.0937 8.35101 14.927 8.18431C14.7603 8.01761 14.6667 7.79152 14.6667 7.55577V6.14244ZM14.6667 18.2224C14.6667 18.4582 14.573 18.6843 14.4063 18.851C14.2396 19.0177 14.0135 19.1113 13.7778 19.1113H6.66667C6.43092 19.1113 6.20483 19.0177 6.03813 18.851C5.87143 18.6843 5.77778 18.4582 5.77778 18.2224V9.33355C5.77778 9.0978 5.87143 8.87171 6.03813 8.70501C6.20483 8.53831 6.43092 8.44466 6.66667 8.44466H7.55556V14.6669C7.55556 15.3741 7.83651 16.0524 8.3366 16.5525C8.8367 17.0526 9.51498 17.3336 10.2222 17.3336H14.6667V18.2224ZM18.2222 14.6669C18.2222 14.9026 18.1286 15.1287 17.9619 15.2954C17.7952 15.4621 17.5691 15.5558 17.3333 15.5558H10.2222C9.98647 15.5558 9.76038 15.4621 9.59368 15.2954C9.42698 15.1287 9.33333 14.9026 9.33333 14.6669V5.778C9.33333 5.54225 9.42698 5.31615 9.59368 5.14946C9.76038 4.98276 9.98647 4.88911 10.2222 4.88911H12.8889V7.55577C12.8889 8.26302 13.1698 8.94129 13.6699 9.44139C14.17 9.94149 14.8483 10.2224 15.5556 10.2224H18.2222V14.6669Z"
        fill={color || 'url(#paint0_linear_2595_229832)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2595_229832"
          x1="7.26431e-08"
          y1="9.00021"
          x2="16"
          y2="9.00021"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

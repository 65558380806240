import React from 'react';

export const SideMenuPin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="#C7C7C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4645 4.74445V5.37636L11.8595 6.16626L8.30494 8.53594H5.30334C4.95579 8.53594 4.77411 8.95458 5.03478 9.21525L7.51504 11.6955L4.35547 15.645L8.30494 12.4854L10.7852 14.9657C10.8395 15.022 10.9093 15.061 10.9858 15.0776C11.0623 15.0942 11.142 15.0877 11.2148 15.0589C11.2876 15.0301 11.3502 14.9804 11.3947 14.916C11.4391 14.8515 11.4634 14.7754 11.4645 14.6971V11.6955L13.8342 8.14099L14.6241 8.53594H15.256C15.6036 8.53594 15.7852 8.11729 15.5246 7.85663L12.1438 4.47588C12.0895 4.41952 12.0197 4.38057 11.9432 4.36397C11.8667 4.34737 11.787 4.35387 11.7142 4.38264C11.6414 4.41142 11.5788 4.46117 11.5344 4.52559C11.4899 4.59001 11.4656 4.66619 11.4645 4.74445Z"
        fill="#C7C7C7"
      />
    </svg>
  );
};

import React from 'react';
export const LabelQuantity = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4 2H6.2C6.04087 2 5.88826 2.06321 5.77574 2.17574C5.66321 2.28826 5.6 2.44087 5.6 2.6V5H4.4C4.24087 5 4.08826 5.06321 3.97574 5.17574C3.86321 5.28826 3.8 5.44087 3.8 5.6V8H2.6C2.44087 8 2.28826 8.06321 2.17574 8.17574C2.06321 8.28826 2 8.44087 2 8.6V13.4C2 13.5591 2.06321 13.7117 2.17574 13.8243C2.28826 13.9368 2.44087 14 2.6 14H7.4C7.55913 14 7.71174 13.9368 7.82426 13.8243C7.93679 13.7117 8 13.5591 8 13.4V12.2H10.4C10.5591 12.2 10.7117 12.1368 10.8243 12.0243C10.9368 11.9117 11 11.7591 11 11.6V10.4H13.4C13.5591 10.4 13.7117 10.3368 13.8243 10.2243C13.9368 10.1117 14 9.95913 14 9.8V2.6C14 2.44087 13.9368 2.28826 13.8243 2.17574C13.7117 2.06321 13.5591 2 13.4 2ZM6.8 12.8H3.2V9.2H6.8V12.8ZM9.8 11H8V8.6C8 8.44087 7.93679 8.28826 7.82426 8.17574C7.71174 8.06321 7.55913 8 7.4 8H5V6.2H9.8V11ZM12.8 9.2H11V5.6C11 5.44087 10.9368 5.28826 10.8243 5.17574C10.7117 5.06321 10.5591 5 10.4 5H6.8V3.2H12.8V9.2Z"
        fill="url(#paint0_linear_1188_33210)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_33210"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

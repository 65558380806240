import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { HemaDateFormat } from "../helpers/commonHelper";
import NavDropdown from "react-bootstrap/NavDropdown";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import HemaHeadingWithSubText from "../utils/HemaHeadingWithSubText";
import { Button, HemaValue, Alert, NoPermissionAlert, FilterColumn } from "../utils";

import {
  ReadyIcon,
  ActionCursor,
  UsedGradientInventory,
  MoveInventory,
  LostInventory,
  ExpiryAlertPurple,
  DeletePurple,
  TickPurple,
  DisposeInventory,
  ExpireInventory,
  TabDamaged,
  DamageInventory,
  TapLost,
  TabExpired,
  TabDeleted,
  TabDisposed,
  DotsIcon,
  PreviousIcon,
  CreateItemPurple,
  Confirm,
  AddInventoryPurple,
  Add,
  Assemblies,
  TableSortIcon,
} from "../HemeIconLibrary";

import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from "../Store/reducers/uiSettings";
import { setThresholdReducer } from "../Store/reducers/common";
import { setFilterALL } from "../Store/reducers/items";

import { getType } from "../helpers/getType";

import {
  getAllInventoriesAction,
  updateAllItemInventoryAction,
  deleteItemInventoryAction,
  updateExpiryAlertAction,
  getAllItemAction,
  getItemByIdAction,
  getInventoryDetailByIdAction,
  updateNewItemAction,
  createNewItemAction,
  uploadFileItemAction,
  addItemInventoryAction,
} from "../Actions/inventory";

import { getItemCategoryByIdAction } from "../Actions/itemCategory";
import { getSystemUserAction } from "../Actions/settings";
import { buildNewKitAction } from "../Actions/kitBuilder";
import { getTypesAction, getAllItemCategoriesAction } from "../Actions/common";

import FormContainer from "../components/Formik/formContainer";

import { ConfirmKitBuilder } from "../components/Formik/AllForms/confirmKitBuilder";
import { quantityChcek } from "../components/Formik/allFormValidation";
import { inventoryKitBuilder } from "../components/Formik/AllForms/inventoryKitbuilder";
import { addNewInventoryCheck } from "../components/Formik/AllForms/addInventoryCheck";
import createNewItemValidator from "../components/Formik/allFormValidation/createItem";
import { createNewItem } from "../components/Formik/AllForms/createNewItem";
import { addNewInventory } from "../components/Formik/AllForms/addInventory";
import { useInventory } from "../components/Formik/AllForms/useInventory";
import { useInventoryAll } from "../components/Formik/AllForms/useInventoryAll";
import { deleteInventory } from "../components/Formik/AllForms/deleteInvetory";
import { moveInventoryForm } from "../components/Formik/AllForms/moveInvenotry";
import { editExpiryAlertForm } from "../components/Formik/AllForms/editExpiryAlert";
import { sortingData } from "../components/Tables/inventoryDataTable";
import Hemadatatable from "../components/datatables/itemdetailHematable";

const getStatus = (type) => {
  let text = "";
  switch (type) {
    case "use":
      text = "used";
      break;
    case "loss":
      text = "marked as lost";
      break;
    case "damage":
      text = "damaged";
      break;
    case "delete":
      text = "deleted";
      break;
    case "dispose":
      text = "disposed";
      break;
    case "change-location":
      text = "moved from";
      break;
    default:
      text = "";
  }
  return text;
};

const InventoryDetail = () => {
  const dispatch = useDispatch();
  const { uisettings, allItems, common, settings, storageLocation } = useSelector((state) => state);
  let { id } = useParams();

  /* form states */
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [catType, setcatType] = useState("");
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = useState("");
  const [activeTab, setactiveTab] = useState("Ready");
  const [filteredData, setfilteredData] = useState([]);
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [cancelBtnText, setCancelBtnText] = useState();
  const [cancelBtnIcon, setSancelBtnIcon] = useState();
  const [activeFilterTab, setActiveFilterTab] = useState();
  const [activeFilterOriginalData, setActiveFilterOriginalData] = useState();

  useEffect(() => {
    setActiveFilterTab(allItems?.itemReadyInventory);
    setActiveFilterOriginalData(allItems?.itemReadyInventory);
  }, [allItems?.itemReadyInventory]);

  useEffect(() => {
    (async () => {
      const typeCheck = await getItemByIdAction(id);
      if (typeCheck?.status === 200) {
        setcatType(typeCheck?.data.itemCategory.name);
        if (typeCheck?.data.itemCategory.name === "H-kit") {
          getAllInventoriesAction(id, "kits", "inventory-management", "ready");
          getAllInventoriesAction(id, "kits", "inventory-management", "expired");
          getAllInventoriesAction(id, "kits", "inventory-management", "damaged");
          getAllInventoriesAction(id, "kits", "inventory-management", "lost");
          getAllInventoriesAction(id, "kits", "inventory-management", "disposed");
          getAllInventoriesAction(id, "kits", "inventory-management", "deleted");
          getAllInventoriesAction(id, "kits", "inventory-management", "used");
        } else {
          getAllInventoriesAction(id, "items", "inventories", "ready");
          getAllInventoriesAction(id, "items", "inventories", "expired");
          getAllInventoriesAction(id, "items", "inventories", "damaged");
          getAllInventoriesAction(id, "items", "inventories", "lost");
          getAllInventoriesAction(id, "items", "inventories", "disposed");
          getAllInventoriesAction(id, "items", "inventories", "deleted");
          getAllInventoriesAction(id, "items", "inventories", "used");
        }
      }

      getAllItemAction();
      getTypesAction();
      getAllItemCategoriesAction();
      getSystemUserAction();
    })();
  }, []);
  const updateItemColumn = (options) => {
    const InventoryColumns = [
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Item Name"} className="text-xs font-normal whitespace-pre" color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="name"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.item?.name)))}
              />
            </div>
          </div>
        ),
        sortable: true,

        selector: (row) => <HemaValue text={row.item?.name} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "string", "item", "name"),
        sortId: "item.name",
      },
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Location"} className="text-xs font-normal " color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="location"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.location?.name)))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={row.location?.name} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "string", "location", "name"),
        sortId: "location.name",
      },
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Lot Number "} className="text-xs font-normal whitespace-pre" color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="lotNumber"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.lotNumber)))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={row.lotNumber} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "number", "lotNumber"),
        sortId: "lotNumber",
      },
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Expiration Date"} className="text-xs font-normal whitespace-pre" color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="expirationDate"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.expirationDate?.split("T")?.[0])))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={HemaDateFormat(row.expirationDate)} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "number", "expirationDate"),
        sortId: "expirationDate",
      },
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Quantity"} className="text-xs font-normal " color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="quantity"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.quantity)))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={row.quantity} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "number", "quantity"),
        sortId: "quantity",
      },

      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Action By"} className="text-xs font-normal " color="text-[#000000]" />{" "}
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="createdBy"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter.createdBy?.name)))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={row.createdBy?.name} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "string", "createdBy", "name"),
        sortId: "createdBy.name",
      },
      {
        name: (
          <div className="w-full">
            <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
              <HemaValue text={"Action At"} className="text-xs font-normal " color="text-[#000000]" />
              <span className="short-icon">
                <TableSortIcon />
              </span>
            </div>
            <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
              <FilterColumn
                columnName="createdUtc"
                setRedux={setFilterALL}
                reduxValues={allItems.allFilters || []}
                options={Array.from(new Set(options?.map((filter) => filter?.createdUtc?.split("T")?.[0])))}
              />
            </div>
          </div>
        ),
        sortable: true,
        selector: (row) => <HemaValue text={HemaDateFormat(row.createdUtc)} className="text-xs font-normal " />,
        sortFunction: (rowA, rowB) => sortingData(rowA, rowB, "string", "createdUtc"),
        sortId: "createdUtc",
      },
    ];
    return InventoryColumns;
  };

  // filter assebley template
  useEffect(() => {
    if (Object.keys(allItems?.allFilters)?.length) {
      const filterResult = activeFilterOriginalData.filter((port) => {
        if (
          (allItems.allFilters?.name?.length ? allItems.allFilters?.name.includes(port?.item?.name) : true) &&
          (allItems.allFilters?.location?.length ? allItems.allFilters?.location.includes(port.location?.name) : true) &&
          (allItems.allFilters?.lotNumber?.length ? allItems.allFilters?.lotNumber.includes(port.lotNumber) : true) &&
          (allItems.allFilters?.expirationDate?.length ? allItems.allFilters?.expirationDate.includes(port.expirationDate?.split("T")?.[0]) : true) &&
          (allItems.allFilters?.quantity?.length ? allItems.allFilters?.quantity.includes(port.quantity) : true) &&
          (allItems.allFilters?.createdUtc?.length ? allItems.allFilters?.createdUtc.includes(port.createdUtc?.split("T")?.[0]) : true) &&
          (allItems.allFilters?.createdBy?.length ? allItems.allFilters?.createdBy.includes(port.createdBy?.name) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setActiveFilterTab(filterResult);
    } else {
      setActiveFilterTab(activeFilterOriginalData);
    }
  }, [JSON.stringify(allItems.allFilters)]);

  // control item thresold switch
  useEffect(() => {
    if (common.switch.threshold) {
      editExpiryAlertForm[6].required = true;
      editExpiryAlertForm[6].disabled = false;
      editExpiryAlertForm[7].initialValue = [];
      editExpiryAlertForm[7].disabled = false;

      addNewInventory[3].required = true;
      addNewInventory[3].disabled = false;
      addNewInventory[8].required = true;
      addNewInventory[8].disabled = false;
      addNewInventory[9].disabled = false;

      createNewItem[7].required = true;
      createNewItem[7].disabled = false;
      createNewItem[8].disabled = false;
    } else {
      editExpiryAlertForm[6].required = false;
      editExpiryAlertForm[6].disabled = true;
      editExpiryAlertForm[7].initialValue = [];
      editExpiryAlertForm[7].disabled = true;

      if (!storageLocation?.itemCategories?.fields?.filter((data) => data.name === "Expiration Date" && data.isRequired)?.length) {
        addNewInventory[3].required = false;
      }
      addNewInventory[8].required = false;
      addNewInventory[8].disabled = true;
      addNewInventory[9].disabled = true;

      createNewItem[7].required = false;
      createNewItem[7].disabled = true;
      createNewItem[8].disabled = true;
    }
  }, [common.switch]);

  const addInventoryCTAUse = (item, state) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const createNewIem = await updateAllItemInventoryAction(id, item.id, state, {
        ...data,
        locationId: data?.locationId?.id,
      });
      if (createNewIem?.status === 200) {
        dispatch(setFormCloseReducer());
        if (state === "change-location") {
          dispatch(showSuccessReducer(`${data.qty} ${data.name} ${getStatus(state)} ${data?.location} to ${data?.locationId?.name}.`));
        } else {
          dispatch(showSuccessReducer(`${data.qty} ${data.name} ${getStatus(state)}.`));
        }
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const expireInventoryCTA = (item, state) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const createNewIem = await updateAllItemInventoryAction(id, item.id, state, data);
      if (createNewIem?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${data.qty} ${data.name} expired.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const deleteInventoryCTA = (item, state) => {
    setCTA(() => async (data) => {
      console.log(data);
      dispatch(setFormLoaderReducer(true));
      const createNewIem = await deleteItemInventoryAction(id, item.id, data?.change_reason);
      if (createNewIem?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${data.qty} ${data.name} deleted.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const expireAlertInventoryCTA = (item, state) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const createNewIem = await updateExpiryAlertAction(id, item.id, {
        ...data,
        closeToExpiryNumberOfDays: data.closeToExpiryAlert ? data.closeToExpiryNumberOfDays : undefined,
        userIds: data.userIds?.map((ids) => ids.id),
      });
      if (createNewIem?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Expiry alert for lot number ${item?.lotNumber} has been updated`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };
  useEffect(() => {
    const regex = new RegExp(searchQuery, "i");
    (async () => {
      if (activeTab === "Ready") {
        const data = allItems?.itemReadyInventory?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true;
            }
          }
          return false;
        });
        setfilteredData(data);
      } else {
        setfilteredData(allItems?.itemReadyInventory);
      }
    })();
  }, [searchQuery, allItems?.itemReadyInventory]);

  ///

  // CTA item add
  const addInventoryCTA = (edit) => {
    setCTA(() => async (data) => {
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const imageToUpload = data.fileId;
        let payload = JSON.parse(JSON.stringify(data));
        payload.fileId = undefined;
        const editIem = await updateNewItemAction(edit, {
          ...payload,
          lowInventoryThreshold: !payload?.lowInventoryAlert ? undefined : payload.lowInventoryThreshold,
          userIds: !payload?.lowInventoryAlert ? undefined : payload?.userIds?.map((data) => data.id),
          unitTypeId: payload?.unitTypeId ? payload.unitTypeId : undefined,
        });
        if (editIem?.data?.id) {
          if (imageToUpload) {
            const imageCreation = await uploadFileItemAction(editIem.data?.id, imageToUpload);
            // if (imageCreation.data.id) {
            dispatch(setFormCloseReducer());
            // }
          } else {
            dispatch(setFormCloseReducer());
          }
        }
        dispatch(setFormLoaderReducer(false));
        dispatch(showSuccessReducer(`${editIem.data.name} item updated.`));
        getAllItemAction();
      } else {
        dispatch(setFormLoaderReducer(true));
        const imageToUpload = data.fileId;
        let payload = JSON.parse(JSON.stringify(data));
        payload.fileId = undefined;
        const createNewIem = await createNewItemAction({
          ...payload,
          lowInventoryThreshold: !payload?.lowInventoryAlert ? undefined : payload.lowInventoryThreshold,
          userIds: !payload?.lowInventoryAlert ? undefined : payload?.userIds?.map((data) => data.id),

          unitTypeId: payload?.unitTypeId ? payload.unitTypeId : undefined,
        });
        if (createNewIem?.status === 200) {
          if (imageToUpload) {
            const imageCreation = await uploadFileItemAction(createNewIem.data?.id, imageToUpload);
            // if (imageCreation.data.id) {
            //dispatch(setFormCloseReducer());
            // }
          }
          // else {
          //   dispatch(setFormCloseReducer());
          // }
          dispatch(showSuccessReducer(`${createNewIem.data.name} item created.`));
          getAllItemAction();
          setformName("Add Inventory");
          setFormIcon(<AddInventoryPurple />);
          setokBtnText("Yes");
          setCancelBtnText("No");
          dispatch(setThresholdReducer(false));
          setUpdatedData([
            {
              label: "Would you like to add inventory to the item you just created?",
              initialValue: "",
              name: "expire",
              fluid: true,
              type: "",
              noCapital: true,
            },
          ]);
          setCTA(() => async (data) => {
            const result = await getItemCategoryByIdAction(createNewIem?.data?.itemCategory?.id);

            var customEnventory = [];
            result.data?.fields?.forEach((field, index) => {
              var obj = {};
              if (!field.isStandard) {
                obj.type = getType(field.format?.name);
                obj.name = field.id || undefined;
                obj.label = field.name || undefined;
                obj.initialValue = field.value || undefined;
                obj.required = field.isRequired || false;
                obj.placeholder = field.placeholder || field.name;
                obj.options = field.options || [];
                customEnventory.push(obj);
              }
            });

            addNewInventory[0].initialValue = createNewIem?.data.id;
            addNewInventory[0].placeholder = createNewIem?.data.name;
            addNewInventory[0].disabled = true;
            addNewInventory[7].initialValue = false;

            addNewInventory[4].required = result.data?.fields?.filter((data) => data.mergeFieldKey === "SerialNumber")?.[0]?.isRequired || false;

            addNewInventory[5].required = result.data?.fields?.filter((data) => data.mergeFieldKey === "Barcode")?.[0]?.isRequired || false;

            addNewInventory[7].required = result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;
            addNewInventory[7].initialValue = result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;
            addNewInventory[7].disabled = !result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;

            addNewInventory[8].required = result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;
            addNewInventory[8].disabled = !result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;

            addNewInventory[3].required = result.data?.fields?.filter((data) => data.mergeFieldKey === "CloseToExpiryAlert")?.[0]?.isRequired || false;

            setokBtnText("Save");
            setCancelBtnText("Cancel");
            setUpdatedData([...addNewInventory, ...customEnventory]);
            addInventoryForItemCTA();
            dispatch(setForm({ state: true, type: "addInventory" }));
          });
        }

        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation((error, values) => (error, values) => {
      createNewItemValidator(error, values);
    });
  };

  // CTA for inventory for selected item
  const addInventoryForItemCTA = () => {
    setCTA(() => async (id, data, values) => {
      dispatch(setFormLoaderReducer(true));

      delete data.StorageLocation;
      const createNewIem = await addItemInventoryAction(id, {
        fields: data,
        userIds: !values?.CloseToExpiryAlert ? undefined : values?.userIds?.map((d) => d.id) || [],
      });
      if (createNewIem?.data?.id) {
        getAllItemAction();
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${createNewIem.data.quantity} ${createNewIem.data.item?.name} added.`));
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const callCreateNewItem = () => {
    createNewItem[4].options = common.allTypes;
    createNewItem[1].options = common.allCategories;
    createNewItem[0].disabled = false;
    createNewItem[1].disabled = false;
    createNewItem[0].initialValue = "";
    createNewItem[1].initialValue = "";
    createNewItem[2].initialValue = "";
    createNewItem[3].storageUrl = "";
    createNewItem[5].initialValue = "";
    createNewItem[1].placeholder = "Select category";
    createNewItem[4].placeholder = "Select unit Type";
    createNewItem[4].initialValue = "";
    createNewItem[6].initialValue = false;
    createNewItem[7].initialValue = "";
    createNewItem[7].disabled = true;
    createNewItem[8].disabled = true;
    createNewItem[8].initialValue = [];
    createNewItem[8].options = settings.systemUsers?.map((data) => {
      return { id: data.id, name: data.firstName + " " + data.lastName };
    });
    setformName("Create New Item");
    setFormIcon(<CreateItemPurple />);
    setokBtnText("Save");
    setokBtnIcon(<Confirm />);
    setCancelBtnText("Cancel");
    // createNewItem = createNewItem?.filter((item) => item.name !== "change_reason");
    setUpdatedData(createNewItem?.filter((item) => item.name !== "change_reason"));
    addInventoryCTA();
    dispatch(setForm({ state: true, type: "additem" }));
  };

  const addkitTemplate = async (data, store) => {
    dispatch(setFormLoaderReducer(true));
    const { uisettings, builder } = store;
    const createNewIem = await buildNewKitAction(uisettings.editForm?.kitId, {
      qty: uisettings.editForm?.selectedQuantity,
      storageLocationId: data?.locationId?.id,
      closeToExpiryAlert: data.closeToExpiryAlert,
      closeToExpiryDays: data.closeToExpiryAlert ? data.closeToExpiryNumberOfDays : undefined,
      items: builder.buildKit?.items?.map((item) => {
        return item.lots.map((lot, counter) => {
          if (counter === 0) {
            return { id: lot.id };
          }
        })?.[0];
      }),
    });
    if (createNewIem?.status === 200) {
      dispatch(setFormCloseReducer());
      dispatch(showSuccessReducer(`${createNewIem?.data?.quantity} ${createNewIem?.data?.item?.name} Kit added.`));
    }
    dispatch(setFormLoaderReducer(false));
  };

  ///

  if (!common.permissions?.["Inventory Management"]?.read) {
    return <NoPermissionAlert />;
  }
  return (
    <>
      <div className="flex gap-[10px] justify-between items-center">
        <HemaHeadingWithSubText heading="Inventory Management" sub="Manage your inventory here." />
        <div className="flex gap-[10px] ">
          <Link to="/inventory-management">
            {" "}
            <Button text="Back" icon={<PreviousIcon color="#fff" />} className="text-white bg-primary1" />
          </Link>
        </div>
        {/* <div className="flex gap-[10px] ">
          <Link to="/inventory-management">
            {' '}
            <Button text="Back" icon={<PreviousIcon color="#fff" />} className="text-white bg-primary1" />
          </Link>
          {allItems?.allItems?.length > 0 ? (
            <Button
              text="Add Inventory"
              icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                addNewInventoryCheck[0].options = allItems?.allItems;
                addNewInventoryCheck[0].disabled = false;
                addNewInventoryCheck[0].placeholder = 'Select an item';
                addNewInventoryCheck[0].initialValue = '';
                addNewInventory[7].initialValue = false;
                addNewInventory[8].disabled = true;

                addNewInventory[8].required = false;
                addNewInventory[3].required = false;

                setCTA(() => async (data) => {
                  const itemFound = allItems?.allItems.filter((item) => item.id === data.itemName)?.[0];
                  if (itemFound) {
                    if (itemFound.itemCategory?.name === 'H-Kit') {
                      // const row = builder?.allTemplates?.filter(
                      //   (kit) => kit.id === itemFound?.kitId
                      // )?.[0];
                      //if (itemFound) {
                      dispatch(editFormReducer(itemFound));
                      //  }
                      inventoryKitBuilder[0].initialValue = itemFound.name;

                      setformName(`Build Kit`);
                      setFormIcon(<AddInventoryPurple />);
                      setUpdatedData(inventoryKitBuilder);
                      // addInventoryForItemCTA();
                      dispatch(setForm({ state: true, type: 'addKit' }));
                      setFormValidation(() => (error, values, store) => {
                        quantityChcek(error, values, store);
                      });
                      setokBtnText('Build');
                      setCTA(() => async (data) => {
                        dispatch(
                          editFormReducer({
                            ...itemFound,
                            selectedQuantity: data?.qty,
                          }),
                        );
                        setformName(`Build Kit`);
                        setformName(`${itemFound.name} Kit Template`);
                        setFormIcon(<AddInventoryPurple />);
                        dispatch(
                          setForm({
                            state: true,
                            type: 'edit-kit-template',
                          }),
                        );
                        setCTA(() => async () => {
                          dispatch(
                            setForm({
                              state: true,
                              type: 'confirm-kit-template',
                            }),
                          );
                          setokBtnText('Confirm');

                          setUpdatedData(ConfirmKitBuilder);
                          setformName('Build Kit');
                          setFormIcon(<AddInventoryPurple />);
                          dispatch(setFormLoaderReducer(false));
                          setCTA(() => async (data, undefined, store) => {
                            addkitTemplate(data, store);
                          });
                        });
                      });
                    } else {
                      const result = await getItemCategoryByIdAction(itemFound.itemCategory.id);

                      var customEnventory = [];
                      result.data?.fields?.forEach((field, index) => {
                        var obj = {};
                        if (!field.isStandard) {
                          obj.type = getType(field.format?.name);
                          obj.name = field.id || undefined;
                          obj.label = field.name || undefined;
                          obj.initialValue = field.value || undefined;
                          obj.required = field.isRequired || false;
                          obj.placeholder = field.placeholder || field.name;
                          obj.options = field.options || [];
                          customEnventory.push(obj);
                        }
                      });

                      setformName('Add Inventory');
                      setFormIcon(<Assemblies purple />);
                      addNewInventory[0].initialValue = itemFound?.id;
                      addNewInventory[0].placeholder = itemFound.name;
                      addNewInventory[0].disabled = true;
                      addNewInventory[7].initialValue = false;
                      addNewInventory[8].disabled = true;
                      addNewInventory[9].disabled = true;
                      addNewInventory[9].options = settings?.systemUsers?.map((data) => {
                        return {
                          id: data.id,
                          name: data.firstName + ' ' + data.lastName,
                        };
                      });
                      addNewInventory[4].required = result.data?.fields?.filter((data) => data.mergeFieldKey === 'SerialNumber')?.[0]?.isRequired || false;

                      addNewInventory[5].required = result.data?.fields?.filter((data) => data.mergeFieldKey === 'Barcode')?.[0]?.isRequired || false;

                      addNewInventory[7].required = result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;
                      addNewInventory[7].initialValue = result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;
                      addNewInventory[7].disabled = !result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;

                      addNewInventory[8].required = result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;
                      addNewInventory[8].disabled = !result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;

                      addNewInventory[3].required = result.data?.fields?.filter((data) => data.mergeFieldKey === 'CloseToExpiryAlert')?.[0]?.isRequired || false;

                      setUpdatedData([...addNewInventory, ...customEnventory]);
                      addInventoryForItemCTA();
                      dispatch(setForm({ state: true, type: 'addInventory' }));
                    }
                  }
                });
                setformName('Add Inventory');
                setFormIcon(<Assemblies purple />);

                setUpdatedData(addNewInventoryCheck);
                dispatch(setForm({ state: true, type: 'addInventory1stStep' }));
                // setFormValidation(() => (error, values, store) => {
                //   negativeQuantityCheck(error, values);
                // });
                setokBtnIcon(<Add color="white" />);
                setokBtnText('Add');
              }}
            />
          ) : (
            <></>
          )}
          {common?.allTypes?.length > 0 && common?.allCategories?.length > 0 && (
            <Button
              text="Create New Item"
              icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                callCreateNewItem();
              }}
            />
          )}
        </div> */}
      </div>

      <Alert />
      <div className="bg-white rounded-[8px] pt-[14px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs tabs_head_space">
        <Tabs
          defaultActiveKey="Ready"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(key) => {
            setactiveTab(key);
            dispatch(setFilterALL([]));
            console.log(key);
            if (key === "Ready") {
              setActiveFilterTab(allItems?.itemReadyInventory);
              setActiveFilterOriginalData(allItems?.itemReadyInventory);
            } else if (key === "Used") {
              setActiveFilterTab(allItems?.itemUsedInventory);
              setActiveFilterOriginalData(allItems?.itemUsedInventory);
            } else if (key === "Damaged") {
              setActiveFilterTab(allItems?.itemDamagedInventory);
              setActiveFilterOriginalData(allItems?.itemDamagedInventory);
            } else if (key === "Lost") {
              setActiveFilterTab(allItems?.itemLostInventory);
              setActiveFilterOriginalData(allItems?.itemLostInventory);
            } else if (key === "Disposed") {
              setActiveFilterTab(allItems?.itemDisposedInventory);
              setActiveFilterOriginalData(allItems?.itemDisposedInventory);
            } else if (key === "Expired") {
              setActiveFilterTab(allItems?.itemExpiredInventory);
              setActiveFilterOriginalData(allItems?.itemExpiredInventory);
            } else if (key === "Deleted") {
              setActiveFilterTab(allItems?.itemDeletedInventory);
              setActiveFilterOriginalData(allItems?.itemDeletedInventory);
            }
          }}
        >
          <Tab
            eventKey="Ready"
            title={
              <div className="flex items-center gap-2 title-icon">
                {activeTab === "Ready" ? <ReadyIcon purple /> : <ReadyIcon />}
                Ready
              </div>
            }
          >
            <div className="inventory_management_ready_table">
              <Hemadatatable
                allItems={activeFilterTab || null}
                InventoryColumns={[
                  ...updateItemColumn(allItems?.itemReadyInventory),

                  {
                    style: {
                      maxWidth: "100%",
                      minWidth: "130px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    },
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal pr-7" />
                        </div>
                      </div>
                    ),
                    cell: (row) => {
                      return (
                        <div className="flex-grow four">
                          <div className="flex justify-start gap-[10px] meta">
                            {common?.permissions?.["Inventory Management"]?.update && (
                              <Button
                                icon={<ActionCursor />}
                                color="text-white"
                                bg="bg-bgActionDots"
                                cta={() => {
                                  useInventory[0].initialValue = row.item?.name;
                                  useInventory[1].initialValue = row.location?.name;
                                  useInventory[2].initialValue = row.lotNumber;
                                  useInventory[3].initialValue = row.expirationDate;
                                  useInventory[5].min = 1;
                                  setFormValidation(() => (errors, values) => {
                                    if (values.qty > row.quantity) {
                                      errors.qty = `Don't have enough quantity available`;
                                    }
                                  });
                                  setUpdatedData(useInventory);
                                  setformName("Use Inventory");
                                  setFormIcon(<TickPurple />);
                                  setokBtnText("Confirm");
                                  dispatch(editFormReducer(row));
                                  addInventoryCTAUse(row, "use");
                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: "use-inventory",
                                    }),
                                  );
                                }}
                              />
                            )}
                            {/* <Button icon={<Edit />} color="text-white" bg="bg-bgActionDots" /> */}
                            <NavDropdown
                              title={<Button icon={<DotsIcon />} color="text-white" bg="bg-bgActionDots" className="min-w-[28px] flex items-center justify-center flex-shrink-0" />}
                              // id="navbarScrollingDropdown"
                              className="hema-ellipse-dropdown"
                              disabled={
                                !(
                                  common?.permissions?.["Inventory Management"].update ||
                                  common?.permissions?.["Inventory Management"].delete ||
                                  common?.permissions?.["Inventory Management"].special
                                )
                              }
                            >
                              {common?.permissions?.["Inventory Management"]?.update && (
                                <>
                                  {" "}
                                  <NavDropdown.Item
                                    className="mb-1"
                                    onClick={() => {
                                      dispatch(editFormReducer(row));

                                      moveInventoryForm[0].initialValue = row.item.name;
                                      moveInventoryForm[1].initialValue = row.location?.name;
                                      moveInventoryForm[2].initialValue = row.lotNumber;
                                      moveInventoryForm[3].initialValue = row.expirationDate;
                                      moveInventoryForm[4].min = 1;
                                      setFormValidation(() => (errors, values) => {
                                        if (values.qty > row.quantity) {
                                          errors.qty = `Don't have enough quantity available`;
                                        }
                                      });
                                      setokBtnText("Confirm");
                                      setUpdatedData(moveInventoryForm);
                                      setformName("Move Inventory");
                                      setFormIcon(<MoveInventory />);
                                      addInventoryCTAUse(row, "change-location");
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "change-location-inventory",
                                        }),
                                      );
                                    }}
                                  >
                                    Move
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    className="mb-1"
                                    onClick={() => {
                                      useInventory[0].initialValue = row.item?.name;
                                      useInventory[1].initialValue = row.location?.name;
                                      useInventory[2].initialValue = row.lotNumber;
                                      useInventory[3].initialValue = row.expirationDate;
                                      setFormValidation(() => (errors, values) => {
                                        if (values.qty > row.quantity) {
                                          errors.qty = `Don't have enough quantity available`;
                                        }
                                      });
                                      setUpdatedData(useInventory);
                                      setokBtnText("Confirm");
                                      dispatch(editFormReducer(row));
                                      setformName("Damaged Inventory");
                                      setFormIcon(<DamageInventory />);
                                      addInventoryCTAUse(row, "damage");
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "use-inventory",
                                        }),
                                      );
                                    }}
                                  >
                                    Mark as Damaged
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    className="mb-1"
                                    onClick={() => {
                                      useInventory[0].initialValue = row.item?.name;
                                      useInventory[1].initialValue = row.location?.name;
                                      useInventory[2].initialValue = row.lotNumber;
                                      useInventory[3].initialValue = row.expirationDate;
                                      setUpdatedData(useInventory);
                                      dispatch(editFormReducer(row));
                                      addInventoryCTAUse(row, "loss");
                                      setformName("Lost Inventory");
                                      setokBtnText("Confirm");
                                      setFormIcon(<LostInventory />);
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "use-inventory",
                                        }),
                                      );
                                    }}
                                  >
                                    Mark as Lost
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    className="mb-1"
                                    onClick={() => {
                                      useInventory[0].initialValue = row.item?.name;
                                      useInventory[1].initialValue = row.location?.name;
                                      useInventory[2].initialValue = row.lotNumber;
                                      useInventory[3].initialValue = row.expirationDate;
                                      setUpdatedData(useInventory);
                                      setokBtnText("Confirm");
                                      dispatch(editFormReducer(row));
                                      addInventoryCTAUse(row, "dispose");
                                      setformName("Dispose Inventory");
                                      setFormIcon(<DisposeInventory />);
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "use-inventory",
                                        }),
                                      );
                                    }}
                                  >
                                    Dispose
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    disabled={row.expirationDate ? false : true}
                                    className="mb-1"
                                    onClick={async () => {
                                      const result = await getInventoryDetailByIdAction(id, row.id);
                                      dispatch(editFormReducer(row));
                                      editExpiryAlertForm[0].initialValue = row.item?.name;
                                      editExpiryAlertForm[1].initialValue = row.location?.name;
                                      editExpiryAlertForm[2].initialValue = row.lotNumber;
                                      editExpiryAlertForm[3].initialValue = row.expirationDate ? row.expirationDate.substring(0, 10) : "";
                                      editExpiryAlertForm[3].disabled = true;
                                      editExpiryAlertForm[4].initialValue = row.quantity;
                                      editExpiryAlertForm[6].min = 1;
                                      editExpiryAlertForm[7].options = settings.systemUsers
                                        ?.filter((active) => active.isActive)
                                        ?.map((data) => {
                                          return {
                                            id: data.id,
                                            name: data.firstName + " " + data.lastName,
                                          };
                                        });
                                      if (result.status === 200) {
                                        editExpiryAlertForm[5].initialValue = result?.data?.closeToExpiryAlert;
                                        editExpiryAlertForm[6].initialValue = result?.data?.closeToExpiryNumberOfDays;

                                        editExpiryAlertForm[7].initialValue = result?.data?.users;

                                        editExpiryAlertForm[6].required = result?.data?.closeToExpiryAlert ? true : false;
                                        editExpiryAlertForm[6].disabled = result?.data?.closeToExpiryAlert ? false : true;
                                        editExpiryAlertForm[7].disabled = result?.data?.closeToExpiryAlert ? false : true;
                                      }
                                      setokBtnText("Confirm");
                                      expireAlertInventoryCTA(row, "expire");
                                      setformName("Edit Expiry Alert");
                                      setFormIcon(<ExpiryAlertPurple />);

                                      setUpdatedData(editExpiryAlertForm);

                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "expire-inventory",
                                        }),
                                      );
                                    }}
                                  >
                                    Edit Expiry Alert
                                  </NavDropdown.Item>
                                  <NavDropdown.Divider />
                                </>
                              )}
                              {common?.permissions?.["Inventory Management"]?.special && (
                                <NavDropdown.Item
                                  className="mb-1"
                                  onClick={() => {
                                    dispatch(editFormReducer(row));
                                    useInventoryAll[0].initialValue = row.item.name;
                                    useInventoryAll[1].initialValue = row.location?.name;
                                    useInventoryAll[2].initialValue = row.lotNumber;
                                    useInventoryAll[3].initialValue = row.expirationDate;
                                    useInventoryAll[4].initialValue = row.quantity;
                                    setokBtnText("Confirm");
                                    setformName("Expire Inventory");
                                    setFormIcon(<ExpireInventory />);
                                    expireInventoryCTA(row, "expire");
                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: "expire-inventory",
                                      }),
                                    );
                                    setUpdatedData(useInventoryAll);
                                  }}
                                >
                                  Expire
                                </NavDropdown.Item>
                              )}
                              {common?.permissions?.["Inventory Management"]?.delete && (
                                <NavDropdown.Item
                                  className="mb-1"
                                  onClick={() => {
                                    dispatch(editFormReducer(row));
                                    deleteInventory[0].initialValue = row.item.name;
                                    deleteInventory[1].initialValue = row.location?.name;
                                    deleteInventory[2].initialValue = row.lotNumber;
                                    deleteInventory[3].initialValue = row.expirationDate;
                                    deleteInventory[4].initialValue = row.quantity;
                                    setokBtnText("Confirm");
                                    setUpdatedData(deleteInventory);
                                    setformName("Delete Inventory");
                                    setFormIcon(<DeletePurple />);
                                    deleteInventoryCTA(row, "Confirm");
                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: "delete-inventory",
                                      }),
                                    );
                                  }}
                                >
                                  Delete
                                </NavDropdown.Item>
                              )}
                            </NavDropdown>
                          </div>
                        </div>
                      );
                    },
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ]}
                activeTab={activeTab}
                type={catType}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </div>
          </Tab>
          {allItems?.itemUsedInventory?.length > 0 && (
            <Tab
              eventKey="Used"
              title={
                <div className="flex items-center gap-2 title-icon">
                  {activeTab === "Used" ? <UsedGradientInventory purple /> : <UsedGradientInventory />}
                  Used
                </div>
              }
            >
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemUsedInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
          {allItems?.itemDamagedInventory?.length > 0 && (
            <Tab
              eventKey="Damaged"
              title={
                <div className="flex items-center gap-2 title-icon">
                  {activeTab === "Damaged" ? <TabDamaged purple /> : <TabDamaged />}
                  Damaged
                </div>
              }
            >
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemDamagedInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
          {allItems?.itemLostInventory?.length > 0 && (
            <Tab eventKey="Lost" title={<div className="flex items-center gap-2 title-icon">{activeTab === "Lost" ? <TapLost purple /> : <TapLost />} Lost</div>}>
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemLostInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
          {allItems?.itemDisposedInventory?.length > 0 && (
            <Tab
              eventKey="Disposed"
              title={
                <div className="flex items-center gap-2 title-icon">
                  {activeTab === "Disposed" ? <TabDisposed purple /> : <TabDisposed />}
                  Disposed
                </div>
              }
            >
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemDisposedInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
          {allItems?.itemExpiredInventory?.length > 0 && (
            <Tab
              eventKey="Expired"
              title={
                <div className="flex items-center gap-2 title-icon">
                  {activeTab === "Expired" ? <TabExpired purple /> : <TabExpired />}
                  Expired
                </div>
              }
            >
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemExpiredInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
          {allItems?.itemDeletedInventory?.length > 0 && (
            <Tab
              eventKey="Deleted"
              title={
                <div className="flex items-center gap-2 title-icon">
                  {activeTab === "Deleted" ? <TabDeleted purple /> : <TabDeleted />}
                  Deleted
                </div>
              }
            >
              <Hemadatatable
                allItems={activeFilterTab || []}
                InventoryColumns={[...updateItemColumn(allItems?.itemDeletedInventory)]}
                activeTab={activeTab}
                setActiveFilterTab={setActiveFilterTab}
                activeFilterOriginalData={activeFilterOriginalData}
              />
            </Tab>
          )}
        </Tabs>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          icon={formIcon}
          formValidation={formValidation}
          cancelBtnIcon={cancelBtnIcon}
          cancelBtnText={cancelBtnText}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
        />
      )}
    </>
  );
};

export default InventoryDetail;

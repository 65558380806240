import React from "react";

export const Unassign = ({ color }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6 5.4C3.26863 5.4 3 5.66863 3 6C3 6.33137 3.26863 6.6 3.6 6.6H8.4C8.73137 6.6 9 6.33137 9 6C9 5.66863 8.73137 5.4 8.4 5.4H3.6ZM6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 10.8C3.354 10.8 1.2 8.646 1.2 6C1.2 3.354 3.354 1.2 6 1.2C8.646 1.2 10.8 3.354 10.8 6C10.8 8.646 8.646 10.8 6 10.8Z"
        fill={color || "#605DAF"}
      />
    </svg>
  );
};

import React from "react";
export const Back = () => {
  return (
    <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2785 14.8535C12.6755 15.4216 12.6472 16.3709 13.2153 16.9739C13.7833 17.5769 14.7326 17.6052 15.3356 17.0371L13.2785 14.8535ZM22.72 10.0806C23.323 9.51255 23.3513 8.56322 22.7832 7.96023C22.2152 7.35724 21.2658 7.32892 20.6628 7.89698L22.72 10.0806ZM20.6626 10.084C21.2656 10.6521 22.2149 10.6238 22.783 10.0208C23.3511 9.41777 23.3227 8.46844 22.7197 7.90039L20.6626 10.084ZM15.3354 0.943866C14.7324 0.375807 13.7831 0.404126 13.215 1.00712C12.647 1.61011 12.6753 2.55944 13.2783 3.1275L15.3354 0.943866ZM21.6912 10.4914C22.5196 10.4914 23.1912 9.81983 23.1912 8.9914C23.1912 8.16298 22.5196 7.4914 21.6912 7.4914V10.4914ZM1.99962 7.4914C1.17119 7.4914 0.499619 8.16298 0.499619 8.9914C0.499619 9.81983 1.17119 10.4914 1.99962 10.4914V7.4914ZM15.3356 17.0371L22.72 10.0806L20.6628 7.89698L13.2785 14.8535L15.3356 17.0371ZM22.7197 7.90039L15.3354 0.943866L13.2783 3.1275L20.6626 10.084L22.7197 7.90039ZM21.6912 7.4914L1.99962 7.4914V10.4914L21.6912 10.4914V7.4914Z"
        fill="white"
      />
      <line
        x1="27.5"
        y1="2.5"
        x2="27.5"
        y2="15.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

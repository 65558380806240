import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'react-data-table-component';
import attachIcon from '../../../assets/images/attach-icon.svg';
import { HemaDateFormat } from '../../../helpers/commonHelper';
import { sortedData, sortedGreenLight } from '../../../helpers/sort';
import { Button, HemaValue, HemaLabel, FormText, SkelatonCoponent, FormTags } from '../../../utils';
import { setForm, setFormLoaderReducer, setFormCloseReducer, showSuccessReducer, showErrorReducer } from '../../../Store/reducers/uiSettings';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { AddInKit, SendIcon, CreateItemPurple, RedMinus, RedDeleteFIle, RedFIleIcon, CircularRed, Message, Confirm, CancelIcon, LightBulb } from '../../../HemeIconLibrary';
import {
  AddFiletoGreenLightAction,
  getGreenLightAction,
  RemoveGreenLightFileAction,
  submitGreenLightApprovalAction,
  getOrderShipDocumentAction,
  uploadPreSeedFileAction,
  attachPreSeededFileAction,
  getShipmentDetailAction,
  cancelGreenLightApprovalAction,
  reSubmitGreenLightApprovalAction,
} from '../../../Actions/order';
import { AddDocument } from '../../../components/Formik/AllForms/greenLightForm';
import Heading from '../../logistOrchestra/logisticComponents/heading';

const GreenLight = (props) => {
  const [greenLightdata, setgreenLightdata] = useState();
  const [submitedGreenlight, setsubmitedGreenlight] = useState([]);
  const [to, setTo] = useState([]);
  const [previousGreen, setPreviousGreen] = useState([]);
  const dispatch = useDispatch();
  const { uisettings, common } = useSelector((state) => state);
  const { setformName, setFormIcon, setUpdatedData, setokBtnText, setCTA, setokBtnIcon, setCancelBtnText, orders } = props;

  useEffect(() => {
    if (orders?.allGreenLights) {
      setgreenLightdata(orders?.allGreenLights);
      const sortedGreendata = sortedGreenLight(orders?.allGreenLights?.history);
      setsubmitedGreenlight(sortedGreendata);
    }
  }, [orders]);
  useEffect(() => {
    if (greenLightdata?.greenLightContacts) {
      setPreviousGreen(greenLightdata?.greenLightContacts?.split(','));
    }
  }, [greenLightdata?.greenLightContacts]);
  const UploadGreeenLightDoc = (desc) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      const formData = new FormData();
      formData.append('file', payload.docFile);

      const res = await AddFiletoGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id, greenLightdata?.greenLightStatus?.id, desc?.trim(), formData);
      if (res?.status === 200) {
        getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Document added Successfully.`));
      }
    });
  };

  const AttachGreeenLightDoc = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      console.log('payload', payload);
      const formData = new FormData();
      formData.append('file', payload.docFile);
      const fileId = payload?.attachFile?.filter((f) => f.checked)?.[0];
      console.log('fileId', fileId);
      var res;
      if (row) {
        if (payload.docFile && !fileId) {
          res = await uploadPreSeedFileAction(orders?.activeOrder?.id, orders?.activeShipment?.id, greenLightdata?.greenLightStatus?.id, row?.id, formData);
        } else if (fileId && payload.docFile === undefined) {
          res = await attachPreSeededFileAction(orders?.activeOrder?.id, orders?.activeShipment?.id, greenLightdata?.greenLightStatus?.id, row?.id, {
            fileId: fileId?.pdfFile?.id,
          });
        } else {
          dispatch(showErrorReducer(`Only select or add new document.`));
          dispatch(setFormLoaderReducer(false));
        }
      }

      if (res?.status === 200) {
        getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Document added Successfully.`));
      }
    });
  };
  const RemoveGreenLightDoc = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      console.log('payload', payload);
      const res = await RemoveGreenLightFileAction(orders?.activeOrder?.id, orders?.activeShipment?.id, greenLightdata?.greenLightStatus?.id, row?.id);
      if (res?.status === 200) {
        getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Document Deleted Successfully.`));
      }
    });
  };
  const cancelGreenlLightCTA = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      window.scrollTo(0, 0);
      const res = await cancelGreenLightApprovalAction(orders?.activeOrder?.id, orders?.activeShipment?.id, row?.id, {
        note: payload?.note || '',
      });
      if (res?.status === 200) {
        setTo([]);
        getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Cancelled Successfully.`));
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          description: '',
          greenLightContact: [...previousGreen, ...to],
        }}
        // innerRef={ref}
        enableReinitialize
        validate={(values) => {
          const errors = {};
          if (values.greenLightContact.length === 0) {
            errors.greenLightContact = 'required';
          }
          if (values.greenLightContact?.toString()?.length > 200) {
            errors.greenLightContact = 'Max 200 characters allowed(Approx:6 contacts)';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          dispatch(setFormLoaderReducer(true));
          window.scrollTo(0, 0);

          const res = await submitGreenLightApprovalAction(orders?.activeOrder?.id, orders?.activeShipment?.id, { to: values.greenLightContact?.join(',') });
          // e.target.innerHTML = temp;
          if (res?.status === 200) {
            getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
            dispatch(setFormLoaderReducer(false));
            dispatch(showSuccessReducer(`Submitted Successfully.`));
            getShipmentDetailAction(orders.activeOrder?.id, orders?.activeShipment?.id);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="flex flex-col items-start justify-between w-full pt-3 m-auto overflow-auto ">
            <div className="w-full px-[20px]">
              <h2 className="text-base font-bold text-primary1  m-b pb-[13px] w-full border-b-[1px] border-dashed border-[#DEE2E6]">Green Light Attachments</h2>
            </div>

            <div className="w-full flex items-start justify-between gap-[16px] px-[16px] mt-1 mb-3">
              <div className={`min-w-[391px] mt-3`}>
                <HemaLabel text="Green Light Contact(s)" className="mb-[10px]" required={true} isLower icon={<LightBulb purple />} />
                <div className="w-full green-light-attachments-tags">
                  <div className=" w-full border-[1px] border-[#ccc] rounded-[5px] border-solid mt-[10px]">
                    {values?.greenLightContact && (
                      <FormTags
                        name="greenLightContact"
                        setFieldValue={(_, dataArry) => {
                          setPreviousGreen(dataArry);
                        }}
                        value={previousGreen}
                        disabled={orders?.type === 2 && (greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4) ? false : true}
                        // disabled={orders.activeOrder.type===1 ? true : false}
                        classNames="hideborder hideinput"
                      />
                    )}
                    {(greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4) && (
                      <FormTags name="greenLightContact" setFieldValue={(_, data) => setTo(data)} value={to} classNames="hideborder" placeholder="Enter Contacts" />
                    )}
                  </div>
                  {errors.greenLightContact && touched.greenLightContact && <div className="error text-[red] text-[12px] pt-[2px]">{errors.greenLightContact}</div>}
                </div>
              </div>
              {(greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4) && (
                <div className="mt-[20px]">
                  {uisettings?.formLoader ? (
                    <Button
                      type="button"
                      bg="bg-primary1"
                      text={
                        <>
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                          <Spinner animation="grow" size="sm" variant="light" />
                        </>
                      }
                    />
                  ) : (
                    common?.permissions?.['Kit Order Process']?.update && (
                      <Button
                        type="submit"
                        text="Submit Green Light Approval"
                        bg="bg-primary1"
                        color="text-white"
                        disabled={greenLightdata?.greenLightStatus?.files?.filter((f) => f.file === null || f.file === '')?.length > 0 ? true : false}
                        icon={<SendIcon />}
                      />
                    )
                  )}
                </div>
              )}
            </div>

            {!greenLightdata ? (
              <SkelatonCoponent />
            ) : greenLightdata?.greenLightStatus?.files ? (
              <DataTable
                data={[...greenLightdata?.greenLightStatus?.files, greenLightdata?.greenLightStatus?.status?.id !== 2 && {}] || []}
                className=" border-t border-[#DEE2E6]"
                columns={[
                  {
                    name: <HemaValue text={' Description'} className="text-xs font-normal " color="text-[#000000]" />,
                    style: {
                      '#cell-1-undefined': {
                        display: 'none !important',
                      },
                    },
                    sortable: true,
                    selector: (row, index) => {
                      return !row?.id && (greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4) ? (
                        <>
                          {common?.permissions?.['Kit Order Process']?.update && (
                            <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                              <Button
                                type="button"
                                cta={() => {
                                  setformName('File Attachments');
                                  // dispatch(editFormReducer(row));
                                  setFormIcon(<CreateItemPurple />);
                                  setokBtnText('Attach');
                                  setokBtnIcon(<img src={attachIcon} alt="logo" />);
                                  AddDocument[0].label = 'Upload and attach files to this request';
                                  AddDocument[0].onClick = () => {
                                    // handleDownload('PortalUser');
                                  };
                                  AddDocument[1].name = 'docFile';
                                  setUpdatedData(AddDocument.slice(0, 2));
                                  UploadGreeenLightDoc(values.description);
                                  setCancelBtnText('Cancel');
                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: 'green-Light-file-attach',
                                    }),
                                  );
                                }}
                                icon={<AddInKit />}
                              />
                              <FormText
                                type="text"
                                name="description"
                                placeholder="Enter Document Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                value={values.description}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        common?.permissions?.['Kit Order Process']?.update && (
                          <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                            {(greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4) && (
                              <Button
                                type="button"
                                cta={() => {
                                  setformName('Delete Document');
                                  setFormIcon(<RedDeleteFIle />);
                                  setokBtnText('Confirm');
                                  setCancelBtnText('Cancel');
                                  Remove[0].label = 'Are you Sure you want to remove this document from this shippment?';
                                  setUpdatedData(Remove.slice(0, 2));
                                  RemoveGreenLightDoc(row);
                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: 'remvove-item',
                                    }),
                                  );
                                }}
                                icon={<RedMinus />}
                              />
                            )}

                            <HemaValue className="text-xs font-normal " text={row?.name?.split('.')?.[0]} />
                          </div>
                        )
                      );
                    },

                    sortId: 'Name',
                  },
                  {
                    name: <HemaValue text={'Attachments '} className="text-xs font-normal " color="text-[#000000]" />,
                    sortable: true,
                    selector: (row, index) => {
                      return row?.id && <HemaValue text={row?.file?.friendlyName} className="text-xs font-normal underline" color="text-[#6765DA]" />;
                    },
                    sortId: 'readToShipQuantity',
                  },
                  {
                    name: <HemaValue text={'Actions'} className="text-xs font-normal " color="text-[#000000]" />,
                    cell: (row, index) => {
                      return (
                        row?.id &&
                        common?.permissions?.['Kit Order Process']?.update && (
                          <div className="flex-grow four">
                            <div className="flex justify-center gap-[5px] meta">
                              <Button
                                icon={<RedFIleIcon color="#775FD5" />}
                                disabled={greenLightdata?.greenLightStatus?.status?.id === 1 || greenLightdata?.greenLightStatus?.status?.id === 4 ? false : true}
                                color="text-white"
                                bg="bg-bgActionDots"
                                className="w-7 h-7"
                                type="button"
                                cta={async () => {
                                  const res = await getOrderShipDocumentAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
                                  if (res.status === 200) {
                                    setformName('File Attachments');
                                    // dispatch(editFormReducer(row));
                                    setFormIcon(<RedFIleIcon />);
                                    setokBtnText('Attach');
                                    setokBtnIcon(<img src={attachIcon} alt="logo" />);
                                    AddDocument[2].initialValue = res.data;
                                    setUpdatedData(AddDocument);

                                    setCancelBtnText('Cancel');
                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: 'green-Light-file-attachment',
                                      }),
                                    );
                                    AttachGreeenLightDoc(row);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )
                      );
                    },
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ]}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                No Document Found.
              </BootstrapAlert>
            )}

            {greenLightdata?.history?.length > 0 && (
              <>
                <div className="mb-[14px] mt-[24px] px-[16px]">
                  <Heading border heading="Green Light Submission" />
                </div>
                <DataTable
                  data={submitedGreenlight || []}
                  className="border-t border-b border-[#DEE2E6]"
                  columns={[
                    {
                      name: <HemaValue text={' Submitted By'} className="text-xs font-normal " color="text-[#000000]" />,
                      sortable: true,
                      selector: (row) => <HemaValue className="text-xs font-normal " text={row?.submittedBy?.name || 'N/A'} />,
                      sortId: 'submittedBy.name',
                    },
                    {
                      name: <HemaValue text={'Submitted On '} className="text-xs font-normal " color="text-[#000000]" />,
                      sortable: true,
                      selector: (row, index) => <HemaValue className="text-xs font-normal " text={HemaDateFormat(row?.submittedOn)} />,
                      sortId: 'submittedOn',
                    },
                    {
                      name: <HemaValue text={'Status '} className="text-xs font-normal " color="text-[#000000]" />,
                      sortable: true,
                      selector: (row, index) => {
                        return (
                          <div className="flex items-center w-full gap-1 cursor-pointer ">
                            <div
                              className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${
                                (row?.status?.name === 'New' && 'bg-[#E6F2F6]') ||
                                (row?.status?.name === 'Pending' && 'bg-[#F6EEE6]') ||
                                (row?.status?.name === 'Approved' && 'bg-[#E6F6EB]') ||
                                (row?.status?.name === 'Rejected' && 'bg-[#f5e2e1]')
                              }`}
                            >
                              <div
                                className={`w-2 h-2 rounded-full ${
                                  (row?.status?.name === 'New' && 'bg-[#06B6D4]') ||
                                  (row?.status?.name === 'Pending' && 'bg-[#F97316]') ||
                                  (row?.status?.name === 'Rejected' && 'bg-[#F91616]') ||
                                  (row?.status?.name === 'Approved' && 'bg-[#0AB745]')
                                }`}
                              />

                              <HemaValue
                                className="text-xs font-normal "
                                color={
                                  (row?.status?.name === 'New' && 'text-[#06B6D4]') ||
                                  (row?.status?.name === 'Pending' && 'text-[#F97316]') ||
                                  (row?.status?.name === 'Rejected' && 'text-[#F91616]') ||
                                  (row?.status?.name === 'Approved' && 'text-[#0AB745]')
                                }
                                text={row?.status?.name}
                              />
                            </div>

                            {row?.status?.name === 'Rejected' && (
                              <div
                                onClick={() => {
                                  setformName('Green Light Reject Reason');

                                  setFormIcon(<Confirm />);
                                  // setokBtnText('ok');
                                  // setokBtnIcon(<Confirm />);

                                  setCancelBtnText('Close');
                                  setUpdatedData([
                                    {
                                      type: 'heading-only',
                                      initialValue: 'Reject Details',
                                      fluid: true,
                                    },
                                    {
                                      label: row?.note,

                                      name: 'shipment-name',
                                      fluid: true,
                                      type: 'table',
                                    },
                                  ]);

                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: 'view-greenlight-reject-resaon',
                                    }),
                                  );
                                  setCTA(() => () => {
                                    dispatch(
                                      setForm({
                                        state: false,
                                        type: '',
                                      }),
                                    );
                                  });
                                }}
                                className="ml-[20px] w-[29px] h-[29px] flex items-center justify-center rounded-full bg-[#FDDAE8]"
                              >
                                <Message />
                              </div>
                            )}
                          </div>
                        );
                      },
                      sortId: 'status.name',
                    },
                    {
                      name: <HemaValue text={'Actions'} className="text-xs font-normal " color="text-[#000000]" />,
                      cell: (row, index) => {
                        return (
                          <>
                            <div className="flex items-center gap-3">
                              <Button
                                type="button"
                                icon={<CircularRed />}
                                color="text-white"
                                bg="bg-bgActionDots"
                                disabled={row?.status?.id === 2 ? false : true}
                                cta={async (e) => {
                                  dispatch(setFormLoaderReducer(true));
                                  window.scrollTo(0, 0);
                                  const res = await reSubmitGreenLightApprovalAction(orders?.activeOrder?.id, orders?.activeShipment?.id, row?.id, {
                                    to: values.greenLightContact?.join(','),
                                  });
                                  if (res?.status === 200) {
                                    getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
                                    dispatch(setFormLoaderReducer(false));
                                    dispatch(showSuccessReducer(`Re-submitted Successfully.`));
                                  }
                                }}
                              />
                              {row?.status?.id === 2 && (
                                <Button
                                  type="button"
                                  icon={<CancelIcon />}
                                  color="text-white"
                                  bg="bg-bgActionDots"
                                  disabled={row?.status?.id === 2 ? false : true}
                                  cta={async (e) => {
                                    setformName('Green Light Cancel Reason');

                                    setFormIcon(<CancelIcon />);
                                    setokBtnText('Confirm');
                                    setokBtnIcon(<Confirm />);

                                    setCancelBtnText('Cancel');
                                    setUpdatedData([
                                      {
                                        type: 'heading-only',
                                        initialValue: 'Cancel Details',
                                        fluid: true,
                                      },
                                      {
                                        label: 'Note',
                                        name: 'note',
                                        fluid: true,
                                        type: 'textarea',
                                        required: true,
                                      },
                                    ]);

                                    dispatch(
                                      setForm({
                                        state: true,
                                        type: 'view-greenlight-cancel-resaon',
                                      }),
                                    );
                                    cancelGreenlLightCTA(row);
                                  }}
                                />
                              )}
                            </div>
                          </>
                        );
                      },
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    },
                  ]}
                  onSort={(row, direction, sorted) => {
                    setsubmitedGreenlight(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
                  }}
                />
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default GreenLight;

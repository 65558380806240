import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';
import { Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent } from '../../../utils';
import { ExpiryAlertPurple, DeletePurple, Edit, TableSortIcon } from '../../../HemeIconLibrary';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import { AddDepotSettings } from '../../../components/Formik/AllForms/addDepotSettings';
import { TestingLabValidation } from '../../../components/Formik/allFormValidation';
import { AddTestingLabSettings } from '../../../components/Formik/AllForms/addTestingLabSettings';
import { DeleteTestingLabSettings } from '../../../components/Formik/AllForms/addTestingLabSettings';
import { sortedData } from '../../../helpers/sort';
import { addPortalUserFields } from '../../../components/Formik/AllForms/addPortalUserFields';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { setalltestingLabFilter } from '../../../Store/reducers/settings';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../../../Store/reducers/uiSettings';
import { sortedTableData } from '../../../helpers/sortedData';
// Actions
import { getTestingLabAction, updateLabSettingsAction, deleteLabSettingsAction } from '../../../Actions/settings';

// Utils
import { getFullName } from '../Utils';

const TestingLabSettings = ({ sites, setCTA, setformName, setFormIcon, setUpdatedData, setokBtnIcon, setokBtnText, setFormValidation }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { isLoading, alltestingLabFilter, alltestingLab } = settings;

  useEffect(() => {
    getTestingLabAction();
  }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  //senpnarch for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(alltestingLab, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(alltestingLab);
      }
    })();
  }, [searchQuery, alltestingLab]);

  const editTestingLabSettings = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      const filterPayload = {
        name: payload.name,
        contactName: payload?.contactName,
        address: {
          address1: payload?.address1,
          address2: payload?.address2,
          city: payload?.city,
          stateProvince: payload?.stateAddress,
          postalCode: payload?.postalCode,
          email: payload?.email,
          phone: payload?.phoneNo.toString(),
          countryId: payload?.countryId,
          // specialInstruction: null,
        },
        isFulfillmentFacility: payload?.Facility === 1 ? true : false,
      };
      const resp = await updateLabSettingsAction(row.id, filterPayload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Depot successfully edited.`));
        // getPortalUserAction();
      }
    });
    setFormValidation((error, values) => (error, values) => {
      TestingLabValidation(error, values);
    });
  };
  const deleteTestingLabSettings = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deleteLabSettingsAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`Testing Lab deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  useEffect(() => {
    if (Object.keys(alltestingLabFilter)?.length) {
      const filterResult = alltestingLab?.filter((port) => {
        if (
          (alltestingLabFilter.name?.length ? alltestingLabFilter.name.includes(port.name) : true) &&
          (alltestingLabFilter.address1?.length ? alltestingLabFilter.address1?.includes(port.address.address1) : true) &&
          (alltestingLabFilter.email?.length ? alltestingLabFilter.email.includes(port.address.email) : true) &&
          (alltestingLabFilter.phone?.length ? alltestingLabFilter.phone?.includes(port.address.phone) : true) &&
          (alltestingLabFilter.isFulfillmentFacility?.length ? alltestingLabFilter.isFulfillmentFacility?.includes(port.isFulfillmentFacility) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      console.log(filterResult)
      setDataList(filterResult);
    } else {
      setDataList(alltestingLab);
    }
  }, [JSON.stringify(alltestingLabFilter)]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>
        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(alltestingLabFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={alltestingLab} resetRedux={setalltestingLabFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Name'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="name"
                          setRedux={setalltestingLabFilter}
                          reduxValues={alltestingLabFilter || []}
                          options={Array.from(new Set(alltestingLab?.map((filter) => filter.name)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.name} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Address'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="address1"
                          setRedux={setalltestingLabFilter}
                          reduxValues={alltestingLabFilter || []}
                          options={Array.from(new Set(alltestingLab?.map((filter) => filter.address?.address1)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row?.address?.address1} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'address.address1',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Email'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="email"
                          setRedux={setalltestingLabFilter}
                          reduxValues={alltestingLabFilter || []}
                          options={Array.from(new Set(alltestingLab?.map((filter) => filter.address?.email)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row?.address?.email} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'address.email',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Phone Number'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="phone"
                          setRedux={setalltestingLabFilter}
                          reduxValues={alltestingLabFilter || []}
                          options={Array.from(new Set(alltestingLab?.map((filter) => filter.address?.phone)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row?.address?.phone} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'address.phone',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Fulfillment Facility'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="isFulfillmentFacility"
                          setRedux={setalltestingLabFilter}
                          reduxValues={alltestingLabFilter || []}
                          type="boolean"
                          boolTrueText="Yes"
                          boolFalseText="No"
                          options={Array.from(new Set(alltestingLab?.map((filter) => filter?.isFulfillmentFacility)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row?.isFulfillmentFacility ? 'Yes' : 'No'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'isFulfillmentFacility',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <HemaValue text={'Actions'} color="text-[#000000]" className="text-xs font-normal pr-7" />{' '}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.['Testing Lab Settings']?.update && (
                          <Button
                            icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row));
                              setformName('Edit Testing Lab ');
                              setokBtnIcon();
                              setokBtnText('Update');
                              setFormIcon(<ExpiryAlertPurple />);
                              AddTestingLabSettings[0].initialValue = row?.name;
                              AddTestingLabSettings[1].initialValue = row?.address?.address1;
                              AddTestingLabSettings[2].initialValue = row?.address?.address2;
                              AddTestingLabSettings[3].initialValue = row?.address?.city;
                              AddTestingLabSettings[4].initialValue = row?.address?.stateProvince;
                              AddTestingLabSettings[5].initialValue = row?.address?.country?.id;
                              AddTestingLabSettings[5].options = common?.allCountries;
                              AddTestingLabSettings[6].initialValue = row?.address?.postalCode;
                              AddTestingLabSettings[7].initialValue = row?.contactName;

                              AddTestingLabSettings[8].initialValue = row?.address?.email;
                              AddTestingLabSettings[9].initialValue = row?.address?.phone;
                              AddTestingLabSettings[10].initialValue = row?.isFulfillmentFacility ? 1 : 2;

                              AddTestingLabSettings[10].options = [
                                { id: 1, name: 'Yes' },
                                { id: 2, name: 'No' },
                              ];
                              setUpdatedData(AddTestingLabSettings);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'edit-lab-settings',
                                }),
                              );
                              editTestingLabSettings(row);
                            }}
                          />
                        )}
                        {/* <Button
                            icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.['Testing Lab Settings']?.delete && (
                          <Button
                            icon={<img src={deleteIcon} />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              DeleteTestingLabSettings[1].initialValue = row?.name;
                              DeleteTestingLabSettings[2].initialValue = row?.address?.address1;
                              DeleteTestingLabSettings[3].initialValue = row?.address?.address2;
                              DeleteTestingLabSettings[4].initialValue = row?.address?.city;
                              DeleteTestingLabSettings[5].initialValue = row?.address?.stateProvince;
                              DeleteTestingLabSettings[6].initialValue = row?.address?.country?.id;
                              DeleteTestingLabSettings[7].initialValue = row?.address?.postalCode;
                              DeleteTestingLabSettings[8].initialValue = row?.contactName;

                              DeleteTestingLabSettings[9].initialValue = row?.address?.email;
                              DeleteTestingLabSettings[10].initialValue = row?.address?.phone;
                              DeleteTestingLabSettings[11].initialValue = row?.isFulfillmentFacility ? 'yes' : 'No';
                              setUpdatedData(DeleteTestingLabSettings);
                              setokBtnIcon();
                              setokBtnText('Confirm');
                              setformName('Delete Testing Lab');
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              );
                              deleteTestingLabSettings(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: 'visible !important' },
                },
              },

              cells: {
                style: { overflow: 'visible !important' },
              },

              responsiveWrapper: {
                style: { overflow: 'visible !important' },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <NoDataComponent text="There are no Testing lab setting to show. Add by clicking on Add Testing Lab." />
        )}
      </>
    </div>
  );
};

export default TestingLabSettings;

import React from 'react';

export const WhenIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 11.2V16L19.7 17.6M24.5 16C24.5 20.4183 20.9183 24 16.5 24C12.0817 24 8.5 20.4183 8.5 16C8.5 11.5817 12.0817 8 16.5 8C20.9183 8 24.5 11.5817 24.5 16Z"
        stroke="#775FD5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { HemaLabel, Button, FormText, ImageUpload } from '../../../utils';
import { uploadDocumentLogisticAction } from '../../../Actions/logistic';
import { Cancel, CrossIcon, LabelName, UploadIcon, Confirm, } from '../../../HemeIconLibrary';

import { setForm, showSuccessReducer, setBuildTemplate } from '../../../Store/reducers/uiSettings';
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading';
import { compareArrays } from '../../../helpers/commonHelper';

const DocumentUpload = () => {
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState();
  const { logistic, uisettings } = useSelector((state) => state);
  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm]);
  return (
    <>
      { (
        <Formik
          initialValues={{
            StudyDocument: '',
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.StudyDocument) {
              errors.StudyDocument = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
           // console.log('add document', values, logistic)
            //return;
            setSubmitting(true);
            const formData = new FormData();
            formData.append('file', values.StudyDocument);

            const payloadData = {
              RecordId: logistic.selectedProtocol.id,
              LogisticBuilderLevelId:1,
              ChangeReason:'string'
            };
            formData.append('model', JSON.stringify(payloadData));
            const result = await uploadDocumentLogisticAction(formData);
            setSubmitting(false);
            if (result.status === 200) {
              dispatch(setForm({ state: false, type: "" }));
              dispatch(showSuccessReducer('document is upload successfully'));
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            initialValues,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                  <div className="w-full items-start gap-[16px] mb-[25px]">
                  <HemaLabel text={'Upload File'} icon={<UploadIcon purple />} className={`mb-[10px]`} color="#454545" />

                  <ImageUpload name={'StudyDocument'} onChange={handleChange} onBlur={handleBlur} value={values.StudyDocument} setFieldValue={setFieldValue} />
                  {errors.StudyDocument && touched.StudyDocument && <div className="error text-[red] text-[12px] pt-[2px]">{errors.StudyDocument}</div>}
                </div>

              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(
                      setForm({ }),
                    );
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Confirm"
                    bg="bg-primary1"
                    color="text-white"
                    icon={<Confirm />}
                    disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  />
                )}
              </div>
            </form>
          )}
        </Formik>
      ) }
    </>
  );
};

export default DocumentUpload;

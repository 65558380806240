import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import packageNameIcon from "../../../assets/images/packageName.svg";
import packageSizeIcon from "../../../assets/images/packageSize.svg";
import ChangeReasonIcon from "../../../assets/images/change-reason.svg";
import {
  HemaLabel,
  FormSelect,
  Button,
  Alert,
  FormText,
  FormCheckbox,
  FormTextarea,
} from "../../../utils";
import {
  Cancel,
  Confirm,
  CrossIcon,
  LabelQuantity,
  DoubleLeftIcon,
  DoubleRightIcon,
  PaginationLeftArrow,
  PaginationRightArrow,
  ChangeReason,
  PackageWeight,
} from "../../../HemeIconLibrary";
import {
  createPackageAction,
  updatePackageAction,
  getShipmentDetailAction,
  getDimenisonsForPackageAction,
} from "../../../Actions/order";
import Heading from "../../../pages/logistOrchestra/logisticComponents/heading";

import {
  setForm,
  showSuccessReducer,
} from "../../../Store/reducers/uiSettings";
import DataTable from "react-data-table-component";

const CreatePackge = ({ approve }) => {
  const { orders, uisettings } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [newPackage, setNewPackage] = useState([]);
  const [itemsAvailable, setItemsAvailable] = useState([]);
  const [activeRows, setActiveRows] = useState([]);
  const [activeRight, setActiveRight] = useState([]);
  const [packageName, setpackageName] = useState("");
  const [packageSize, setPackageSize] = useState("");
  const [packageWeight, setpackageWeight] = useState("");

  useEffect(() => {
    if (orders?.activeShipment?.unAssignedItems) {
      setItemsAvailable(orders?.activeShipment?.unAssignedItems || []);
    }
  }, [orders?.activeShipment]);

  useEffect(() => {
    if (uisettings?.editForm?.items) {
      setNewPackage(uisettings?.editForm?.items);
    }
  }, [uisettings?.editForm?.items]);
  useEffect(() => {
    getDimenisonsForPackageAction();
  }, []);
  // const conditionalRowStyles = [
  //   {
  //     when: (row) => activeRows?.map((data) => data.id)?.includes(row.id),
  //     style: {
  //       backgroundColor: "#ccc",
  //       color: "white",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //     },
  //   },
  // ];
  // const conditionalRowStylesRight = [
  //   {
  //     when: (row) => activeRight?.map((data) => data.id)?.includes(row.id),
  //     style: {
  //       backgroundColor: "#ccc",
  //       color: "white",
  //       "&:hover": {
  //         cursor: "pointer",
  //       },
  //     },
  //   },
  // ];
  const getObjectsBetweenIds = (objectsArray, id1, id2) => {
    var startIndex = objectsArray.findIndex((obj) => obj.id === id1);
    var endIndex = objectsArray.findIndex((obj) => obj.id === id2);

    if (startIndex === -1 || endIndex === -1) {
      return []; // Either ID not found in the array
    }

    if (startIndex > endIndex) {
      [startIndex, endIndex] = [endIndex, startIndex]; // Swap if start > end
    }

    const objectsBetween = objectsArray.slice(startIndex, endIndex + 1);

    return objectsBetween;
  };

  return (
    <Formik
      initialValues={{
        name: uisettings?.editForm?.name || packageName,
        weight: uisettings?.editForm?.weight || packageWeight,
        packageDimensionId:
          uisettings?.editForm?.packageDimension || packageSize,
        ids: newPackage,
        changeReason: "",
      }}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.packageDimensionId) {
          errors.packageDimensionId = "Required";
        }
        if (!values.weight) {
          errors.weight = "Required";
        }
        if (!values.name) {
          errors.name = "Required";
        }
        if (values.name && values.name?.length > 100) {
          errors.name = "Max 100 characters allowed";
        }
        if (values.weight === 0) {
          errors.weight = "Weight cannot be zero";
        }
        if (values.ids?.length === 0) {
          errors.ids = "atleast  assign one item from available items";
        }
        if (!values.changeReason && uisettings?.editForm) {
          errors.changeReason = "Required";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        if (uisettings?.editForm?.id) {
          const result = await updatePackageAction(
            orders.activeOrder?.id,
            orders.activeShipment?.id,
            uisettings?.editForm?.id,
            {
              ...values,
              packageDimensionId: values?.packageDimensionId.id,
              ids: newPackage?.map((data) => data.id),
            },
          );
          await getShipmentDetailAction(
            orders.activeOrder?.id,
            orders.activeShipment?.id,
          );
          setSubmitting(false);
          if (result.status === 200) {
            dispatch(setForm({ state: false, type: "" }));
            dispatch(showSuccessReducer(`new Package updated.`));
          }
        } else {
          const result = await createPackageAction(
            orders.activeOrder?.id,
            orders.activeShipment?.id,
            {
              ...values,
              packageDimensionId: values?.packageDimensionId.id,
              ids: values.ids?.map((data) => data.id),
            },
          );
          await getShipmentDetailAction(
            orders.activeOrder?.id,
            orders.activeShipment?.id,
          );
          setSubmitting(false);
          if (result.status === 200) {
            dispatch(setForm({ state: false, type: "" }));
            dispatch(showSuccessReducer(`Package added.`));
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form
          onSubmit={handleSubmit}
          className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]"
        >
          <div>
            <Heading heading="Package Details" />
            <div className={"w-full mt-[21px]"}>
              <div className="mb-[30px]">
                <HemaLabel
                  text={"Package Number"}
                  required={true}
                  icon={<img src={packageNameIcon} alt="icon" />}
                  className={`mb-[10px]`}
                  textSize="text-[12px]"
                  color="text-[#454545]"
                />
                <FormText
                  value={values.name}
                  placeholder="Package name"
                  min="1"
                  onChange={(e) => {
                    setpackageName(e.target.value);
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={handleBlur}
                  name="name"
                  type="number"
                  setFieldValue={setFieldValue}
                  crossIcon={values?.name ? <CrossIcon /> : ""}
                  setpackageName={setpackageName}
                ></FormText>
                {errors.name && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.name}
                  </div>
                )}
              </div>
            </div>
            <div className={"w-full mt-[21px]"}>
              <div className="mb-[30px]">
                <HemaLabel
                  text={"Package Weight"}
                  required={true}
                  icon={<PackageWeight />}
                  className={`mb-[10px]`}
                  textSize="text-[12px]"
                  color="text-[#454545]"
                />
                <FormText
                  value={values.weight}
                  placeholder="Package Weight"
                  min="1"
                  onChange={(e) => {
                    setpackageWeight(e.target.value);
                    setFieldValue("weight", e.target.value);
                  }}
                  onBlur={handleBlur}
                  name="weight"
                  type="number"
                  setFieldValue={setFieldValue}
                  crossIcon={values?.weight ? <CrossIcon /> : ""}
                  setpackageWeight={setpackageWeight}
                ></FormText>
                {errors.weight && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.weight}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full flex gap-[16px] items-center">
              <div className="w-full mb-[30px]">
                <HemaLabel
                  text={"Package Dimensions"}
                  icon={<img src={packageSizeIcon} alt="icon" />}
                  className={`mb-[10px]`}
                  textSize="text-[12px]"
                  color="text-[#454545]"
                  required
                />
                <FormSelect
                  name="packageDimensionId"
                  setFieldValueFull={(name, value) => {
                    setPackageSize(value);
                    setFieldValue("packageDimensionId", value);
                  }}
                  placeholder={
                    values.packageDimensionId?.name || "select dimensions"
                  }
                  value={values.packageDimensionId?.name}
                  options={orders.packageDimensions}
                  crossIcon={values?.packageDimensionId ? <CrossIcon /> : ""}
                  setPackageSize={setPackageSize}
                ></FormSelect>
                {errors.packageDimensionId && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.packageDimensionId}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full ">
              <div
                className={
                  " w-full h-full min-h-max flex items-center gap-[10px] "
                }
              >
                <div className=" w-full h-full min-h-full max-w-[259px] border border-[#ccc] rounded-[5px] ">
                  <p className="border-b text-sm font-bold m-0 leading-[24px] border-[#ccc] p-[10px] text-primary1 ">
                    Available Items
                  </p>
                  <div className="h-full overflow-auto ">
                    <DataTable
                      data={itemsAvailable || []}
                      // selectableRows
                      // onSelectedRowsChange={(e) => {
                      //   setActiveRows(e.selectedRows);
                      // }}
                      // conditionalRowStyles={conditionalRowStyles}
                      columns={[
                        {
                          name: "Item Name",
                          selector: (row) => {
                            return (
                              <div className="flex-shrink-0 py-[11px] flex items-center gap-[10px] cursor-pointer">
                                <FormCheckbox
                                  onClick={(e) => {
                                    if (e.shiftKey) {
                                      const latestId =
                                        activeRows[activeRows.length - 1]?.id;
                                      const selectionRows = [];
                                      if (latestId) {
                                        let resultData = getObjectsBetweenIds(
                                          itemsAvailable,
                                          latestId,
                                          row.id,
                                        );

                                        setActiveRows(resultData);
                                      } else {
                                        for (
                                          var i = 0;
                                          i < itemsAvailable.length;
                                          i++
                                        ) {
                                          if (itemsAvailable[i].id === row.id) {
                                            selectionRows.push(
                                              itemsAvailable[i],
                                            );
                                            break;
                                          } else {
                                            selectionRows.push(
                                              itemsAvailable[i],
                                            );
                                          }
                                        }

                                        setActiveRows(selectionRows);
                                      }
                                    } else {
                                      if (
                                        activeRows?.filter(
                                          (data) => data.id === row.id,
                                        ).length
                                      ) {
                                        setActiveRows(
                                          activeRows?.filter(
                                            (data) => data.id !== row.id,
                                          ),
                                        );
                                      } else {
                                        setActiveRows([...activeRows, row]);
                                      }
                                    }
                                  }}
                                  checked={
                                    activeRows?.filter(
                                      (data) => data.id === row.id,
                                    ).length
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                  className="item_package"
                                  // disabled={allItems?.filter((item) => item.checked)?.length === 1 ? true : false}
                                />
                                <HemaLabel
                                  text={row?.name}
                                  textSize="text-[12px]"
                                  className=""
                                />
                              </div>
                            );
                          },
                        },
                        {
                          name: "Lot Number",
                          selector: (row) => {
                            return (
                              <div className="flex-shrink-0 py-[11px] flex items-center gap-[10px] cursor-pointer">
                                <HemaLabel
                                  text={row?.lotNumber}
                                  textSize="text-[12px]"
                                />
                              </div>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <Button
                    cta={() => {
                      const filterId = activeRows.map((data) => data.id);

                      setNewPackage([...activeRows, ...newPackage]);
                      setActiveRows([]);
                      setItemsAvailable(
                        itemsAvailable.filter(
                          (data) => !filterId.includes(data.id),
                        ),
                      );
                    }}
                    disabled={activeRows?.length ? false : true}
                    type="button"
                    text=""
                    bg="bg-white"
                    border="border-[1px] border-solid border-[#DEE2E6] "
                    color="text-white"
                    className="p-0"
                    icon={<PaginationRightArrow />}
                  />

                  <Button
                    disabled={!itemsAvailable?.length ? true : false}
                    cta={() => {
                      if (uisettings?.editForm?.items) {
                        setNewPackage([
                          ...orders?.activeShipment?.unAssignedItems,
                          ...uisettings?.editForm?.items,
                        ]);
                        setItemsAvailable([]);
                        setActiveRows([]);
                      } else {
                        setNewPackage(orders?.activeShipment?.unAssignedItems);
                        setItemsAvailable([]);
                        setActiveRows([]);
                      }
                    }}
                    type="button"
                    text=""
                    bg="bg-white"
                    border="border-[1px] border-solid border-[#DEE2E6] "
                    color="text-white"
                    className="w-[30px] h-[30px]"
                    icon={<DoubleRightIcon />}
                  />

                  <Button
                    cta={() => {
                      const filterId = activeRight.map((data) => data.id);

                      setItemsAvailable([...activeRight, ...itemsAvailable]);
                      setActiveRight([]);
                      setNewPackage(
                        newPackage.filter(
                          (data) => !filterId.includes(data.id),
                        ),
                      );
                    }}
                    disabled={activeRight?.length ? false : true}
                    type="button"
                    text=""
                    bg="bg-white"
                    border="border-[1px] border-solid border-[#DEE2E6] "
                    color="text-white"
                    className="p-0"
                    icon={<PaginationLeftArrow />}
                  />
                  <Button
                    disabled={!newPackage?.length ? true : false}
                    cta={() => {
                      if (uisettings?.editForm?.items) {
                        setItemsAvailable([
                          ...orders?.activeShipment?.unAssignedItems,
                          ...uisettings?.editForm?.items,
                        ]);
                        setNewPackage([]);
                        setActiveRight([]);
                      } else {
                        setItemsAvailable(
                          orders?.activeShipment?.unAssignedItems,
                        );
                        setNewPackage([]);
                        setActiveRight([]);
                      }
                    }}
                    type="button"
                    text=""
                    bg="bg-white"
                    border="border-[1px] border-solid border-[#DEE2E6] "
                    color="text-white"
                    className="w-[30px] h-[30px]"
                    icon={<DoubleLeftIcon />}
                  />
                </div>
                <div className=" w-full max-w-[259px] min-h-full border border-[#ccc] rounded-[5px] ">
                  <div className="border-b text-sm font-bold leading-[24px] border-[#ccc] p-[10px] text-primary1 bold">
                    Package Contents
                  </div>
                  <div className="h-full overflow-auto ">
                    <DataTable
                      data={newPackage || []}
                      // selectableRows
                      // onSelectedRowsChange={(e) => {
                      //   setActiveRight(e.selectedRows);
                      // }}
                      // conditionalRowStyles={conditionalRowStylesRight}
                      columns={[
                        {
                          name: "Item Name",
                          selector: (row) => {
                            return (
                              <div className="flex-shrink-0 py-[11px] flex items-center gap-[10px] cursor-pointer">
                                <FormCheckbox
                                  onClick={(e) => {
                                    if (e.shiftKey) {
                                      const latestId =
                                        activeRight[activeRight.length - 1]?.id;
                                      const selectionRows = [];
                                      if (latestId) {
                                        let resultData = getObjectsBetweenIds(
                                          newPackage,
                                          latestId,
                                          row.id,
                                        );

                                        setActiveRight(resultData);
                                      } else {
                                        for (
                                          var i = 0;
                                          i < newPackage.length;
                                          i++
                                        ) {
                                          if (newPackage[i].id === row.id) {
                                            selectionRows.push(newPackage[i]);
                                            break;
                                          } else {
                                            selectionRows.push(newPackage[i]);
                                          }
                                        }

                                        setActiveRight(selectionRows);
                                      }
                                    } else {
                                      if (
                                        activeRight?.filter(
                                          (data) => data.id === row.id,
                                        ).length
                                      ) {
                                        setActiveRight(
                                          activeRight?.filter(
                                            (data) => data.id !== row.id,
                                          ),
                                        );
                                      } else {
                                        setActiveRight([...activeRight, row]);
                                      }
                                    }
                                  }}
                                  checked={
                                    activeRight?.filter(
                                      (data) => data.id === row.id,
                                    ).length
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                  // disabled={allItems?.filter((item) => item.checked)?.length === 1 ? true : false}
                                  className="item_package"
                                />
                                <HemaLabel
                                  text={row?.name}
                                  textSize="text-[12px]"
                                />
                              </div>
                            );
                          },
                        },
                        {
                          name: "Lot Number",
                          selector: (row) => {
                            return (
                              <div className="flex-shrink-0 py-[11px] flex items-center gap-[10px] cursor-pointer">
                                <HemaLabel
                                  text={row?.lotNumber}
                                  textSize="text-[12px]"
                                />
                              </div>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            {errors.ids && touched.ids && (
              <div className="error text-[red] text-[12px] pt-[2px]">
                {errors.ids}
              </div>
            )}
            {uisettings?.editForm && (
              <div className="mt-[20px] gap-[15px] items-center w-full block">
                <HemaLabel
                  text={"Change Reason"}
                  icon={<img src={ChangeReasonIcon} alt="icon" />}
                  required={true}
                  className={`mb-[10px]`}
                />

                <FormTextarea
                  placeholder="write edit reason"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={"changeReason"}
                  value={values?.changeReason}
                />

                {errors.changeReason && touched.changeReason && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.changeReason}
                  </div>
                )}
              </div>
            )}
          </div>

          <Alert type="error" />
          <div className="">
            <div className="flex gap-[8px] items-end justify-end my-[20px]">
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: "" }));
                }}
                type="button"
                text="Cancel"
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                icon={<Cancel />}
              />

              {isSubmitting ? (
                <Button
                  type="button"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <Button
                  type="submit"
                  text="Save"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<Confirm />}
                  disabled={
                    values?.name &&
                    values?.packageDimensionId &&
                    values?.weight &&
                    values?.ids.length &&
                    (uisettings?.editForm
                      ? values.changeReason
                        ? true
                        : false
                      : true)
                      ? false
                      : true
                  }
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreatePackge;

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import Spinner from 'react-bootstrap/Spinner'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compareArrays } from '../../../helpers/commonHelper'
import { setForm, showErrorReducer } from '../../../Store/reducers/uiSettings'
import { HemaLabel, FormText, Button, HemaValue, Alert } from '../../../utils'
import FormSelect from '../../../utils/FormElements/select2'
import {
  Cancel,
  Add,
  AddInKit,
  DragDrop,
  Minus,
  DeleteBin,
  NameKit,
  Confirm,
  CrossIcon,
} from '../../../HemeIconLibrary'

export const NewRow = ({
  addRow,
  dropDownItemList = 'assemblyItem',
  setItems,
  setFieldValue,
  hideSort,
  items,
  data,
  disabled = false,
  allItemsInTemplate,
}) => {
  const selectRef = useRef()
  const selectedObject = useRef()
  const [dropdownItems, setdropdownItems] = useState([])
  const {
    allItems,
    uisettings,
    common: { dropdownItemList, activeDropdownValue },
  } = useSelector((state) => state)
  useEffect(() => {
    if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Initial Request Assembly'
    ) {
      setItems(allItemsInTemplate)
      setFieldValue('testingLabId', '')
      setFieldValue('inboundShippingConditionId', '')
      data[2].required = false
      data[3].required = false
      setdropdownItems(
        dropdownItemList?.[dropDownItemList]?.filter(
          (item) => item.itemCategory?.id !== 2,
        ),
      )
    } else if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Shipping Assembly'
    ) {
      setItems(allItemsInTemplate)
      setFieldValue('testingLabId', '')
      setFieldValue('inboundShippingConditionId', '')
      data[2].required = false
      data[3].required = false
      setdropdownItems(
        dropdownItemList?.[dropDownItemList]?.filter(
          (item) => item.itemCategory?.id === 2 || item.itemCategory?.id === 3,
        ),
      )
    } else if (
      uisettings?.formName === 'create-assembly' &&
      activeDropdownValue?.name === 'Return Kit Assembly'
    ) {
      setItems(allItemsInTemplate)
      setdropdownItems(dropdownItemList?.[dropDownItemList])
      data[2].required = true
      data[3].required = true
    } else if (
      uisettings?.formName === 'create-assembly' ||
      uisettings?.formName === 'copy-assembly-LO'
    ) {
      setdropdownItems(dropdownItemList?.[dropDownItemList])
    } else if (
      uisettings?.formName === 'create-kit-template' ||
      uisettings?.formName === 'copy-kit-template'
    ) {
      setdropdownItems(
        allItems.allItems?.filter(
          (item) =>
            item?.itemCategory?.id !== 2 || item?.itemCategory?.id !== 3,
        ),
      )
    }
  }, [activeDropdownValue, dropDownItemList])

  return (
    <Formik
      initialValues={{ sort: '', itemName: '' }}
      enableReinitialize
      validate={(values) => {
        const errors = {}

        if (!values.itemName) {
          errors.itemName = 'Required'
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        values.obj = selectedObject.current
        addRow(values)
        resetForm({ values: '' })
        selectRef.current.clearSelect()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex items-start gap-[6px] border border-1 py-2 pl-[11px]  pr-[20px] w-full">
            <div className="flex">
              <Button
                disabled={disabled}
                type="button"
                className="p-0 mr-[11px] w-6 h-6  flex-shrink-0 flex items-center justify-center"
                cta={() => handleSubmit()}
                icon={<AddInKit />}
              />
            </div>

            <div className="mr-[25px] grow-[2] w-full flex">
              {!hideSort && (
                <div className={`w-auto mr-[27px]`}>
                  <HemaValue
                    text={items ? items?.length + 1 : 'Order'}
                    color=" text-placeholderColor "
                    className="border border-1 mr-3 min-w-[93px] rounded px-[6px] py-[3px] text-xs leading-4"
                  />
                </div>
              )}
              <div className="w-full">
                <div
                  className={`${
                    !hideSort ? 'min-w-[175px]' : 'w-full'
                  } border border-solid border-[#DEE2E6] rounded-[5px] `}
                >
                  <FormSelect
                    className={`kit-dropdown-custom ${'bg-[#f1f1f1]'} custom-dropdown-kit2`}
                    ref={selectRef}
                    placeholder={'Item name'}
                    setFieldValue={setFieldValue}
                    onChange={(event) => {
                      handleChange(event)
                      selectedObject.current = event?.target?.selectedObject
                      // setTimeout(() => handleSubmit());
                    }}
                    onBlur={handleBlur}
                    value={values.itemName}
                    name="itemName"
                    options={dropdownItems}
                    selectType={'itemQuantity'}
                    notRefresh
                    disabled={disabled}
                  />
                </div>
                {errors.itemName && touched.itemName && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.itemName}
                  </div>
                )}
              </div>
            </div>
            <HemaValue
              text="Quantity Per Kit"
              className="flex-shrink-0 text-xs"
              color="text-placeholderColor "
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export const DataTableRowItems = ({
  index,
  item,
  setItems,
  allItems,
  items,
  hideSort,
  formName = '',
  provided,
}) => {
  useEffect(() => {
    console.log(item)
  }, [item])
  return (
    <div className="flex flex-col border items-flex-start border-1">
      <div className="flex items-center gap-[20px] py-[10px] px-[12px] justify-between">
        {!hideSort && (
          <div className="flex" {...provided.dragHandleProps}>
            <Button
              type="button"
              className="flex items-center justify-center flex-shrink-0 w-6 h-6 p-0"
              icon={<DragDrop />}
            />
            <div className="self-center ml-[13px] mr-[21px]">
              <div>
                <HemaValue text={++index} className="text-xs leading-[18px]" />
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            !hideSort ? 'min-w-[175px]' : 'w-full'
          } border border-solid border-[#DEE2E6] rounded-[5px] ${
            !hideSort ? 'ml-[29px]' : 'ml-[32px]'
          }`}
        >
          <FormSelect
            className="kit-dropdown-custom custom-dropdown-kit"
            placeholder={item.name || item?.item?.name}
            value={item?.item?.id || item.itemName || item.id}
            options={allItems}
            setFieldValue={(name, data) => {
              console.log(data)
              setItems(
                items.map((up) => {
                  if (up.id === item.id) {
                    return {
                      ...up,
                      itemId: data,
                      item: {
                        id: data,
                        name:
                          allItems?.filter((nam) => nam.id === data)?.[0]
                            ?.name || undefined,
                      },
                      name:
                        allItems?.filter((nam) => nam.id === data)?.[0]?.name ||
                        undefined,
                    }
                  } else {
                    return up
                  }
                }),
              )
            }}
            name="item-name-kil-builder"
          />
        </div>
        <div className="flex items-center">
          <Button
            type="button"
            bg="bg-[#F1F5F7]"
            className="flex items-center justify-center  min-w-[24px] h-[24px] flex-shrink-0 p-0"
            icon={<Minus color="#F87FB1" />}
            cta={() => {
              setItems(
                items.map((up) => {
                  if (up.id === item.id) {
                    if (up.itemPerKit > 0 || up.qty > 0 || up.quantity > 0) {
                      // return {
                      //   ...up,
                      //   qty: up.qty - 1,
                      //   quantity: up.quantity - 1,
                      //   itemPerKit: up.itemPerKit -1
                      // };
                      let finalQty =
                        up.qty === 0
                          ? 0
                          : up.itemPerKit || up.qty || up.quantity
                      return {
                        ...up,
                        qty: finalQty - 1,
                        quantity: finalQty - 1,
                        itemPerKit: finalQty - 1,
                      }
                    } else {
                      return up
                    }
                  } else {
                    return up
                  }
                }),
              )
            }}
          />
          {/* <HemaValue className="px-[15px] self-center " text={item.qty || item.quantity || item.itemPerKit} /> */}
          <input
            type="text"
            // size={20}
            pattern="[0-9]+"
            className="text-sm font-normal text-black text-center px-1 w-[100px]"
            value={
              item.qty === 0 ? 0 : item.qty || item.itemPerKit || item.quantity
            }
            onChange={(e) => {
              const targetValue = e.target.value || 0
              setItems(
                items.map((up) => {
                  if (up.id === item.id) {
                    if (targetValue >= 0 && targetValue <= 2147483647) {
                      return {
                        ...up,
                        qty: parseInt(targetValue),
                        itemPerKit: parseInt(targetValue),
                      }
                    } else {
                      return up
                    }
                  } else {
                    return up
                  }
                }),
              )
            }}
          />
          <Button
            type="button"
            bg="bg-[#F1F5F7]"
            icon={<Add color="#F87FB1" />}
            className="flex items-center justify-center  min-w-[24px] h-[24px] flex-shrink-0 p-0"
            cta={() => {
              setItems(
                items.map((up) => {
                  if (up.id === item.id) {
                    if (up.qty < 2147483647) {
                      let finalQty =
                        up.qty === 0
                          ? 0
                          : up.itemPerKit || up.qty || up.quantity
                      return {
                        ...up,
                        qty: finalQty + 1,
                        quantity: finalQty + 1,
                        itemPerKit: finalQty + 1,
                      }
                    } else {
                      return up
                    }
                  } else {
                    return up
                  }
                }),
              )
            }}
          />
          <Button
            type="button"
            className="flex items-center justify-center ml-[10px] min-w-[24px] h-[24px] flex-shrink-0 p-0"
            bg="bg-[#F1F5F7]"
            icon={<DeleteBin color="#F87FB1" />}
            cta={() => {
              setItems(items.filter((up) => up.id !== item.id))
            }}
          />
        </div>
      </div>
      {(item.qty === '' || item.qty === 0) && !hideSort && (
        <div className="error text-[red] text-[12px] pb-[2px] pl-[20px]">
          {'Item must contain 1 or more quantity per kit'}
        </div>
      )}
    </div>
  )
}

const FormikForm = ({ cta, hideSort }) => {
  const [items, setItems] = useState([])
  const mainName = useRef('')
  const [errorMsg, seterrorMsg] = useState('')
  const { uisettings, allItems } = useSelector((state) => state)
  const dispatch = useDispatch()
  const formikRef = useRef()
  // formik validation
  useEffect(() => {
    ;(async () => {
      await formikRef.current?.validateForm()
    })()
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm, items])

  useEffect(() => {
    if (uisettings.formName === 'copy-kit-template') {
      let obj = [...uisettings.editForm?.items]
      obj = obj.map((element, key) => {
        return {
          ...element,
          itemId: element.id,
          id: String(Date.now()) + key,
          qty: element.itemPerKit,
        }
      })
      setItems(obj)
      mainName.current = uisettings.editForm?.name + ' Copy'
    }
  }, [uisettings.formName])
  // duplicate items checks
  useEffect(() => {
    if (items?.length > 0) {
      const uniqueValues = new Set(items.map((v) => parseInt(v.itemId)))

      if (uniqueValues.size < items?.length) {
        seterrorMsg(`Can't have duplicate item`)
      } else {
        seterrorMsg('')
      }
    }
  }, [items])

  const onDragEnd = (result) => {
    // dropped outside the lista
    console.log(result)
    if (!result.destination) {
      return
    }

    const itemsData = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    //const sortArray = itemsData.map((data) => data.sort).sort();
    // itemsData[result.source.index].sort = sortArray[result.destination.index];
    // itemsData[result.destination.index].sort = sortArray[result.source.index];
    setItems(itemsData)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  return (
    <Formik
      initialValues={{
        name: uisettings?.editForm?.name
          ? uisettings?.editForm?.name + ' copy'
          : '',
      }}
      enableReinitialize
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        if (values.name && values?.name.length > 100) {
          errors.name = 'Max 100 characters allowed'
        }
        console.log('errors', errors)
        return errors
      }}
      onSubmit={(values, { resetForm }) => {
        dispatch(showErrorReducer(''))
        const emptyQty = items?.filter(
          (item) => item.qty === '' || item.qty === 0,
        )

        if (emptyQty?.length === 0 && !errorMsg) {
          cta({
            ...values,
            items: items?.map((data, index) => {
              return { ...data, sort: index + 1 }
            }),
          })
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        initialValues,
        /* and other goodies */
      }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]"
          >
            <div className={'w-full'}>
              <HemaLabel
                text={'Name '}
                icon={<NameKit color="blue" />}
                required={true}
                className={`mb-[10px]`}
              />
              <div className="flex gap-[15px] items-center ">
                <FormText
                  type={'text'}
                  name={'name'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  setFieldValue={setFieldValue}
                  crossIcon={values?.name ? <CrossIcon /> : ''}
                  placeholder={'Enter template name'}
                />
              </div>
              {errors.name && (
                <div className="error text-[red] text-[12px] pt-[2px]">
                  {errors.name}
                </div>
              )}
              <div className="">
                <div className="border-dashed border-t-2 border-[#DEE2E6] mb-[16px] mt-[16px]"></div>
                <div className="flex items-align py-[11px] border w-full rounded-t pl-[15px] pr-[35px]  justify-between ">
                  <div>
                    <HemaValue
                      color="text-[#000000]"
                      className="text-xs  font-[400]"
                      text="Sort Order"
                    />
                  </div>
                  <div className={`${!hideSort ? 'mr-[107px]' : 'mr-[200px]'}`}>
                    <HemaValue
                      color="text-[#000000]"
                      className="text-xs  font-[400]"
                      text="Item Name"
                    />
                  </div>
                  <div>
                    <HemaValue
                      color="text-[#000000]"
                      className="text-xs  font-[500]"
                      text="Quantity Per Kit"
                    />
                  </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <DataTableRowItems
                                    index={index}
                                    item={item}
                                    setItems={setItems}
                                    allItems={allItems?.allItems?.filter(
                                      (item) =>
                                        item?.itemCategory.id !== 2 ||
                                        item?.itemCategory.id !== 3,
                                    )}
                                    items={items}
                                    formName={uisettings.formName}
                                    provided={provided}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <NewRow
                addRow={(data) =>
                  setItems([
                    ...items,
                    {
                      id: String(Date.now()),
                      sort: items.length + 1,
                      itemId: data?.itemName,
                      qty: 1,
                      name: allItems.allItems.filter(
                        (itm) => String(itm.id) === data?.itemName,
                      )?.[0]?.name,
                      customItem: data.Search ? true : false,
                    },
                  ])
                }
                items={items}
              />
              {errorMsg && (
                <div className="error text-[red] text-[12px] pt-[2px]">
                  {errorMsg}
                </div>
              )}
            </div>

            <div className="flex gap-[8px] justify-end my-[20px]">
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: '' }))
                }}
                type="button"
                text="Cancel"
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                icon={<Cancel />}
              />

              {uisettings.formLoader ? (
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <Button
                  type="submit"
                  text="Create"
                  disabled={values.name && items.length ? false : true}
                  bg="bg-primary1"
                  color="text-white"
                  icon={<Add color="#fff" />}
                />
              )}
            </div>
          </form>
        </>
      )}
    </Formik>
  )
}

export default FormikForm

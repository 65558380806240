import React from "react";

export const DotsIcon = () => {
  return (
    <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="2.5" fill="#775FD5" />
      <circle cx="3" cy="10" r="2.5" fill="#F87FB1" />
      <circle cx="3" cy="17" r="2.5" fill="#6AD4A1" />
    </svg>
  );
};

import { ShipmentName, PostShipping } from '../../../HemeIconLibrary';

export const addtoShipment = [
  {
    type: 'heading-only',
    initialValue: 'Create New Shipment',
    fluid: true,
  },
  {
    label: 'Shipment Name',
    icon: <ShipmentName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter ',
    name: 'name',
    fluid: true,
  },
  {
    type: 'heading-only',
    initialValue: 'OR',
    fluid: true,
  },
  {
    type: 'heading-only',
    initialValue: 'Add to Existing Shipment',
    fluid: true,
  },
  {
    label: 'Shipment Name',
    icon: <PostShipping />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select',
    name: 'shipmentId',
    fluid: true,
    options: [],
  },
];

import React from "react";

export const CustomField = () => {
  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88235 0.0571289C4.26635 1.06372 0 5.94401 0 11.1527C0 15.1247 1.95953 18.5827 5.64706 20.394V28.0939C5.64706 31.6028 8.49106 34.4468 12 34.4468C15.5089 34.4468 18.3529 31.6028 18.3529 28.0939V20.394C22.0405 18.5827 24 15.1254 24 11.1527C24 5.94401 19.7336 1.06372 14.1176 0.0571289V8.32919C14.1176 9.49883 13.1696 10.4468 12 10.4468C10.8304 10.4468 9.88235 9.49883 9.88235 8.32919V0.0571289ZM7.05882 4.31172V8.32919C7.05882 11.0581 9.27106 13.2704 12 13.2704C14.7289 13.2704 16.9412 11.0581 16.9412 8.32919V4.31172C19.4859 5.93102 21.1765 8.77085 21.1765 12.0737C21.1765 15.3038 18.8449 17.6826 15.5294 18.661V28.0953C15.5294 30.0442 13.9489 31.6247 12 31.6247C10.0511 31.6247 8.47059 30.0442 8.47059 28.0953V18.661C5.15506 17.6819 2.82353 15.3031 2.82353 12.0737C2.82353 8.77085 4.51412 5.93031 7.05882 4.31172ZM12 26.6821C11.22 26.6821 10.5882 27.3139 10.5882 28.0939C10.5882 28.8739 11.22 29.5057 12 29.5057C12.78 29.5057 13.4118 28.8739 13.4118 28.0939C13.4118 27.3139 12.78 26.6821 12 26.6821Z"
        fill="url(#paint0_linear_3377_48467)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3377_48467"
          x1="1.08965e-07"
          y1="17.252"
          x2="24"
          y2="17.252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

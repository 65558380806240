import React from 'react';

export const TabShipping = ({ purple, color, w, h }) => {
  return (
    <svg
      width={w || '32'}
      height={h || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2569 20.8606C23.2635 21.7572 22.7285 22.5691 21.902 22.9167C21.0756 23.2643 20.1211 23.079 19.4848 22.4474C18.8485 21.8157 18.6562 20.8626 18.9978 20.0337C19.3394 19.2047 20.1473 18.6638 21.0439 18.6637C21.6287 18.6616 22.1904 18.8918 22.6054 19.3038C23.0204 19.7158 23.2548 20.2758 23.2569 20.8606ZM23.2569 20.8606C23.8816 20.8579 24.4786 20.6072 24.9183 20.1635C25.3579 19.7199 25.6033 19.1197 25.6004 18.495L25.0577 15.5382H18.5712L18.5712 20.8607M18.5712 20.8607H12.7101M18.5712 20.8607H18.8298M18.5701 10.807H22.2798C22.9089 10.7945 23.5156 11.0405 23.9585 11.4875C24.4013 11.9346 24.6416 12.5436 24.6232 13.1726L25.0567 15.5382M18.5691 15.5382V9.99854C18.5647 9.39339 18.0708 8.90628 17.4656 8.9102H7.50692C7.21612 8.90751 6.93617 9.02045 6.72866 9.22418C6.52114 9.4279 6.40306 9.70572 6.40039 9.99652V19.7733C6.40306 20.0641 6.52114 20.342 6.72866 20.5457C6.93617 20.7494 7.21612 20.8624 7.50692 20.8597H8.28704M12.7132 20.8606C12.7197 21.7572 12.1847 22.5691 11.3583 22.9167C10.5318 23.2643 9.57733 23.079 8.94105 22.4474C8.30476 21.8157 8.11244 20.8626 8.45401 20.0337C8.79558 19.2047 9.60354 18.6638 10.5001 18.6637C11.0849 18.6616 11.6466 18.8918 12.0616 19.3038C12.4766 19.7158 12.711 20.2758 12.7132 20.8606Z"
        stroke={purple ? 'url(#paint0_linear_5498_186712)' : color || '#414753'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_186712"
          x1="8.63762"
          y1="17.8333"
          x2="23.4988"
          y2="18.1541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

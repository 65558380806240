import React from 'react';

export const ItemName = ({ purple }) => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95999 5.98677C3.66498 5.98677 4.22998 6.55727 4.22998 7.26727V8.97176C4.22998 9.67676 3.66498 10.2518 2.95999 10.2518H1.26999C0.569997 10.2518 0 9.67676 0 8.97176V7.26727C0 6.55727 0.569997 5.98677 1.26999 5.98677H2.95999ZM8.73001 5.98677C9.43 5.98677 10 6.55727 10 7.26727V8.97176C10 9.67676 9.43 10.2518 8.73001 10.2518H7.04002C6.33502 10.2518 5.77002 9.67676 5.77002 8.97176V7.26727C5.77002 6.55727 6.33502 5.98677 7.04002 5.98677H8.73001ZM2.95999 0.251953C3.66498 0.251953 4.22998 0.82695 4.22998 1.53245V3.23694C4.22998 3.94693 3.66498 4.51693 2.95999 4.51693H1.26999C0.569997 4.51693 0 3.94693 0 3.23694V1.53245C0 0.82695 0.569997 0.251953 1.26999 0.251953H2.95999ZM8.73001 0.251953C9.43 0.251953 10 0.82695 10 1.53245V3.23694C10 3.94693 9.43 4.51693 8.73001 4.51693H7.04002C6.33502 4.51693 5.77002 3.94693 5.77002 3.23694V1.53245C5.77002 0.82695 6.33502 0.251953 7.04002 0.251953H8.73001Z"
        fill="url(#paint0_linear_1986_60301)"
      />
      <defs>
        {purple ? (
          <linearGradient
            id="paint0_linear_1986_60301"
            x1="3"
            y1="8.00002"
            x2="13"
            y2="8.00002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#775FD5" />
            <stop offset="1" stopColor="#F03A85" />
          </linearGradient>
        ) : (
          <linearGradient
            id="paint0_linear_1986_60301"
            x1="4.54019e-08"
            y1="5.25185"
            x2="10"
            y2="5.25185"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFCB57" />
            <stop offset="1" stopColor="#FF7443" />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
};

import React from "react";
export const ExpireInventory = () => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2312 0C5.78064 0 0.53125 5.24939 0.53125 11.7C0.53125 17.6943 5.0655 22.6453 10.883 23.3161C10.9584 23.1198 11.0188 22.9193 11.1267 22.7347L11.5177 22.0644L17.9619 11.0144C18.5937 9.93153 19.723 9.2514 20.958 9.1279C21.0841 9.11491 21.205 9.07966 21.3337 9.07966C21.6236 9.07966 21.9049 9.12768 22.1818 9.19138C22.772 9.33438 23.3222 9.60762 23.7915 9.99372C22.9621 4.35044 18.102 0 12.2312 0ZM10.9312 5.19999H13.5312V12.2382L9.25037 16.5191L7.41209 14.6808L10.9312 11.1617V5.19999ZM21.3312 11.6797C20.895 11.6797 20.4598 11.8943 20.2089 12.3246L13.3713 24.0449C12.8656 24.912 13.4899 25.9999 14.4935 25.9999H28.1689C29.1725 25.9999 29.7968 24.912 29.2911 24.0449L22.4535 12.3246C22.2026 11.8943 21.7673 11.6797 21.3312 11.6797ZM20.0312 15.6H22.6312V20.7999H20.0312V15.6ZM20.0312 22.0999H22.6312V24.6999H20.0312V22.0999Z"
        fill="url(#paint0_linear_3263_64431)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_64431"
          x1="0.53125"
          y1="12.9999"
          x2="29.4704"
          y2="12.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

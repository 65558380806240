import { Fridge } from '../HemeIconLibrary/storageIcons/fridge';
import { Rack } from '../HemeIconLibrary/storageIcons/rack';
import { Shelve } from '../HemeIconLibrary/storageIcons/shelve';
import { Freezer } from '../HemeIconLibrary/storageIcons/freezer';
import { Room } from '../HemeIconLibrary/storageIcons/room';
import { Desk } from '../HemeIconLibrary/storageIcons/desk';
import { Almirah } from '../HemeIconLibrary/storageIcons/almirah';
import { Locker } from '../HemeIconLibrary/storageIcons/locker';

export const markItemInTree = (ob, key, value, source = null) => {
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return { found: false, data: obj };
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i][`${key}`] === value) {
          if (obj[i].expand === true && source === 'sidebar') {
            obj[i].expand = false;
          } else {
            obj[i].expand = true;
          }
          return { found: true, data: obj };
        }
        const result = keyExists(obj[i].children);
        if (result.found) {
          return { found: result.found, data: obj };
        }
      }
    }
    return { found: false, data: obj };
  };

  return keyExists(ob);
};

export const addPropertyToTree = (locationTree, newProperty, newValue) => {
  const tree = structuredClone(locationTree);
  const addProperty = (obj) => {
    if (obj.length === 0) {
      return;
    }
    for (let i = 0; i < obj.length; i++) {
      obj[i][`${newProperty}`] = newValue;
      addProperty(obj[i].children, newProperty, newValue);
    }
  };
  addProperty(tree);
  return tree;
};

export const createOptions = (data) => {
  let options = [];
  if (data.options) {
    options = data.options.trim();
    if (options.length) {
      options = options.split(',');
      options = options.map((ele) => {
        ele.trim();
        return {
          name: ele,
          value: ele,
        };
      });
      return options;
    } else {
      return [];
    }
  }
  return options;
};

export const getIcon = (iconName) => {
  const icons = {
    shelve: <Shelve />,
    rack: <Rack />,
    fridge: <Fridge />,
    freezer: <Freezer />,
    room: <Room />,
    desk: <Desk />,
    locker: <Locker />,
    almirah: <Almirah />,
  };

  if (iconName && iconName.length) {
    return icons[iconName];
  }
};

export const flattternTree = (members) => {
  let children = [];
  const flattenMembers = members.map((m) => {
    if (m.children && m.children.length) {
      children = [...children, ...m.children];
    }
    return m;
  });

  return flattenMembers.concat(children.length ? flattternTree(children) : children);
};

export const findPath = (ob, key, value) => {
  const path = [];
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return { found: false, data: obj };
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i][`${key}`] === value) {
          path.push({ name: obj[i][`${key}`], id: obj[i][`id`] });
          return { found: true, data: obj };
        }
        const result = keyExists(obj[i].children);
        if (result.found) {
          path.push({ name: obj[i][`${key}`], id: obj[i][`id`] });
          return { found: result.found, data: obj };
        }
      }
    }
    return { found: false, data: obj };
  };
  keyExists(ob);
  return path.reverse();
};

export const getFullName = (row) => {
  let name = [];
  if (row?.firstName) {
    name.push(row.firstName);
  }
  if (row?.lastName) {
    name.push(row.lastName);
  }
  return name.join(' ');
};

export const caseInsensitiveSort = (rowA, rowB) => {
  const a = rowA.name?.toLowerCase();
  const b = rowB.name?.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const caseInsensitiveSortStudy = (rowA, rowB) => {
  const a = rowA?.study?.name?.toLowerCase();
  const b = rowB?.study?.name?.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const caseInsensitiveSortItem = (rowA, rowB) => {
  const a = rowA?.item?.name?.toLowerCase();
  const b = rowB?.item?.name?.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const caseInsensitiveSortByFirtName = (rowA, rowB) => {
  const a = getFullName(rowA);
  const b = getFullName(rowB);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const nameArray = ['change_reason', 'changeReason'];
export const removeForms = [
  'mark-qc',
  'remove-shipping-items',
  'delete-shipping-items',
  'deleteItem',
  'deleteCustomField',
  'DeleteItemCategory',
  'deleteStorageLocation',
  'copy-document-template',
  'unlock Package',
  'delete-package',
  'delete-inventory',
  'expire-inventory',
  'delete-kit-config',
  'delete-document',
  'copy-assembly-LO',
  'deleteStorageLocation',
  'kit-assembly-preparation',
  'un-assign-custom-line',
  'delete-shipping-items',
  'shipping-assembly-preparation',
  'unlock Shioment',
  'editItemCategory',
  'additem',
  'approveQC',
  'copy-kit-template',
  'build-custom-line-kit',
  'remvove-item',
  'delete-email-template',
  'create-assembly',
  'add-new-country'
];

export const compareArrays = (obj1, obj2, formName, items) => {

  if (removeForms.includes(formName)) {
    return false;
  }

  if (!items?.length && formName === 'create-assembly') {
    return true;
  }
  const keys1 = Object.keys(obj1).filter((data) => !nameArray.includes(data));
  const keys2 = Object.keys(obj2).filter((data) => !nameArray.includes(data));
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const HemaDateFormat = (inputDate) => {
  if (inputDate) {
    const dateObj = new Date(inputDate);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const monthIndex = dateObj.getMonth();
    const year = dateObj.getFullYear();

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[monthIndex];
    if(year && month && day) {
    return `${day}-${month}-${year}`;
    } else {
      return inputDate
    }
  } else {
    return 'N/A';
  }
};

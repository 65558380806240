import React from 'react';

export const RejectRed = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5358_201154)">
        <circle cx="10" cy="10" r="10" fill="#E07C7B" />
        <rect x="8.5" y="3.39844" width="3" height="9" rx="0.5" fill="white" />
        <rect x="8.5" y="13.6016" width="3" height="3" rx="0.5" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_5358_201154">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from 'react';

export const CheckApprove = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1484 1.22204C21.8816 0.953022 21.5642 0.739492 21.2144 0.593778C20.8647 0.448064 20.4895 0.373047 20.1106 0.373047C19.7317 0.373047 19.3566 0.448064 19.0068 0.593778C18.6571 0.739492 18.3396 0.953022 18.0728 1.22204L8.63001 10.6935L4.92752 6.96234C4.65991 6.69473 4.34221 6.48245 3.99256 6.33762C3.64292 6.19279 3.26816 6.11825 2.88971 6.11825C2.51125 6.11825 2.1365 6.19279 1.78685 6.33762C1.4372 6.48245 1.11951 6.69473 0.8519 6.96234C0.584291 7.22995 0.37201 7.54764 0.227182 7.89729C0.0823529 8.24694 0.0078125 8.62169 0.0078125 9.00014C0.0078125 9.3786 0.0823529 9.75335 0.227182 10.103C0.37201 10.4526 0.584291 10.7703 0.8519 11.0379L6.5922 16.7782C6.85902 17.0473 7.17646 17.2608 7.52621 17.4065C7.87597 17.5522 8.25111 17.6272 8.63001 17.6272C9.0089 17.6272 9.38405 17.5522 9.7338 17.4065C10.0836 17.2608 10.401 17.0473 10.6678 16.7782L22.1484 5.29764C22.4174 5.03083 22.631 4.71339 22.7767 4.36363C22.9224 4.01388 22.9974 3.63873 22.9974 3.25984C22.9974 2.88095 22.9224 2.5058 22.7767 2.15605C22.631 1.80629 22.4174 1.48885 22.1484 1.22204Z"
        fill="url(#paint0_linear_3002_66046)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3002_66046"
          x1="0.0078126"
          y1="9.00013"
          x2="22.9974"
          y2="9.00013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';
export const LabelUnitType = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 2C2.6775 2 2 2.6775 2 3.5V5.5C2 6.3225 2.6775 7 3.5 7H4V9H3.5C2.673 9 2 9.673 2 10.5V12.5C2 13.327 2.673 14 3.5 14H5.5C6.327 14 7 13.327 7 12.5V12H9V12.5C9 13.3225 9.6775 14 10.5 14H12.5C13.3225 14 14 13.3225 14 12.5V10.5C14 9.6775 13.3225 9 12.5 9H12V7H12.5C13.3225 7 14 6.3225 14 5.5V3.5C14 2.6775 13.3225 2 12.5 2H10.5C9.6775 2 9 2.6775 9 3.5V4H7V3.5C7 2.6775 6.3225 2 5.5 2H3.5ZM3.5 3H5.5C5.78217 3 6 3.21783 6 3.5V4.41797C5.99118 4.47165 5.99118 4.5264 6 4.58008V5.5C6 5.78217 5.78217 6 5.5 6H3.5C3.21783 6 3 5.78217 3 5.5V3.5C3 3.21783 3.21783 3 3.5 3ZM10.5 3H12.5C12.7822 3 13 3.21783 13 3.5V5.5C13 5.78217 12.7822 6 12.5 6H11.582C11.5524 5.99491 11.5223 5.99252 11.4922 5.99284C11.4677 5.99344 11.4434 5.99583 11.4193 6H10.5C10.2178 6 10 5.78217 10 5.5V4.58203C10.0088 4.52835 10.0088 4.4736 10 4.41992V3.5C10 3.21783 10.2178 3 10.5 3ZM7 5H9V5.5C9 6.3225 9.6775 7 10.5 7H11V9H10.5C9.6775 9 9 9.6775 9 10.5V11H7V10.5C7 9.673 6.327 9 5.5 9H5V7H5.5C6.3225 7 7 6.3225 7 5.5V5ZM10.5 10H11.418C11.4716 10.0088 11.5264 10.0088 11.5801 10H12.5C12.7822 10 13 10.2178 13 10.5V12.5C13 12.7822 12.7822 13 12.5 13H10.5C10.2178 13 10 12.7822 10 12.5V11.582C10.0088 11.5284 10.0088 11.4736 10 11.4199V10.5C10 10.2178 10.2178 10 10.5 10Z"
        fill="url(#paint0_linear_1188_38757)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_38757"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

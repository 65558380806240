import React from "react";
export const Save = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_12994)">
        <path
          d="M15.7403 5.59113L10.4069 0.257797C10.325 0.17784 10.2284 0.114457 10.1225 0.0711301C10.0123 0.0263452 9.89477 0.00223959 9.77582 1.90735e-05H2.66471C1.95747 1.90735e-05 1.27919 0.280971 0.779096 0.781068C0.278999 1.28116 -0.00195312 1.95944 -0.00195312 2.66669V13.3334C-0.00195312 14.0406 0.278999 14.7189 0.779096 15.219C1.27919 15.7191 1.95747 16 2.66471 16H13.3314C14.0386 16 14.7169 15.7191 15.217 15.219C15.7171 14.7189 15.998 14.0406 15.998 13.3334V6.22224C15.9987 6.10526 15.9763 5.98929 15.9321 5.88099C15.8878 5.77269 15.8227 5.67419 15.7403 5.59113ZM5.33138 1.7778H8.88694V3.55557H5.33138V1.7778ZM10.6647 14.2222H5.33138V11.5556C5.33138 11.3198 5.42503 11.0937 5.59173 10.927C5.75843 10.7603 5.98452 10.6667 6.22027 10.6667H9.77582C10.0116 10.6667 10.2377 10.7603 10.4044 10.927C10.5711 11.0937 10.6647 11.3198 10.6647 11.5556V14.2222ZM14.2203 13.3334C14.2203 13.5691 14.1266 13.7952 13.9599 13.9619C13.7932 14.1286 13.5671 14.2222 13.3314 14.2222H12.4425V11.5556C12.4425 10.8483 12.1615 10.1701 11.6614 9.66996C11.1613 9.16986 10.4831 8.88891 9.77582 8.88891H6.22027C5.51303 8.88891 4.83475 9.16986 4.33465 9.66996C3.83455 10.1701 3.5536 10.8483 3.5536 11.5556V14.2222H2.66471C2.42897 14.2222 2.20287 14.1286 2.03617 13.9619C1.86948 13.7952 1.77582 13.5691 1.77582 13.3334V2.66669C1.77582 2.43094 1.86948 2.20485 2.03617 2.03815C2.20287 1.87145 2.42897 1.7778 2.66471 1.7778H3.5536V4.44446C3.5536 4.68021 3.64725 4.9063 3.81395 5.073C3.98065 5.2397 4.20674 5.33335 4.44249 5.33335H9.77582C10.0116 5.33335 10.2377 5.2397 10.4044 5.073C10.5711 4.9063 10.6647 4.68021 10.6647 4.44446V3.03113L14.2203 6.58669V13.3334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_12994">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

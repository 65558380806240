import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';

import { Button, FormSearch, HemaValue, Pagination, NoDataComponent } from '../../../utils';
import { ExpiryAlertPurple, DeletePurple, Edit, DeleteColor, EditColor, Confirm, TableSortIcon } from '../../../HemeIconLibrary';
import { sortedData } from '../../../helpers/sort';
import { companyLocationField } from '../../../components/Formik/AllForms/companyLocationField';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { filter } from 'smart-array-filter';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../../../Store/reducers/uiSettings';

// Actions
import { getCompanyLocationAction, updateCompanyLocationAction, deleteCompanyLocationAction } from '../../../Actions/settings';

const CompanyLocationPage = ({ setCTA, setformName, setFormIcon, setUpdatedData, setokBtnText, setokBtnIcon, setCancelBtnText }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);

  useEffect(() => {
    getCompanyLocationAction();
  }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  //search for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(settings?.companyLocations, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(settings?.companyLocations);
      }
    })();
  }, [searchQuery, settings?.companyLocations]);

  const editCompanyLocation = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const payload = {
        name: data.name,
        iconName: data.itemCategoryId,
        changeReason: data.changeReason,
      };
      const resp = await updateCompanyLocationAction(row.id, payload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${row?.name} edited.`));
        getCompanyLocationAction();
      }
    });
  };

  const deleteCompanyLocation = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deleteCompanyLocationAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`${row.name} company location deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>
        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 ? (
          <DataTable
            data={dataList}
            className="border-t border-[#DEE2E6] mt-[10px] rounded-t-none managment-table-st"
            columns={[
              {
                name: (
                  <div className="w-fit flex items-center gap-1 py-[8px] px-[16px]">
                    <HemaValue text={'Flag'} color="text-[#000000]" className="text-xs font-normal" />
                    <span className="short-icon">
                      <TableSortIcon />
                    </span>
                  </div>
                ),
                sortable: true,
                selector: (row) => <img width={20} height={20} alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.iconName}.svg`} />,
                sortId: 'iconName',
              },
              {
                name: (
                  <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                    <HemaValue text={'Company Location'} color="text-[#000000]" className="text-xs font-normal" />
                    <span className="short-icon">
                      <TableSortIcon />
                    </span>
                  </div>
                ),
                sortable: true,
                selector: (row) => <HemaValue text={row.name} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'name',
              },
              {
                name: <HemaValue text={'Actions'} color="text-[#000000]" className="text-xs font-normal pr-7" />,
                cell: (row) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.['Company Locations']?.update && (
                          <Button
                            icon={<Edit color="#775FD5" />}
                            color="text-white"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              setformName('Edit Company Location');
                              setFormIcon(<ExpiryAlertPurple />);
                              setokBtnIcon(<Confirm />);
                              setCancelBtnText('Cancel');
                              setokBtnText('Update');
                              companyLocationField[0].initialValue = row.name;
                              companyLocationField[1].initialValue = row.iconName;
                              companyLocationField[1].value = row.iconName;

                              setUpdatedData(companyLocationField);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'add-company-location',
                                }),
                              );
                              editCompanyLocation(row);
                            }}
                          />
                        )}
                        {common?.permissions?.['Company Locations']?.delete && (
                          <Button
                            icon={<DeleteColor />}
                            color="text-white"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              Remove[0].label = 'Company Location';
                              Remove[0].initialValue = row?.name;
                              setUpdatedData(Remove);
                              setokBtnIcon(<Confirm />);
                              setCancelBtnText('Cancel')
                              setokBtnText('Confirm');
                              setformName('Delete Company Location');
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              );
                              deleteCompanyLocation(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            There is no data found.
          </BootstrapAlert>
        )}
      </>
    </div>
  );
};

export default CompanyLocationPage;

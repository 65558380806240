import React, { useState, forwardRef, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Formik } from "formik";
import DataTable from "react-data-table-component";

import { useSelector, useDispatch } from "react-redux";
import { sortedData } from "../../helpers/sort";
import { HemaLabel, HemaValue, FormSelect, FormText, FormTextarea, Pagination } from "../../utils";

import { AllTasks, CrossIcon, Status, ChangeReason, TabAdd } from "../../HemeIconLibrary";
import Heading from "../logistOrchestra/logisticComponents/heading";
import { createNewTaskAction } from "../../Actions/tasks";
import { showSuccessReducer } from "../../Store/reducers/uiSettings";

import SubjectImg from "../../assets/images/subject.svg";
import userIcon from "../../assets/images/userIcon.svg";

const TaskAssignment = forwardRef((props, ref) => {
  const { settings, orders, tasks, common } = useSelector((state) => state);

  const [subtab, setsubTab] = useState("CreateNew");

  const [allTasks, setAllTasks] = useState(tasks?.allTasks);

  useEffect(() => {
    if (tasks?.allTasks) {
      setAllTasks(tasks?.allTasks);
    }
  }, [tasks?.allTasks]);

  const dispatch = useDispatch();
  const { setLoaderAssignment } = props;
  return (
    <div className="w-full bg-white rounded-[5px] mb-[13px] inventory-tabs pb-[20px]">
      <Tabs
        activeKey={subtab}
        id="controlled-tab-example"
        className="mb-4"
        onSelect={(key) => {
          setsubTab(key);
        }}
      >
        {common?.permissions?.["Kit Order Process"]?.update && (
          <Tab
            eventKey="CreateNew"
            title={
              <div className="flex items-center title-icon gap-[7px]">
                {subtab === "CreateNew" ? <TabAdd purple /> : <TabAdd />}
                Create New
              </div>
            }
          >
            <div className="w-full px-[16px]">
              <div className="flex justify-between w-full mb-[21px]">
                <Heading heading="Shipments" buttonData={undefined} />
              </div>

              <div className="w-full">
                <Formik
                  initialValues={{
                    title: "",
                    body: "",
                    assignedTo: "",
                    statusId: "",
                  }}
                  innerRef={ref}
                  validate={(values) => {
                    const errors = {};

                    if (!values.title) {
                      errors.title = "Required";
                    }

                    if (!values.body) {
                      errors.body = "Required";
                    }
                    if (!values.assignedTo) {
                      errors.assignedTo = "Required";
                    }

                    if (!values.statusId) {
                      errors.statusId = "Required";
                    }

                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setLoaderAssignment(true);
                    const result = await createNewTaskAction({
                      ...values,
                      recordId: orders.activeOrder?.id,
                    });
                    setLoaderAssignment(false);
                    if (result.status === 200) {
                      setsubTab("AllTasks");
                      dispatch(showSuccessReducer("new task created successfully."));
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    validateForm,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="pr-4">
                        <div className="flex items-start gap-[16px] mb-[16px]">
                          <div className={`w-full `}>
                            <HemaLabel text="Title" required className="mb-[10px]" textSize="text-[12px]" color="#454545" icon={<img src={SubjectImg} alt="subject" />} />

                            <FormText
                              type="text"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              value={values.title}
                              placeholder={"Enter title"}
                              crossIcon={values.title ? <CrossIcon /> : ""}
                            />

                            {errors.title && touched.title && <div className="error text-[red] text-[12px] pt-[2px]">{errors.title}</div>}
                          </div>
                        </div>

                        <div className=" gap-[15px] items-center w-full block mb-[16px]">
                          <HemaLabel text="Text" icon={<ChangeReason />} required={true} textSize="text-[12px]" color="#454545" className={`mb-[10px]`} />

                          <FormTextarea placeholder="Type reason" onChange={handleChange} onBlur={handleBlur} name={"body"} value={values?.body || ""} />

                          {errors.body && touched.body && <div className="error text-[red] text-[12px] pt-[2px]">{errors.body}</div>}
                        </div>

                        <div className="flex items-start gap-[16px] mb-[16px]">
                          <div className="w-full">
                            <HemaLabel text="Assign To" className="mb-[10px] " textSize="text-[12px]" color="#454545" icon={<img src={userIcon} alt="subject" />} required />
                            <FormSelect
                              formikRef={ref}
                              options={
                                settings?.systemUsers?.map((data) => {
                                  return {
                                    id: data.id,
                                    name: data.firstName + " " + data.lastName,
                                  };
                                }) || []
                              }
                              placeholder={"Select"}
                              setFieldValue={setFieldValue}
                              name={"assignedTo"}
                              value={values?.assignedTo}
                              crossIcon={values?.assignedTo ? <CrossIcon /> : ""}
                            />
                            {errors.assignedTo && touched.assignedTo && <div className="error text-[red] text-[12px] pt-[2px]">{errors.assignedTo}</div>}
                          </div>
                          <div className={`w-full `}>
                            <HemaLabel text="Status" className="mb-[10px]" required={true} textSize="text-[12px]" color="#454545" icon={<Status />} />

                            <FormSelect
                              formikRef={ref}
                              options={tasks?.statuses}
                              placeholder={"Select"}
                              setFieldValue={setFieldValue}
                              name={"statusId"}
                              value={values?.statusId}
                              crossIcon={values?.statusId ? <CrossIcon /> : ""}
                            />

                            {errors.statusId && touched.statusId && <div className="error text-[red] text-[12px] pt-[2px]">{errors.statusId}</div>}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </Tab>
        )}
        <Tab
          eventKey="AllTasks"
          title={
            <div className="flex items-center title-icon gap-[7px] ">
              {subtab === "AllTasks" ? <AllTasks purple /> : <AllTasks />}
              All Tasks
            </div>
          }
        >
          <div className="mb-[18px] px-[16px]">
            <Heading heading="All Tasks" border buttonData={undefined} />
          </div>
          <div className="border-t-[1px] border-b-[1px] border-[#DEE2E6]">
            <DataTable
              data={allTasks || []}
              columns={[
                {
                  name: <HemaValue text={"Title"} color="text-[#000000]" className="font-normal  text-xs leading-[18px]" />,
                  sortable: true,
                  selector: (row) => <HemaValue className="text-xs leading-[18px]" text={row?.title} />,
                  sortId: "title",
                },
                {
                  name: <HemaValue text={"created By"} color="text-[#000000]" className="font-normal  text-xs leading-[18px]" />,
                  sortable: true,
                  selector: (row) => <HemaValue className=" text-xs leading-[18px]" text={row?.createdUser?.name} />,
                  sortId: "createdUser.name",
                },
                {
                  name: <HemaValue text={"Assigned To"} color="text-[#000000]" className="font-normal  text-xs leading-[18px]" />,
                  sortable: true,
                  selector: (row) => <HemaValue className=" text-xs leading-[18px]" text={row?.assignedUser?.name} />,
                  sortId: "assignedUser.name",
                },
                {
                  name: <HemaValue text={"Status"} color="text-[#000000]" className="font-normal  text-xs leading-[18px]" />,
                  sortable: true,
                  selector: (row) => (
                    <div
                      className={`w-fit rounded-full px-3 py-1  flex items-center justify-center gap-1 ${
                        (row?.status?.name === "New" && "bg-[#D6D7FF]") ||
                        (row?.status?.name === "Active" && "bg-[#fbe8db]") ||
                        (row?.status?.name === "Resolved" && "bg-[#fcdaee]") ||
                        (row?.status?.name === "Completed" && "bg-[#e1ffee]") ||
                        "bg-[#D6D7FF]"
                      } `}
                    >
                      <div
                        className={`w-2 h-2 rounded-full flex-shrink-0 ${
                          (row?.status?.name === "New" && "bg-[#4868DA]") ||
                          (row?.status?.name === "Active" && "bg-[#F97316]") ||
                          (row?.status?.name === "Resolved" && "bg-[#C92286]") ||
                          (row?.status?.name === "Completed" && "bg-[#065528]") ||
                          "bg-[#4868DA]"
                        } `}
                      />
                      <HemaValue
                        color={
                          (row?.status?.name === "New" && "text-[#4868DA]") ||
                          (row?.status?.name === "Active" && "text-[#F97316]") ||
                          (row?.status?.name === "Resolved" && "text-[#C92286]") ||
                          (row?.status?.name === "Completed" && "text-[#065528]") ||
                          "text-[#4868DA]"
                        }
                        className="text-xs leading-[18px]"
                        text={row?.status?.name || "New"}
                      />
                    </div>
                  ),
                  sortId: "status.name",
                },
              ]}
              onSort={(row, direction, sorted) => {
                setAllTasks(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
              }}
              pagination={true}
              paginationComponent={(e) => {
                return <Pagination e={e} />;
              }}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
});

export default TaskAssignment;

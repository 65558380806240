import React from "react";
export const Next = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.76933 11.4424C8.3694 11.8233 8.35396 12.4562 8.73485 12.8562C9.11573 13.2561 9.74871 13.2715 10.1486 12.8906L8.76933 11.4424ZM14.5236 8.72398C14.9236 8.34309 14.939 7.71011 14.5581 7.31018C14.1772 6.91025 13.5443 6.89481 13.1443 7.2757L14.5236 8.72398ZM13.1443 8.72398C13.5443 9.10486 14.1772 9.08942 14.5581 8.68949C14.939 8.28956 14.9236 7.65659 14.5236 7.2757L13.1443 8.72398ZM10.1486 3.10903C9.74871 2.72815 9.11573 2.74359 8.73485 3.14352C8.35396 3.54345 8.3694 4.17642 8.76933 4.55731L10.1486 3.10903ZM13.834 8.99984C14.3863 8.99984 14.834 8.55212 14.834 7.99984C14.834 7.44755 14.3863 6.99984 13.834 6.99984V8.99984ZM2.16732 6.99984C1.61503 6.99984 1.16732 7.44755 1.16732 7.99984C1.16732 8.55212 1.61503 8.99984 2.16732 8.99984V6.99984ZM10.1486 12.8906L14.5236 8.72398L13.1443 7.2757L8.76933 11.4424L10.1486 12.8906ZM14.5236 7.2757L10.1486 3.10903L8.76933 4.55731L13.1443 8.72398L14.5236 7.2757ZM13.834 6.99984L2.16732 6.99984V8.99984L13.834 8.99984V6.99984Z"
        fill="white"
      />
    </svg>
  );
};

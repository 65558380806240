import React from 'react';

export const Telephone = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17921 3.5616C3.87145 2.83238 4.11609 2.62786 4.41108 2.53267C4.61523 2.47918 4.82933 2.47621 5.03489 2.52402C5.30549 2.60269 5.37707 2.66247 6.2644 3.54665C7.04396 4.32306 7.12184 4.40723 7.19657 4.55906C7.33946 4.82555 7.36179 5.14037 7.25793 5.42436C7.17926 5.64068 7.06756 5.78385 6.59479 6.25819L6.28643 6.56734C6.20545 6.64964 6.18642 6.77461 6.23923 6.87728C6.92434 8.04611 7.89634 9.02082 9.06326 9.70918C9.19758 9.78109 9.36299 9.75787 9.47231 9.65175L9.76888 9.35991C9.95217 9.17202 10.1463 8.99499 10.3502 8.82971C10.6705 8.63306 11.0696 8.61552 11.4059 8.7833C11.5703 8.86197 11.6246 8.91074 12.4285 9.71311C13.2576 10.5399 13.2812 10.5658 13.3725 10.7554C13.5442 11.0692 13.5424 11.4493 13.3677 11.7615C13.2789 11.9377 13.2246 12.0014 12.7581 12.4781C12.4765 12.7661 12.2114 13.0288 12.1689 13.0673C11.7841 13.386 11.2904 13.5423 10.7923 13.5031C9.881 13.4201 8.99896 13.1384 8.20818 12.6779C6.45645 11.7499 4.96519 10.3976 3.87066 8.74476C3.63227 8.39885 3.42032 8.03544 3.23663 7.65762C2.74387 6.81306 2.4894 5.85066 2.50034 4.87292C2.53796 4.36142 2.78326 3.88759 3.17921 3.5616Z"
        stroke="url(#paint0_linear_2850_43017)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_43017"
          x1="2.5"
          y1="7.99963"
          x2="13.5"
          y2="7.99963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
export const AddRegion = ({ width, height }) => {
  return (
    <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4976 2C9.01261 2 6.99761 4.015 6.99761 6.5C6.99761 9.044 9.55502 12.2276 10.814 13.6406C11.178 14.0496 11.8172 14.0496 12.1812 13.6406C13.4402 12.2276 15.9976 9.044 15.9976 6.5C15.9976 4.015 13.9826 2 11.4976 2ZM11.4976 5C12.3256 5 12.9976 5.672 12.9976 6.5C12.9976 7.328 12.3256 8 11.4976 8C10.6696 8 9.99761 7.328 9.99761 6.5C9.99761 5.672 10.6696 5 11.4976 5ZM18.5347 5.86719C18.2133 5.93323 17.9572 6.21331 17.9527 6.57031C17.9487 6.91631 17.9354 7.24461 17.9214 7.34961C17.9104 7.41961 17.9012 7.48859 17.8882 7.55859C17.2342 11.3566 13.3877 15.3131 12.9117 15.7891C12.1307 16.5701 10.8645 16.5701 10.0835 15.7891C9.70355 15.4091 7.17555 12.8126 5.83355 9.84961V9.84766C5.65355 9.44866 5.49475 9.04472 5.36675 8.63672C5.23675 8.22272 4.70658 8.1145 4.41558 8.4375C3.28958 9.6865 2.33881 10.9416 2.15581 11.3086C1.87481 11.8736 1.98686 12.2801 2.15386 12.5371L4.99761 17.2773V19C4.99761 19.389 5.22955 19.7462 5.58355 19.9102C8.99855 21.4912 10.4082 22.0547 11.0972 22.0547C11.5182 22.0547 11.6712 21.8433 11.8472 21.5273C12.0202 21.2773 12.7722 20.4623 13.2222 20.0703C13.7432 20.3033 14.7363 20.8767 15.2593 21.1777C16.2153 21.7287 16.5312 21.9058 16.8082 21.9688C17.6812 22.1628 18.9398 21.3132 19.6148 20.7852C20.3478 20.2112 19.9347 19.4883 19.4117 18.5723C19.3167 18.4053 19.1835 18.1681 19.0875 17.9961C19.3875 17.5691 20.0665 16.791 20.5035 16.291C21.8865 14.707 22.4036 14.114 21.7046 13.293L20.3589 11.9473C21.5599 10.5373 21.59 10.4365 21.65 10.2305C21.948 9.20847 22.4985 7.3277 20.9585 6.5957C20.7355 6.4607 20.2287 6.23895 18.8707 5.87695C18.7557 5.8462 18.6419 5.84517 18.5347 5.86719ZM7.99761 16C8.54961 16 8.99761 16.448 8.99761 17C8.99761 17.552 8.54961 18 7.99761 18C7.44561 18 6.99761 17.552 6.99761 17C6.99761 16.448 7.44561 16 7.99761 16Z"
        fill="url(#paint0_linear_2903_44876)"
      />
      <defs>
        <linearGradient id="paint0_linear_2903_44876" x1="1.98438" y1="12.0273" x2="22.0148" y2="12.0273" gradientUnits="userSpaceOnUse">
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';
export const LabelCategory = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44727 1.25V2.67105H5.15779V11.9079C5.15779 13.4692 6.43863 14.75 7.9999 14.75C9.56116 14.75 10.842 13.4692 10.842 11.9079V2.67105H11.5525V1.25H4.44727ZM6.57884 2.67105H9.42095V5.51316H6.57884V2.67105ZM6.57884 6.93421H9.42095V9.06579V11.9079C9.42095 12.7013 8.79332 13.3289 7.9999 13.3289C7.25415 13.3289 6.66205 12.7721 6.59272 12.0467C6.62482 12.2078 6.71178 12.3529 6.8388 12.4571C6.96582 12.5614 7.12505 12.6184 7.28937 12.6184C7.47781 12.6184 7.65854 12.5436 7.79179 12.4103C7.92504 12.2771 7.9999 12.0963 7.9999 11.9079C7.9999 11.7195 7.92504 11.5387 7.79179 11.4055C7.65854 11.2722 7.47781 11.1974 7.28937 11.1974C7.10093 11.1974 6.9202 11.2722 6.78695 11.4055C6.6537 11.5387 6.57884 11.7195 6.57884 11.9079V6.93421ZM9.42095 9.06579C9.42095 8.87735 9.34609 8.69662 9.21284 8.56337C9.07959 8.43012 8.89887 8.35526 8.71042 8.35526C8.52198 8.35526 8.34126 8.43012 8.20801 8.56337C8.07476 8.69662 7.9999 8.87735 7.9999 9.06579C7.9999 9.25423 8.07476 9.43496 8.20801 9.56821C8.34126 9.70146 8.52198 9.77632 8.71042 9.77632C8.89887 9.77632 9.07959 9.70146 9.21284 9.56821C9.34609 9.43496 9.42095 9.25423 9.42095 9.06579Z"
        fill="url(#paint0_linear_1188_38723)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_38723"
          x1="4.44727"
          y1="7.99999"
          x2="11.5525"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

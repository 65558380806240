import React from 'react';

export const AllTasks = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4788 8.80078H13.8388C13.6479 8.80078 13.4647 8.87664 13.3297 9.01166C13.1947 9.14669 13.1188 9.32983 13.1188 9.52078V12.4008H11.6788C11.4879 12.4008 11.3047 12.4766 11.1697 12.6117C11.0347 12.7467 10.9588 12.9298 10.9588 13.1208V16.0008H9.51883C9.32787 16.0008 9.14474 16.0766 9.00971 16.2117C8.87469 16.3467 8.79883 16.5298 8.79883 16.7208V22.4808C8.79883 22.6717 8.87469 22.8549 9.00971 22.9899C9.14474 23.1249 9.32787 23.2008 9.51883 23.2008H15.2788C15.4698 23.2008 15.6529 23.1249 15.7879 22.9899C15.923 22.8549 15.9988 22.6717 15.9988 22.4808V21.0408H18.8788C19.0698 21.0408 19.2529 20.9649 19.3879 20.8299C19.523 20.6949 19.5988 20.5117 19.5988 20.3208V18.8808H22.4788C22.6698 18.8808 22.8529 18.8049 22.9879 18.6699C23.123 18.5349 23.1988 18.3517 23.1988 18.1608V9.52078C23.1988 9.32983 23.123 9.14669 22.9879 9.01166C22.8529 8.87664 22.6698 8.80078 22.4788 8.80078ZM14.5588 21.7608H10.2388V17.4408H14.5588V21.7608ZM18.1588 19.6008H15.9988V16.7208C15.9988 16.5298 15.923 16.3467 15.7879 16.2117C15.6529 16.0766 15.4698 16.0008 15.2788 16.0008H12.3988V13.8408H18.1588V19.6008ZM21.7588 17.4408H19.5988V13.1208C19.5988 12.9298 19.523 12.7467 19.3879 12.6117C19.2529 12.4766 19.0698 12.4008 18.8788 12.4008H14.5588V10.2408H21.7588V17.4408Z"
        fill={purple ? 'url(#paint1_linear_5498_185711)' : ' #414753'}
      />
      <defs>
        <linearGradient
          id="paint1_linear_5498_185711"
          x1="10.4767"
          y1="17.8625"
          x2="21.625"
          y2="18.0402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import {
  Address,
  Barcode,
  Country,
  LabelCategory,
  LabelName,
  Location,
  Recipient,
  StudyName,
} from "../../../HemeIconLibrary";

export const ProformaInvoice = [
  {
    label: "",
    initialValue: "Fill the details",
    fluid: true,
    type: "heading-only",
  },
  {
    label: "Study/Protocol Name",
    icon: <StudyName />,
    type: "text",
    initialValue: "",
    placeholder: "Protocol Name",
    fluid: true,
    name: "name",
  },
  {
    label: "Shipper Address",
    icon: <Address purple />,
    type: "textarea",
    initialValue: "",
    placeholder: "Shipper Address",
    fluid: true,
    name: "shipper",
  },
  {
    label: "Broker",
    icon: <Address purple />,
    type: "textarea",
    initialValue: "",
    fluid: true,
    placeholder: "Broker",
    required: false,
    name: "Broker",
  },
  {
    label: "Importer of Record",
    icon: <Location purple />,
    type: "textarea",
    initialValue: "",
    placeholder: "Importer of Record",
    fluid: true,
    name: "importer",
  },

  {
    label: "Recipient",
    icon: <Address purple />,
    type: "textarea",
    initialValue: "",
    fluid: true,
    placeholder: "Recipient",
    required: true,
    name: "respient",
  },
  {
    label: "Kit Contents",
    icon: <LabelName />,
    type: "tabeDetails",
    initialValue: "",
    fluid: true,
    placeholder: "kit content table",
    required: true,
    name: "items",
  },
  {
    label: "HS/HTS Code",
    icon: <Barcode purple />,
    type: "text",
    initialValue: "",
    placeholder: "HS/HTS Code",
    required: false,
    fluid: true,
    name: "htsCode",
  },
  {
    label: "Country of Origin",
    icon: <Country purple />,
    type: "text",
    initialValue: "",
    placeholder: "Select country",
    required: true,
    fluid: true,
    options: [],
    name: "country",
  },
];

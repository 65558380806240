import React from 'react';

export const LOIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4235 11.7515L16.8717 8.01527M14.5374 11.8323L16.4121 15.9144M14.3388 11.8763L10.0158 11.5305M17.619 8.10323L20.555 7.39546C21.1631 7.24888 21.5422 6.61534 21.4018 5.98041L20.724 2.91472C20.5836 2.2798 19.9769 1.88392 19.3688 2.0305L16.4328 2.73827C15.8247 2.88486 15.4456 3.5184 15.586 4.15332L16.2638 7.21901C16.4042 7.85394 17.0109 8.24982 17.619 8.10323ZM15.9655 16.5461L15.0845 19.555C14.902 20.1781 15.2379 20.8377 15.8347 21.0282L18.7163 21.9481C19.3131 22.1386 19.9448 21.7879 20.1273 21.1648L21.0083 18.1559C21.1907 17.5328 20.8548 16.8732 20.258 16.6826L17.3765 15.7627C16.7797 15.5722 16.1479 15.9229 15.9655 16.5461ZM9.71516 10.8108L7.66013 8.50977C7.23452 8.0332 6.5195 8.00713 6.06309 8.45153L3.85934 10.5973C3.40292 11.0417 3.37796 11.7883 3.80357 12.2649L5.8586 14.566C6.28421 15.0426 6.99923 15.0686 7.45564 14.6242L9.65939 12.4784C10.1158 12.034 10.1408 11.2874 9.71516 10.8108Z"
        stroke="url(#paint0_linear_9398_117558)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9398_117558"
          x1="3.5"
          y1="12"
          x2="21.431"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { LabelName, ExpiryNotification, NameKit, Icon, TickOrange, ChangeReason, CheckReady } from "../../../HemeIconLibrary";

export const createNewSponser = [
  {
    label: "Sponsor Name",
    icon: <NameKit />,
    type: "text",
    initialValue: "",
    placeholder: "Enter sponsor name",
    required: true,
    name: "name",
    fluid: true,
  },
  {
    label: "Sponsor Abbreviation",
    icon: <Icon />,
    type: "text",
    initialValue: "",
    placeholder: "Enter sponsor abbreviation",
    required: true,
    name: "abbreviation",
    fluid: true,
  },
  {
    name: "isActive",
    label: "Active",
    icon: <CheckReady purple />,
    type: "switch",
    initialValue: true,
    placeholder: "",
  },
  {
    name: "changeReason",
    label: "Change Reason",
    icon: <ChangeReason />,
    type: "textarea",
    fluid: true,
    initialValue: "",
    required: true,
    placeholder: "Type Reason",
  },
];

import React from "react";
export const TranslateIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9999 15.5769L12.6922 6.96155L8.38452 15.5769"
        stroke="#775FD5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61548 13.1154H15.7693"
        stroke="#775FD5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9231 1.4231V3.26925"
        stroke="#775FD5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3.26929H10.8462"
        stroke="#775FD5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.38462 3.26929C8.38462 5.22781 7.6066 7.10611 6.22171 8.491C4.83683 9.87588 2.95852 10.6539 1 10.6539M4.13188 6.34626C4.72157 7.63115 5.66765 8.7198 6.85775 9.48292C8.04784 10.246 9.43196 10.6516 10.8457 10.6513"
        stroke="#775FD5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

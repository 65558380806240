import React from 'react';

export const StudyAlias = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 0.369141C0.604625 0.369141 0 0.973765 0 1.70247V3.03581C0 3.76452 0.604625 4.36914 1.33333 4.36914H10.6667C11.3954 4.36914 12 3.76452 12 3.03581V1.70247C12 0.973765 11.3954 0.369141 10.6667 0.369141H1.33333ZM1.33333 1.70247H10.6667V3.03581H1.33333V1.70247ZM1.33333 5.70247C0.604625 5.70247 0 6.3071 0 7.03581V11.0358C0 11.7645 0.604625 12.3691 1.33333 12.3691H5.33333C6.06204 12.3691 6.66667 11.7645 6.66667 11.0358V7.03581C6.66667 6.3071 6.06204 5.70247 5.33333 5.70247H1.33333ZM9.33333 5.70247C8.60462 5.70247 8 6.3071 8 7.03581V11.0358C8 11.7645 8.60462 12.3691 9.33333 12.3691H10.6667C11.3954 12.3691 12 11.7645 12 11.0358V7.03581C12 6.3071 11.3954 5.70247 10.6667 5.70247H9.33333ZM1.33333 7.03581H5.33333V11.0358H1.33333V7.03581ZM9.33333 7.03581H10.6667V11.0358H9.33333V7.03581Z"
        fill="url(#paint0_linear_1986_52038)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1986_52038"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
